var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "countsheet" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { loading: _vm.processing } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "d-flex align-center justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background"
            },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "fd-badge-radio-group",
                  attrs: { row: "" },
                  model: {
                    value: _vm.selectionType,
                    callback: function($$v) {
                      _vm.selectionType = $$v
                    },
                    expression: "selectionType"
                  }
                },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "primary",
                        value:
                          !!_vm.pendingCountSheets &&
                          _vm.pendingCountSheets.length > 0,
                        content: _vm.pendingCountSheets.length
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t(
                            "countsheet.list.selection-type-pending"
                          ),
                          value: "pending",
                          "data-cy": "radiopending"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "primary",
                        value:
                          !!_vm.declinedCountSheets &&
                          _vm.declinedCountSheets.length > 0,
                        content: _vm.declinedCountSheets.length
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t(
                            "countsheet.list.selection-type-declined"
                          ),
                          value: "declined",
                          "data-cy": "radiodeclined"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("countsheet.list.selection-type-approved"),
                      value: "approved",
                      "data-cy": "radioapproved"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      class: ["pt-0", "pb-5"],
                      attrs: {
                        cols: "12",
                        sm: _vm.selectionTypeIsApproved ? 6 : 12,
                        md: _vm.selectionTypeIsApproved ? 9 : 12
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                "data-cy": "reload",
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a",
                                disabled: _vm.processing
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                mdi-reload\n              "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.selectionTypeIsApproved
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0 order-sm-2 order-1",
                          attrs: { cols: "12", sm: "6", md: "3" }
                        },
                        [
                          _c("fd-date-range-picker", {
                            attrs: {
                              dense: "",
                              min: _vm.minDate,
                              max: _vm.maxDate,
                              loading: _vm.processing,
                              disabled: _vm.processing,
                              "hide-details": "",
                              outlined: false,
                              presets: _vm.dateRangePresetOptions
                            },
                            on: {
                              "input:fromDate": _vm.fromDateChanged,
                              "input:toDate": _vm.toDateChanged
                            },
                            model: {
                              value: _vm.showArchivedDateRange,
                              callback: function($$v) {
                                _vm.showArchivedDateRange = $$v
                              },
                              expression: "showArchivedDateRange"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:scaffoldNumber",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("countsheet.list.scaffold-number-column-label")
                  : _vm.$t(
                      "countsheet.list.scaffold-number-column-label-short"
                    ),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('countsheet.list.scaffold-number-column-label')\n          : $t('countsheet.list.scaffold-number-column-label-short')\n      ",
                arg: "scaffoldNumber"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:legacyWorkOrderID",
                value: _vm.$t("countsheet.list.work-order-column-label"),
                expression: "$t('countsheet.list.work-order-column-label')",
                arg: "legacyWorkOrderID"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:requestTypeName.hide-when-extra-small",
                value: _vm.$t("countsheet.list.request-type-column-label"),
                expression:
                  "\n        $t('countsheet.list.request-type-column-label')\n      ",
                arg: "requestTypeName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:workOrderStatusName.hide-when-medium",
                value: _vm.$t("countsheet.list.work-order-status-column-label"),
                expression:
                  "\n        $t('countsheet.list.work-order-status-column-label')\n      ",
                arg: "workOrderStatusName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:transferIDsList.hide-when-small.hide-when-header-text-empty",
                value: _vm.selectionTypeIsApproved
                  ? _vm.$t("countsheet.list.transfer-ids-column-label")
                  : "",
                expression:
                  "\n        selectionTypeIsApproved ? $t('countsheet.list.transfer-ids-column-label') : ''\n      ",
                arg: "transferIDsList",
                modifiers: {
                  "hide-when-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:submittedByName.hide-when-extra-small",
                value: _vm.$t("countsheet.list.submitter-column-label"),
                expression:
                  "\n        $t('countsheet.list.submitter-column-label')\n      ",
                arg: "submittedByName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:submittedByContractorName.hide-when-small",
                value: _vm.$t("countsheet.list.contractor-column-label"),
                expression:
                  "\n        $t('countsheet.list.contractor-column-label')\n      ",
                arg: "submittedByContractorName",
                modifiers: { "hide-when-small": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:reviewStatusName.hide-when-small.hide-when-header-text-empty",
                value: _vm.selectionTypeIsApproved
                  ? ""
                  : _vm.$t("countsheet.list.status-column-label"),
                expression:
                  "\n        selectionTypeIsApproved ? '' : $t('countsheet.list.status-column-label')\n      ",
                arg: "reviewStatusName",
                modifiers: {
                  "hide-when-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:formattedReviewStatusChangeTime",
                value: _vm.selectionTypeIsApproved
                  ? _vm.$t("countsheet.list.approved-time-column-label")
                  : _vm.$t("countsheet.list.status-change-time-column-label"),
                expression:
                  "\n        selectionTypeIsApproved\n          ? $t('countsheet.list.approved-time-column-label')\n          : $t('countsheet.list.status-change-time-column-label')\n      ",
                arg: "formattedReviewStatusChangeTime"
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:reviewApprovedByName.hide-when-small.hide-when-header-text-empty",
                value: _vm.selectionTypeIsApproved
                  ? _vm.$t("countsheet.list.approved-by-column-label")
                  : "",
                expression:
                  "\n        selectionTypeIsApproved ? $t('countsheet.list.approved-by-column-label') : ''\n      ",
                arg: "reviewApprovedByName",
                modifiers: {
                  "hide-when-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:countSheetTypeName.hide-when-medium",
                value: _vm.$t("countsheet.list.count-sheet-type-column-label"),
                expression:
                  "\n        $t('countsheet.list.count-sheet-type-column-label')\n      ",
                arg: "countSheetTypeName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:action.no-sort.hide-when-extra-small.class_fd-action-cell",
                arg: "action",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-action-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: function(item) {
                  return _vm.$router.push("/countsheets/" + item.id)
                },
                expression: "item => $router.push(`/countsheets/${item.id}`)",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            class: [
              "fd-expanding-table",
              "fd-actions-table",
              "fd-countsheet-list"
            ],
            attrs: {
              "data-cy": "list",
              items: _vm.countSheets,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "mobile-breakpoint": "0",
              "show-expand": true
            },
            on: { "item-expanded": _vm.itemExpanded },
            scopedSlots: _vm._u([
              {
                key: "item.transferIDsList",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: item.transferIDsList,
                        "no-value-key": "countsheet.list.no-parts"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.reviewApprovedByName",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          !item.reviewApprovalIsAutomatic
                            ? item.reviewApprovedByName
                            : _vm.$t("countsheet.list.automatic-approval-label")
                        ) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.scaffoldNumber",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-scaffold-number-with-badges", {
                      attrs: {
                        value: item.scaffoldNumber,
                        requestCount: item.activeRequests,
                        otherWorkOrderCount: item.activeWorkOrders,
                        workOrderCount: item.activeWorkOrders,
                        dismantleCount: item.hasActiveDismantle ? 1 : 0
                      }
                    })
                  ]
                }
              },
              {
                key: "item.reviewStatusName",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("countsheet.status." + item.reviewStatusName)
                        ) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.countSheetTypeName",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("countsheet.type." + item.countSheetTypeName)
                        ) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "expanded-item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: 999 } },
                      [
                        _c("fd-work-order-details", {
                          staticClass:
                            "fd-expanding-table-single-details-section",
                          attrs: { workOrder: item.workOrder }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.action",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "table-1-action-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "data-cy": "edit",
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: "/countsheets/" + item.id
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }