var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", attrs: { "data-cy": "lemexisting" } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: function(item) {
                                        return _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || "/lems"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  !!_vm.lem
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            {
                                              "ml-2":
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            "py-1",
                                            "px-3",
                                            "fd-status-indicator"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.formattedDay) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "d-flex align-center justify-end ml-0 mr-0 fd-primary-context-qualifier-background"
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("fd-text-field", {
                            staticClass: "fd-readonly-input fd-remove-messages",
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: _vm.$t("lems.contractor-label"),
                              disabled: _vm.processing,
                              readonly: true
                            },
                            model: {
                              value: _vm.lem.contractorName,
                              callback: function($$v) {
                                _vm.$set(_vm.lem, "contractorName", $$v)
                              },
                              expression: "lem.contractorName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  false
                    ? _c(
                        "v-row",
                        {
                          staticClass:
                            "d-flex align-center justify-end ml-0 mr-0 fd-secondary-context-qualifier-background"
                        },
                        [
                          _c("div", { staticClass: "pl-3" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("lems.existing.group-by-label")) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "ml-4 mr-3 fd-button-context-separator"
                            },
                            [_vm._v("\n            |\n          ")]
                          ),
                          _vm._v(" "),
                          _vm.$vuetify.breakpoint.mdAndDown
                            ? _c("v-select", {
                                staticClass: "py-2 pr-3",
                                staticStyle: { "max-width": "250px" },
                                attrs: {
                                  outlined: "",
                                  dense: _vm.$vuetify.breakpoint.xsOnly,
                                  items: _vm.entryGroupingTypeOptions,
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.selectedEntryGroupingType,
                                  callback: function($$v) {
                                    _vm.selectedEntryGroupingType = $$v
                                  },
                                  expression: "selectedEntryGroupingType"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$vuetify.breakpoint.lgAndUp
                            ? _c(
                                "v-radio-group",
                                {
                                  staticClass: "fd-badge-radio-group",
                                  attrs: { row: "" },
                                  model: {
                                    value: _vm.selectedEntryGroupingType,
                                    callback: function($$v) {
                                      _vm.selectedEntryGroupingType = $$v
                                    },
                                    expression: "selectedEntryGroupingType"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "lems.existing.group-by-none-radio"
                                      ),
                                      value: "groupnone",
                                      "data-cy": "radionone"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "lems.existing.group-by-person-radio"
                                      ),
                                      value: "groupperson",
                                      "data-cy": "radioperson"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "lems.existing.group-by-work-order-radio"
                                      ),
                                      value: "groupworkorder",
                                      "data-cy": "radioworkorder"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "lems.existing.group-by-person-work-order-radio"
                                      ),
                                      value: "groupemployeeworkorder",
                                      "data-cy": "groupemployeeworkorder"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "lems.existing.group-by-cost-code-radio"
                                      ),
                                      value: "groupcostcode",
                                      "data-cy": "groupcostcode"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-3 py-0", attrs: { cols: "12" } },
                        [
                          _vm.lemIsDeclined
                            ? _c("fd-alert", {
                                attrs: {
                                  type: "warning",
                                  label: !!_vm.lemDeclineComments
                                    ? _vm.$t("lems.declined-with-reason")
                                    : _vm.$t("lems.declined"),
                                  comments: _vm.lemDeclineComments
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-title",
                    [
                      _c("fd-inline-add-button", {
                        staticClass: "fd-inner-table-fab-button",
                        attrs: { disabled: _vm.isReadonly },
                        on: {
                          click: function($event) {
                            return _vm.openNewLemEntryDialog()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "ml-1",
                                        attrs: { color: "black", dark: "" }
                                      },
                                      on
                                    ),
                                    [_vm._v("info")]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("lems.existing.header-additional-info")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        staticClass: "pl-0",
                        attrs: {
                          "append-icon": "search",
                          label: "Search",
                          "single-line": "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.tablesearch,
                          callback: function($$v) {
                            _vm.tablesearch = $$v
                          },
                          expression: "tablesearch"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    directives: [
                      {
                        name: "fd-column",
                        rawName:
                          "v-fd-column:timesheetNumberAndOwnerName.hidden",
                        value: "",
                        expression: "''",
                        arg: "timesheetNumberAndOwnerName",
                        modifiers: { hidden: true }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:employeeWorkOrder.hidden",
                        value: "",
                        expression: "''",
                        arg: "employeeWorkOrder",
                        modifiers: { hidden: true }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:submitterName.hidden",
                        value: "",
                        expression: "''",
                        arg: "submitterName",
                        modifiers: { hidden: true }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:timesheetOwnerName.hidden",
                        value: _vm.$t(
                          "lems.existing.timesheet-owner-column-label"
                        ),
                        expression:
                          "$t('lems.existing.timesheet-owner-column-label')",
                        arg: "timesheetOwnerName",
                        modifiers: { hidden: true }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:scaffoldNumber.hidden",
                        value: _vm.$t("lems.existing.scaffold-column-label"),
                        expression: "$t('lems.existing.scaffold-column-label')",
                        arg: "scaffoldNumber",
                        modifiers: { hidden: true }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:subAreaName.hidden",
                        value: _vm.$t("lems.existing.sub-area-column-label"),
                        expression: "$t('lems.existing.sub-area-column-label')",
                        arg: "subAreaName",
                        modifiers: { hidden: true }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:employeeName",
                        value: _vm.$t("lems.existing.employee-column-label"),
                        expression: "$t('lems.existing.employee-column-label')",
                        arg: "employeeName"
                      },
                      {
                        name: "fd-column",
                        rawName:
                          "v-fd-column:classificationName.hide-when-small",
                        value: _vm.$t(
                          "lems.existing.classification-column-label"
                        ),
                        expression:
                          "\n            $t('lems.existing.classification-column-label')\n          ",
                        arg: "classificationName",
                        modifiers: { "hide-when-small": true }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:workTypeName.hide-when-small",
                        value: _vm.$t("lems.existing.work-type-column-label"),
                        expression:
                          "$t('lems.existing.work-type-column-label')",
                        arg: "workTypeName",
                        modifiers: { "hide-when-small": true }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:workSubTypeName",
                        value: _vm.$t(
                          "lems.existing.work-sub-type-column-label"
                        ),
                        expression:
                          "$t('lems.existing.work-sub-type-column-label')",
                        arg: "workSubTypeName"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:costCodeName",
                        value: _vm.$t("lems.existing.cost-code-column-label"),
                        expression:
                          "$t('lems.existing.cost-code-column-label')",
                        arg: "costCodeName"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:workOrderNumber",
                        value: _vm.$t("lems.existing.work-order-column-label"),
                        expression:
                          "$t('lems.existing.work-order-column-label')",
                        arg: "workOrderNumber"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:areaName.hide-when-medium",
                        value: _vm.$t("lems.existing.area-column-label"),
                        expression: "$t('lems.existing.area-column-label')",
                        arg: "areaName",
                        modifiers: { "hide-when-medium": true }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:regularTime",
                        value: _vm.$t(
                          "lems.existing.regular-time-column-label"
                        ),
                        expression:
                          "$t('lems.existing.regular-time-column-label')",
                        arg: "regularTime"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:overTime",
                        value: _vm.$t("lems.existing.over-time-column-label"),
                        expression:
                          "$t('lems.existing.over-time-column-label')",
                        arg: "overTime"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:units",
                        value: _vm.$t("lems.existing.units-column-label"),
                        expression: "$t('lems.existing.units-column-label')",
                        arg: "units"
                      },
                      {
                        name: "fd-column",
                        rawName:
                          "v-fd-column:action.class_fd-action-cell.no-sort",
                        arg: "action",
                        modifiers: {
                          "class_fd-action-cell": true,
                          "no-sort": true
                        }
                      }
                    ],
                    ref: "datatable",
                    staticClass: "fd-lem-entries-table",
                    attrs: {
                      page: _vm.tablepage,
                      search: _vm.tablesearch,
                      loading: _vm.processing,
                      "loading-text": _vm.$t("common.table-loading-message"),
                      "mobile-breakpoint": "0",
                      "item-class": _vm.lemEntryClassName,
                      "items-per-page": _vm.itemsPerPage,
                      "footer-props": {
                        "items-per-page-options": _vm.itemsPerPageOptions
                      },
                      items: _vm.lem.entries,
                      "group-by": _vm.groupColumn,
                      "show-expand": ""
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.tablepage = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "expanded-item",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("td", { attrs: { colspan: "99" } }, [
                              _c(
                                "div",
                                { staticClass: "fd-details-section pt-3" },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pl-0 pr-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mx-0",
                                              staticStyle: { width: "100%" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lems.existing.work-order-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        item.workOrderNumber
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lems.existing.scaffold-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: item.scaffoldNumber
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lems.existing.area-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: item.areaName
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lems.existing.sub-area-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: item.subAreaName
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lems.existing.work-type-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: item.workTypeName
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lems.existing.work-sub-type-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        item.workSubTypeName
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lems.existing.classification-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        item.classificationName
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lems.existing.cost-code-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: item.costCodeName
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.regularTime",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm.isReadonly || true
                              ? _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedRegularTime,
                                    "no-value-key": "lems.list.no-time-value"
                                  }
                                })
                              : _c("fd-text-field", {
                                  ref: _vm.getFieldRef("regularTime", item),
                                  staticClass: "fd-short-table-input",
                                  attrs: {
                                    "data-id": item.id,
                                    "data-cy": "regularTime",
                                    disabled: _vm.processing,
                                    numeric: "",
                                    outlined: false,
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectPreviousField(
                                          "regularTime",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectNextField(
                                          "regularTime",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return (function(e) {
                                          return _vm.enterPressed(
                                            e,
                                            "regularTime",
                                            item
                                          )
                                        })($event)
                                      }
                                    ]
                                  },
                                  model: {
                                    value: item.regularTime,
                                    callback: function($$v) {
                                      _vm.$set(item, "regularTime", $$v)
                                    },
                                    expression: "item.regularTime"
                                  }
                                })
                          ]
                        }
                      },
                      {
                        key: "item.overTime",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm.isReadonly || true
                              ? _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedOverTime,
                                    "no-value-key": "lems.list.no-time-value"
                                  }
                                })
                              : _c("fd-text-field", {
                                  ref: _vm.getFieldRef("overTime", item),
                                  staticClass: "fd-short-table-input",
                                  attrs: {
                                    "data-id": item.id,
                                    "data-cy": "overTime",
                                    disabled: _vm.processing,
                                    numeric: "",
                                    outlined: false,
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectPreviousField(
                                          "overTime",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectNextField(
                                          "overTime",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return (function(e) {
                                          return _vm.enterPressed(
                                            e,
                                            "overTime",
                                            item
                                          )
                                        })($event)
                                      }
                                    ]
                                  },
                                  model: {
                                    value: item.overTime,
                                    callback: function($$v) {
                                      _vm.$set(item, "overTime", $$v)
                                    },
                                    expression: "item.overTime"
                                  }
                                })
                          ]
                        }
                      },
                      {
                        key: "item.doubleTime",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm.isReadonly || true
                              ? _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedDoubleTime,
                                    "no-value-key": "lems.list.no-time-value"
                                  }
                                })
                              : _c("fd-text-field", {
                                  ref: _vm.getFieldRef("doubleTime", item),
                                  staticClass: "fd-short-table-input",
                                  attrs: {
                                    "data-id": item.id,
                                    "data-cy": "doubleTime",
                                    disabled: _vm.processing,
                                    numeric: "",
                                    outlined: false,
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectPreviousField(
                                          "doubleTime",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectNextField(
                                          "doubleTime",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return (function(e) {
                                          return _vm.enterPressed(
                                            e,
                                            "doubleTime",
                                            item
                                          )
                                        })($event)
                                      }
                                    ]
                                  },
                                  model: {
                                    value: item.doubleTime,
                                    callback: function($$v) {
                                      _vm.$set(item, "doubleTime", $$v)
                                    },
                                    expression: "item.doubleTime"
                                  }
                                })
                          ]
                        }
                      },
                      {
                        key: "item.units",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm.isReadonly || true
                              ? _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedUnits,
                                    "no-value-key": "lems.list.no-time-value"
                                  }
                                })
                              : _c("fd-text-field", {
                                  ref: _vm.getFieldRef("units", item),
                                  staticClass: "fd-short-table-input",
                                  attrs: {
                                    "data-id": item.id,
                                    "data-cy": "units",
                                    disabled: _vm.processing,
                                    numeric: "",
                                    outlined: false,
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectPreviousField(
                                          "units",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.selectNextField(
                                          "units",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return (function(e) {
                                          return _vm.enterPressed(
                                            e,
                                            "units",
                                            item
                                          )
                                        })($event)
                                      }
                                    ]
                                  },
                                  model: {
                                    value: item.units,
                                    callback: function($$v) {
                                      _vm.$set(item, "units", $$v)
                                    },
                                    expression: "item.units"
                                  }
                                })
                          ]
                        }
                      },
                      {
                        key: "group.header",
                        fn: function(ref) {
                          var group = ref.group
                          var items = ref.items
                          var isOpen = ref.isOpen
                          var toggle = ref.toggle
                          return [
                            _c(
                              "td",
                              {
                                attrs: {
                                  colspan: isOpen
                                    ? _vm.numCols - _vm.postTimeNumCols
                                    : _vm.preTimeNumCols
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    ref: "grouptoggle" + group,
                                    attrs: { icon: "" },
                                    on: { click: toggle }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        _vm._s(
                                          isOpen ? "mdi-minus" : "mdi-plus"
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(
                                  _vm._s(_vm.labelForGroup(group)) +
                                    "\n            "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !isOpen
                              ? _c(
                                  "td",
                                  [
                                    _c("fd-value-display", {
                                      attrs: {
                                        value: _vm.sum(items, "regularTime"),
                                        "no-value-key":
                                          "lems.list.no-time-value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !isOpen
                              ? _c(
                                  "td",
                                  [
                                    _c("fd-value-display", {
                                      attrs: {
                                        value: _vm.sum(items, "overTime"),
                                        "no-value-key":
                                          "lems.list.no-time-value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !isOpen
                              ? _c(
                                  "td",
                                  [
                                    _c("fd-value-display", {
                                      attrs: {
                                        value: _vm.sum(items, "units"),
                                        "no-value-key":
                                          "lems.list.no-time-value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "td",
                              { attrs: { colspan: _vm.postTimeNumCols } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.$vuetify.breakpoint.lgAndUp ||
                                        _vm.processing,
                                      left: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      disabled:
                                                        _vm.processing ||
                                                        _vm.isReadonly
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.removeEntriesForTimesheet(
                                                            group
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-delete\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("common.remove")))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "group.summary",
                        fn: function(ref) {
                          var items = ref.items
                          var isOpen = ref.isOpen
                          return [
                            isOpen
                              ? _c(
                                  "td",
                                  {
                                    staticClass:
                                      "text-end fd-table-group-footer-cell",
                                    attrs: { colspan: "2" }
                                  },
                                  [
                                    _c("fd-alert", {
                                      staticClass:
                                        "pa-0 mt-0 fd-timesheet-entry-group-summary",
                                      attrs: {
                                        dense: true,
                                        label: _vm.$t(
                                          "lems.existing.group-totals-label"
                                        ),
                                        type: "hint"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            isOpen
                              ? _c("td", {
                                  staticClass: "fd-table-group-footer-cell",
                                  attrs: { colspan: _vm.preTimeNumCols - 2 }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            isOpen
                              ? _c(
                                  "td",
                                  { staticClass: "fd-table-group-footer-cell" },
                                  [
                                    _c("fd-value-display", {
                                      attrs: {
                                        value: _vm.sum(items, "regularTime"),
                                        "no-value-key":
                                          "lems.list.no-time-value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            isOpen
                              ? _c(
                                  "td",
                                  { staticClass: "fd-table-group-footer-cell" },
                                  [
                                    _c("fd-value-display", {
                                      attrs: {
                                        value: _vm.sum(items, "overTime"),
                                        "no-value-key":
                                          "lems.list.no-time-value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            isOpen
                              ? _c(
                                  "td",
                                  { staticClass: "fd-table-group-footer-cell" },
                                  [
                                    _c("fd-value-display", {
                                      attrs: {
                                        value: _vm.sum(items, "units"),
                                        "no-value-key":
                                          "lems.list.no-time-value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            isOpen
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "fd-table-group-footer-cell",
                                    attrs: { colspan: _vm.postTimeNumCols }
                                  },
                                  [_vm._v("\n               \n            ")]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.action",
                        fn: function() {
                          return [
                            _c("div", {
                              staticClass: "table-1-action-column-min-width"
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-0", attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: _vm.inlineMessage.type,
                                      label: _vm.inlineMessage.message,
                                      "label-style": "white-space: pre-line;"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isReadonly || true
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-0", attrs: { cols: "12" } },
                            [
                              _vm.lemIsSubmitted
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "lems.existing.cannot-be-edited-submitted"
                                      )
                                    }
                                  })
                                : _vm.lemIsApproved
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "lems.existing.cannot-be-edited-approved"
                                      )
                                    }
                                  })
                                : _vm.lemIsCancelled
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "lems.existing.cannot-be-edited-cancelled"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-3 mb-3 d-sm-none justify-end v-card__actions"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "approve-extra-small",
                            outlined: "",
                            color: "success",
                            loading: _vm.approving,
                            disabled: _vm.processing || !_vm.lemCanBeApproved,
                            small: ""
                          },
                          on: { click: _vm.approveLEM }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.approve")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "decline-extra-small",
                            outlined: "",
                            color: "error",
                            loading: _vm.declining,
                            disabled: _vm.processing || !_vm.lemCanBeDeclined,
                            small: ""
                          },
                          on: { click: _vm.declineLEM }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.decline")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-3 mb-3 d-flex d-md-none justify-end align-center v-card__actions"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "history-small",
                            outlined: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.showStatusLogDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.history")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "approve-small",
                            outlined: "",
                            color: "success",
                            loading: _vm.approving,
                            disabled: _vm.processing || !_vm.lemCanBeApproved,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.approveLEM }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.approve")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "decline-small",
                            outlined: "",
                            color: "error",
                            loading: _vm.declining,
                            disabled: _vm.processing || !_vm.lemCanBeDeclined,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.declineLEM }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.decline")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "submit-small",
                            outlined: "",
                            color: "primary",
                            loading: _vm.submitting,
                            disabled: _vm.isReadonly || _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.submitLEM }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.submit")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "mx-3" },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            "data-cy": "history",
                            outlined: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.showStatusLogDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.history")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            outlined: "",
                            color: "success",
                            "data-cy": "approve",
                            loading: _vm.approving,
                            disabled: _vm.processing || !_vm.lemCanBeApproved,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.approveLEM }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.approve")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            "data-cy": "decline",
                            outlined: "",
                            color: "error",
                            loading: _vm.declining,
                            disabled: _vm.processing || !_vm.lemCanBeDeclined,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.declineLEM }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.decline")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("\n            |\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            "data-cy": "submit",
                            color: "primary",
                            loading: _vm.submitting,
                            disabled: _vm.processing || !_vm.lemCanBeSubmitted,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.submitLEM }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.submit")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }