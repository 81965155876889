var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value !== undefined || _vm.loading
    ? _c(
        "v-row",
        { staticClass: "mx-0 px-0" },
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c("strong", [_vm._v(_vm._s(_vm.label))])
          ]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "6" } }, [
            _vm.loading
              ? _c(
                  "div",
                  [
                    _c("v-progress-circular", {
                      staticClass: "mr-3",
                      attrs: {
                        indeterminate: true,
                        rotate: 0,
                        size: 16,
                        width: 3,
                        color: _vm.loadingColor
                      }
                    })
                  ],
                  1
                )
              : !!_vm.value && _vm.value.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.value, function(item, key) {
                    return _c("div", { key: key }, [
                      _vm._v("\n        " + _vm._s(item) + "\n      ")
                    ])
                  }),
                  0
                )
              : _c("div", { staticClass: "fd-item-placeholder" }, [
                  _vm._v(_vm._s(_vm.$t("common.not-available")))
                ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }