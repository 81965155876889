export function valueInArray<T>(val: T | null | undefined, array: T[]): boolean {
  if (!val) return false;
  if (!array || array.length == 0) return false;
  return array.includes(val);
}

export function compareStringArrays(
  rawArray1: string[] | undefined,
  rawArray2: string[] | undefined
): boolean {
  if (rawArray1?.length != rawArray2?.length) {
    return false;
  }

  var compareForSort = (a: string, b: string) => {
    let idA = a.toLowerCase() ?? "";
    let idB = b.toLowerCase() ?? "";
    if (idA != idB) {
      if (idA < idB) return -1;
      else return 1;
    }

    return 0;
  };

  let array1 = (rawArray1 ?? []).slice().sort(compareForSort);
  let array2 = (rawArray2 ?? []).slice().sort(compareForSort);

  for (var i = 0; i < array1.length; i++) {
    if (array1[i] != array2[i]) {
      return false;
    }
  }

  return true;
}

