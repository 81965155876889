import FDVue from "@fd/lib/vue";

export default FDVue.extend({
  name: "sp-work-order-estimate-modifiers-form",
  props: {
    processing: {},
    scaffoldIsHardBarricade: {},
    scaffoldTypeModifierID: {},
    scaffoldDistanceModifierID: {},
    scaffoldElevationModifierID: {},
    scaffoldHeightModifierID: {},
    buildDismantleRatioID: {},
    scaffoldCongestionFactorID: {},
    internalModifierID: {},
    hoardingModifierID: {},
    allScaffoldTypes: {},
    allScaffoldDistances: {},
    allScaffoldElevations: {},
    allScaffoldHeights: {},
    allBuildDismantleRatios: {},
    allScaffoldCongestionFactors: {},
    allInternalModifiers: {},
    allHoardingModifiers: {}
  },
  data: function() {
    return {};
  },
  computed: {},
  watch: {
    scaffoldTypeModifierID(newValue, oldValue) {
      console.log(`scaffoldTypeModifierID changed: ${oldValue} -> ${newValue}`);
    }
  },
  methods: {}
});

