import { render, staticRenderFns } from "./WorkOrderDetails.vue?vue&type=template&id=0c62d0a1&"
import script from "./WorkOrderDetails.vue.ts?vue&type=script&lang=ts&"
export * from "./WorkOrderDetails.vue.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c62d0a1')) {
      api.createRecord('0c62d0a1', component.options)
    } else {
      api.reload('0c62d0a1', component.options)
    }
    module.hot.accept("./WorkOrderDetails.vue?vue&type=template&id=0c62d0a1&", function () {
      api.rerender('0c62d0a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/estimationtool/client/views/components/data/workOrderDetails/WorkOrderDetails.vue"
export default component.exports