var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "1180px", "data-cy": "foremantimesheetdialog" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content" },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("timesheets.existing.timesheet-dialog-title", [
                        _vm.formattedDay
                      ])
                    )
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("fd-foreman-timesheet-form", {
            attrs: {
              "parent-context": _vm.parentContext,
              timesheet: _vm.currentTimesheet,
              "read-only": _vm.isReadonly
            },
            on: {
              "click:add-crew": _vm.addNewCrew,
              "click:edit-crew": _vm.editCrew,
              "click:delete-crew": _vm.deleteCrew
            }
          }),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isReadonly
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mx-0", attrs: { cols: "12" } },
                    [
                      _vm.timesheetIsSubmitted
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-submitted"
                              )
                            }
                          })
                        : _vm.timesheetIsApproved
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-approved"
                              )
                            }
                          })
                        : _vm.timesheetIsCancelled
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-cancelled"
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "save-draft",
                    text: "",
                    color: "primary",
                    disabled: _vm.processing || !_vm.canSave,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.save")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-4 mr-3 fd-button-context-separator" },
                [_vm._v("|")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "submit",
                    color: "primary",
                    disabled: _vm.processing || !_vm.canSubmit,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.submitDialog }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.save-and-submit")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }