var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "1800px" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "addtimesheetentriesdialog" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [
                    _vm.processing ? "breadcrumb-processing-opacity" : "",
                    !_vm.$vuetify.breakpoint.lgAndUp
                      ? "fd-timesheet-small-font-header"
                      : ""
                  ]
                },
                [
                  _vm._v(
                    _vm._s(
                      !!_vm.currentTimesheet &&
                        !!_vm.currentTimesheet.ownerID &&
                        _vm.currentTimesheet.ownerID != _vm.curUserID
                        ? !_vm.$vuetify.breakpoint.lgAndUp
                          ? _vm.$t(
                              "timesheets.entries.timesheet-dialog-title-with-owner-small",
                              [_vm.currentTimesheet.ownerName]
                            )
                          : _vm.$t(
                              "timesheets.entries.timesheet-dialog-title-with-owner",
                              [_vm.currentTimesheet.ownerName]
                            )
                        : _vm.$t("timesheets.entries.timesheet-dialog-title")
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "font-size": "12px", "line-height": "1.5" } },
                [
                  !!_vm.workOrder.legacyID
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ml-2 fd-status-chip",
                          attrs: { small: !_vm.$vuetify.breakpoint.lgAndUp }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-wo-number",
                                  [_vm.workOrder.legacyID]
                                )
                              ) +
                              " -\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-request-type",
                                  [
                                    _vm.workOrder.requestTypeName,
                                    _vm.workOrder.requestSubTypeName
                                  ]
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.workOrder.scaffoldNumber
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ml-2 fd-status-chip",
                          attrs: { small: !_vm.$vuetify.breakpoint.lgAndUp }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-tag-number",
                                  [_vm.workOrder.scaffoldNumber]
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ml-0 mr-0 fd-primary-context-qualifier-background",
                  attrs: { align: "center", justify: "end" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          cy: "selectedday",
                          disabled: _vm.processing,
                          label: "",
                          items: _vm.workOrderDateList,
                          "hide-details": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.text) +
                                    "\n              "
                                ),
                                !!_vm.currentTimesheet
                                  ? _c(
                                      "v-chip",
                                      {
                                        attrs: { color: "primary", small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm
                                                .$t(
                                                  "timesheets.status." +
                                                    (_vm.currentTimesheet.isNew
                                                      ? 0
                                                      : _vm.currentTimesheet
                                                          .timesheetStatusID)
                                                )
                                                .toUpperCase()
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b({}, "v-list-item", attrs, false),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            style: _vm.dateIsToday(item.value)
                                              ? "font-weight: bold;"
                                              : "",
                                            attrs: { "no-gutters": "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item.text) +
                                                "\n                    "
                                            ),
                                            _vm.dateIsToday(item.value)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "0.75rem",
                                                      color:
                                                        "rgba(0, 0, 0, 0.6)"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                       (" +
                                                        _vm._s(
                                                          _vm.$t("common.today")
                                                        ) +
                                                        ")\n                    "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        !item.hasTimesheet
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "fd-item-detail-light mt-1 pb-1"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "timesheets.entries.timesheet-missing-for-day-message"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : item.disabled
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-1 pb-1" },
                                              [
                                                item.disabled
                                                  ? _c(
                                                      "fp-item-disabled-message",
                                                      {
                                                        attrs: {
                                                          message: _vm.$t(
                                                            "timesheets.entries.timesheet-for-day-not-editable-message"
                                                          )
                                                        }
                                                      }
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selectedDay,
                          callback: function($$v) {
                            _vm.selectedDay = $$v
                          },
                          expression: "selectedDay"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      !!_vm.currentTimesheet && _vm.currentTimesheet.isNew
                        ? _c("fd-alert", {
                            attrs: {
                              dense: true,
                              label: _vm.$t(
                                "timesheets.entries.timesheet-will-be-created-message"
                              ),
                              type: "info"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "max-height": "calc(100vh - 215px)",
                    overflow: "auto"
                  }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-3 py-0", attrs: { cols: "12" } },
                        [
                          _vm.currentTimesheetIsDeclined
                            ? _c("fd-alert", {
                                attrs: {
                                  type: "warning",
                                  label: !!_vm.currentTimesheetDeclineComments
                                    ? _vm.$t("timesheets.declined-with-reason")
                                    : _vm.$t("timesheets.declined"),
                                  comments: _vm.currentTimesheetDeclineComments
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    { ref: "addform", on: { submit: _vm.preventSubmit } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "px-3 py-3",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  "data-cy": "employees",
                                  dense: "",
                                  outlined: "",
                                  items: _vm.selectableEmployees,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: _vm.$t(
                                    "timesheets.entries.select-employee-label"
                                  ),
                                  clearable: "",
                                  disabled:
                                    _vm.processing ||
                                    !!_vm.selectedCrewID ||
                                    _vm.currentTimesheetIsReadonly,
                                  "hide-details": ""
                                },
                                on: { input: _vm.addTimesheetRows },
                                model: {
                                  value: _vm.selectedEmployeeID,
                                  callback: function($$v) {
                                    _vm.selectedEmployeeID = $$v
                                  },
                                  expression: "selectedEmployeeID"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-3 py-3",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-autocomplete", {
                                    staticClass:
                                      "fd-select-with-context-actions",
                                    attrs: {
                                      "data-cy": "crews",
                                      dense: "",
                                      outlined: "",
                                      items: _vm.selectableCrews,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "timesheets.entries.select-crew-label"
                                      ),
                                      clearable: "",
                                      disabled:
                                        _vm.processing ||
                                        !!_vm.selectedEmployeeID ||
                                        _vm.currentTimesheetIsReadonly,
                                      "hide-details": ""
                                    },
                                    on: { input: _vm.addTimesheetRows },
                                    model: {
                                      value: _vm.selectedCrewID,
                                      callback: function($$v) {
                                        _vm.selectedCrewID = $$v
                                      },
                                      expression: "selectedCrewID"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "fd-select-context-actions",
                                      attrs: { dark: false, flat: true }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.$vuetify.breakpoint
                                                .lgAndUp || _vm.processing,
                                            top: ""
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: "new",
                                                          fab: "",
                                                          elevation: "0",
                                                          "x-small": "",
                                                          dark: "",
                                                          disabled:
                                                            _vm.processing ||
                                                            _vm.currentTimesheetIsReadonly
                                                        },
                                                        on: {
                                                          click: _vm.addNewCrew
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-plus")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.entries.add-crew-label"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.$vuetify.breakpoint
                                                .lgAndUp || _vm.processing,
                                            top: ""
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          dark: "",
                                                          disabled:
                                                            _vm.processing ||
                                                            _vm.currentTimesheetIsReadonly ||
                                                            !_vm.canModifySelectedCrew
                                                        },
                                                        on: {
                                                          click: _vm.editCrew
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-pencil")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.entries.edit-crew-label"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.$vuetify.breakpoint
                                                .lgAndUp || _vm.processing,
                                            top: ""
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _vm.$vuetify.breakpoint
                                                    .mdAndUp
                                                    ? _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              dark: "",
                                                              disabled:
                                                                _vm.processing ||
                                                                _vm.currentTimesheetIsReadonly ||
                                                                !_vm.canModifySelectedCrew
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.deleteCrew
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-delete")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.entries.delete-crew-label"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    ref: "datatable",
                    staticClass:
                      "fd-timesheet-entries-table fixed-header v-table__overflow elevation-1 fd-man-hours-table",
                    staticStyle: {
                      "max-height": "calc(100vh - 415px)",
                      "backface-visibility": "hidden"
                    },
                    attrs: {
                      page: _vm.tablepage,
                      loading: _vm.processing,
                      "loading-text": _vm.$t("common.table-loading-message"),
                      "mobile-breakpoint": "0",
                      "items-per-page": _vm.itemsPerPage,
                      "footer-props": {
                        "items-per-page-options": _vm.itemsPerPageOptions
                      },
                      items: !!_vm.currentTimesheet
                        ? _vm.currentTimesheet.timesheetRows
                        : [],
                      headers: _vm.tableHeaders,
                      "disable-sort": ""
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.tablepage = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.employeeName",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [_vm._v(_vm._s(item.employeeName))]),
                              _vm._v(" "),
                              !!item.employeeCode
                                ? _c("div", { staticClass: "fd-item-detail" }, [
                                    _vm._v(_vm._s(item.employeeCode))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !!item.classificationDisplayName
                                ? _c("div", { staticClass: "fd-item-detail" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.classificationDisplayName) +
                                        "\n            "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          }
                        },
                        _vm._l(_vm.visibleWorkSubTypes, function(wst) {
                          return {
                            key: "item." + wst.id,
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm.currentTimesheetIsReadonly
                                  ? _c("fd-value-display", {
                                      key: wst.id,
                                      attrs: {
                                        value: _vm.formatNumber(
                                          item["" + wst.id]
                                        ),
                                        "no-value-key":
                                          "timesheets.list.no-time-value"
                                      }
                                    })
                                  : _c("fd-text-field", {
                                      key: wst.id,
                                      ref: _vm.getFieldRef(wst.id, item),
                                      refInFor: true,
                                      staticClass: "fd-short-table-input",
                                      attrs: {
                                        disabled: _vm.processing,
                                        numeric: "",
                                        outlined: false,
                                        "highlight-on-focus": ""
                                      },
                                      on: {
                                        input: function(v) {
                                          return _vm.workSubTypeHoursValueChanged(
                                            item,
                                            wst.id,
                                            v
                                          )
                                        },
                                        keydown: [
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "up",
                                                38,
                                                $event.key,
                                                ["Up", "ArrowUp"]
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.selectPreviousField(
                                              wst.id,
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "down",
                                                40,
                                                $event.key,
                                                ["Down", "ArrowDown"]
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.selectNextField(
                                              wst.id,
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return (function(e) {
                                              return _vm.enterPressed(
                                                e,
                                                wst.id,
                                                item
                                              )
                                            })($event)
                                          }
                                        ]
                                      },
                                      model: {
                                        value: item["" + wst.id],
                                        callback: function($$v) {
                                          _vm.$set(item, "" + wst.id, $$v)
                                        },
                                        expression: "item[`${wst.id}`]"
                                      }
                                    })
                              ]
                            }
                          }
                        }),
                        {
                          key: "item.total",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "fd-table-column-text-end-override"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.calculateTotal(item)) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "item.action",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "table-1-action-column-min-width"
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.$vuetify.breakpoint.lgAndUp ||
                                          _vm.processing,
                                        left: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        disabled:
                                                          _vm.processing ||
                                                          _vm.currentTimesheetIsReadonly
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeTimesheetRow(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      mdi-delete\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("common.remove")))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.workOrderNumbersWithDetailWorkSubTypes.length
                ? _c(
                    "v-form",
                    { ref: "additionaldetailsform" },
                    [
                      _c("v-subheader", { staticClass: "pt-3" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheets.existing.additional-details-header"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.workOrderNumbersWithDetailWorkSubTypes,
                        function(woNumber) {
                          return _c(
                            "v-alert",
                            {
                              key: woNumber,
                              staticClass: "mx-3 py-0",
                              attrs: { color: "primary" }
                            },
                            [
                              _c(
                                "v-subheader",
                                { staticClass: "white--text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "timesheets.existing.additional-details-label",
                                        ["WO#" + woNumber]
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                _vm._l(
                                  _vm.currentTimesheetExplanationsForWorkOrderNumber(
                                    woNumber
                                  ),
                                  function(explanation) {
                                    return _c(
                                      "v-col",
                                      {
                                        key:
                                          woNumber +
                                          "_" +
                                          explanation.workSubTypeID,
                                        staticClass: "fd-alert-container",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c("fd-text-field", {
                                          staticStyle: {
                                            "background-color": "white"
                                          },
                                          attrs: {
                                            label: _vm.workSubTypeNameForExplanation(
                                              explanation
                                            ),
                                            rules: [_vm.rules.required],
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: explanation.explanation,
                                            callback: function($$v) {
                                              _vm.$set(
                                                explanation,
                                                "explanation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "explanation.explanation"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        }
                      )
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentTimesheetIsReadonly
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mx-0", attrs: { cols: "12" } },
                    [
                      _vm.timesheetIsSubmitted
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-submitted"
                              )
                            }
                          })
                        : _vm.timesheetIsApproved
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-approved"
                              )
                            }
                          })
                        : _vm.timesheetIsCancelled
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-cancelled"
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "save-draft",
                    color: "primary",
                    disabled: _vm.processing || !_vm.canSave,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.save-and-close")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }