var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "timesheetslist" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            {
              attrs: { loading: _vm.processing },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c("fd-add-button", {
                        attrs: { action: _vm.openNewDialog }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "d-flex align-center justify-end ml-0 mr-0 fd-primary-context-qualifier-background"
            },
            [
              _c(
                "div",
                {
                  staticClass: "pl-3 mb-1 d-none d-sm-flex",
                  staticStyle: { color: "rgba(0,0,0,0.6)" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("timesheets.existing.group-by-label")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ml-4 mr-3 mb-1 d-none d-sm-flex fd-button-context-separator"
                },
                [_vm._v("\n        |\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-radio-group",
                {
                  class: [
                    _vm.$vuetify.breakpoint.smAndUp
                      ? ""
                      : "fd-timesheets-existing-radio-buttons-mobile"
                  ],
                  attrs: { row: "" },
                  model: {
                    value: _vm.selectedGroupingType,
                    callback: function($$v) {
                      _vm.selectedGroupingType = $$v
                    },
                    expression: "selectedGroupingType"
                  }
                },
                [
                  _c("v-radio", {
                    staticStyle: { "font-size": "0.7rem" },
                    attrs: {
                      label: _vm.$t("timesheets.list.group-by-none-radio"),
                      value: "groupnone",
                      "data-cy": "radionone"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    staticStyle: { "font-size": "0.7rem" },
                    attrs: {
                      label: _vm.$t("timesheets.list.group-by-day-radio"),
                      value: "groupday",
                      "data-cy": "radioday"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    staticStyle: { "font-size": "0.7rem" },
                    attrs: {
                      label: _vm.$vuetify.breakpoint.smAndUp
                        ? _vm.$t("timesheets.list.group-by-person-radio")
                        : _vm.$t("timesheets.list.group-by-person-radio-short"),
                      value: "groupperson",
                      "data-cy": "radioperson"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    staticStyle: { "font-size": "0.7rem" },
                    attrs: {
                      label: _vm.$t("timesheets.list.group-by-status-radio"),
                      value: "groupstatus",
                      "data-cy": "radiostatus"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "v-row",
                [
                  !!_vm.canViewContractorFilter || !!_vm.canViewPersonFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _vm.canViewContractorFilter
                            ? _c("fd-select", {
                                staticClass: "mt-1",
                                attrs: {
                                  "data-cy": "contractorfilter",
                                  items: _vm.selectableContractorsWithCount,
                                  label: _vm.$t(
                                    "users.filter-by-contractor-label"
                                  ),
                                  "item-text": "alias",
                                  "item-value": "id",
                                  multiple: "",
                                  "small-chips": "",
                                  clearable: "",
                                  disabled: _vm.processing,
                                  "display-count": ""
                                },
                                model: {
                                  value: _vm.contractorIDsSelectedForFiltering,
                                  callback: function($$v) {
                                    _vm.contractorIDsSelectedForFiltering = $$v
                                  },
                                  expression:
                                    "contractorIDsSelectedForFiltering"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.canViewContractorFilter || !!_vm.canViewPersonFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _vm.canViewPersonFilter
                            ? _c("fd-select", {
                                staticClass: "mt-0 pt-0 mt-sm-1 pt-sm-3",
                                attrs: {
                                  "data-cy": "personfilter",
                                  label: _vm.$t(
                                    "timesheets.filter-by-owner-label"
                                  ),
                                  disabled: _vm.processing,
                                  items: _vm.selectableOwnersWithCount,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "display-count": "",
                                  "small-chips": "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.selectedOwnerID,
                                  callback: function($$v) {
                                    _vm.selectedOwnerID = $$v
                                  },
                                  expression: "selectedOwnerID"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 pb-sm-5 order-sm-0 order-1",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": "",
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    left: _vm.$vuetify.breakpoint.smAndUp
                                  }
                                },
                                [_vm._v("mdi-reload")]
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-1",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "pt-0 pt-sm-1 pb-5 pb-sm-0 order-sm-1 order-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("fd-select", {
                        staticClass: "mt-0 pt-0",
                        attrs: {
                          label: _vm.$t("timesheets.filter-by-status-label"),
                          items: _vm.selectableFilterStatuses,
                          "display-count": "",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.selectedStatuses,
                          callback: function($$v) {
                            _vm.selectedStatuses = $$v
                          },
                          expression: "selectedStatuses"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:ownerID.hidden",
                value: "",
                expression: "''",
                arg: "ownerID",
                modifiers: { hidden: true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:empty.no-sort.class_fd-table-icon-cell",
                value: "",
                expression: "''",
                arg: "empty",
                modifiers: { "no-sort": true, "class_fd-table-icon-cell": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:timesheetNumberString",
                value: _vm.$t("timesheets.number-label"),
                expression: "$t('timesheets.number-label')",
                arg: "timesheetNumberString"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:contractorName",
                value: _vm.$t("timesheets.contractor-label"),
                expression: "$t('timesheets.contractor-label')",
                arg: "contractorName"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:ownerName",
                value: _vm.$t("timesheets.owner-label"),
                expression: "$t('timesheets.owner-label')",
                arg: "ownerName"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:classification",
                value: _vm.$t("timesheets.classification-label"),
                expression: "$t('timesheets.classification-label')",
                arg: "classification"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:timesheetTypeID",
                value: _vm.$t("timesheets.timesheet-type-label"),
                expression: "$t('timesheets.timesheet-type-label')",
                arg: "timesheetTypeID"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:isoDay",
                value: _vm.$t("timesheets.day-label"),
                expression: "$t('timesheets.day-label')",
                arg: "isoDay"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:timesheetStatus",
                value: _vm.$t("timesheets.list.status-column-label"),
                expression: "$t('timesheets.list.status-column-label')",
                arg: "timesheetStatus"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:associatedToLemNumber",
                value: _vm.$t("timesheets.associated-to-lem-label"),
                expression: "$t('timesheets.associated-to-lem-label')",
                arg: "associatedToLemNumber"
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:submittedToName.hidden.hide-when-header-text-empty",
                value: _vm.$t("timesheets.list.submitted-to-column-label"),
                expression:
                  "\n        $t('timesheets.list.submitted-to-column-label')\n      ",
                arg: "submittedToName",
                modifiers: { hidden: true, "hide-when-header-text-empty": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:entryCount.hidden",
                value: _vm.$t("timesheets.list.entry-count-column-label"),
                expression: "$t('timesheets.list.entry-count-column-label')",
                arg: "entryCount",
                modifiers: { hidden: true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalRegularTime",
                value: _vm.$t(
                  "timesheets.list.total-regular-time-column-label"
                ),
                expression:
                  "$t('timesheets.list.total-regular-time-column-label')",
                arg: "totalRegularTime"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalOverTime.hidden",
                value: _vm.$t("timesheets.list.total-over-time-column-label"),
                expression:
                  "$t('timesheets.list.total-over-time-column-label')",
                arg: "totalOverTime",
                modifiers: { hidden: true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalPerDiemUnits",
                value: _vm.$t(
                  "timesheets.list.total-per-diem-units-column-label"
                ),
                expression:
                  "$t('timesheets.list.total-per-diem-units-column-label')",
                arg: "totalPerDiemUnits"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalDoubleTime",
                value: _vm.$t("timesheets.list.total-double-time-column-label"),
                expression:
                  "$t('timesheets.list.total-double-time-column-label')",
                arg: "totalDoubleTime"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalEquipmentUnits",
                value: _vm.$t(
                  "timesheets.list.total-equipment-units-column-label"
                ),
                expression:
                  "$t('timesheets.list.total-equipment-units-column-label')",
                arg: "totalEquipmentUnits"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:isNightShift",
                value: _vm.$t("timesheets.is-night-shift-label"),
                expression: "$t('timesheets.is-night-shift-label')",
                arg: "isNightShift"
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                arg: "actions",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-actions-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: function(item) {
                  return _vm.$router.push("/foremantimesheets/" + item.id)
                },
                expression:
                  "\n        item => $router.push(`/foremantimesheets/${item.id}`)\n      ",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            ref: "datatable",
            staticClass: "fd-timesheets-list",
            class: [{ "fd-archive-bonus-padding-bottom": _vm.showArchived }],
            attrs: {
              items: _vm.visibleTimesheets,
              "sort-by": "day",
              "sort-desc": true,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "mobile-breakpoint": "0",
              "items-per-page": _vm.itemsPerPage,
              "group-by": _vm.groupColumn,
              "group-desc": _vm.groupSortDesc,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.timesheetTypeID",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("timesheets.types." + item.timesheetTypeID)
                          ) +
                          "\n      "
                      )
                    ]
                  }
                },
                {
                  key: "item.ownerName",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " + _vm._s(item.ownerName) + "\n        "
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    !!item.creatorID &&
                                    item.creatorID != item.ownerID
                                      ? _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                small: "",
                                                color: "black",
                                                dark: ""
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("info")]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("timesheets.list.created-by-message", [
                                  item.creatorName
                                ])
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "header.empty",
                  fn: function() {
                    return [
                      _vm.visibleTimesheets.length > 0 && !!_vm.groupColumn
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.$vuetify.breakpoint.lgAndUp ||
                                      _vm.processing,
                                    left: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    tile: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggleTableGroups(
                                                        _vm.allGroupsExpanded
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      !_vm.allGroupsExpanded
                                                        ? "mdi-plus-box-multiple-outline"
                                                        : "mdi-minus-box-multiple-outline"
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1360808001
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.allGroupsExpanded
                                          ? _vm.$t("common.expand-all")
                                          : _vm.$t("common.collapse-all")
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer.prepend",
                  fn: function() {
                    return [
                      _c("fd-archived-data-loader", {
                        staticClass: "ml-2 mr-3",
                        attrs: {
                          showArchived: _vm.showArchived,
                          showArchivedDateRange: _vm.showArchivedDateRange,
                          showArchivedMinDate: _vm.showArchivedMinDate,
                          showArchivedMaxDate: _vm.showArchivedMaxDate,
                          loading: _vm.archivedLoading,
                          disabled: _vm.processing
                        },
                        on: {
                          "input:showArchived": function(v) {
                            return (_vm.showArchived = v)
                          },
                          "input:showArchivedDateRange": function(v) {
                            return (_vm.showArchivedDateRange = v)
                          }
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.fd-nav",
                  fn: function() {
                    return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                  },
                  proxy: true
                },
                false && !_vm.selectedOwnerID
                  ? {
                      key: "no-data",
                      fn: function() {
                        return [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticStyle: { "justify-content": "center" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "fd-position-relative fd-table-alert-badge"
                                    },
                                    [
                                      _c("fd-alert", {
                                        attrs: {
                                          label: "people have open timesheets.",
                                          type: "info",
                                          color: "primary"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-table-badge-placement"
                                        },
                                        [
                                          _c("v-badge", {
                                            attrs: {
                                              color: "red",
                                              content:
                                                "" +
                                                (!_vm.timesheetSummary
                                                  ? 0
                                                  : _vm.timesheetSummary.length)
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "group.header",
                  fn: function(ref) {
                    var group = ref.group
                    var items = ref.items
                    var isOpen = ref.isOpen
                    var toggle = ref.toggle
                    return [
                      _c(
                        "td",
                        { attrs: { colspan: "100" } },
                        [
                          _c(
                            "v-btn",
                            {
                              ref: "grouptoggle" + group,
                              attrs: { icon: "" },
                              on: { click: toggle }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  _vm._s(isOpen ? "mdi-minus" : "mdi-plus")
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(
                            _vm._s(_vm.labelForGroup(group)) + "\n        "
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.isNightShift",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("fd-checkbox", {
                        attrs: { readonly: true },
                        model: {
                          value: item.isNightShift,
                          callback: function($$v) {
                            _vm.$set(item, "isNightShift", $$v)
                          },
                          expression: "item.isNightShift"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.timesheetStatus",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-0",
                          staticStyle: {
                            "min-width": "75px",
                            position: "relative"
                          }
                        },
                        [
                          _vm.timesheetIsDeclined(item)
                            ? _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "fd-table-cell-warning-icon fd-status-icon-placement-readonly"
                                },
                                [
                                  _vm._v(
                                    "\n            fas fa-exclamation-circle\n          "
                                  )
                                ]
                              )
                            : _vm.timesheetIsSubmitted(item)
                            ? _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "fd-table-cell-started-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                },
                                [
                                  _vm._v(
                                    "\n            fas fa-circle\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(item.timesheetStatus) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.isoDay",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " + _vm._s(item.formattedDay) + "\n      "
                      )
                    ]
                  }
                },
                {
                  key: "item.day",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n        " + _vm._s(item.formattedDay) + "\n      "
                      )
                    ]
                  }
                },
                {
                  key: "item.entryCount",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("fd-value-display", {
                        attrs: {
                          value: item.entryCount,
                          "no-value-key": "timesheets.list.no-entries-value"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.totalRegularTime",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.timesheetTypeID != 2
                        ? _c("fd-value-display", {
                            attrs: {
                              value: item.formattedTotalRegularTime,
                              "no-value-key": "timesheets.list.no-time-value"
                            }
                          })
                        : _c("fd-value-display", {
                            attrs: {
                              "no-value-key":
                                "timesheets.list.no-time-entry-value"
                            }
                          })
                    ]
                  }
                },
                {
                  key: "item.totalOverTime",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.timesheetTypeID != 2
                        ? _c("fd-value-display", {
                            attrs: {
                              value: item.formattedTotalOverTime,
                              "no-value-key": "timesheets.list.no-time-value"
                            }
                          })
                        : _c("fd-value-display", {
                            attrs: {
                              "no-value-key":
                                "timesheets.list.no-time-entry-value"
                            }
                          })
                    ]
                  }
                },
                {
                  key: "item.totalPerDiemUnits",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.timesheetTypeID != 2
                        ? _c(
                            "span",
                            [
                              _c("fd-value-display", {
                                attrs: {
                                  value: item.formattedTotalUnits,
                                  "no-value-key":
                                    "timesheets.list.no-time-value"
                                }
                              })
                            ],
                            1
                          )
                        : _c("fd-value-display", {
                            attrs: {
                              "no-value-key":
                                "timesheets.list.no-time-entry-value"
                            }
                          })
                    ]
                  }
                },
                {
                  key: "item.totalDoubleTime",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.timesheetTypeID == 2
                        ? _c("fd-value-display", {
                            attrs: {
                              value: item.formattedTotalDoubleTime,
                              "no-value-key": "timesheets.list.no-time-value"
                            }
                          })
                        : _c("fd-value-display", {
                            attrs: {
                              "no-value-key":
                                "timesheets.list.no-time-entry-value"
                            }
                          })
                    ]
                  }
                },
                {
                  key: "item.totalEquipmentUnits",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.timesheetTypeID == 2
                        ? _c(
                            "span",
                            [
                              _c("fd-value-display", {
                                attrs: {
                                  value: item.formattedTotalUnits,
                                  "no-value-key":
                                    "timesheets.list.no-time-value"
                                }
                              })
                            ],
                            1
                          )
                        : _c("fd-value-display", {
                            attrs: {
                              "no-value-key":
                                "timesheets.list.no-time-entry-value"
                            }
                          })
                    ]
                  }
                },
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "table-3-actions-column-min-width" },
                        [
                          item.currentUserPermissions.canSubmit
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.$vuetify.breakpoint.lgAndUp ||
                                      _vm.processing,
                                    left: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    link: "",
                                                    disabled:
                                                      !_vm.timesheetCanBeSubmitted(
                                                        item
                                                      ) || _vm.processing,
                                                    loading: _vm.submitting
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { small: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.submitTimesheet(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  far fa-paper-plane\n                "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.submit")))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                disabled:
                                  !_vm.$vuetify.breakpoint.lgAndUp ||
                                  _vm.processing,
                                left: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                "data-cy": "cancel",
                                                icon: "",
                                                link: "",
                                                disabled:
                                                  !_vm.timesheetCanBeCancelled(
                                                    item
                                                  ) || _vm.processing,
                                                loading: _vm.cancelling
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.cancelTimesheet(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  far fa-ban\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.cancel")))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                disabled:
                                  !_vm.$vuetify.breakpoint.lgAndUp ||
                                  _vm.processing,
                                left: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                "data-cy": "edit",
                                                icon: "",
                                                link: "",
                                                disabled: _vm.processing,
                                                to:
                                                  "/foremantimesheets/" +
                                                  item.id
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-pencil\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.edit")))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }