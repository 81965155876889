var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "yardexisting" } },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: function(item) {
                                        return _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || "/yards"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-3", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("yards.existing.name"),
                                  disabled: _vm.processing,
                                  rules: _vm.yardRules.name
                                },
                                model: {
                                  value: _vm.yard.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.yard, "name", $$v)
                                  },
                                  expression: "yard.name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  items: _vm.allYardTypes,
                                  label: _vm.$t("yards.existing.yard-type"),
                                  disabled: _vm.processing,
                                  rules: _vm.yardRules.yardTypeID
                                },
                                model: {
                                  value: _vm.yard.yardTypeID,
                                  callback: function($$v) {
                                    _vm.$set(_vm.yard, "yardTypeID", $$v)
                                  },
                                  expression: "yard.yardTypeID"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12" }
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t(
                                    "yards.existing.is-system-yard"
                                  ),
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.yard.isSystemYard,
                                  callback: function($$v) {
                                    _vm.$set(_vm.yard, "isSystemYard", $$v)
                                  },
                                  expression: "yard.isSystemYard"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12" }
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t(
                                    "yards.existing.is-receiving-yard"
                                  ),
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.yard.isReceivingYard,
                                  callback: function($$v) {
                                    _vm.$set(_vm.yard, "isReceivingYard", $$v)
                                  },
                                  expression: "yard.isReceivingYard"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12" }
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  "data-cy": "archive",
                                  label: _vm.$t("common.archived"),
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.yard.isArchived,
                                  callback: function($$v) {
                                    _vm.$set(_vm.yard, "isArchived", $$v)
                                  },
                                  expression: "yard.isArchived"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.inlineMessage.message
                        ? _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        { staticClass: "mx-2" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                link: "",
                                disabled: _vm.processing,
                                loading: _vm.deleting,
                                color: "error",
                                outlined: "",
                                small: _vm.$vuetify.breakpoint.name == "xs"
                              },
                              on: { click: _vm.deleteItem }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.delete")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "data-cy": "close",
                                text: "",
                                link: "",
                                disabled: _vm.processing,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.cancel }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.cancel")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("fd-menu-save", {
                            attrs: {
                              disabled: _vm.processing,
                              loading: _vm.saving,
                              small: _vm.$vuetify.breakpoint.xsOnly
                            },
                            on: {
                              "click:save": function($event) {
                                return _vm.save(false)
                              },
                              "click:save-and-close": function($event) {
                                return _vm.save(true)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }