var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", [_vm._v(_vm._s(_vm.details.title))]),
      _vm._v(" "),
      _c(
        "v-expansion-panel-content",
        { staticClass: "no-left-right-bottom-padding" },
        [
          !_vm.details.groups || _vm.details.groups.length == 0
            ? _c(
                "v-container",
                { staticClass: "pa-0", attrs: { flat: "", tile: "" } },
                _vm._l(_vm.moduleVisiblePermissions(_vm.details), function(
                  permission
                ) {
                  return _c(
                    "v-row",
                    {
                      key: permission.text,
                      staticClass: "fd-security-summary-details-row",
                      attrs: { dense: "" }
                    },
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("strong", [_vm._v(_vm._s(permission.text))])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("fd-checkbox", {
                            attrs: {
                              readonly: "",
                              dense: "",
                              value: permission.value
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.details.groups, function(group) {
            return _c(
              "v-container",
              {
                key: group,
                staticClass: "pa-0",
                attrs: { flat: "", tile: "" }
              },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "fd-security-summary-section-header",
                    attrs: { dense: "" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "mt-0 mb-0 py-0", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-subheader",
                          {
                            staticClass: "px-0",
                            staticStyle: { height: "inherit" }
                          },
                          [_vm._v(_vm._s(group))]
                        ),
                        _vm._v(" "),
                        _c("v-divider")
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.getPermissionsInGroup(group), function(permission) {
                  return _c(
                    "v-row",
                    {
                      key: permission.text,
                      staticClass: "fd-security-summary-details-row",
                      attrs: { dense: "" }
                    },
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c("strong", [_vm._v(_vm._s(permission.text))])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("fd-checkbox", {
                            attrs: {
                              readonly: "",
                              dense: "",
                              value: permission.value
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }