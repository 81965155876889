import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import {
  scaffoldRequestService,
  legacyReferenceDataService,
  contractorService,
  ScaffoldRequestWithDetails,
  ScaffoldRequestStatuses,
  projectLocationService,
  disciplineService,
  Environment,
  ScaffoldRequestTypes
} from "../services";
import {
  createNewScaffoldRequest,
  RequestTypeCategory
} from "./components/LegacyScaffoldRequestNew.vue";
import { valueInArray } from "@fd/lib/client-util/array";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { WorkOrderDetails } from "./components/WorkOrderDetailsForm.vue";
import scaffoldRequestList, {
  ScaffoldRequestWithExtraDetails
} from "../dataMixins/scaffoldRequestList";
import { GetPersonName } from "../utils/person";

type FilteringRequestorContext = "all" | "mine";
type FilteringContext = {
  requestorFilter: FilteringRequestorContext;
  showScaffoldRequests: boolean;
  showPaintRequests: boolean;
  showMaintenanceRequests: boolean;
  showInsulationRequests: boolean;
};

export default FDVue.extend({
  name: "fd-web-scaffold-requests-list",

  mixins: [scaffoldRequestList],

  data: function() {
    return {
      submitting: false,
      legacyStatusNames: [] as string[]
    };
  },

  computed: {
    canFilterRequestType(): boolean {
      return (
        !!this.curUserAccess.canViewMaintenanceJobs ||
        !!this.curUserAccess.canViewPaintJobs ||
        !!this.curUserAccess.canViewInsulationJobs
      );
    },
    allowedRequestCategories() {
      var allowedCategories = [];
      allowedCategories.push(RequestTypeCategory.Scaffold);
      let environment = this.$store.state.curEnvironment as Environment;
      if (!!environment) {
        if (!!environment.enableMaintenanceWork) {
          allowedCategories.push(RequestTypeCategory.Maintenance);
        }
        if (!!environment.enablePaintWork) {
          allowedCategories.push(RequestTypeCategory.Paint);
        }
        if (!!environment.enableInsulationWork) {
          allowedCategories.push(RequestTypeCategory.Insulation);
        }
      }
      return allowedCategories;
    },
    allowMaintenanceRequests(): boolean {
      return (this.$store.state.curEnvironment as Environment)?.enableMaintenanceWork ?? false;
    },
    allowPaintRequests(): boolean {
      return (this.$store.state.curEnvironment as Environment)?.enablePaintWork ?? false;
    },
    allowInsulationRequests(): boolean {
      return (this.$store.state.curEnvironment as Environment)?.enableInsulationWork ?? false;
    },
    expanderColSpan(): number {
      if (this.$vuetify.breakpoint.xs) {
        return this.showArchived ? 5 : 4;
      } else if (this.$vuetify.breakpoint.sm) {
        return this.showArchived ? 11 : 10;
      } else if (this.$vuetify.breakpoint.md) {
        return this.showArchived ? 14 : 13;
      }

      return 18;
    },

    legacyStatusNamesWithCount(): any[] {
      return this.legacyStatusNames.map(x => {
        return {
          name: x,
          count: this.allScaffoldRequests.filter(r => r.legacyRequestStatusName == x).length
        };
      });
    },

    selectedStatuses: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.statusesForFiltering;
      },
      set(val) {
        this.$store.commit("SET_STATUSES_FOR_FILTERING", val);
      }
    },

    statusItems(): { value: number; text: string }[] {
      var values = Object.keys(ScaffoldRequestStatuses);
      var keys = values.filter(x => !isNaN(Number(x))).map(x => Number(x)) as number[];
      var items = keys.map(x => {
        return {
          value: x,
          text: ScaffoldRequestStatuses[x]
        };
      });
      return items;
    },

    filteringContext: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val) {
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },

    selectedRequestor: {
      get(): FilteringRequestorContext {
        var context: FilteringContext = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        return context.requestorFilter ?? "all";
      },
      set(val: FilteringRequestorContext) {
        var context: FilteringContext =
          this.$store.state.filters.find(
            (x: any) => x.context == this.$store.state.filteringContext
          )!.contextForFiltering ?? {};
        context.requestorFilter = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },
    showScaffoldRequests: {
      get(): boolean {
        return this.filteringContext.showScaffoldRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showScaffoldRequests = val;
        this.filteringContext = filteringContext;
      }
    },
    showMaintenanceRequests: {
      get(): boolean {
        return this.filteringContext.showMaintenanceRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showMaintenanceRequests = val;
        this.filteringContext = filteringContext;
      }
    },
    showPaintRequests: {
      get(): boolean {
        return this.filteringContext.showPaintRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showPaintRequests = val;
        this.filteringContext = filteringContext;
      }
    },
    showInsulationRequests: {
      get(): boolean {
        return this.filteringContext.showInsulationRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showInsulationRequests = val;
        this.filteringContext = filteringContext;
      }
    },
    requestorFilterIsMine: {
      get(): boolean {
        return this.selectedRequestor == "mine";
      },
      set(val: boolean) {
        this.selectedRequestor = val ? "mine" : "all";
      }
    },

    scaffoldRequests(): any[] {
      var selectedRequests = this.filteredScaffoldRequests;
      if (this.requestorFilterIsMine) {
        // returns requests either submitted by the current user, or on the current user's behalf
        selectedRequests = selectedRequests.filter(
          x =>
            x.requestingEmployeeID == this.curUserID ||
            x.coordinatorID == this.curUserID ||
            x.generalForemanID == this.curUserID ||
            x.foremanID == this.curUserID
        );
      }
      if (!this.showScaffoldRequests) {
        selectedRequests = selectedRequests.filter(
          r =>
            r.requestType != ScaffoldRequestTypes.Erect &&
            r.requestType != ScaffoldRequestTypes.Modify &&
            r.requestType != ScaffoldRequestTypes.Dismantle
        );
      }
      if (!this.showMaintenanceRequests) {
        selectedRequests = selectedRequests.filter(
          r => r.requestType != ScaffoldRequestTypes.Maintenance
        );
      }
      if (!this.showPaintRequests) {
        selectedRequests = selectedRequests.filter(
          r => r.requestType != ScaffoldRequestTypes.Paint
        );
      }
      if (!this.showInsulationRequests) {
        selectedRequests = selectedRequests.filter(
          r => r.requestType != ScaffoldRequestTypes.Insulation
        );
      }
      if (this.selectedStatuses.length) {
        selectedRequests = selectedRequests.filter(x =>
          valueInArray(x.legacyRequestStatusName, this.selectedStatuses)
        );
      }
      return selectedRequests;
    }
  },

  methods: {
    itemclass(item: any) {
      return `class-${item.id}`;
    },
    itemID(item: any) {
      return item.id;
    },
    formatDate(item: Date | string | undefined | null): string {
      return DateUtil.stripTimeFromLocalizedDateTime(item);
    },
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    /*** NAVIGATION ***/
    async openNewScaffoldRequestDialog() {
      await this.openNewRequestDialog(RequestTypeCategory.Scaffold);
    },
    async openNewMaintenanceRequestDialog() {
      await this.openNewRequestDialog(RequestTypeCategory.Maintenance);
    },
    async openNewPaintRequestDialog() {
      await this.openNewRequestDialog(RequestTypeCategory.Paint);
    },
    async openNewInsulationRequestDialog() {
      await this.openNewRequestDialog(RequestTypeCategory.Insulation);
    },
    async openNewRequestDialog(category: RequestTypeCategory) {
      if (await createNewScaffoldRequest(category)) {
        await this.reloadTableData();
      }
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadRequests();
        this.inlineMessage.message = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadRequests() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      let scaffoldRequests = await scaffoldRequestService.getAllWithDetails(
        this.showArchived,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      this.allScaffoldRequests = scaffoldRequests.map(x => {
        return {
          ...x,
          archived: !!x.archivedDate,
          willBeArchived: !!x.archivedDate && x.archivedDate.getTime() > new Date().getTime(),
          requestorFullName: GetPersonName(x.requestor),
          requestingContractorName: !!x.requestingContractor?.name?.length
            ? `${x.requestingContractor.name}`
            : "",
          requestingEmployeeFullName: !!x.requestingEmployee
            ? `${x.requestingEmployee.firstName} ${x.requestingEmployee.lastName}`
            : "",
          siteContact: x.siteContact?.toUpperCase(),
          createdOn: DateUtil.localizedDateTimeString(x.created),
          filteredWorkOrderID:
            x.scaffoldRequestStatus == ScaffoldRequestStatuses.Draft ||
            x.scaffoldRequestStatus == ScaffoldRequestStatuses.Submitted ||
            x.scaffoldRequestStatus == ScaffoldRequestStatuses.Declined
              ? undefined
              : x.legacyWorkOrderID
        };
      });
      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    // DOES NOT manage processing or error message logic
    async loadLegacyStatusNames(): Promise<void> {
      this.legacyStatusNames = (await legacyReferenceDataService.getAllRequestStatuses()).map(
        x => x.name!
      );
    },

    // *** ACTIONS ***
    async submitRequest(request: ScaffoldRequestWithDetails) {
      this.processing = true;
      this.submitting = true;
      try {
        let success = await scaffoldRequestService.submitScaffoldRequestByLegacyWorkOrder(
          request.legacyWorkOrderID!
        );
        var snackbarPayload = {
          text: this.$t("scaffold-requests.list-submit-success", [request.internalRequestNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        if (success) {
          await this.loadRequests();
        }
      } catch (error) {
        if ((error as any).statusCode == 422) {
          var snackbarPayload = {
            text: this.$t("scaffold-requests.list-submit-validation-failed", [
              request.internalRequestNumber
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    }
  },

  created: async function() {
    this.processing = true;
    var requestorFilter = !!this.curUserAccess.homeContractorID ? "mine" : "all";
    var filteringContext = {
      requestorFilter: requestorFilter,
      showScaffoldRequests: true,
      showMaintenanceRequests: this.curUserAccess.canViewMaintenanceJobs,
      showPaintRequests: this.curUserAccess.canViewPaintJobs,
      showInsulationRequests: this.curUserAccess.canViewInsulationJobs
    } as FilteringContext;
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "scaffoldrequests",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      disciplinesForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: filteringContext
    });

    this.notifyNewBreadcrumb({
      text: this.$t("scaffold-requests.list-title"),
      to: "/scaffoldrequests",
      resetHistory: true
    });
    try {
      await Promise.all([
        this.loadLegacyStatusNames(),
        this.loadRequests(),
        this.loadCurrentUserDisciplines(),
        this.loadDisciplines(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.loadContractors()
      ]);

      // double check selectedRequestor filter based on request results
      var selectedRequests = this.allScaffoldRequests.filter(
        x =>
          x.requestingEmployeeID == this.curUserID ||
          x.coordinatorID == this.curUserID ||
          x.generalForemanID == this.curUserID ||
          x.foremanID == this.curUserID
      );
      if (this.requestorFilterIsMine && selectedRequests.length == 0) {
        this.requestorFilterIsMine = false;
      }
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

