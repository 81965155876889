import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { mapActions } from "vuex";
import { SortParts } from "../../../dataMixins/countSheetGroupSorting";
import {
  PartWithTags,
  walkdownReferenceDataService,
  ScaffoldDesignEstimate,
  BuildDismantleRatio,
  HoardingModifier,
  InternalModifier,
  ScaffoldCongestionFactor,
  ScaffoldDistanceModifier,
  ScaffoldElevationModifier,
  ScaffoldHeightModifier,
  ScaffoldTypeModifier,
  scaffoldDesignEstimateService,
  ScaffoldDesignTakeoff,
  ScaffoldDesignWithDetails
} from "../../../services";
import { parseBCountString } from "../../../utils/bcount";
import { HasName } from "../../../utils/person";
import { openBCountPartsListBottomDialog } from "./BCountPartsListBottomDialog.vue";

const ScaffoldDesignEstimateDesignedNewDialog = FDVue.extend({
  name: "sp-work-order-estimate-new-dialog",

  components: {
    "sp-designed-estimate-form": () => import("../forms/SP.EstimateDesignedForm.vue"),
    "sp-estimate-modifiers-form": () => import("../forms/SP.EstimateModifiersForm.vue")
  },
  mixins: [dialogSupport],
  data: function() {
    return {
      saving: false,
      // Variable to hold which expansion panel is currently open
      step: 1,
      lastStep: 2,
      estimateStep: {
        name: this.$t("work-order-estimates.new-estimate.steps.estimate"),
        number: 1,
        error: false
      },
      modifiersStep: {
        name: this.$t("work-order-estimates.new-estimate.steps.modifiers"),
        number: 2,
        error: false
      },

      scaffoldDesign: {} as ScaffoldDesignWithDetails,
      estimate: {} as ScaffoldDesignEstimate,
      bCountString: "",

      allScaffoldDistances: [] as ScaffoldDistanceModifier[],
      allScaffoldElevations: [] as ScaffoldElevationModifier[],
      allScaffoldHeights: [] as ScaffoldHeightModifier[],
      allBuildDismantleRatios: [] as BuildDismantleRatio[],
      allScaffoldCongestionFactors: [] as ScaffoldCongestionFactor[],
      allInternalModifiers: [] as InternalModifier[],
      allHoardingModifiers: [] as HoardingModifier[]
    };
  },
  computed: {
    allScaffoldTypes(): ScaffoldTypeModifier[] {
      return (this.$store.state.scaffoldTypeModifiers.fullList as ScaffoldTypeModifier[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },
    // Data is stored in the SP1 db with a value called "ScaffoldType"
    // Possible values are:
    // - 0 = regular (volume) scaffold
    // - 1 = hard barricade
    scaffoldIsHardBarricade: {
      get(): boolean {
        return this.estimate.isHardBarricade ?? false;
      },
      set(val: boolean) {
        // Set scaffold type to hard barricade
        this.estimate.isHardBarricade = val;
      }
    },
    allParts(): PartWithTags[] {
      return this.$store.state.parts.fullList as PartWithTags[];
    },
    selectableParts(): PartWithTags[] {
      let selectableParts = this.allParts.filter(p => !!p.countSheetGroupID?.length);
      return SortParts(selectableParts);
    },
    bCountPartQuantities(): (ScaffoldDesignTakeoff & HasName)[] {
      let partQuantities = parseBCountString(this.bCountString);
      let bCountPartQuantities = partQuantities
        .filter(pq => this.allParts.findIndex(p => p.name == pq.code) !== -1)
        .map(pq => {
          let part = this.allParts.find(p => p.name == pq.code)!;
          return {
            partID: part.id,
            name: part.name,
            quantity: pq.quantity
          } as ScaffoldDesignTakeoff & HasName;
        });

      return bCountPartQuantities;
    }
  },
  methods: {
    ...mapActions({
      loadParts: "LOAD_PARTS",
      loadScaffoldTypeModifiers: "LOAD_SCAFFOLD_TYPE_MODIFIERS"
    }),
    async loadData() {
      await Promise.all([
        this.loadParts(),
        this.loadScaffoldTypeModifiers(),
        this.loadScaffoldHeights(),
        this.loadScaffoldElevations(),
        this.loadScaffoldDistances(),
        this.loadScaffoldCongestionFactors(),
        this.loadBuildDismantleRatios(),
        this.loadInternalModifiers(),
        this.loadHoardingModifiers()
      ]);
    },
    async open(
      scaffoldDesign: ScaffoldDesignWithDetails,
      estimate: ScaffoldDesignEstimate | undefined
    ) {
      this.scaffoldDesign = scaffoldDesign;
      this.estimate = {
        ...estimate,
        isHardBarricade: estimate?.isHardBarricade ?? false,
        walkdownID: null
      } as ScaffoldDesignEstimate;
      // console.log(`scaffoldTypeModifierID: ${this.estimate.scaffoldTypeModifierID}`);
      this.optOutOfErrorHandling();

      this.loadData();

      return await this.showDialog!();
    },

    cancelDialog() {
      this.closeDialog(false);
    },

    sanitizeNumber(value: number | string | undefined | null): number | null {
      if (!value) return null;

      let number = Number(value);
      if (isNaN(number)) return null;
      return number;
    },
    async saveDialog() {
      let quantities = this.bCountPartQuantities;
      if (!quantities.length) {
        this.inlineMessage.message = this.$t("No items.");
        this.inlineMessage.type = "error";
        return;
      }
      this.processing = true;
      this.saving = true;
      try {
        await scaffoldDesignEstimateService.generateNewDesignedEstimateForScaffoldDesignWithID(
          this.scaffoldDesign.id!,
          quantities,
          this.estimate
        );
        this.closeDialog(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    async showPartsDialog() {
      this.optOutOfErrorHandling();
      let bCount = await openBCountPartsListBottomDialog(
        this.selectableParts,
        this.$refs.content as Vue
      );
      // console.log(`openBCountPartsListBottomDialog done - bCount: ${bCount}`);
      if (!!bCount?.length) {
        this.bCountString = bCount;
      }
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldDistances(): Promise<void> {
      this.allScaffoldDistances = (
        await walkdownReferenceDataService.getAllScaffoldDistanceModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldHeights(): Promise<void> {
      this.allScaffoldHeights = (
        await walkdownReferenceDataService.getAllScaffoldHeightModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldElevations(): Promise<void> {
      this.allScaffoldElevations = (
        await walkdownReferenceDataService.getAllScaffoldElevationModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldCongestionFactors(): Promise<void> {
      this.allScaffoldCongestionFactors = (
        await walkdownReferenceDataService.getAllScaffoldCongestionFactors()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadBuildDismantleRatios(): Promise<void> {
      this.allBuildDismantleRatios = (
        await walkdownReferenceDataService.getAllBuildDismantleRatios()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadInternalModifiers(): Promise<void> {
      this.allInternalModifiers = (
        await walkdownReferenceDataService.getAllInternalModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadHoardingModifiers(): Promise<void> {
      this.allHoardingModifiers = (
        await walkdownReferenceDataService.getAllHoardingModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    }
  }
});

export default ScaffoldDesignEstimateDesignedNewDialog;

export async function createScaffoldDesignEstimateDesignedNewDialog(
  scaffoldDesign: ScaffoldDesignWithDetails,
  lastLWHEstimateDetails: ScaffoldDesignEstimate | undefined
): Promise<boolean> {
  let dialog = createDialog(ScaffoldDesignEstimateDesignedNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(scaffoldDesign, lastLWHEstimateDetails);
}

