import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { mapActions, mapMutations } from "vuex";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { InternalModifier } from "../../services";
import { createNewInternalModifier } from "./dialogs/InternalModifierNewDialog.vue";

type InternalModifierWithArchived = InternalModifier & { archived: boolean };
export default FDVue.extend({
  name: "fd-estimate-setup-internal-modifier-form",
  props: {
    parentContext: { type: String, default: "" }
  },
  mixins: [serviceErrorHandling],
  components: {
    "fd-detail-view-header": () => import("@fd/lib/vue/components/layout/DetailViewHeader.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  data: function() {
    return {
      context: "internalmodifiers",

      archivedLoading: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },
  computed: {
    internalModifiers(): InternalModifierWithArchived[] {
      return (this.$store.state.internalModifiers.fullList as InternalModifier[]).map(
        x =>
          ({
            ...x,
            archived: !!x.archivedDate
          } as InternalModifierWithArchived)
      );
    },
    tablesearch: {
      get(): string {
        return this.$store.state.filters.find((x: any) => x.context == this.context)!
          .searchStringForFiltering;
      },
      set(val: string) {
        this.$store.state.filters.find(
          (x: any) => x.context == this.context
        )!.searchStringForFiltering = val;
      }
    },

    showArchived: {
      get(): boolean {
        return this.$store.state.filters.find((x: any) => x.context == this.context)!
          .showArchivedForFiltering;
      },
      async set(val: boolean) {
        this.$store.state.filters.find(
          (x: any) => x.context == this.context
        )!.showArchivedForFiltering = val;
        this.reloadData();
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          await this.reloadData();
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find((x: any) => x.context == this.context)!
          .showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.state.filters.find(
          (x: any) => x.context == this.context
        )!.showArchivedForFilteringFromDate = val;
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find((x: any) => x.context == this.context)!
          .showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.state.filters.find(
          (x: any) => x.context == this.context
        )!.showArchivedForFilteringToDate = val;
      }
    }
  },
  watch: {},
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      addFilteringContext: "ADD_CHILD_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadInternalModifiers: "LOAD_INTERNAL_MODIFIERS",
      updateInternalModifier: "UPDATE_INTERNAL_MODIFIER",
      deleteInternalModifier: "DELETE_INTERNAL_MODIFIER"
    }),
    async reloadData() {
      this.processing = true;
      this.archivedLoading = this.showArchived;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.archivedLoading = false;
      }
    },
    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadInternalModifiers({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    async showAddNewDialog() {
      await createNewInternalModifier();
    },
    async deleteTableItem(item: InternalModifier) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteInternalModifier({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async flipArchived(item: InternalModifierWithArchived) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateInternalModifier({
          id: item.id,
          legacyID: item.legacyID,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },
  created: async function() {
    this.addFilteringContext({
      context: this.context,
      parentalContext: this.parentContext,
      searchStringForFiltering: "",
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.minimumDate(),
      showArchivedForFilteringToDate: DateUtil.maximumDate()
    });
  },
  mounted: async function() {
    this.processing = true;
    await this.reloadData();
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});

