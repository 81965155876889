import FDVue from "@fd/lib/vue";

import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { loginService } from "../../services";
import { hmacSha512 } from "@fd/lib/client-util/encryption";

const ReauthenticateDialog = FDVue.extend({
  name: "fd-reauthenticate-dialog",
  mixins: [dialogSupport, rules],

  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
      rules
    };
  },

  methods: {
    async tryLogin(username: string): Promise<boolean> {
      this.optOutOfErrorHandling();
      this.username = username;
      return await this.showDialog();
    },
    async finishLogin() {
      var email = "";
      var phoneNumber = "";
      let identifier = "";
      if (this.rules.validPhoneNumber(this.username) == true) {
        phoneNumber = this.username;
      } else if (this.rules.validEmail(this.username) == true) {
        email = this.username;
      } else {
        identifier = this.username;
      }
      let loginChallenge = await loginService.getLoginChallenge(email, phoneNumber, identifier);
      if (!loginChallenge) {
        this.inlineMessage.message = this.$t("username-not-found");
        this.inlineMessage.type = "error";
        return;
      }
      let encoder = new TextEncoder();
      let publicHash = await hmacSha512(loginChallenge.publicSalt, encoder.encode(this.password));
      let loginResult = await loginService.respondToLoginChallenge(
        loginChallenge.loginContext,
        publicHash,
        loginChallenge.contactMethodID
      );
      if (loginResult) {
        this.closeDialog(true);
      } else {
        this.inlineMessage.message = this.$t("invalid-password");
        this.inlineMessage.type = "warning";
      }
    }
  }
});

export default ReauthenticateDialog;

export async function showReauthentication(username: string): Promise<boolean> {
  let dialog = createDialog(ReauthenticateDialog);
  return await dialog.tryLogin(username);
}

