var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        {
          "appear-nicely": _vm.show,
          "disappear-nicely": !_vm.show,
          "login-screen-floating-placement-mobile-override":
            _vm.smallishMobileDevice || _vm.smallWidthBrowser
        },
        "login-screen-floating-placement",
        "sign-in-controls-container"
      ],
      staticStyle: { opacity: "0", "pointer-events": "none" }
    },
    [
      _c(
        "v-form",
        { ref: "termsAndConditionsAcceptedForm" },
        [
          _c(
            "v-row",
            { staticClass: "ml-0" },
            [
              _c(
                "v-col",
                { staticClass: "pl-0 pb-0", attrs: { cols: "10", md: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-0", attrs: { justify: "start" } },
                    [
                      _c(
                        "span",
                        {
                          class: {
                            "update-password-title-text-regular":
                              !_vm.smallishMobileDevice &&
                              !_vm.smallWidthBrowser,
                            "update-password-title-text-smaller":
                              _vm.smallishMobileDevice || _vm.smallWidthBrowser
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "terms.consent-form.terms-accepted-title"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", { staticClass: "mt-5 mr-0 ml-0" }, [
                _c(
                  "span",
                  {
                    class: { "appear-nicely": _vm.show },
                    staticStyle: { "font-size": "1.0em", opacity: "0" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "terms.consent-form.terms-accepted-you-will-be-signed-in-in"
                          )
                        ) +
                        "\n          "
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          color: "#FF4D00",
                          "font-size": "1.5em"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.value))]
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }