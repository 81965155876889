import FDVue from "@fd/lib/vue";
import { Contractor, lemsService, LemStatus, LEMWithDetails } from "../services";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { mapActions, mapMutations } from "vuex";
import { DateRangePreset } from "@fd/lib/vue/components/DateRangePicker.vue";
import { createNewLem } from "./components/dialogs/LemContainerNewDialog.vue";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import rules from "@fd/lib/vue/rules";
import { TranslateResult } from "vue-i18n";

type FormattedLEMWithDetails = LEMWithDetails & {
  lemStatus: string | TranslateResult;
  formattedDay: string;
  formattedTotalRegularTime: string;
  formattedTotalOverTime: string;
  formattedTotalDoubleTime: string;
  formattedTotalUnits: string;
};
export default FDVue.extend({
  name: "fd-lems",

  mixins: [serviceErrorHandling, rules],

  directives: {
    "fd-column": FDColumnDirective,
    "fd-row-navigate": FDRowNavigateDirective
  },

  data: function() {
    return {
      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined,
      allLems: [] as LEMWithDetails[],
      allPendingLEMs: [] as LEMWithDetails[],

      submitting: false,
      approving: false,
      declining: false,

      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },
  computed: {
    selectionType: {
      get(): "pending" | "all" {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val: "pending" | "all") {
        var current = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        if (val == current) return;

        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },
    lems(): LEMWithDetails[] {
      let lems = this.allLems;
      if (this.selectionType == "pending") lems = this.allPendingLEMs;
      if (this.contractorsSelectedForFiltering.length) {
        //If the items that has a contractorID associated to it is within the selected Contractors return it
        let returnValueItems = lems.filter(x => {
          if (!x.contractorID) return false;
          for (let contractor of this.contractorsSelectedForFiltering) {
            if (x.contractorID == contractor) return true;
          }
          return false;
        });
        //Cannot sort this returned list on "Name" since for objects like "Users" there is no "name" property.
        return returnValueItems;
      }
      return lems;
    },
    contractorsInUse(): Contractor[] {
      return this.$store.getters.getSortedInUseContractors(this.allLems);
    },

    // *** FILTERING ***
    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    contractorsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },
    // Archived Props for Approved Count Sheets
    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -13),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -1),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-month",
          labelKey: "fd-date-range-picker.preset-previous-month-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    },
    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      return minDate;
    },
    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      return maxDate;
    },
    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          try {
            await this.reloadTableData();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
          }
        } else this.showArchivedToDate = null;
      }
    },
    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },
    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS"
    }),
    numAllowedActions(item: LEMWithDetails): number {
      let actionCount = 4;

      if (!item.currentUserPermissions.canSubmit) actionCount -= 1;
      if (!item.currentUserPermissions.canApprove) actionCount -= 2;

      return actionCount;
    },

    async openNewDialog() {
      let lemID = await createNewLem();
      if (!!lemID) {
        this.$router.push(`/lems/${lemID}`);
      }
    },

    async loadLEMs() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      this.allLems = (
        await lemsService.getAll(this.showArchivedFromDate, this.showArchivedToDate)
      ).map(
        x =>
          ({
            ...x,
            lemStatus: this.$t(`lems.status.${x.lemStatusID}`),
            formattedDay: DateUtil.stripTimeFromLocalizedDateTime(x.day),
            formattedTotalRegularTime:
              !!x.totalRegularTime && x.totalRegularTime > 0
                ? x.totalRegularTime.toFixed(2)
                : undefined,
            formattedTotalOverTime:
              !!x.totalOverTime && x.totalOverTime > 0 ? x.totalOverTime.toFixed(2) : undefined,
            formattedTotalDoubleTime:
              !!x.totalDoubleTime && x.totalDoubleTime > 0
                ? x.totalDoubleTime.toFixed(2)
                : undefined,
            formattedTotalUnits:
              !!x.totalUnits && x.totalUnits > 0 ? x.totalUnits.toFixed(2) : undefined
          } as FormattedLEMWithDetails)
      );

      this.allPendingLEMs = (await lemsService.getAllPending()).map(
        x =>
          ({
            ...x,
            lemStatus: this.$t(`lems.status.${x.lemStatusID}`),
            formattedDay: DateUtil.stripTimeFromLocalizedDateTime(x.day),
            formattedTotalRegularTime:
              !!x.totalRegularTime && x.totalRegularTime > 0
                ? x.totalRegularTime.toFixed(2)
                : undefined,
            formattedTotalOverTime:
              !!x.totalOverTime && x.totalOverTime > 0 ? x.totalOverTime.toFixed(2) : undefined,
            formattedTotalDoubleTime:
              !!x.totalDoubleTime && x.totalDoubleTime > 0
                ? x.totalDoubleTime.toFixed(2)
                : undefined,
            formattedTotalUnits:
              !!x.totalUnits && x.totalUnits > 0 ? x.totalUnits.toFixed(2) : undefined
          } as FormattedLEMWithDetails)
      );

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadLEMs();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    lemIsDeclined(item: FormattedLEMWithDetails) {
      return item.lemStatusID == LemStatus.Declined;
    },
    lemCanBeSubmitted(item: FormattedLEMWithDetails) {
      return (
        (item.lemStatusID == LemStatus.New || item.lemStatusID == LemStatus.Declined) &&
        item.currentUserPermissions.canSubmit
      );
    },
    lemCanBeApproved(item: FormattedLEMWithDetails) {
      return item.lemStatusID == LemStatus.Submitted && item.currentUserPermissions.canApprove;
    },
    lemCanBeDeclined(item: FormattedLEMWithDetails) {
      return item.lemStatusID == LemStatus.Submitted && item.currentUserPermissions.canApprove;
    },
    async submitLEM(item: FormattedLEMWithDetails) {
      if (!item.currentUserPermissions.canSubmit) return;
      this.processing = true;
      this.submitting = true;
      try {
        await lemsService.submitLEM(item.id!);
        item.lemStatusID = LemStatus.Submitted;
        item.lemStatus = this.$t(`lems.status.${item.lemStatusID}`);

        var snackbarPayload = {
          text: this.$t("lems.list.submit-success", [item.legacyID, item.formattedDay]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        await this.reloadTableData();
      } catch (error) {
        if ((error as any).statusCode == 422) {
          var snackbarPayload = {
            text: this.$t("lems.list.submit-validation-failed", [item.legacyID, item.formattedDay]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },
    async approveLEM(item: FormattedLEMWithDetails) {
      if (!item.currentUserPermissions.canApprove) return;
      this.processing = true;
      this.approving = true;
      try {
        await lemsService.approvePendingLEM(item.id!);
        item.lemStatusID = LemStatus.Approved;
        item.lemStatus = this.$t(`lems.status.${item.lemStatusID}`);

        var snackbarPayload = {
          text: this.$t("lems.approval.approve-success", [item.legacyID, item.formattedDay]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        await this.reloadTableData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },
    async declineLEM(item: FormattedLEMWithDetails) {
      if (!item.currentUserPermissions.canApprove) return;
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("lems.approval.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          this.declining = false;
          this.processing = false;
          return false;
        }

        await lemsService.declinePendingLEM(item.id!, reason);
        item.lemStatusID = LemStatus.Declined;
        item.lemStatus = this.$t(`lems.status.${item.lemStatusID}`);

        var snackbarPayload = {
          text: this.$t("lems.approval.decline-success", [item.legacyID, item.formattedDay]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        await this.reloadTableData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    },

    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
      this.showArchivedMinDate;
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.addMonthsToDate(val, -2);
    }
  },
  created: async function() {
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "lems",
      parentalContext: null,
      contractorsForFiltering: [],
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -1),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      contextForFiltering: "all"
    });

    this.notifyNewBreadcrumb({
      text: this.$t("lems.list.title"),
      to: "/lems",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.loadContractors();
      await this.loadLEMs();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  },
  beforeDestroy: function() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});

