var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        {
          "appear-nicely": _vm.show,
          "disappear-nicely": !_vm.show,
          "login-screen-floating-placement-mobile-override":
            _vm.smallishMobileDevice || _vm.smallWidthBrowser
        },
        "login-screen-floating-placement",
        "sign-in-controls-container"
      ],
      staticStyle: { opacity: "0", "pointer-events": "none" }
    },
    [
      _c(
        "v-form",
        { ref: "selectDifferentContactMethodForm" },
        [
          _c(
            "v-row",
            { staticClass: "ml-0" },
            [
              _c(
                "v-col",
                { staticClass: "pl-0 pb-0", attrs: { cols: "10", md: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-0", attrs: { justify: "start" } },
                    [
                      _c(
                        "span",
                        {
                          class: {
                            "update-password-title-text-regular":
                              !_vm.smallishMobileDevice &&
                              !_vm.smallWidthBrowser,
                            "update-password-title-text-smaller":
                              _vm.smallishMobileDevice || _vm.smallWidthBrowser
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("login.select-contact-method-label"))
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.show
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      staticStyle: { "margin-left": "-25px" },
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { value: _vm.value },
                          on: {
                            change: function(v) {
                              return _vm.$emit("input", v)
                            }
                          }
                        },
                        _vm._l(_vm.contactMethods, function(contactMethod) {
                          return _c("v-radio", {
                            key: contactMethod.id,
                            attrs: {
                              disabled: !contactMethod.allowAccessCodes,
                              value: contactMethod.id,
                              label: _vm.contactMethodLabel(contactMethod)
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "pb-0",
                  staticStyle: { "margin-left": "-25px" },
                  attrs: { cols: "12" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        loading: _vm.loading,
                        text: "",
                        small: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("done")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("login.send-new-code-label")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }