import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import rules from "@fd/lib/vue/rules";
import { workOrderService } from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import workOrderList, {
  ParseWorkOrderWithLegacyDetails,
  FilteringRequestorContext,
  FilteringContext
} from "../dataMixins/workOrderList";
import { DateRangePreset } from "@fd/lib/vue/components/DateRangePicker.vue";

export default FDVue.extend({
  name: "fd-work-order-history",

  mixins: [rules, workOrderList],

  data: function() {
    return {
      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined
    };
  },

  computed: {
    expanderColSpan(): number {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 14;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        return 5;
      } else if (this.$vuetify.breakpoint.smOnly) {
        return 10;
      } else {
        return 14;
      }

      return 14;
    },
    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -13),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    }
  },

  watch: {},

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    async loadWorkOrders() {
      this.allWorkOrders = await (
        await workOrderService.getWorkOrderHistory(
          this.showArchivedFromDate,
          this.showArchivedToDate,
          null
        )
      ).map(x => {
        return ParseWorkOrderWithLegacyDetails(x);
      });
    },
    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
      this.showArchivedMinDate;
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.addMonthsToDate(val, -2);
    }
  },

  created: async function() {
    var requestorFilter: FilteringRequestorContext = !!this.curUserAccess.homeContractorID
      ? "mine"
      : "all";
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.maxDate = DateUtil.addDaysToDate(null, 0);
    this.minDate = DateUtil.addMonthsToDate(this.maxDate, -2);
    let fromDate = DateUtil.addDaysToDate(null, -13);
    this.setFilteringContext({
      context: "history",
      parentalContext: null,
      showArchivedForFiltering: true,
      showArchivedForFilteringFromDate: fromDate,
      showArchivedForFilteringToDate: this.maxDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      requestTypesForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      disciplinesForFiltering: [],
      foremanIDsForFiltering: [],
      generalForemanIDsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: {
        requestorFilter: requestorFilter,
        assignedFilter: true,
        unassignedFilter: true,
        showScaffoldRequests: true,
        showMaintenanceRequests: this.curUserAccess.canViewMaintenanceJobs,
        showPaintRequests: this.curUserAccess.canViewPaintJobs,
        showInsulationRequests: this.curUserAccess.canViewInsulationJobs
      } as FilteringContext
    });

    this.notifyNewBreadcrumb({
      text: this.$t("work-order-history.list-title"),
      to: "/workorderhistory",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([
        this.loadDisciplines(),
        this.loadCurrentUserDisciplines(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.loadCoordinators(),
        this.loadGeneralForemen(),
        this.loadForemen(),
        this.loadContractors()
      ]);
      this.processing = true;
      await this.reloadTableData();

      // double check selectedRequestor filter based on table results
      var myWorkOrders = this.allWorkOrders.filter(
        x =>
          (!!x.coordinatorLegacyID && x.coordinatorLegacyID == this.curUserAccess.legacyID) ||
          (!!x.coordinatorID && x.coordinatorID == this.curUserID) ||
          (!!x.generalForemanID && x.generalForemanID == this.curUserID) ||
          (!!x.foremanID && x.foremanID == this.curUserID)
      );
      if (this.requestorFilterIsMine && myWorkOrders.length == 0) {
        this.requestorFilterIsMine = false;
      } else if (!this.requestorFilterIsMine && myWorkOrders.length > 0) {
        this.requestorFilterIsMine = true;
      }
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

