import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { TranslateResult } from "vue-i18n";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  Classification,
  ContractorWithTags,
  crewService,
  CrewWithEmployees,
  EmployeeTimeSummary,
  PersonWithDetails,
  ProjectLocation,
  projectLocationService,
  ScaffoldRequestTypes,
  TimesheetExplanationWithWorkOrderDetails,
  TimesheetStatus,
  TimesheetType,
  WorkOrderSearchResult,
  workOrderService,
  WorkOrderStatuses,
  WorkSubType,
  WorkType
} from "../../../services";
import {
  SortTimesheetRows,
  TimesheetRow,
  TimesheetRowType,
  UpdatableTimesheetEntryWithDetails,
  UpdatableTimesheetWithTimesheetRows,
  TableHeader,
  SortWorkTypes,
  SortWorkSubTypes,
  ParseWorkSubTypeIDsFromRow,
  SortAllWorkSubTypes,
  areTimesheetRowsEqual,
  HashTable
} from "../../../utils/timesheet";
import userAccess from "../../../dataMixins/userAccess";
import {
  GetPersonName,
  PersonHasEquipmentClassification,
  PersonWithDetailsAndName,
  SortItemsWithName
} from "../../../utils/person";
import { mapActions, mapMutations } from "vuex";
import { VDataTable } from "@fd/lib/vue/types";
import { GroupableSelectListOption, SelectListOption } from "@fd/lib/vue/utility/select";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { showAdditionalDetailsDialog } from "../../../../../common/client/views/components/AdditionalDetailsDialog.vue";
import Vue from "vue";
import { stripTimeFromLocalizedDateTime } from "../../../../../lib/client-util/datetime";
import { SortCrewEmployees } from "../dialogs/CrewDetailsBottomDialog.vue";

type FormattedWorkOrder = WorkOrderSearchResult & { details: string; description: string };
type TransactionType = "equipment" | "indirect" | "generaldirect" | "workorder";

export default FDVue.extend({
  name: "fd-foreman-timesheet-form",
  inheritAttrs: false,
  mixins: [serviceErrorHandling, rules, tabbedView, userAccess],
  components: {
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  props: {
    timesheet: { type: Object, default: undefined },
    employeeTimeSummaries: { type: Array, default: undefined },
    readOnly: { type: Boolean, default: false },
    parentContext: { type: String, default: undefined },
    makeCorrections: { type: Boolean, default: false }
  },
  data: function() {
    return {
      // Variable to hold which expansion panel is currently open
      panel: 0,
      firstTabKey: `0`,
      workspaceTab: {
        tabname: this.$t("timesheets.existing.tabs.workspace"),
        key: "0",
        visible: true
      } as Tab,
      summaryTab: {
        tabname: this.$t("timesheets.existing.tabs.summary"),
        key: "1",
        visible: true
      } as Tab,

      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],
      selectedEmployeeID: null as string | null,
      selectedCrewID: null as string | null,

      availableWorkOrders: [] as FormattedWorkOrder[],
      selectedEntryType: null as TransactionType | null,
      selectedWorkOrders: [] as FormattedWorkOrder[],

      workOrderSearch: "",
      workOrderSearching: false,
      didWorkOrderSearch: false,
      timer: null as NodeJS.Timeout | null
    };
  },

  computed: {
    canEditCurrentTimesheet(): boolean {
      return this.canEditTimesheet(this.currentTimesheet);
    },
    timeSummaries(): EmployeeTimeSummary[] | undefined {
      return this.employeeTimeSummaries as [EmployeeTimeSummary];
    },
    hasWorkOrderDirectRows(): boolean {
      let rows = this.allTimesheetRows.filter(
        x => x.rowType == TimesheetRowType.DirectWorkOrderRelated && !!x.workOrderID
      );
      return !!rows?.length;
    },
    workOrderDirectPanelNumber(): number {
      return 0;
      // let panelNumber = 0;
      // if (!this.hasWorkOrderDirectRows) panelNumber -= 1;
      // return panelNumber;
    },
    hasGeneralDirectRows(): boolean {
      let rows = this.allTimesheetRows.filter(x => x.rowType == TimesheetRowType.DirectGeneral);
      return !!rows?.length;
    },
    generalDirectPanelNumber(): number {
      return 1;
      // let panelNumber = 1;
      // if (!this.hasWorkOrderDirectRows) panelNumber -= 1;
      // if (!this.hasGeneralDirectRows) panelNumber -= 1;
      // return panelNumber;
    },
    hasIndirectRows(): boolean {
      let rows = this.allTimesheetRows.filter(x => x.rowType == TimesheetRowType.Indirect);
      return !!rows?.length;
    },
    indirectPanelNumber(): number {
      return 2;
    },
    hasEquipmentRows(): boolean {
      let rows = this.allTimesheetRows.filter(x => x.rowType == TimesheetRowType.Equipment);
      return !!rows?.length;
    },
    equipmentPanelNumber(): number {
      return 3;
    },
    allTimesheetRows(): TimesheetRow[] {
      return this.currentTimesheet?.timesheetRows ?? [];
    },
    indirectTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(
        x => !x.workOrderID && x.rowType == TimesheetRowType.Indirect
      );
    },
    equipmentTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(
        x => !x.workOrderID && x.rowType == TimesheetRowType.Equipment
      );
    },
    generalizedDirectTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(
        x => !x.workOrderID && x.rowType == TimesheetRowType.DirectGeneral
      );
    },
    workOrderTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(x => !!x.workOrderID);
    },
    tabDefinitions(): Tab[] {
      return [this.summaryTab];
    },
    workTypeOptions(): any[] {
      if (!this.currentTimesheet?.id) return [];

      let options = [] as any[];
      if (this.currentTimesheet?.timesheetTypeID == TimesheetType.Indirect) {
        options.push({
          text: this.$t("timesheets.existing.indirect-label"),
          value: "indirect" as TransactionType
        });
      } else if (this.currentTimesheet?.timesheetTypeID == TimesheetType.Equipment) {
        options.push({
          text: this.$t("timesheets.existing.equipment-label"),
          value: "equipment" as TransactionType
        });
      } else {
        options.push({
          text: this.$t("timesheets.existing.generalized-direct-label"),
          value: "generaldirect" as TransactionType
        });
        options.push({
          text: this.$t("timesheets.existing.work-orders-label"),
          value: "workorder" as TransactionType
          // disabled: !this.availableWorkOrders?.length && !this.makeCorrections
        });
      }
      return options;
    },
    workOrderNumbersWithDetailWorkSubTypes(): any[] {
      if (!this.currentTimesheet?.id) return [];
      return [...new Set(this.currentTimesheet.explanations?.map(x => x.workOrderNumber))];
    },
    currentTimesheet(): UpdatableTimesheetWithTimesheetRows | undefined {
      return this.timesheet;
    },
    currentTimesheetIsEquipment(): boolean {
      return (this.currentTimesheet?.timesheetTypeID ?? 0) == TimesheetType.Equipment;
    },
    currentTimesheetIsReadonly(): boolean {
      return this.readOnly;
    },
    canModifySelectedCrew(): boolean {
      if (!this.selectedCrewID) return false;
      return this.selectedCrew?.ownerID == this.curUserID || this.currentUserCanConfigureSettings;
    },
    // Used for both the table templates (sorting doesn't matter) and table headers (sorting DOES matter)
    allContractorWorkSubTypes(): WorkSubType[] {
      let contractorWorkSubTypes = [] as WorkSubType[];
      this.selectableWorkTypes.forEach(wt => {
        let subTypes = SortWorkSubTypes(
          this.allSelectableWorkSubTypes.filter(wst => wst.workTypeID == wt.id)
        );
        subTypes.forEach(wst => {
          contractorWorkSubTypes.push(wst);
        });
      });
      return contractorWorkSubTypes;
    },
    summaryWorkSubTypes(): WorkSubType[] {
      // Equipment timesheets don't have work sub types, and always
      if (this.currentTimesheetIsEquipment) return [];

      let summaryWorkSubTypes = [] as WorkSubType[];

      if (this.hasIndirectRows) {
        summaryWorkSubTypes = summaryWorkSubTypes.concat(this.indirectWorkSubTypes);
      }
      if (this.hasGeneralDirectRows) {
        summaryWorkSubTypes = summaryWorkSubTypes.concat(this.generalizedDirectWorkSubTypes);
      }
      if (this.hasWorkOrderDirectRows) {
        summaryWorkSubTypes = summaryWorkSubTypes.concat(this.workOrderWorkSubTypes);
      }

      return SortAllWorkSubTypes(summaryWorkSubTypes, this.allWorkTypes);
    },
    workOrderWorkSubTypes(): WorkSubType[] {
      return this.allContractorWorkSubTypes.filter(
        x => this.workSubTypeIsDirect(x.id!) && this.workSubTypeIsDirectAndWorkOrderRelated(x.id!)
      );
    },
    indirectWorkSubTypes(): WorkSubType[] {
      return this.allContractorWorkSubTypes.filter(x => this.workSubTypeIsIndirect(x.id!));
    },
    generalizedDirectWorkSubTypes(): WorkSubType[] {
      return this.allContractorWorkSubTypes.filter(
        x => this.workSubTypeIsDirect(x.id!) && !this.workSubTypeIsDirectAndWorkOrderRelated(x.id!)
      );
    },
    summaryTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell",
          cellClass: "fd-table-icon-cell"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName"
        }
      ] as TableHeader[];

      if (!this.currentTimesheetIsEquipment) {
        headers = headers.concat([
          {
            text: this.$t("timesheets.existing.work-order-column-label") as
              | string
              | TranslateResult
              | undefined,
            value: "workOrderNumber"
          },
          {
            text: this.$t("timesheets.existing.area-column-label") as
              | string
              | TranslateResult
              | undefined,
            value: "areaName"
          },
          {
            text: this.$t("timesheets.existing.sub-area-column-label") as
              | string
              | TranslateResult
              | undefined,
            value: "subAreaName"
          }
        ] as TableHeader[]);

        for (let workSubType of this.summaryWorkSubTypes) {
          if (!workSubType.useWorkOrderCostCode && !workSubType.defaultCostCodeID?.length) {
            console.log(
              `${workSubType.name} - defaultCostCodeID: ${workSubType.defaultCostCodeID}, useWorkOrderCostCode: ${workSubType.useWorkOrderCostCode}`
            );
          }
          headers.push({
            text: workSubType.code ?? workSubType.name,
            value: workSubType.id,
            class: "fd-rotate-header-text"
          });
        }
      } else {
        headers.push({
          text: this.$t("timesheets.existing.equipment-days-column-label"),
          value: "equipmentDays",
          class: "fd-rotate-header-text"
        });
        headers.push({
          text: this.$t("timesheets.existing.equipment-quantity-column-label"),
          value: "equipmentQuantity",
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "text-end"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }
      headers.push({
        text: this.$t("common.action"),
        value: "action",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    workOrderTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell",
          cellClass: "fd-table-icon-cell"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName"
        },
        {
          text: this.$t("timesheets.existing.work-order-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "workOrderNumber"
        }
      ] as TableHeader[];

      for (let workSubType of this.workOrderWorkSubTypes) {
        if (!workSubType.useWorkOrderCostCode && !workSubType.defaultCostCodeID?.length) {
          console.log(
            `${workSubType.name} - defaultCostCodeID: ${workSubType.defaultCostCodeID}, useWorkOrderCostCode: ${workSubType.useWorkOrderCostCode}`
          );
        }
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      headers.push({
        text: this.$t("common.action"),
        value: "action",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    generalizedDirectTableHeaders(): TableHeader[] {
      let headers = [
        // {
        //   value: "empty",
        //   sortable: false,
        //   class: "fd-table-icon-cell",
        //   cellClass: "fd-table-icon-cell"
        // },
        {
          value: "icon",
          sortable: false,
          class: "fd-table-icon-cell",
          cellClass: "fd-table-icon-cell"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName"
        },
        // {
        //   text: this.$t("timesheets.existing.work-order-column-label") as
        //     | string
        //     | TranslateResult
        //     | undefined,
        //   value: "workOrderNumber"
        // },
        {
          text: this.$t("timesheets.existing.area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "areaName"
        },
        {
          text: this.$t("timesheets.existing.sub-area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "subAreaName"
        }
      ] as TableHeader[];

      for (let workSubType of this.generalizedDirectWorkSubTypes) {
        if (!workSubType.useWorkOrderCostCode && !workSubType.defaultCostCodeID?.length) {
          console.log(
            `${workSubType.name} - defaultCostCodeID: ${workSubType.defaultCostCodeID}, useWorkOrderCostCode: ${workSubType.useWorkOrderCostCode}`
          );
        }
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }
      headers.push({
        text: this.$t("common.action"),
        value: "action",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    indirectTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "icon",
          sortable: false,
          class: "fd-table-icon-cell",
          cellClass: "fd-table-icon-cell"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName"
        },
        // {
        //   text: this.$t("timesheets.existing.work-order-column-label") as
        //     | string
        //     | TranslateResult
        //     | undefined,
        //   value: "workOrderNumber"
        // },
        {
          text: this.$t("timesheets.existing.area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "areaName"
        },
        {
          text: this.$t("timesheets.existing.sub-area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "subAreaName"
        }
      ] as TableHeader[];

      for (let workSubType of this.indirectWorkSubTypes) {
        if (!workSubType.useWorkOrderCostCode && !workSubType.defaultCostCodeID?.length) {
          console.log(
            `${workSubType.name} - defaultCostCodeID: ${workSubType.defaultCostCodeID}, useWorkOrderCostCode: ${workSubType.useWorkOrderCostCode}`
          );
        }
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }
      headers.push({
        text: this.$t("common.action"),
        value: "actions",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    equipmentTableHeaders(): TableHeader[] {
      if (!this.equipmentSubType) return [];

      let headers = [
        {
          value: "icon",
          sortable: false,
          class: "fd-table-icon-cell",
          cellClass: "fd-table-icon-cell"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName"
        }
      ] as TableHeader[];

      headers.push({
        text: this.$t("timesheets.existing.equipment-days-column-label"),
        value: "equipmentDays",
        class: "fd-rotate-header-text"
      });
      headers.push({
        text: this.$t("timesheets.existing.equipment-quantity-column-label"),
        value: "equipmentQuantity",
        class: "fd-rotate-header-text"
      });

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      headers.push({
        text: this.$t("common.action"),
        value: "action",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    unwatchedMethodNames(): string[] {
      return [
        "workSubTypeNameForExplanation",
        "canEditPerDiem",
        "rowCanEditWorkSubType",
        "sum",
        "canEditTimesheet",
        "saveDialog",
        "submitDialog",
        "getFieldRef",
        "focusFieldForVisibleItemAtIndex",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "calculateTotal",
        "calculateTotalForItem",
        "calculateTotalForItems",
        "formatNumber",
        "selectableWorkSubTypesForWorkTypeID",
        "clientWorkOrderNumberForGroup",
        "changeOrderNumberForGroup",
        "reworkNumberForGroup"
      ];
    },
    contractor(): ContractorWithTags | undefined {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.currentTimesheet?.contractorID);
      return contractor;
    },
    selectableAreas(): ProjectLocation[] {
      if (!this.contractor?.areaIDs?.length) return [];
      return this.allAreas.filter(x => this.contractor!.areaIDs!.includes(x.id!));
    },
    selectableWorkTypes(): WorkType[] {
      if (!this.contractor?.workTypeIDs?.length) return [];
      return SortWorkTypes(
        this.allWorkTypes.filter(
          x => !x.isPerDiem && !x.isEquipment && this.contractor?.workTypeIDs?.includes(x.id!)
        )
      );
    },
    allWorkTypes(): WorkType[] {
      return this.$store.state.workTypes.fullList as WorkType[];
    },
    allWorkSubTypes(): WorkSubType[] {
      return (this.$store.state.workSubTypes.fullList as WorkSubType[]).filter(
        x => !!this.workSubTypeIsConfiguredCorrectly(x)
      );
    },
    perDiemSubType(): WorkSubType | undefined {
      if (!this.contractor?.employeesReceivePerDiem) return undefined;
      if (this.currentTimesheetIsEquipment) return undefined;

      let perDiemTypeID = this.allWorkTypes.find(x => !!x.isPerDiem)?.id;
      return this.allWorkSubTypes.find(x => x.workTypeID == perDiemTypeID);
    },
    equipmentSubType(): WorkSubType | undefined {
      if (!this.currentTimesheetIsEquipment) return undefined;

      let equipmentTypeID = this.allWorkTypes.find(x => !!x.isEquipment)?.id;
      return this.allWorkSubTypes.find(x => x.workTypeID == equipmentTypeID);
    },
    allSelectableWorkSubTypes(): WorkSubType[] {
      return this.selectableGroupedWorkSubType
        .filter(x => !!(x as WorkSubType))
        .map(x => x as WorkSubType);
    },
    selectableGroupedWorkSubType(): GroupableSelectListOption<WorkSubType>[] {
      if (!this.contractor?.workTypeIDs?.length) return [];

      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let entriesWithTime = [] as UpdatableTimesheetEntryWithDetails[];
      if (!!this.allTimesheetRows.length) {
        entriesWithTime = this.currentTimesheet!.getEntries(
          this.perDiemSubType,
          this.equipmentSubType,
          this.allWorkSubTypes,
          allPeople
        ).filter(x => !!x.regularTime);
      }

      let selectableGroupedWorkSubType = [] as GroupableSelectListOption<WorkSubType>[];
      this.selectableWorkTypes.forEach(workType => {
        let selectableSubTypes = this.selectableWorkSubTypesForWorkTypeID(workType.id);
        if (!selectableSubTypes?.length) return;

        selectableGroupedWorkSubType.push({ header: workType.name! });
        selectableGroupedWorkSubType = selectableGroupedWorkSubType.concat(
          selectableSubTypes.map(x => ({
            ...x,
            displayName: x.code ?? x.name,
            disabled: entriesWithTime.findIndex(e => e.workSubTypeID == x.id) !== -1
          }))
        );
      });
      return selectableGroupedWorkSubType;
    },
    selectedRowType(): TimesheetRowType {
      let rowType = TimesheetRowType.DirectWorkOrderRelated;
      if (this.selectedEntryType == "generaldirect") rowType = TimesheetRowType.DirectGeneral;
      else if (this.selectedEntryType == "indirect") rowType = TimesheetRowType.Indirect;
      else if (this.selectedEntryType == "equipment") rowType = TimesheetRowType.Equipment;
      return rowType;
    },
    selectableEmployees(): (PersonWithDetailsAndName & { disabled?: boolean })[] {
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let selectableEmployees = allPeople.filter(
        x =>
          !PersonHasEquipmentClassification(x) &&
          !!x.contractorID &&
          x.contractorID == this.currentTimesheet?.contractorID
      );
      if (this.currentTimesheetIsEquipment) {
        selectableEmployees = allPeople.filter(
          x =>
            !!PersonHasEquipmentClassification(x) &&
            !!x.contractorID &&
            x.contractorID == this.currentTimesheet?.contractorID
        );
      }
      let sortedSelectableEmployees = SortItemsWithName(
        selectableEmployees.map(x => ({
          ...x,
          name: GetPersonName(x),
          nameWithBadge: GetPersonName(x, true, true),
          disabled: this.allTimesheetRowsAlreadyExists(
            this.timesheet.isLocked,
            x.id,
            this.selectedRowType,
            this.selectedWorkOrders,
            !!this.selectedWorkOrders.length ? undefined : null, // If we have a work order selected, we don't care about the area.  If we don't, the check against empty area.
            !!this.selectedWorkOrders.length ? undefined : null // If we have a work order selected, we don't care about the subarea.  If we don't, the check against empty subarea.
          )
        }))
      );

      return sortedSelectableEmployees;
    },
    selectedEmployee(): PersonWithDetailsAndName | undefined {
      return this.selectableEmployees.find(x => x.id == this.selectedEmployeeID);
    },
    availableCrews(): CrewWithEmployees[] {
      let allCrews = this.$store.state.crews.fullList as CrewWithEmployees[];
      let availableCrews = allCrews.filter(
        x => x.contractorID == this.currentTimesheet?.contractorID
        // For now, we rely on the server to determine what crews are visible for this person, and we show ALL visible crews for this contractor
        // This means that admins with Configure Settings permission see all crews for all of this contractor's employees
        // && (!x.ownerID || x.ownerID == this.curUserID)
      );
      return availableCrews;
    },
    selectableCrews(): GroupableSelectListOption<CrewWithEmployees>[] {
      let selectableCrews = this.availableCrews.map(
        x =>
          ({
            ...x
          } as SelectListOption<CrewWithEmployees>)
      );

      let allPeople = (this.$store.state.users.fullList as PersonWithDetails[]).map(p => ({
        ...p,
        name: GetPersonName(p)
      }));
      let selectableCrewsByOwnerName = selectableCrews.reduce((a, b) => {
        let ownerName = allPeople.find(p => p.id == b.ownerID)?.name ?? "";
        let existingCrews = a[ownerName] ?? [];
        existingCrews.push(b);
        a[ownerName] = existingCrews;
        return a;
      }, {} as HashTable<SelectListOption<CrewWithEmployees>[]>);

      var curUserName = allPeople.find(p => p.id == this.curUserID)?.name ?? this.curUserID;
      let myCrews = SortItemsWithName(selectableCrewsByOwnerName[curUserName]);
      let unownedCrews = SortItemsWithName(selectableCrewsByOwnerName[""]);
      let otherCrewOwnerNames = Object.keys(selectableCrewsByOwnerName)
        .filter(x => x != "" && x != curUserName)
        .sort();

      let returnList = [] as GroupableSelectListOption<CrewWithEmployees>[];
      if (myCrews.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.my-crews") });
        myCrews.forEach(c => returnList.push(c));
        if (unownedCrews.length > 0 || otherCrewOwnerNames.length > 0)
          returnList.push({ divider: true });
      }

      if (unownedCrews.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.global-crews") });
        unownedCrews.forEach(c => returnList.push(c));
        if (otherCrewOwnerNames.length > 0) returnList.push({ divider: true });
      }

      if (otherCrewOwnerNames.length > 0) {
        for (let i = 0; i < otherCrewOwnerNames.length; i++) {
          let ownerName = otherCrewOwnerNames[i];
          let list = selectableCrewsByOwnerName[ownerName];
          if (list.length > 0) {
            returnList.push({ header: ownerName });
            list.forEach(c => returnList.push(c));
            if (i + 1 < otherCrewOwnerNames.length) returnList.push({ divider: true });
          }
        }
      }

      return returnList;
    },
    selectedCrew(): CrewWithEmployees | undefined {
      return this.availableCrews.find(x => x.id == this.selectedCrewID);
    },
    selectedEmployees(): PersonWithDetailsAndName[] {
      var selectedEmployeeIDs = [] as string[];
      if (!!this.selectedEmployeeID) selectedEmployeeIDs.push(this.selectedEmployeeID);
      if (!!this.selectedCrew)
        selectedEmployeeIDs = selectedEmployeeIDs.concat(
          SortCrewEmployees(this.selectedCrew.employees).map(x => x.employeeID!)
        );

      return selectedEmployeeIDs
        .filter(x => !!this.selectableEmployees.find(e => e.id! == x))
        .map(x => this.selectableEmployees.find(e => e.id! == x)!);
    },
    timesheetIsDeclined(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Declined;
    },
    timesheetDeclineComments(): string | undefined {
      return this.currentTimesheet?.lastStatusLog?.comments;
    },
    formattedDay(): string {
      return this.formatDate(this.currentTimesheet?.day);
    },
    workOrderSelectorNoDataText(): string | TranslateResult | undefined {
      if (this.selectedEntryType != "workorder")
        return this.$t("timesheets.existing.work-orders-not-selectable");
      if (this.workOrderSearching) return this.$t("loading-dot-dot-dot");
      if (!this.didWorkOrderSearch)
        return this.$t("timesheets.existing.no-work-orders", [this.formattedDay]);

      return undefined;
    }
  },

  watch: {
    timesheet() {
      this.loadDataForTimesheet();
      if (!this.selectedEntryType && this.workTypeOptions.length == 1)
        this.selectedEntryType = this.workTypeOptions[0].value;
    },
    workOrderSearch(newValue) {
      // If the user kept typing/changed the search before the service call, cancel the previous call in preparation of the new one
      if (this.timer) clearTimeout(this.timer);

      let restrictDay = false;
      let restrictAssignment = false;
      if (!newValue?.length) {
        // If we're searching with NO search value, re-search using the initial "Recommended" search value
        restrictDay = true;
        restrictAssignment = true;
      }

      var obj = this;
      this.workOrderSearching = true;
      // Delay the service call to allow the user to keep typing if they choose before making a server call
      this.timer = setTimeout(async function() {
        obj.processing = true;
        obj.$nextTick(async () => {
          try {
            await obj.loadWorkOrders(newValue, restrictDay, restrictAssignment);
          } finally {
            obj.timer = null;
            obj.didWorkOrderSearch = true;
            obj.processing = false;
            obj.workOrderSearching = false;
          }
        });
      }, 1500);
    }
  },

  methods: {
    crewIsSelectable(crew: CrewWithEmployees | undefined): boolean {
      if (!crew?.employees?.length) return false;

      var crewEmployeesWithoutRows = crew.employees.filter(
        ce =>
          !this.allTimesheetRowsAlreadyExists(
            this.timesheet.isLocked,
            ce.employeeID,
            this.selectedRowType,
            this.selectedWorkOrders,
            !!this.selectedWorkOrders.length ? undefined : null, // If we have a work order selected, we don't care about the area.  If we don't, the check against empty area.
            !!this.selectedWorkOrders.length ? undefined : null // If we have a work order selected, we don't care about the subarea.  If we don't, the check against empty subarea.
          )
      );
      return !!crewEmployeesWithoutRows?.length;
    },
    formatDate(date: string | Date | undefined | null): string {
      return stripTimeFromLocalizedDateTime(date);
    },
    addTimesheetRowRules(): any {
      return {
        selectedEntryType: [this.rules.required],
        selectedWorkOrders:
          this.selectedEntryType == "workorder" ? [this.rules.required] : undefined,
        selectedCrewID: !this.selectedEmployeeID ? [this.rules.required] : undefined,
        selectedEmployeeID: !this.selectedCrewID ? [this.rules.required] : undefined
      };
    },
    validTimeForDayRule(row: TimesheetRow): boolean | TranslateResult | string {
      let totalHours = this.totalTimeForEmployeOnDay(row.employeeID);

      let errorMessage = "";
      if (totalHours < 0) {
        errorMessage = `${this.$t("timesheets.existing.too-few-hours-error-message")}`;
      } else if (totalHours > 20) {
        errorMessage = `${this.$t("timesheets.existing.too-many-hours-error-message")}`;
      } else {
        let negativeWstID = ParseWorkSubTypeIDsFromRow(row).find(x => {
          let totalHoursForSubType = this.totalTimeForEmployeForWorkSubTypeOnDay(row.employeeID, x);
          return totalHoursForSubType < 0;
        });
        if (!!negativeWstID) {
          let workSubType = this.allWorkSubTypes.find(x => x.id == negativeWstID);
          errorMessage = `${this.$t(
            "timesheets.existing.too-few-work-sub-type-hours-error-message",
            [workSubType?.name]
          )}`;
        }
      }

      if (!!errorMessage) {
        if (!row.errorMessage.includes(errorMessage)) {
          row.errorMessage = errorMessage;
        }
        return errorMessage;
      } else {
        row.errorMessage = "";
        return true;
      }
    },
    timesheetRowRules(item: TimesheetRow): Array<Function | boolean | TranslateResult | string> {
      return [this.validTimeForDayRule(item)];
    },
    // This method determines if the row in the data-table should be colored to represent if it is "Urgent".
    timesheetRowClassName(item: any) {
      return item.isCorrectionRow ? "fd-correction-table-row-background" : "";
    },
    updateAllIndirectItemValues(workSubTypeID: string, value: any) {
      let rows = this.indirectTimesheetRows;
      for (let row of rows) {
        (row as any)[workSubTypeID] = value;
        this.confirmEmployeePerDiemForRow(row);
      }
      this.checkWorkSubTypeAdditionalDetails(value, workSubTypeID, null, null);
    },
    updateAllEquipmentDayValues(value: any) {
      let rows = this.equipmentTimesheetRows;
      for (let row of rows) {
        row.equipmentDays = value;
      }
    },
    updateAllEquipmentQuantityValues(value: any) {
      let rows = this.equipmentTimesheetRows;
      for (let row of rows) {
        row.equipmentQuantity = value;
      }
    },
    updateAllNonWorkOrderItemValues(workSubTypeID: string, value: any) {
      let rows = this.generalizedDirectTimesheetRows;
      for (let row of rows) {
        (row as any)[workSubTypeID] = value;
        this.confirmEmployeePerDiemForRow(row);
      }
      this.checkWorkSubTypeAdditionalDetails(value, workSubTypeID, null, null);
    },
    updateAllWorkOrderItemValues(workOrderNumber: string, workSubTypeID: string, value: any) {
      let rows = this.workOrderTimesheetRows.filter(x => x.workOrderNumber == workOrderNumber);
      var workOrderID = "" as string | null | undefined;
      for (let row of rows) {
        (row as any)[workSubTypeID] = value;
        if (!workOrderID) workOrderID = row.workOrderID;
        this.confirmEmployeePerDiemForRow(row);
      }
      this.checkWorkSubTypeAdditionalDetails(value, workSubTypeID, workOrderID, workOrderNumber);
    },
    checkWorkSubTypeAdditionalDetails(
      newValue: number,
      workSubTypeID: string,
      workOrderID: string | null | undefined,
      workOrderNumber: string | null | undefined
    ) {
      let wst = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!!wst?.requiresAdditionalDetails) {
        let existingExplanation = this.currentTimesheet?.explanations?.find(
          x => x.workSubTypeID == workSubTypeID && x.workOrderID == (workOrderID ?? "")
        );

        if (newValue > 0) {
          if (!existingExplanation) {
            this.showNewExplanationDialog(
              workOrderID ?? "",
              workOrderNumber ?? "",
              workSubTypeID,
              wst.name ?? ""
            );
          }
        } else {
          // Value was removed for this WST/WO combination
          // Check if there are any other entries with this combination
          if (!!existingExplanation) {
            let otherExistingRowsWithValue = this.allTimesheetRows.filter(
              x =>
                x.workOrderID == workOrderID &&
                !!(x as any)[workSubTypeID] &&
                (x as any)[workSubTypeID] > 0
            );
            if (!otherExistingRowsWithValue?.length) {
              let existingIndex = this.currentTimesheet!.explanations.indexOf(existingExplanation);
              this.currentTimesheet!.explanations.splice(existingIndex, 1);
            }
          }
        }
      }
    },
    totalTimeForEmployeeForWorkSubType(employeeID: string, workSubTypeID: string): number {
      var total = 0;
      let employeeRows = this.allTimesheetRows.filter(x => x.employeeID == employeeID);
      for (let row of employeeRows) {
        let hours = Number((row as any)[workSubTypeID]);
        if (isNaN(hours)) hours = 0;
        total += hours;
      }
      return total;
    },
    totalTimeForEmployeForWorkSubTypeOnDay(employeeID: string, workSubTypeID: string): number {
      let fullSummary = this.timeSummaries?.find(x => x.employeeID == employeeID);
      let summary = fullSummary?.workSubTypeTimeSummaries.find(
        x => x.workSubTypeID == workSubTypeID
      );
      let otherTotalRegularTime = summary?.totalRegularTime ?? 0;
      let totalRegularTime =
        otherTotalRegularTime + this.totalTimeForEmployeeForWorkSubType(employeeID, workSubTypeID);
      return totalRegularTime;
    },
    totalTimeForEmployee(employeeID: string): number {
      var total = 0;
      let employeeRows = this.allTimesheetRows.filter(x => x.employeeID == employeeID);
      for (let row of employeeRows) {
        // Clear out all hours values for row to be removed to confirm if explanations also need to be removed
        ParseWorkSubTypeIDsFromRow(row).forEach(wstid => {
          let hours = Number((row as any)[wstid]);
          if (isNaN(hours)) hours = 0;
          total += hours;
        });
      }
      return total;
    },
    totalTimeForEmployeOnDay(employeeID: string): number {
      let summary = this.timeSummaries?.find(x => x.employeeID == employeeID);
      let otherTotalRegularTime = summary?.totalRegularTime ?? 0;
      let totalRegularTime = otherTotalRegularTime + this.totalTimeForEmployee(employeeID);
      return totalRegularTime;
    },
    confirmEmployeePerDiemForRow(row: TimesheetRow) {
      // console.log(`confirmEmployeePerDiemForRow`);
      // If the timesheet is locked and this is a correction row, don't do any automatic logic
      if (row.isCorrectionRow) return;
      // Confirm the hours for this work sub type don't go below 0 or above 18
      let totalRegularTime = this.totalTimeForEmployeOnDay(row.employeeID);
      let perdiemOtherTimesheetOwner = this.perDiemOwnerFromOtherTimesheet(row);
      if (!perdiemOtherTimesheetOwner && !!this.contractor?.employeesReceivePerDiem) {
        let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
        let person = allPeople.find(x => x.id == row.employeeID);
        if (!!person && !person.disableAutomaticPerDiem) {
          let requiredHours = this.contractor.perDiemHoursRequirement ?? 0;

          if (totalRegularTime >= requiredHours) {
            let existingPerDiemRow = this.existingPerDiemRowForEmployee(row.employeeID);
            if (!existingPerDiemRow) {
              let desiredRowType =
                this.currentTimesheet?.timesheetTypeID == TimesheetType.Indirect
                  ? TimesheetRowType.Indirect
                  : TimesheetRowType.DirectGeneral;
              if (row.rowType == desiredRowType) {
                row.hasPerDiem = true;
              } else {
                let existingIndirectRow = this.getExistingTimesheetRow(
                  row.isCorrectionRow,
                  row.employeeID,
                  desiredRowType,
                  null,
                  undefined,
                  undefined
                );
                if (!!existingIndirectRow) {
                  existingIndirectRow.hasPerDiem = true;
                } else {
                  let newRow = this.addNonWorkOrderRelatedTimesheetRow(
                    row.isCorrectionRow,
                    row.employeeID,
                    row.employeeName,
                    row.employeeCode,
                    row.classificationID,
                    desiredRowType,
                    null,
                    null
                  );
                  newRow.hasPerDiem = true;
                }
              }
            }
          } else {
            let existingPerDiemRow = this.existingPerDiemRowForEmployee(row.employeeID);
            if (!!existingPerDiemRow) {
              existingPerDiemRow.hasPerDiem = false;
            }
          }
        }
      }
    },
    workSubTypeHoursValueChanged(row: TimesheetRow, workSubTypeID: string, newValue: any) {
      if (row.rowType == TimesheetRowType.Equipment) {
        console.error("Tried changing Equipment value by WST ID!");
        return;
      }

      // When we display  numbers in read-only, we only display 2 decimal digits
      // The server also automatically rounds to 2 decimal digits
      // As such, when the user types the value we want to make sure they don't end up saving a different value than they typed
      let valueNumber = Number(newValue);
      if (isNaN(valueNumber)) valueNumber = 0;

      let sanitizedValueNumber = Number(valueNumber.toFixed(2));
      if (isNaN(sanitizedValueNumber)) sanitizedValueNumber = 0;

      if (valueNumber != sanitizedValueNumber) {
        this.$nextTick(() => {
          (row as any)[workSubTypeID] = sanitizedValueNumber;
          this.workSubTypeHoursValueChanged(row, workSubTypeID, sanitizedValueNumber);
        });
        return;
      }

      // If the timesheet is locked and this is a correction row, don't do any automatic logic
      if (row.isCorrectionRow) return;

      this.confirmEmployeePerDiemForRow(row);

      this.checkWorkSubTypeAdditionalDetails(
        newValue,
        workSubTypeID,
        row.workOrderID,
        row.workOrderNumber
      );
    },
    async showNewExplanationDialog(
      workOrderID: string,
      workOrderNumber: string,
      workSubTypeID: string,
      workSubTypeName: string
    ) {
      if (!this.currentTimesheet?.id) return;

      let newExplanation = {
        timesheetID: this.currentTimesheet.id,
        workOrderID: workOrderID,
        workOrderNumber: workOrderNumber,
        workSubTypeID: workSubTypeID
      } as TimesheetExplanationWithWorkOrderDetails;

      let title = this.$t("timesheets.existing.additional-details-label", [
        `WO#${workOrderNumber}`
      ]);
      let label = workSubTypeName;
      let explanationText = await showAdditionalDetailsDialog(title, label);

      newExplanation.explanation = explanationText;
      this.currentTimesheet.explanations = this.currentTimesheet.explanations?.concat([
        newExplanation
      ]);
    },
    addIndirectRowRelatedToExistingRow(row: TimesheetRow) {
      if (row.rowType == TimesheetRowType.DirectWorkOrderRelated) return;
      if (row.rowType == TimesheetRowType.Equipment) return;
      if (!this.currentTimesheet) return;

      // If this person doesn't have a general indirect row, add that too
      let existingIndirectRow = this.timesheetRowAlreadyExists(
        row.isCorrectionRow,
        row.employeeID,
        row.rowType,
        null,
        null,
        null
      );
      if (!existingIndirectRow) {
        this.addNonWorkOrderRelatedTimesheetRow(
          row.isCorrectionRow,
          row.employeeID,
          row.employeeName,
          row.employeeCode,
          row.classificationID,
          row.rowType,
          null,
          null
        );
        this.currentTimesheet!.timesheetRows = SortTimesheetRows(
          this.currentTimesheet!.timesheetRows
        );
      }
    },
    getExistingTimesheetRow(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      rowType: TimesheetRowType,
      workOrderID: string | null | undefined,
      areaID: string | null | undefined,
      subAreaID: string | null | undefined
    ): TimesheetRow | undefined {
      return this.allTimesheetRows?.find(
        t =>
          t.isCorrectionRow == isCorrectionRow &&
          t.employeeID == employeeID &&
          t.rowType == rowType &&
          (t.workOrderID ?? "") == (workOrderID ?? "") &&
          (areaID === undefined || (t.areaID ?? "") == (areaID ?? "")) &&
          (subAreaID == undefined || (t.subAreaID ?? "") == (subAreaID ?? ""))
      );
    },
    allTimesheetRowsAlreadyExists(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      rowType: TimesheetRowType,
      workOrders: FormattedWorkOrder[],
      areaID: string | null | undefined,
      subAreaID: string | null | undefined
    ): boolean {
      if (!workOrders.length) {
        return !!this.getExistingTimesheetRow(
          isCorrectionRow,
          employeeID,
          rowType,
          undefined,
          areaID,
          subAreaID
        );
      }

      let existingRows = [];
      for (let wo of workOrders) {
        let existingRow = this.getExistingTimesheetRow(
          isCorrectionRow,
          employeeID,
          rowType,
          wo.id!,
          undefined,
          undefined
        );
        if (!!existingRow) existingRows.push(existingRow);
      }
      return existingRows.length == workOrders.length;
    },
    timesheetRowAlreadyExists(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      rowType: TimesheetRowType,
      workOrderID: string | null | undefined,
      areaID: string | null | undefined,
      subAreaID: string | null | undefined
    ): boolean {
      return !!this.getExistingTimesheetRow(
        isCorrectionRow,
        employeeID,
        rowType,
        workOrderID,
        areaID,
        subAreaID
      );
    },
    workOrderNumberTextForRow(row: TimesheetRow): string | TranslateResult {
      if (!!row.workOrderNumber) return this.$t("common.work-order-number", [row.workOrderNumber]);
      if (row.rowType == TimesheetRowType.DirectGeneral)
        return this.$t("timesheets.existing.generalized-direct-work-order-placeholder");
      else if (row.rowType == TimesheetRowType.Equipment)
        return this.$t("timesheets.existing.equipment-work-order-placeholder");
      return this.$t("timesheets.existing.indirect-work-order-placeholder");
    },
    selectableSubAreasForRow(row: TimesheetRow | undefined | null): ProjectLocation[] {
      if (!row?.areaID) return [];
      return this.allSubAreas
        .filter(x => x.parentLocationID == row.areaID)
        .map(x => ({
          ...x,
          disabled:
            row.subAreaID != x.id &&
            this.timesheetRowAlreadyExists(
              row.isCorrectionRow,
              row.employeeID,
              row.rowType,
              row.workOrderID,
              row.areaID,
              x.id
            )
        }));
    },
    currentTimesheetExplanationsForWorkOrderNumber(
      woNumber: string
    ): TimesheetExplanationWithWorkOrderDetails[] {
      return this.currentTimesheet?.explanations?.filter(x => x.workOrderNumber == woNumber) ?? [];
    },
    workSubTypeNameForExplanation(
      explanation: TimesheetExplanationWithWorkOrderDetails
    ): string | null | undefined {
      let workSubType = this.allWorkSubTypes.find(x => x.id == explanation.workSubTypeID);
      return workSubType?.name;
    },
    allGroupsExpanded(tableIdentifier: string): boolean {
      let datatableRef = `${tableIdentifier}datatable`;
      let datatable = this.$refs[datatableRef] as VDataTable;
      if (!datatable) {
        // console.log(`datatable "${datatableRef}" not found`);
        return false;
      }
      let toggleRefs = Object.keys(this.$refs).filter(x =>
        x.startsWith(`${tableIdentifier}grouptoggle`)
      );
      let anyGroupsClosed = false;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`${tableIdentifier}grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if (!isOpen) {
          anyGroupsClosed = true;
          break;
        }
      }
      return !anyGroupsClosed;
    },
    validate(): boolean {
      let additionalDetailsValid =
        (this.$refs.additionaldetailsform as HTMLFormElement)?.validate() ?? true;
      let timesheetValid = (this.$refs.timesheetform as HTMLFormElement)?.validate() ?? true;
      return additionalDetailsValid && timesheetValid;
    },
    workSubTypeIsConfiguredCorrectly(workSubType: WorkSubType | undefined): boolean {
      if (!workSubType) return false;

      return (
        (!!workSubType.isWorkOrderRelated && !!workSubType.useWorkOrderCostCode) ||
        !!workSubType.defaultCostCodeID
      );
    },
    workSubTypeIsIndirect(workSubTypeID: string): boolean {
      let workSubType = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!workSubType) return false;

      let workType = this.allWorkTypes.find(x => !x.isEquipment && x.id == workSubType?.workTypeID);
      if (!workType) return false;

      return !workType.isDirect ?? false;
    },
    workSubTypeIsEquipment(workSubTypeID: string): boolean {
      let workSubType = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!workSubType) return false;

      let workType = this.allWorkTypes.find(
        x => !!x.isEquipment && x.id == workSubType?.workTypeID
      );
      if (!workType) return false;

      return workType.isEquipment ?? false;
    },
    workSubTypeIsDirect(workSubTypeID: string): boolean {
      let workSubType = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!workSubType) return false;

      let workType = this.allWorkTypes.find(x => x.id == workSubType?.workTypeID);
      if (!workType) return false;

      return workType.isDirect ?? false;
    },
    workSubTypeIsDirectAndWorkOrderRelated(workSubTypeID: string): boolean {
      let workSubType = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!workSubType) return false;

      let isDirect = this.workSubTypeIsDirect(workSubTypeID);
      let isWorkOrderRelated = workSubType.isWorkOrderRelated ?? false;
      return isDirect && isWorkOrderRelated;
    },
    rowIsEditable(item: TimesheetRow): boolean {
      if (!item.isCorrectionRow && this.currentTimesheetIsReadonly) return false;
      if (!!item.isCorrectionRow && !this.makeCorrections) return false;
      return true;
    },
    rowCanEditWorkSubType(row: TimesheetRow, workSubTypeID: string): boolean {
      if (row.rowType == TimesheetRowType.Equipment) return false;
      if (!row.isCorrectionRow && this.currentTimesheetIsReadonly) return false;
      if (!!row.isCorrectionRow && !this.makeCorrections) return false;

      let workSubTypeIsDirect = this.workSubTypeIsDirect(workSubTypeID);
      let workSubTypeIsDirectAndWorkOrderRelated = this.workSubTypeIsDirectAndWorkOrderRelated(
        workSubTypeID
      );
      let hasWorkOrder = !!row.workOrderID?.length ?? false;
      let canEdit =
        (workSubTypeIsDirect && workSubTypeIsDirectAndWorkOrderRelated && hasWorkOrder) ||
        (workSubTypeIsDirect &&
          !workSubTypeIsDirectAndWorkOrderRelated &&
          row.rowType == TimesheetRowType.DirectGeneral) ||
        (!workSubTypeIsDirect && row.rowType == TimesheetRowType.Indirect);
      return canEdit;
    },
    existingPerDiemRowForEmployee(employeeID: string): TimesheetRow | undefined {
      return this.allTimesheetRows.find(x => x.employeeID == employeeID && x.hasPerDiem);
    },
    existingOtherCorrectionPerDiemRow(row: TimesheetRow): boolean {
      let correctionPerDiemRow = this.allTimesheetRows.find(
        x =>
          x.employeeID == row.employeeID && (x.hasPerDiem || x.removePerDiem) && !!x.isCorrectionRow
      );
      return !!correctionPerDiemRow && !areTimesheetRowsEqual(row, correctionPerDiemRow);
    },
    // Only indirect work rows can have per diem entry
    isPerDiemApplicable(item: TimesheetRow): boolean {
      return (
        !item.workOrderID &&
        (item.rowType == TimesheetRowType.DirectGeneral ||
          item.rowType == TimesheetRowType.Indirect)
      );
    },
    // Only one row can have a per diem per employee
    canEditPerDiem(item: TimesheetRow): boolean {
      // Per Diem's can ONLY be associated to non-work-order timesheet rows
      if (!!item.workOrderID) return false;
      // Only admins on indirect timesheets can edit per diems
      if (item.rowType == TimesheetRowType.Equipment) return false;

      let existingPerDiemRowForEmployee = this.existingPerDiemRowForEmployee(item.employeeID);
      if (!!existingPerDiemRowForEmployee) {
        // If there is an existing per diem row on this timesheet, check if it's the current row
        // If it is it's editable, if it's not it's not editable
        let existingPerDiemRowIsCurrentItem =
          item.isCorrectionRow == existingPerDiemRowForEmployee.isCorrectionRow &&
          item.workOrderID == existingPerDiemRowForEmployee.workOrderID &&
          item.rowType == existingPerDiemRowForEmployee.rowType &&
          (item.areaID ?? "") == (existingPerDiemRowForEmployee.areaID ?? "") &&
          (item.subAreaID ?? "") == (existingPerDiemRowForEmployee.subAreaID ?? "");
        return existingPerDiemRowIsCurrentItem;
      } else {
        // If there isn't an existing per diem row, it may exist on another timesheet.  Check the time summary
        let employeeSummary = this.timeSummaries?.find(x => x.employeeID == item.employeeID);
        let perdiemSummary = employeeSummary?.workSubTypeTimeSummaries.find(
          x => x.workSubTypeID == this.perDiemSubType?.id
        );
        return !perdiemSummary?.totalUnits;
      }
    },
    hasPerDiemOnOtherTimesheet(item: TimesheetRow): boolean {
      let employeeSummary = this.timeSummaries?.find(x => x.employeeID == item.employeeID);
      let perdiemSummary = employeeSummary?.workSubTypeTimeSummaries.find(
        x => x.workSubTypeID == this.perDiemSubType?.id
      );
      return !!perdiemSummary?.totalUnits;
    },
    perDiemOwnerFromOtherTimesheet(item: TimesheetRow): string | undefined {
      let existingPerDiemRowForEmployee = this.existingPerDiemRowForEmployee(item.employeeID);
      if (!existingPerDiemRowForEmployee) {
        let employeeSummary = this.timeSummaries?.find(x => x.employeeID == item.employeeID);
        let perdiemSummary = employeeSummary?.workSubTypeTimeSummaries.find(
          x => x.workSubTypeID == this.perDiemSubType?.id
        );
        if (!!perdiemSummary) {
          return employeeSummary?.perDiemTimesheetOwnerName;
        }
      }

      return undefined;
    },
    perDiemReadonlyReason(item: TimesheetRow) {
      let perdiemOtherTimesheetOwner = this.perDiemOwnerFromOtherTimesheet(item);
      if (!!perdiemOtherTimesheetOwner) {
        return this.$t("timesheets.per-diem-already-applied-chip-label");
      }

      // Let the control use its default text
      return undefined;
    },
    canCorrectPerDiemInRow(row: TimesheetRow): boolean {
      if (!row.hasPerDiem) {
        let perDiemOnOtherTimesheet = !!this.perDiemOwnerFromOtherTimesheet(row);
        if (perDiemOnOtherTimesheet) {
          return false;
        }
      }
      return true;
    },
    perDiemCorrectionItemsForRow(row: TimesheetRow) {
      let items = [
        { text: this.$t("timesheets.existing.correction-no-per-diem-change"), value: false }
      ];
      if (row.hasPerDiem) {
        items.push({
          text: this.$t("timesheets.existing.correction-add-per-diem"),
          value: true
        });
      } else {
        let perDiemOnOtherTimesheet = !!this.perDiemOwnerFromOtherTimesheet(row);
        if (!perDiemOnOtherTimesheet) {
          let employeeHasPerDiem = this.existingPerDiemRowForEmployee(row.employeeID);
          if (employeeHasPerDiem) {
            items.push({
              text: this.$t("timesheets.existing.correction-remove-per-diem"),
              value: true
            });
          } else {
            items.push({
              text: this.$t("timesheets.existing.correction-add-per-diem"),
              value: true
            });
          }
        }
      }
      return items;
    },
    existingEquipmentRowForEmployee(employeeID: string): TimesheetRow | undefined {
      return this.allTimesheetRows.find(x => x.employeeID == employeeID && x.equipmentDays != 0);
    },
    existingOtherCorrectionEquipmentRow(row: TimesheetRow): boolean {
      let correctionEquipmentRow = this.allTimesheetRows.find(
        x => x.employeeID == row.employeeID && x.equipmentDays != 0 && !!x.isCorrectionRow
      );
      return !!correctionEquipmentRow && !areTimesheetRowsEqual(row, correctionEquipmentRow);
    },
    clientWorkOrderNumberForGroup(workOrderNumber: string): string {
      return (
        this.workOrderTimesheetRows.find(x => x.workOrderNumber == workOrderNumber)
          ?.workOrderClientWorkOrderNumber ?? ""
      );
    },
    changeOrderNumberForGroup(workOrderNumber: string): string {
      return (
        this.workOrderTimesheetRows.find(x => x.workOrderNumber == workOrderNumber)
          ?.workOrderChangeOrderNumber ?? ""
      );
    },
    reworkNumberForGroup(workOrderNumber: string): string {
      return (
        this.workOrderTimesheetRows.find(x => x.workOrderNumber == workOrderNumber)
          ?.workOrderReworkNumber ?? ""
      );
    },
    // Only indirect work rows can have per diem entry
    isEquipmentApplicable(item: TimesheetRow): boolean {
      return item.rowType == TimesheetRowType.Equipment;
    },
    // Only one row can have a per diem per employee
    canEditEquipment(row: TimesheetRow): boolean {
      if (!!row.workOrderID || row.rowType != TimesheetRowType.Equipment) return false;
      if (!row.isCorrectionRow && this.currentTimesheetIsReadonly) return false;
      if (!!row.isCorrectionRow && !this.makeCorrections) return false;

      return true;
    },
    canAddNewRowFromRow(row: TimesheetRow): boolean {
      let existingSimilarRowWithoutArea = this.timesheetRowAlreadyExists(
        row.isCorrectionRow,
        row?.employeeID,
        row?.rowType,
        row?.workOrderID,
        null,
        null
      );
      return !existingSimilarRowWithoutArea;
    },
    // Only one row can have a per diem per employee
    canEditNightShift(item: TimesheetRow): boolean {
      // Per Diem's can ONLY be associated to non-work-order timesheet rows
      if (!item.workOrderID) return false;
      return !this.currentTimesheetIsReadonly;
    },
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadEmployees: "LOAD_USERS",
      loadCrews: "LOAD_CREWS",
      loadClassifications: "LOAD_CLASSIFICATIONS"
    }),
    countOn(items: any[], propName: string): number {
      let result = items.reduce((a, b) => a + (!!(b as any)[propName] ? 1 : 0), 0);
      return result;
    },
    sum(items: UpdatableTimesheetEntryWithDetails[], propName: string): string | undefined {
      let result = items.reduce((a, b) => a + Number((b as any)[propName] ?? 0), 0);
      return !result ? undefined : result.toFixed(2);
    },
    toggleTableGroups(tableIdentifier: string, closed: boolean = true) {
      let toggleRefs = Object.keys(this.$refs).filter(x =>
        x.startsWith(`${tableIdentifier}grouptoggle`)
      );
      let datatable = this.$refs[`${tableIdentifier}datatable`] as VDataTable;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`${tableIdentifier}grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if ((closed && isOpen) || (!closed && !isOpen)) {
          datatable.openCache[groupName] = !datatable.openCache[groupName];
        }
      }
    },

    canEditTimesheet(timesheet: UpdatableTimesheetWithTimesheetRows | null | undefined) {
      if (!timesheet?.id) return false;

      let locked = timesheet.isLocked ?? false;
      let currentDay =
        new Date(timesheet.day!.toDateString()).getTime() ==
        new Date(new Date().toDateString()).getTime();
      return !locked && currentDay;
    },

    calculateTotal(item: any): string {
      return this.calculateTotalForItem(item).toFixed(2);
    },
    calculateTotalForItem(item: any): number {
      let total = this.allWorkSubTypes.reduce((a: number, b: any) => {
        let val = Number(item[b.id!]) ?? 0;
        if (isNaN(val)) val = 0;
        return a + val;
      }, 0);

      let equipmentTotal =
        ((item as TimesheetRow).equipmentDays ?? 0) *
        ((item as TimesheetRow).equipmentQuantity ?? 1);

      return total + equipmentTotal;
    },
    calculateTotalForItems(items: any[]): string {
      let total: number =
        items.reduce((a: number, b: any) => {
          return a + this.calculateTotalForItem(b);
        }, 0) ?? 0;
      return total.toFixed(2);
    },

    formatNumber(number: string | number | undefined | null): string | undefined {
      if (!number) return undefined;
      let val = Number(number);
      if (isNaN(val)) return undefined;
      return val.toFixed(2);
    },

    // *** NAVIGATION ***
    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    addFormOnSubmit(e: Event) {
      e.preventDefault();
      this.addTimesheetRows(this.makeCorrections ?? false);
    },

    // *** ENTRY MANAGEMENT ***
    _addTimesheetRow(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      employeeName: string | null | undefined,
      employeeCode: string | undefined,
      employeeClassificationID: string | null | undefined,
      rowType: TimesheetRowType,
      workOrder: FormattedWorkOrder | null,
      areaID: string | null,
      subAreaID: string | null
    ): TimesheetRow {
      // console.log(
      //   `_addTimesheetRow isCorrectionRow: ${isCorrectionRow}, employeeName: ${employeeName}, rowType: ${rowType}, next number will be: ${this
      //     .currentTimesheet?.nextRowNumber ?? 1}`
      // );
      let area = this.allAreas.find(x => x.id == areaID);
      let subArea = this.allSubAreas.find(x => x.id == subAreaID);
      let classificationDisplayName = this.getClassificationDisplayNameForID(
        employeeClassificationID
      );
      let existingRow = this.getExistingTimesheetRow(
        isCorrectionRow,
        employeeID,
        rowType,
        workOrder?.id,
        areaID,
        subAreaID
      );
      if (!!existingRow) return existingRow;
      let newRow = {
        rowType: rowType,
        isCorrectionRow: isCorrectionRow,
        rowNumber: this.currentTimesheet?.nextRowNumber ?? 1,
        employeeID: employeeID,
        employeeName: employeeName,
        employeeCode: employeeCode,
        classificationID: employeeClassificationID,
        classificationDisplayName: classificationDisplayName,
        workOrderID: workOrder?.id ?? "",
        workOrderNumber: `${workOrder?.legacyID ?? ""}`,
        workOrderClientWorkOrderNumber: workOrder?.clientWorkOrderReferenceNumber,
        workOrderChangeOrderNumber: workOrder?.changeOrderReferenceNumber,
        workOrderReworkNumber: workOrder?.reworkReferenceNumber,
        scaffoldID: workOrder?.scaffoldID ?? "",
        areaID: areaID ?? "",
        areaName: area?.name,
        subAreaID: subAreaID ?? "",
        subAreaName: subArea?.name,
        workOrderCostCodeID: workOrder?.costCodeID ?? "",
        hasPerDiem: false,
        removePerDiem: false,
        equipmentDays: 0,
        equipmentQuantity: rowType == TimesheetRowType.Equipment ? 1 : 0,
        errorMessage: ""
      } as TimesheetRow;
      // console.log(`New row added with number: #${newRow.rowNumber}`);

      // Here we find all available WSTs for this row type so we can prefill with null values
      // The purpose of this is to populate the row object with WST IDs as keys so they can be turned into empty Entries if necessary
      // Right now, we want drafts to save empty entries as a "working state", which will be removed when submitted
      let availableWSTs = [] as WorkSubType[];
      switch (rowType) {
        case TimesheetRowType.DirectGeneral:
          availableWSTs = this.generalizedDirectWorkSubTypes;
          break;
        case TimesheetRowType.DirectWorkOrderRelated:
          availableWSTs = this.workOrderWorkSubTypes;
          break;
        case TimesheetRowType.Indirect:
          availableWSTs = this.indirectWorkSubTypes;
          break;
        case TimesheetRowType.Equipment:
          availableWSTs = [];
          break;
      }
      for (let wst of availableWSTs) {
        (newRow as any)[wst.id!] = null;
      }

      this.currentTimesheet!.timesheetRows.push(newRow);

      this.$nextTick(() => {
        switch (rowType) {
          case TimesheetRowType.DirectGeneral:
            this.panel = this.generalDirectPanelNumber;
            break;
          case TimesheetRowType.DirectWorkOrderRelated:
            this.panel = this.workOrderDirectPanelNumber;
            break;
          case TimesheetRowType.Indirect:
            this.panel = this.indirectPanelNumber;
            break;
          case TimesheetRowType.Equipment:
            this.panel = this.equipmentPanelNumber;
            break;
          default:
            break;
        }
      });
      return newRow;
    },
    addTimesheetRowForWorkOrder(
      isCorrectionRow: boolean,
      e: PersonWithDetailsAndName,
      workOrder: FormattedWorkOrder
    ) {
      // If this person doesn't have a general indirect row, add that too
      // Pass in 'undefined' for area/subarea as we don't care which area the row is for (null would force it to be empty)
      let existingGeneralDirectRow = this.timesheetRowAlreadyExists(
        isCorrectionRow,
        e.id,
        TimesheetRowType.DirectGeneral,
        null,
        undefined,
        undefined
      );
      if (!existingGeneralDirectRow) {
        this.addNonWorkOrderRelatedTimesheetRow(
          isCorrectionRow,
          e.id,
          e.name,
          e.employeeCode,
          e.classificationID,
          TimesheetRowType.DirectGeneral,
          null,
          null
        );
      }

      // Add the workorder timesheet row last so its section is opened isntead of the non-work order section
      this._addTimesheetRow(
        isCorrectionRow,
        e.id,
        e.name,
        e.employeeCode,
        e.classificationID,
        TimesheetRowType.DirectWorkOrderRelated,
        workOrder,
        workOrder.areaID ?? null,
        workOrder.subAreaID ?? null
      );
    },
    addNonWorkOrderRelatedTimesheetRow(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      employeeName: string | null | undefined,
      employeeCode: string | undefined,
      employeeClassificationID: string | null | undefined,
      rowType: TimesheetRowType,
      areaID: string | null,
      subAreaID: string | null
    ): TimesheetRow {
      return this._addTimesheetRow(
        isCorrectionRow,
        employeeID,
        employeeName,
        employeeCode,
        employeeClassificationID,
        rowType,
        null,
        areaID,
        subAreaID
      );
    },
    async addTimesheetRows(isCorrectionRow: boolean) {
      if (!this.currentTimesheet) return;
      if (this.currentTimesheet.isLocked && !isCorrectionRow) return;

      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.addform as HTMLFormElement).validate()) {
        return;
      }

      if (!this.selectedEntryType) return;
      if (!this.selectedEmployeeID && !this.selectedCrewID) return;
      if (!!this.selectedCrewID && !this.crewIsSelectable(this.selectedCrew)) return;
      if (this.selectedEntryType == "workorder" && !this.selectedWorkOrders.length) return;

      this.processing = true;
      try {
        // console.log(`addTimesheetRows selectedWorkOrders: ${this.selectedWorkOrders}`);
        this.selectedEmployees.forEach(e => {
          if (!!this.selectedWorkOrders.length) {
            this.selectedWorkOrders.forEach(wo => {
              this.addTimesheetRowForWorkOrder(isCorrectionRow, e, wo);
            });
          } else {
            let rowType = TimesheetRowType.Indirect;
            if (this.selectedEntryType == "generaldirect") rowType = TimesheetRowType.DirectGeneral;
            else if (this.selectedEntryType == "equipment") rowType = TimesheetRowType.Equipment;
            this.addNonWorkOrderRelatedTimesheetRow(
              isCorrectionRow,
              e.id,
              e.name,
              e.employeeCode,
              e.classificationID,
              rowType,
              null,
              null
            );
          }
        });
        this.currentTimesheet.timesheetRows = SortTimesheetRows(
          this.currentTimesheet.timesheetRows
        );

        this.selectedEntryType = null;
        this.selectedWorkOrders = [];
        if (this.workTypeOptions.length == 1)
          this.selectedEntryType = this.workTypeOptions[0].value;
        this.selectedCrewID = null;
        this.selectedEmployeeID = null;

        this.$emit("rows-added");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    removeTimesheetRow(row: TimesheetRow) {
      if (!this.currentTimesheet) return;
      const index = this.currentTimesheet.timesheetRows.indexOf(row);
      if (index < 0) {
        return;
      }
      // Clear out all hours values for row to be removed to confirm if explanations also need to be removed
      ParseWorkSubTypeIDsFromRow(row).forEach(wstid => {
        (row as any)[wstid] = 0;
        this.workSubTypeHoursValueChanged(row, wstid, 0);
      });
      this.currentTimesheet.timesheetRows.splice(index, 1);
    },

    // *** CALCULATED PROPERTIES ***
    selectableWorkSubTypesForWorkTypeID(workTypeID: string | null | undefined): WorkSubType[] {
      return SortWorkSubTypes(this.allWorkSubTypes.filter(x => x.workTypeID == workTypeID));
    },

    // *** LOADING ***
    async loadWorkOrders(
      workOrderSearchString: string | undefined,
      restrictDay: boolean,
      restrictAssignment: boolean
    ) {
      // If the current timesheet doesn't have an owner, there can't be any WOs associated so don't bother checking
      // Also, if the current timesheet is indirect it can't have WOs associated even if they exist
      if (
        !this.currentTimesheet?.ownerID ||
        this.currentTimesheet.timesheetTypeID != TimesheetType.Direct
      )
        return;

      if (!workOrderSearchString && !this.contractor?.id) {
        return;
      }

      let activeDay = restrictDay ? this.currentTimesheet.day : undefined;
      let assignedForeman = restrictAssignment
        ? (this.currentTimesheet.ownerID as string | undefined)
        : undefined;

      // console.log(` calling workOrderService.search(
      //   ${activeDay},
      //   ${workOrderSearchString},
      //   undefined,
      //   ${this.contractor?.id},
      //   undefined,
      //   ${assignedForeman}
      // )`);
      let workOrders = await workOrderService.search(
        activeDay,
        workOrderSearchString,
        undefined,
        this.contractor?.id,
        undefined,
        assignedForeman
      );
      this.availableWorkOrders = workOrders.map(x => {
        let formattedWorkOrder = {
          ...x,
          description: "WO-" + `00000${x.workOrderNumber}`.slice(-5),
          workOrderNumber: "WO-" + `00000${x.workOrderNumber}`.slice(-5),
          details: `${ScaffoldRequestTypes[x.scaffoldRequestType!]} (${
            WorkOrderStatuses[x.workOrderStatus!]
          })`,
          requestTypeName: `${ScaffoldRequestTypes[x.scaffoldRequestType!]}`,
          workOrderStatusName: `${WorkOrderStatuses[x.workOrderStatus!]}`,
          startDateString: !!x.startDate ? this.formatDate(x.startDate) : "",
          completedDateString: !!x.completedDate ? this.formatDate(x.completedDate) : ""
        } as FormattedWorkOrder;
        return formattedWorkOrder;
      });
    },
    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },

    async loadDataForTimesheet() {
      if (!this.currentTimesheet) {
        return;
      }
      this.processing = true;
      try {
        // Load the initial list with "suggested" restricted work orders
        await this.loadWorkOrders(undefined, true, true);
        this.$nextTick(() => {
          this.toggleTableGroups("summary", true);
          if (this.hasWorkOrderDirectRows) this.panel = this.workOrderDirectPanelNumber;
          else if (this.hasGeneralDirectRows) this.panel = this.generalDirectPanelNumber;
          else if (this.hasIndirectRows) this.panel = this.indirectPanelNumber;
          else if (this.hasEquipmentRows) this.panel = this.equipmentPanelNumber;
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadData() {
      this.processing = true;
      try {
        await Promise.all([
          this.loadAreas(),
          this.loadSubAreas(),
          this.loadContractors(),
          this.loadWorkTypes(),
          this.loadWorkSubTypes(),
          this.loadCostCodes(),
          this.loadEmployees(),
          this.loadCrews(),
          this.loadClassifications()
        ]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    getClassificationDisplayNameForID(
      classificationID: string | null | undefined
    ): string | undefined {
      let classification = (this.$store.state.classifications.fullList as Classification[]).find(
        x => x.id == classificationID
      );
      return classification?.alias ?? classification?.name;
    },

    // *** INLINE NAVIGATION ***
    getDataTable(tableIdentifier: string) {
      return this.$refs[`${tableIdentifier}datatable`] as VDataTable;
    },
    getFieldRef(tableIdentifier: string, fieldName: string, item: TimesheetRow) {
      let field = fieldName!.replace("-", "").replace("-", "");
      let employeeID = item.employeeID!.replace("-", "").replace("-", "");
      let areaID = item.areaID?.replace("-", "").replace("-", "") ?? "";
      let subAreaID = item.subAreaID?.replace("-", "").replace("-", "") ?? "";
      let correctionType = item.isCorrectionRow ? "correction" : "entry";
      return `${tableIdentifier}_${field}_${employeeID}_${areaID}_${subAreaID}_${correctionType}`;
    },
    focusFieldForVisibleItemAtIndex(
      tableIdentifier: string,
      fieldName: string,
      index: number,
      visibleItems: TimesheetRow[]
    ) {
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(tableIdentifier, fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(
      e: Event,
      tableIdentifier: string,
      fieldName: string,
      item: TimesheetRow
    ) {
      e.preventDefault();

      let datatable = this.getDataTable(tableIdentifier);
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex <= 0) {
        let _this = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          _this.focusFieldForVisibleItemAtIndex(
            tableIdentifier,
            fieldName,
            datatable.computedItemsPerPage,
            visibleItems
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, previousIndex, visibleItems);
    },
    async selectNextField(
      e: Event,
      tableIdentifier: string,
      fieldName: string,
      item: TimesheetRow
    ) {
      e.preventDefault();
      let datatable = this.getDataTable(tableIdentifier);
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex >= visibleItems.length - 1) {
        let _this = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          _this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, 0, visibleItems);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, nextIndex, visibleItems);
    },
    async enterPressed(
      e: KeyboardEvent,
      tableIdentifier: string,
      fieldName: string,
      item: TimesheetRow
    ) {
      if (e.shiftKey) await this.selectPreviousField(e, tableIdentifier, fieldName, item);
      else await this.selectNextField(e, tableIdentifier, fieldName, item);
    },

    ...mapMutations({
      setFilteringContext: "SET_FILTERING_CONTEXT"
    })
  },

  mounted: async function() {
    await this.loadData();
    await this.loadDataForTimesheet();
    if (this.workTypeOptions.length == 1) this.selectedEntryType = this.workTypeOptions[0].value;
  },

  created: async function() {
    this.setFilteringContext({
      context: "foreman-timesheet-form",
      parentalContext: this.parentContext,
      selectedTab: `tab-${this.firstTabKey}`
    });
  }
});

