var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: function(item) {
                                        return _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || "/contractors/"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pb-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          attrs: { "icons-and-text": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: "#tab-" + _vm.detailsTab.key },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.detailsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: "tab-" + _vm.detailsTab.key }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-0 px-2 mb-3 fd-inline-request-tags-qualifier",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-chip-selector", {
                                        attrs: {
                                          "available-items": _vm.availableTags,
                                          "selected-items": _vm.selectedTags,
                                          "item-label": "name",
                                          disabled:
                                            _vm.detailsReadonly ||
                                            _vm.processing,
                                          "control-label": _vm.$t(
                                            "common.add-tags"
                                          ),
                                          "chip-class":
                                            "keywords-selection-chip-colors",
                                          outlined: false
                                        },
                                        on: {
                                          "update:selectedItems": function(
                                            $event
                                          ) {
                                            _vm.selectedTags = $event
                                          },
                                          "update:selected-items": function(
                                            $event
                                          ) {
                                            _vm.selectedTags = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pb-md-0 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled:
                                            _vm.detailsReadonly ||
                                            _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.contractor.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "contractor.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.alias"),
                                          disabled:
                                            _vm.detailsReadonly ||
                                            _vm.processing,
                                          "persistent-hint": "",
                                          hint: _vm.$t("common.alias-hint"),
                                          rules: [
                                            _vm.rules.max20Characters,
                                            _vm.rules.required
                                          ],
                                          maxlength: "20",
                                          counter: ""
                                        },
                                        model: {
                                          value: _vm.contractor.alias,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "alias",
                                              $$v
                                            )
                                          },
                                          expression: "contractor.alias"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass: "pt-0",
                                        attrs: {
                                          label: _vm.$t(
                                            "contractors.existing-contractor.is-scaffold-company"
                                          ),
                                          disabled:
                                            _vm.detailsReadonly ||
                                            _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.contractor.isScaffoldCompany,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "isScaffoldCompany",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contractor.isScaffoldCompany"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("fd-rich-textarea", {
                                        attrs: {
                                          disabled:
                                            _vm.detailsReadonly ||
                                            _vm.processing,
                                          label: _vm.$t("common.description"),
                                          allowImages: false
                                        },
                                        model: {
                                          value: _vm.contractor.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "contractor.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass: "pt-0",
                                        attrs: {
                                          label: _vm.$t(
                                            "contractors.existing-contractor.tracks-employee-time"
                                          ),
                                          disabled:
                                            _vm.detailsReadonly ||
                                            _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.contractor.tracksEmployeeTime,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "tracksEmployeeTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contractor.tracksEmployeeTime"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "contractors.existing-contractor.overtime-threshold-label"
                                          ),
                                          disabled:
                                            !_vm.contractor
                                              .tracksEmployeeTime ||
                                            _vm.detailsReadonly ||
                                            _vm.processing,
                                          type: "number",
                                          "hide-spin-buttons": ""
                                        },
                                        model: {
                                          value:
                                            _vm.contractor
                                              .employeeOvertimeHoursThreshold,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "employeeOvertimeHoursThreshold",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contractor.employeeOvertimeHoursThreshold"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { position: "relative" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "absolute",
                                                top: "0px",
                                                left: "0px"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                staticClass: "pt-0",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "contractors.existing-contractor.apply-per-diem"
                                                  ),
                                                  disabled:
                                                    !_vm.contractor
                                                      .tracksEmployeeTime ||
                                                    _vm.detailsReadonly ||
                                                    _vm.processing
                                                },
                                                model: {
                                                  value:
                                                    _vm.contractor
                                                      .employeesReceivePerDiem,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.contractor,
                                                      "employeesReceivePerDiem",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contractor.employeesReceivePerDiem"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("fd-text-field", {
                                            staticStyle: {
                                              "margin-left": "200px",
                                              "max-width": "300px"
                                            },
                                            attrs: {
                                              label: _vm.$t(
                                                "contractors.existing-contractor.min-per-diem-hours"
                                              ),
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "contractors.existing-contractor.min-per-diem-hours-hint"
                                              ),
                                              disabled:
                                                !_vm.contractor
                                                  .tracksEmployeeTime ||
                                                !_vm.contractor
                                                  .employeesReceivePerDiem ||
                                                _vm.detailsReadonly ||
                                                _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.contractor
                                                  .perDiemHoursRequirement,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contractor,
                                                  "perDiemHoursRequirement",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contractor.perDiemHoursRequirement"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "contractors.existing-contractor.apply-weekend-per-diem"
                                          ),
                                          disabled:
                                            !_vm.contractor
                                              .tracksEmployeeTime ||
                                            !_vm.contractor
                                              .employeesReceivePerDiem ||
                                            _vm.detailsReadonly ||
                                            _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.contractor
                                              .automaticallyApplyWeekendPerDiem,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "automaticallyApplyWeekendPerDiem",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contractor.automaticallyApplyWeekendPerDiem"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "contractors.existing-contractor.can-act-as-proxy"
                                          ),
                                          disabled:
                                            _vm.detailsReadonly ||
                                            _vm.processing
                                        },
                                        model: {
                                          value: _vm.contractor.canActAsProxy,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "canActAsProxy",
                                              $$v
                                            )
                                          },
                                          expression: "contractor.canActAsProxy"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t("common.archived"),
                                          disabled:
                                            _vm.detailsReadonly ||
                                            _vm.processing
                                        },
                                        model: {
                                          value: _vm.contractor.archived,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "archived",
                                              $$v
                                            )
                                          },
                                          expression: "contractor.archived"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.currentUserCanConfigureSettings
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.timesheetsTab.key,
                                  ref: "tab",
                                  attrs: {
                                    href: "#tab-" + _vm.timesheetsTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.timesheetsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.timesheetsTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-clock")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.timesheetsTab.key,
                              attrs: { value: "tab-" + _vm.timesheetsTab.key }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "optionsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _vm.timesheetsError
                                    ? _c("fd-alert", {
                                        staticClass: "mx-3",
                                        attrs: {
                                          type: "error",
                                          label: _vm.$t(
                                            "contractors.timesheets.timesheet-selection-error-message"
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-2" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "contractors.timesheets.allow-direct-label"
                                              ),
                                              disabled:
                                                _vm.processing ||
                                                !_vm.contractor
                                                  .tracksEmployeeTime
                                            },
                                            model: {
                                              value:
                                                _vm.contractor
                                                  .allowDirectTimesheets,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contractor,
                                                  "allowDirectTimesheets",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contractor.allowDirectTimesheets"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "contractors.timesheets.allow-indirect-label"
                                              ),
                                              disabled:
                                                _vm.processing ||
                                                !_vm.contractor
                                                  .tracksEmployeeTime
                                            },
                                            model: {
                                              value:
                                                _vm.contractor
                                                  .allowIndirectTimesheets,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contractor,
                                                  "allowIndirectTimesheets",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contractor.allowIndirectTimesheets"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "contractors.timesheets.allow-equipment-label"
                                              ),
                                              disabled:
                                                _vm.processing ||
                                                !_vm.contractor
                                                  .tracksEmployeeTime
                                            },
                                            model: {
                                              value:
                                                _vm.contractor
                                                  .allowEquipmentTimesheets,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contractor,
                                                  "allowEquipmentTimesheets",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contractor.allowEquipmentTimesheets"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  !_vm.contractor.tracksEmployeeTime
                                    ? _c("fd-alert", {
                                        staticClass: "mx-3",
                                        attrs: {
                                          type: "info",
                                          label: _vm.$t(
                                            "contractors.timesheets.timesheet-selection-disabled-message"
                                          )
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.currentUserCanConfigureSettings
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.disciplinesTab.key,
                                  ref: "tab",
                                  attrs: {
                                    href: "#tab-" + _vm.disciplinesTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.disciplinesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.disciplinesTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v("fas fa-user-helmet-safety")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.disciplinesTab.key,
                              attrs: { value: "tab-" + _vm.disciplinesTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: { background: "#EAEAEA" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { row: "" },
                                                  model: {
                                                    value:
                                                      _vm.disciplinesUsageType,
                                                    callback: function($$v) {
                                                      _vm.disciplinesUsageType = $$v
                                                    },
                                                    expression:
                                                      "disciplinesUsageType"
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "contractors.selection-type-all-disciplines"
                                                      ),
                                                      value: "entire"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "contractors.selection-type-individual-disciplines"
                                                      ),
                                                      value: "selection"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.disciplinesUsageType ==
                                                "selection",
                                              expression:
                                                "disciplinesUsageType == 'selection'"
                                            }
                                          ],
                                          staticClass: "new-project",
                                          attrs: { flat: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ml-0 mr-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "pl-3 pr-0 pt-0 pb-0"
                                                        },
                                                        [
                                                          _c("v-switch", {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "contractors.only-included-disciplines-label"
                                                              )
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.showOnlyIncludedDisciplines,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.showOnlyIncludedDisciplines = $$v
                                                              },
                                                              expression:
                                                                "showOnlyIncludedDisciplines"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "ml-4",
                                                                              attrs: {
                                                                                color:
                                                                                  "black",
                                                                                dark:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ]
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "contractors.disciplines-selection-sub-menu-information"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "mr-0 ml-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          "append-icon":
                                                            "search",
                                                          label: "Search",
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.disciplinesTableSearch,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.disciplinesTableSearch = $$v
                                                          },
                                                          expression:
                                                            "disciplinesTableSearch"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:costcode.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "common.costcode"
                                                ),
                                                expression:
                                                  "$t('common.costcode')",
                                                arg: "costcode",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:description.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "common.description"
                                                ),
                                                expression:
                                                  "$t('common.description')",
                                                arg: "description",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:craftcode.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "common.craft-code"
                                                ),
                                                expression:
                                                  "$t('common.craft-code')",
                                                arg: "craftcode",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:include.no-sort",
                                                value: _vm.$t("common.include"),
                                                expression:
                                                  "$t('common.include')",
                                                arg: "include",
                                                modifiers: { "no-sort": true }
                                              }
                                            ],
                                            ref: "disciplinesDataTable",
                                            staticClass:
                                              "fixed-header v-table__overflow",
                                            staticStyle: {
                                              "max-height":
                                                "calc(100vh - 515px)",
                                              "backface-visibility": "hidden"
                                            },
                                            attrs: {
                                              items: _vm.disciplines,
                                              search:
                                                _vm.disciplinesTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "mobile-breakpoint": "0"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "header.include",
                                                fn: function(ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        value:
                                                          _vm.allSearchedDisciplinesSelected,
                                                        indeterminate:
                                                          _vm.someSearchedDisciplinesSelected,
                                                        label: _vm.$t(
                                                          "common." +
                                                            header.value
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipSearchedDisciplinesSelected()
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.include",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("v-simple-checkbox", {
                                                      attrs: {
                                                        value: item.selected,
                                                        disabled: _vm.processing
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipDisciplineSelected(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.currentUserCanConfigureSettings
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.areasTab.key,
                                  ref: "tab",
                                  attrs: { href: "#tab-" + _vm.areasTab.key },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.areasTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("contractors.tabs.areas")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v("fas fa-map-location-dot")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.areasTab.key,
                              attrs: { value: "tab-" + _vm.areasTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: { background: "#EAEAEA" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { row: "" },
                                                  model: {
                                                    value: _vm.areasUsageType,
                                                    callback: function($$v) {
                                                      _vm.areasUsageType = $$v
                                                    },
                                                    expression: "areasUsageType"
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "contractors.selection-type-all-areas"
                                                      ),
                                                      value: "entire"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "contractors.selection-type-individual-areas"
                                                      ),
                                                      value: "selection"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.areasUsageType ==
                                                "selection",
                                              expression:
                                                "areasUsageType == 'selection'"
                                            }
                                          ],
                                          staticClass: "new-project",
                                          attrs: { flat: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ml-0 mr-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "pl-3 pr-0 pt-0 pb-0"
                                                        },
                                                        [
                                                          _c("v-switch", {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "contractors.only-included-areas-label"
                                                              )
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.showOnlyIncludedAreas,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.showOnlyIncludedAreas = $$v
                                                              },
                                                              expression:
                                                                "showOnlyIncludedAreas"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "ml-4",
                                                                              attrs: {
                                                                                color:
                                                                                  "black",
                                                                                dark:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ]
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "contractors.areas-selection-sub-menu-information"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "mr-0 ml-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          "append-icon":
                                                            "search",
                                                          label: "Search",
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.areasTableSearch,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.areasTableSearch = $$v
                                                          },
                                                          expression:
                                                            "areasTableSearch"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:description.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "common.description"
                                                ),
                                                expression:
                                                  "$t('common.description')",
                                                arg: "description",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:include.no-sort",
                                                value: _vm.$t("common.include"),
                                                expression:
                                                  "$t('common.include')",
                                                arg: "include",
                                                modifiers: { "no-sort": true }
                                              }
                                            ],
                                            ref: "areasDataTable",
                                            staticClass:
                                              "fixed-header v-table__overflow",
                                            staticStyle: {
                                              "max-height":
                                                "calc(100vh - 515px)",
                                              "backface-visibility": "hidden"
                                            },
                                            attrs: {
                                              items: _vm.areas,
                                              search: _vm.areasTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "mobile-breakpoint": "0"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "header.include",
                                                fn: function(ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        value:
                                                          _vm.allSearchedAreasSelected,
                                                        indeterminate:
                                                          _vm.someSearchedAreasSelected,
                                                        label: _vm.$t(
                                                          "common." +
                                                            header.value
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipSearchedAreasSelected()
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.include",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("v-simple-checkbox", {
                                                      attrs: {
                                                        value: item.selected,
                                                        disabled: _vm.processing
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipAreaSelected(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.currentUserCanConfigureSettings
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.costCodesTab.key,
                                  ref: "tab",
                                  attrs: {
                                    href: "#tab-" + _vm.costCodesTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.costCodesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("contractors.tabs.cost-codes")
                                      ) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-binary")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.costCodesTab.key,
                              attrs: { value: "tab-" + _vm.costCodesTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: { background: "#EAEAEA" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { row: "" },
                                                  model: {
                                                    value:
                                                      _vm.costCodesUsageType,
                                                    callback: function($$v) {
                                                      _vm.costCodesUsageType = $$v
                                                    },
                                                    expression:
                                                      "costCodesUsageType"
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "contractors.selection-type-all-cost-codes"
                                                      ),
                                                      value: "entire"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "contractors.selection-type-individual-cost-codes"
                                                      ),
                                                      value: "selection"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.costCodesUsageType ==
                                                "selection",
                                              expression:
                                                "costCodesUsageType == 'selection'"
                                            }
                                          ],
                                          staticClass: "new-project",
                                          attrs: { flat: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            { staticClass: "pt-1" },
                                            [
                                              _c("v-switch", {
                                                staticClass: "mt-0",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "contractors.only-included-cost-codes-label"
                                                  ),
                                                  "hide-details": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.showOnlyIncludedCostCodes,
                                                  callback: function($$v) {
                                                    _vm.showOnlyIncludedCostCodes = $$v
                                                  },
                                                  expression:
                                                    "showOnlyIncludedCostCodes"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-1 mr-3",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "contractors.cost-codes-selection-sub-menu-information"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-0 pt-0 mt-0",
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.costCodesTableSearch,
                                                  callback: function($$v) {
                                                    _vm.costCodesTableSearch = $$v
                                                  },
                                                  expression:
                                                    "costCodesTableSearch"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:code.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "common.costcode"
                                                ),
                                                expression:
                                                  "$t('common.costcode')",
                                                arg: "code",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:include.no-sort",
                                                value: _vm.$t("common.include"),
                                                expression:
                                                  "$t('common.include')",
                                                arg: "include",
                                                modifiers: { "no-sort": true }
                                              }
                                            ],
                                            ref: "costCodesDataTable",
                                            staticClass:
                                              "fixed-header v-table__overflow",
                                            staticStyle: {
                                              "max-height":
                                                "calc(100vh - 515px)",
                                              "backface-visibility": "hidden"
                                            },
                                            attrs: {
                                              items: _vm.costCodes,
                                              search: _vm.costCodesTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "mobile-breakpoint": "0"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "header.include",
                                                fn: function(ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        value:
                                                          _vm.allSearchedCostCodesSelected,
                                                        indeterminate:
                                                          _vm.someSearchedCostCodesSelected,
                                                        label: _vm.$t(
                                                          "common." +
                                                            header.value
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipSearchedCostCodesSelected()
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.include",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("v-simple-checkbox", {
                                                      attrs: {
                                                        value: item.selected,
                                                        disabled: _vm.processing
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipCostCodeSelected(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.currentUserCanConfigureSettings
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.classificationsTab.key,
                                  ref: "tab",
                                  attrs: {
                                    href: "#tab-" + _vm.classificationsTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(
                                        _vm.classificationsTab
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.classificationsTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-screen-users")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.classificationsTab.key,
                              attrs: {
                                value: "tab-" + _vm.classificationsTab.key
                              }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "new-project",
                                          attrs: { flat: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ml-0 mr-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "pl-3 pr-0 pt-0 pb-0"
                                                        },
                                                        [
                                                          _c("v-switch", {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "contractors.only-included-classifications-label"
                                                              )
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.showOnlyIncludedClassifications,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.showOnlyIncludedClassifications = $$v
                                                              },
                                                              expression:
                                                                "showOnlyIncludedClassifications"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "ml-4",
                                                                              attrs: {
                                                                                color:
                                                                                  "black",
                                                                                dark:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ]
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "contractors.classifications-selection-sub-menu-information"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "mr-0 ml-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          "append-icon":
                                                            "search",
                                                          label: "Search",
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.classificationsTableSearch,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.classificationsTableSearch = $$v
                                                          },
                                                          expression:
                                                            "classificationsTableSearch"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:description.hide-when-medium",
                                                value: _vm.$t(
                                                  "common.description"
                                                ),
                                                expression:
                                                  "$t('common.description')",
                                                arg: "description",
                                                modifiers: {
                                                  "hide-when-medium": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:regularRate.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "contractors.classifications-regular-rate-column-header"
                                                ),
                                                expression:
                                                  "\n                        $t('contractors.classifications-regular-rate-column-header')\n                      ",
                                                arg: "regularRate",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:overtimeRate.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "contractors.classifications-overtime-rate-column-header"
                                                ),
                                                expression:
                                                  "\n                        $t('contractors.classifications-overtime-rate-column-header')\n                      ",
                                                arg: "overtimeRate",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:doubleTimeRate.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "contractors.classifications-double-time-rate-column-header"
                                                ),
                                                expression:
                                                  "\n                        $t('contractors.classifications-double-time-rate-column-header')\n                      ",
                                                arg: "doubleTimeRate",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:include.no-sort",
                                                value: _vm.$t("common.include"),
                                                expression:
                                                  "$t('common.include')",
                                                arg: "include",
                                                modifiers: { "no-sort": true }
                                              }
                                            ],
                                            ref: "classificationsDataTable",
                                            staticClass:
                                              "fixed-header v-table__overflow",
                                            staticStyle: {
                                              "max-height":
                                                "calc(100vh - 515px)",
                                              "backface-visibility": "hidden"
                                            },
                                            attrs: {
                                              page:
                                                _vm.classificationstablepage,
                                              items: _vm.classifications,
                                              search:
                                                _vm.classificationsTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "mobile-breakpoint": "0"
                                            },
                                            on: {
                                              "update:page": function($event) {
                                                _vm.classificationstablepage = $event
                                              }
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.regularRate",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("fd-text-field", {
                                                      ref: _vm.getFieldRef(
                                                        "contractorRegularRate",
                                                        item
                                                      ),
                                                      staticClass:
                                                        "fd-short-table-input",
                                                      attrs: {
                                                        "data-id": item.id,
                                                        "data-cy":
                                                          "contractorRegularRate",
                                                        disabled:
                                                          _vm.processing ||
                                                          !item.selected,
                                                        numeric: "",
                                                        outlined: false
                                                      },
                                                      on: {
                                                        keydown: [
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "up",
                                                                38,
                                                                $event.key,
                                                                [
                                                                  "Up",
                                                                  "ArrowUp"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.selectPreviousClassificationsField(
                                                              "contractorRegularRate",
                                                              item
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "down",
                                                                40,
                                                                $event.key,
                                                                [
                                                                  "Down",
                                                                  "ArrowDown"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.selectNextClassificationsField(
                                                              "contractorRegularRate",
                                                              item
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return (function(
                                                              e
                                                            ) {
                                                              return _vm.classificationsEnterPressed(
                                                                e,
                                                                "contractorRegularRate",
                                                                item
                                                              )
                                                            })($event)
                                                          }
                                                        ]
                                                      },
                                                      model: {
                                                        value:
                                                          item.contractorRegularRate,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "contractorRegularRate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.contractorRegularRate"
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.overtimeRate",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("fd-text-field", {
                                                      ref: _vm.getFieldRef(
                                                        "contractorOvertimeRate",
                                                        item
                                                      ),
                                                      staticClass:
                                                        "fd-short-table-input",
                                                      attrs: {
                                                        "data-id": item.id,
                                                        "data-cy":
                                                          "contractorOvertimeRate",
                                                        disabled:
                                                          _vm.processing ||
                                                          !item.selected,
                                                        numeric: "",
                                                        outlined: false
                                                      },
                                                      on: {
                                                        keydown: [
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "up",
                                                                38,
                                                                $event.key,
                                                                [
                                                                  "Up",
                                                                  "ArrowUp"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.selectPreviousClassificationsField(
                                                              "contractorOvertimeRate",
                                                              item
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "down",
                                                                40,
                                                                $event.key,
                                                                [
                                                                  "Down",
                                                                  "ArrowDown"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.selectNextClassificationsField(
                                                              "contractorOvertimeRate",
                                                              item
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return (function(
                                                              e
                                                            ) {
                                                              return _vm.classificationsEnterPressed(
                                                                e,
                                                                "contractorOvertimeRate",
                                                                item
                                                              )
                                                            })($event)
                                                          }
                                                        ]
                                                      },
                                                      model: {
                                                        value:
                                                          item.contractorOvertimeRate,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "contractorOvertimeRate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.contractorOvertimeRate"
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.doubleTimeRate",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("fd-text-field", {
                                                      ref: _vm.getFieldRef(
                                                        "contractorDoubleTimeRate",
                                                        item
                                                      ),
                                                      staticClass:
                                                        "fd-short-table-input",
                                                      attrs: {
                                                        "data-id": item.id,
                                                        "data-cy":
                                                          "contractorDoubleTimeRate",
                                                        disabled:
                                                          _vm.processing ||
                                                          !item.selected,
                                                        numeric: "",
                                                        outlined: false
                                                      },
                                                      on: {
                                                        keydown: [
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "up",
                                                                38,
                                                                $event.key,
                                                                [
                                                                  "Up",
                                                                  "ArrowUp"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.selectPreviousClassificationsField(
                                                              "contractorDoubleTimeRate",
                                                              item
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "down",
                                                                40,
                                                                $event.key,
                                                                [
                                                                  "Down",
                                                                  "ArrowDown"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.selectNextClassificationsField(
                                                              "contractorDoubleTimeRate",
                                                              item
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return (function(
                                                              e
                                                            ) {
                                                              return _vm.classificationsEnterPressed(
                                                                e,
                                                                "contractorDoubleTimeRate",
                                                                item
                                                              )
                                                            })($event)
                                                          }
                                                        ]
                                                      },
                                                      model: {
                                                        value:
                                                          item.contractorDoubleTimeRate,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "contractorDoubleTimeRate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.contractorDoubleTimeRate"
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "header.include",
                                                fn: function(ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        value:
                                                          _vm.allSearchedClassificationsSelected,
                                                        indeterminate:
                                                          _vm.someSearchedClassificationsSelected,
                                                        label: _vm.$t(
                                                          "common." +
                                                            header.value
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipSearchedClassificationsSelected()
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.include",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("v-simple-checkbox", {
                                                      attrs: {
                                                        value: item.selected,
                                                        disabled: _vm.processing
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipClassificationSelected(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.currentUserCanConfigureSettings
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.workTypesTab.key,
                                  ref: "tab",
                                  attrs: {
                                    href: "#tab-" + _vm.workTypesTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.workTypesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("contractors.tabs.work-types")
                                      ) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v("fas fa-person-digging")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.workTypesTab.key,
                              attrs: { value: "tab-" + _vm.workTypesTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: { background: "#EAEAEA" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { row: "" },
                                                  model: {
                                                    value:
                                                      _vm.workTypesUsageType,
                                                    callback: function($$v) {
                                                      _vm.workTypesUsageType = $$v
                                                    },
                                                    expression:
                                                      "workTypesUsageType"
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "contractors.selection-type-all-work-types"
                                                      ),
                                                      value: "entire"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "contractors.selection-type-individual-work-types"
                                                      ),
                                                      value: "selection"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.workTypesUsageType ==
                                                "selection",
                                              expression:
                                                "workTypesUsageType == 'selection'"
                                            }
                                          ],
                                          staticClass: "new-project",
                                          attrs: { flat: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ml-0 mr-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "pl-3 pr-0 pt-0 pb-0"
                                                        },
                                                        [
                                                          _c("v-switch", {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "contractors.only-included-work-types-label"
                                                              )
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.showOnlyIncludedWorkTypes,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.showOnlyIncludedWorkTypes = $$v
                                                              },
                                                              expression:
                                                                "showOnlyIncludedWorkTypes"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "ml-4",
                                                                              attrs: {
                                                                                color:
                                                                                  "black",
                                                                                dark:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ]
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "contractors.work-types-selection-sub-menu-information"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "mr-0 ml-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          "append-icon":
                                                            "search",
                                                          label: "Search",
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.workTypesTableSearch,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.workTypesTableSearch = $$v
                                                          },
                                                          expression:
                                                            "workTypesTableSearch"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:code.hide-when-extra-small",
                                                value: _vm.$t("common.code"),
                                                expression: "$t('common.code')",
                                                arg: "code",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:include.no-sort",
                                                value: _vm.$t("common.include"),
                                                expression:
                                                  "$t('common.include')",
                                                arg: "include",
                                                modifiers: { "no-sort": true }
                                              }
                                            ],
                                            ref: "workTypesDataTable",
                                            staticClass:
                                              "fixed-header v-table__overflow",
                                            staticStyle: {
                                              "max-height":
                                                "calc(100vh - 515px)",
                                              "backface-visibility": "hidden"
                                            },
                                            attrs: {
                                              items: _vm.workTypes,
                                              search: _vm.workTypesTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "mobile-breakpoint": "0",
                                              "show-expand": ""
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "expanded-item",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "fd-embedded-table-container",
                                                        attrs: { colspan: "99" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-subheader",
                                                          {
                                                            staticClass:
                                                              "white--text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "work-types.work-sub-types.header"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("v-data-table", {
                                                          directives: [
                                                            {
                                                              name: "fd-column",
                                                              rawName:
                                                                "v-fd-column:name",
                                                              value: _vm.$t(
                                                                "common.name"
                                                              ),
                                                              expression:
                                                                "$t('common.name')",
                                                              arg: "name"
                                                            },
                                                            {
                                                              name: "fd-column",
                                                              rawName:
                                                                "v-fd-column:code.hide-when-extra-small",
                                                              value: _vm.$t(
                                                                "common.code"
                                                              ),
                                                              expression:
                                                                "$t('common.code')",
                                                              arg: "code",
                                                              modifiers: {
                                                                "hide-when-extra-small": true
                                                              }
                                                            },
                                                            {
                                                              name: "fd-column",
                                                              rawName:
                                                                "v-fd-column:include.no-sort",
                                                              value: _vm.$t(
                                                                "common.include"
                                                              ),
                                                              expression:
                                                                "$t('common.include')",
                                                              arg: "include",
                                                              modifiers: {
                                                                "no-sort": true
                                                              }
                                                            }
                                                          ],
                                                          ref:
                                                            "workSubTypesDataTable",
                                                          attrs: {
                                                            items: _vm.workSubTypesForWorkType(
                                                              item
                                                            ),
                                                            search:
                                                              _vm.workTypesTableSearch,
                                                            loading:
                                                              _vm.processing,
                                                            "loading-text": _vm.$t(
                                                              "common.table-loading-message"
                                                            ),
                                                            "sort-by": ["name"],
                                                            "mobile-breakpoint":
                                                              "0"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "header.include",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var header =
                                                                    ref.header
                                                                  return [
                                                                    _c(
                                                                      "v-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            _vm.allSearchedWorkSubTypesSelected,
                                                                          indeterminate:
                                                                            _vm.someSearchedWorkSubTypesSelected,
                                                                          label: _vm.$t(
                                                                            "common." +
                                                                              header.value
                                                                          ),
                                                                          disabled:
                                                                            _vm.processing ||
                                                                            !item.selected
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.flipSearchedWorkSubTypesSelected()
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                }
                                                              },
                                                              {
                                                                key:
                                                                  "item.include",
                                                                fn: function(
                                                                  data
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "fd-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            data
                                                                              .item
                                                                              .selected,
                                                                          disabled:
                                                                            _vm.processing ||
                                                                            !item.selected,
                                                                          readonly: !item.selected
                                                                        },
                                                                        on: {
                                                                          input: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.flipWorkSubTypeSelected(
                                                                              data.item
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "header.include",
                                                fn: function(ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        value:
                                                          _vm.allSearchedWorkTypesSelected,
                                                        indeterminate:
                                                          _vm.someSearchedWorkTypesSelected,
                                                        label: _vm.$t(
                                                          "common." +
                                                            header.value
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipSearchedWorkTypesSelected()
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.include",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("v-simple-checkbox", {
                                                      attrs: {
                                                        value: item.selected,
                                                        disabled: _vm.processing
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipWorkTypeSelected(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.peopleTab.key,
                              attrs: { href: "#tab-" + _vm.peopleTab.key },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.peopleTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("users.menu-title")) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-users")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.peopleTab.key,
                              attrs: { value: "tab-" + _vm.peopleTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c("fd-inline-add-button", {
                                            on: {
                                              click: function($event) {
                                                return _vm.openNewUserDialog()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.users-listing-header-additional-info"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: "Search",
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.peopletablesearch,
                                              callback: function($$v) {
                                                _vm.peopletablesearch = $$v
                                              },
                                              expression: "peopletablesearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("fd-people-list", {
                                        attrs: {
                                          items: _vm.users,
                                          tablesearch: _vm.peopletablesearch,
                                          processing: _vm.processing,
                                          archivedLoading: _vm.archivedLoading,
                                          showArchived: _vm.showArchived,
                                          showArchivedDateRange:
                                            _vm.showArchivedDateRange,
                                          showArchivedMinDate:
                                            _vm.showArchivedMinDate,
                                          showArchivedMaxDate:
                                            _vm.showArchivedMaxDate,
                                          allowDelete:
                                            _vm.currentUserCanConfigureSettings,
                                          "hide-date-range-picker": ""
                                        },
                                        on: {
                                          navigate: function(item) {
                                            return _vm.$router.push(
                                              "/people/" + item.id
                                            )
                                          },
                                          showArchivedChanged: function(v) {
                                            return (_vm.showArchived = v)
                                          },
                                          showArchivedDateRangeChanged: function(
                                            v
                                          ) {
                                            return (_vm.showArchivedDateRange = v)
                                          },
                                          flipArchived: _vm.flipPersonArchived,
                                          flipCanLogIn: _vm.flipPersonCanLogIn,
                                          deleteTableItem:
                                            _vm.deletePeopleTableItem
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.crewsTab.key,
                              ref: "tab",
                              attrs: { href: "#tab-" + _vm.crewsTab.key },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.crewsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.crewsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-people-carry-box")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.crewsTab.key,
                              attrs: { value: "tab-" + _vm.crewsTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c("fd-inline-add-button", {
                                            on: {
                                              click: function($event) {
                                                return _vm.openNewCrewDialog()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.crews-listing-header-additional-info"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: "Search",
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.crewstablesearch,
                                              callback: function($$v) {
                                                _vm.crewstablesearch = $$v
                                              },
                                              expression: "crewstablesearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:description.hide-when-extra-small",
                                            arg: "description",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:ownerName.hide-when-small",
                                            value: _vm.$t(
                                              "contractors.crews.owner"
                                            ),
                                            expression:
                                              "$t('contractors.crews.owner')",
                                            arg: "ownerName",
                                            modifiers: {
                                              "hide-when-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:employees.hide-when-extra-small",
                                            arg: "employees",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:employeeListString.hidden",
                                            value: "",
                                            expression: "''",
                                            arg: "employeeListString",
                                            modifiers: { hidden: true }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:archived.hide-when-extra-small.hide-when-header-text-empty",
                                            value: _vm.showArchived
                                              ? _vm.$t("common.archived")
                                              : "",
                                            expression:
                                              "\n                      showArchived ? $t('common.archived') : ''\n                    ",
                                            arg: "archived",
                                            modifiers: {
                                              "hide-when-extra-small": true,
                                              "hide-when-header-text-empty": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.show-when-extra-small",
                                            value: function(item) {
                                              return _vm.$router.push(
                                                "/crews/" + item.id
                                              )
                                            },
                                            expression:
                                              "\n                      item => $router.push(`/crews/${item.id}`)\n                    ",
                                            modifiers: {
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "fd-actions-table fd-archiving-table",
                                        class: [
                                          {
                                            "fd-archive-bonus-padding-bottom":
                                              _vm.showArchived
                                          }
                                        ],
                                        attrs: {
                                          items: _vm.crews,
                                          search: _vm.crewstablesearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["name"],
                                          "mobile-breakpoint": "0"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.ownerName",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value: item.ownerName
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "footer.prepend",
                                            fn: function() {
                                              return [
                                                _c("fd-archived-data-loader", {
                                                  staticClass: "ml-2 mr-5",
                                                  attrs: {
                                                    showArchived:
                                                      _vm.showArchived,
                                                    showArchivedDateRange:
                                                      _vm.showArchivedDateRange,
                                                    showArchivedMinDate:
                                                      _vm.showArchivedMinDate,
                                                    showArchivedMaxDate:
                                                      _vm.showArchivedMaxDate,
                                                    loading:
                                                      _vm.archivedLoading,
                                                    disabled: _vm.processing,
                                                    "hide-date-range-picker": ""
                                                  },
                                                  on: {
                                                    "input:showArchived": function(
                                                      v
                                                    ) {
                                                      return (_vm.showArchived = v)
                                                    },
                                                    "input:showArchivedDateRange": function(
                                                      v
                                                    ) {
                                                      return (_vm.showArchivedDateRange = v)
                                                    }
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.fd-nav",
                                            fn: function() {
                                              return [
                                                _c("v-icon", [
                                                  _vm._v("mdi-chevron-right")
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.archived",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c("v-simple-checkbox", {
                                                  attrs: {
                                                    value: item.archived,
                                                    disabled: _vm.processing
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.flipCrewArchived(
                                                        item
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to:
                                                                            "/crews/" +
                                                                            item.id
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { right: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteCrewsTableItem(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-form",
                        { ref: "form", on: { submit: _vm.onSubmit } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _vm.inlineMessage.message
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mr-0",
                                          attrs: {
                                            type: _vm.inlineMessage.type
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-line"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.inlineMessage.message
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { staticClass: "mx-1" },
                            [
                              _vm.currentUserCanConfigureSettings
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        link: "",
                                        disabled: _vm.processing,
                                        color: "error",
                                        outlined: "",
                                        small: _vm.$vuetify.breakpoint.xsOnly
                                      },
                                      on: { click: _vm.deleteItem }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("common.delete")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    text: "",
                                    link: "",
                                    disabled: _vm.processing,
                                    small: _vm.$vuetify.breakpoint.name == "xs"
                                  },
                                  on: { click: _vm.cancel }
                                },
                                [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                              ),
                              _vm._v(" "),
                              _c("fd-menu-save", {
                                attrs: {
                                  disabled:
                                    _vm.detailsReadonly || _vm.processing,
                                  loading: _vm.saving,
                                  small: _vm.$vuetify.breakpoint.xsOnly
                                },
                                on: {
                                  "click:save": function($event) {
                                    return _vm.save(false)
                                  },
                                  "click:save-and-close": function($event) {
                                    return _vm.save(true)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }