import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import store from "../store";
import {
  PersonalEmailAddressWithVerified,
  PersonalPhoneNumberWithVerified,
  PersonLoginStates,
  PersonWithDetails
} from "../services";
import { filterByTags, filterByContractors } from "../services/taggableItems";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";
import { GetPersonName, HasName } from "../utils/person";

export type PersonWithExtraDetails = PersonWithDetails &
  HasName & {
    isActiveWithUnusedLogin: boolean;
    canSignIn: boolean;
    primaryEmailAddress: string | null | undefined;
    primaryPhoneNumber: string | null | undefined;
    roles: string | null | undefined;
  };
function GetRoles(user: PersonWithDetails): string {
  let roles = [] as string[];
  if (user.isForeman) roles.push("FM");
  if (user.isGeneralForeman) roles.push("General|GF");
  if (user.isCoordinator) roles.push("Coordinator");
  if (user.isPlanner) roles.push("Planner");
  return roles.join("|");
}
function GetPrimaryEmailAddress(user: PersonWithDetails): string | null | undefined {
  if (!user.emailAddresses?.length) return undefined;
  let primary = undefined as PersonalEmailAddressWithVerified | undefined;
  if (user.emailAddresses.length == 1) primary = user.emailAddresses[0];
  else {
    // Grab the first email address marked as Primary
    primary = user.emailAddresses.find(x => !!x.isPrimary);
    // If none are primary, just grab the first item
    if (!primary) primary = user.emailAddresses[0];
  }

  return primary?.emailAddress;
}
function GetPrimaryPhoneNumber(user: PersonWithDetails): string | null | undefined {
  if (!user.phoneNumbers?.length) return undefined;
  let primary = undefined as PersonalPhoneNumberWithVerified | undefined;
  if (user.phoneNumbers.length == 1) primary = user.phoneNumbers[0];
  else {
    // Grab the first email address marked as Primary
    primary = user.phoneNumbers.find(x => !!x.isPrimary);
    // If none are primary, just grab the first item
    if (!primary) primary = user.phoneNumbers[0];
  }

  return primary?.phoneNumber;
}
export default FDVue.extend({
  mixins: [errorHandling],

  data: function() {
    return {
      archivedLoading: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },
  computed: {
    users(): PersonWithExtraDetails[] {
      var allUsers = (this.$store.state.users.fullList as PersonWithDetails[]).map(x => {
        return {
          ...x,
          name: GetPersonName(x),
          isActiveWithUnusedLogin: !x.isArchived && x.loginState == PersonLoginStates.Unused,
          canSignIn: !x.isArchived && x.isLoginActive,
          primaryEmailAddress: GetPrimaryEmailAddress(x),
          primaryPhoneNumber: GetPrimaryPhoneNumber(x),
          roles: GetRoles(x)
        } as PersonWithExtraDetails;
      });
      return filterByContractors(
        this.contractorsSelectedForFiltering,
        filterByTags(this.tagsSelectedForFiltering, allUsers)
      );
    },
    tagsInUse() {
      return this.$store.getters.getSortedInUseTags(this.$store.state.users.fullList);
    },
    contractorsInUse() {
      return this.$store.getters.getSortedInUseContractors(this.$store.state.users.fullList);
    },

    showArchived: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        this.archivedLoading = true;
        await this.refreshUserList();
        this.archivedLoading = false;
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.refreshUserList();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    },

    contractorsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    }
  },
  methods: {
    ...mapActions({
      loadStoreUsers: "LOAD_USERS"
    }),

    async deleteUser(user: PersonWithDetails) {
      await store.dispatch("DELETE_USER", user);
    },
    async refreshUserList() {
      this.processing = true;
      try {
        await Promise.all([
          this.loadUsers(),
          this.$store.dispatch("LOAD_CONTRACTORS"),
          this.$store.dispatch("LOAD_TAGS")
        ]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadUsers();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadUsers() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadStoreUsers({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  beforeCreate() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.

    var toDate = addDaysToDate(null, 0);
    this.$store.commit("SET_FILTERING_CONTEXT", {
      context: "users",
      parentalContext: null,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      contractorsForFiltering: [],
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });
  },

  created() {
    this.refreshUserList();
  }
});

