import FDVue from "@fd/lib/vue";
import { TranslateResult } from "vue-i18n";
import { mapActions, mapMutations } from "vuex";
import { addDaysToDate, localizedDateTimeString } from "@fd/lib/client-util/datetime";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { VDataTable } from "@fd/lib/vue/types";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  Contractor,
  ContractorWithTags,
  reportService,
  SummaryTimesheetRow,
  timesheetService,
  TimesheetType,
  WorkSubType,
  WorkType
} from "../services";
import {
  HashTable,
  ParseWorkSubTypeIDsFromRow,
  SortAllWorkSubTypes,
  TableHeader,
  TimesheetRow,
  TimesheetRowType,
  UpdatableTimesheetWithTimesheetRows
} from "../utils/timesheet";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import downloadBlob from "../../../lib/client-util/downloadBlob";
import printBlob from "../../../lib/client-util/printBlob";
import { HasName, SortItemsWithName } from "../utils/person";

type EntryGroupingType = "groupnone" | "groupperson" | "grouptransactiontype";
let today = new Date(new Date().toDateString());
export default FDVue.extend({
  name: "fd-foreman-timesheet-summary",
  mixins: [serviceErrorHandling, tabbedView],
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  data: function() {
    return {
      firstTabKey: `0`,
      peopleTab: {
        tabname: this.$t("timesheets.summary.tabs.people"),
        key: "0",
        visible: true
      } as Tab,
      equipmentTab: {
        tabname: this.$t("timesheets.summary.tabs.equipment"),
        key: "1",
        visible: true
      } as Tab,

      tablesearch: "",
      selectedGroupingType: "groupperson" as EntryGroupingType,
      selectedDay: today,
      showDayShift: true,
      showNightShift: true,
      timesheetsForDay: [] as UpdatableTimesheetWithTimesheetRows[]
      // allTimesheetRowsForDay: [] as TimesheetRow[]
    };
  },
  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.peopleTab, this.equipmentTab] as Tab[];
    },
    contractorsInUse(): Contractor[] {
      return this.$store.getters.getSortedInUseContractors(
        this.timesheetsForDay.filter(x => !!x.timesheetRows.length)
      );
    },
    contractorsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },
    visibleTimesheetsForDay(): UpdatableTimesheetWithTimesheetRows[] {
      let visibleTimesheetsForDay = this.timesheetsForDay;
      if (!!this.contractorsSelectedForFiltering?.length) {
        visibleTimesheetsForDay = visibleTimesheetsForDay.filter(x =>
          this.contractorsSelectedForFiltering.includes(x.contractorID!)
        );
      }
      return visibleTimesheetsForDay;
    },

    allPeopleTimesheetRowsForDay(): TimesheetRow[] {
      let allTimesheetRowsForDay = this.timesheetsForDay
        .map(x => x.timesheetRows)
        .reduce((a, b) => {
          return a.concat(b);
        }, []);
      return allTimesheetRowsForDay.filter(x => x.rowType != TimesheetRowType.Equipment);
    },
    visiblePeopleTimesheetRowsForDay(): TimesheetRow[] {
      let allTimesheetRowsForDay = this.visibleTimesheetsForDay
        .map(x => x.timesheetRows)
        .reduce((a, b) => {
          return a.concat(b);
        }, []);
      let visiblePeopleTimesheetRowsForDay = allTimesheetRowsForDay.filter(
        x => x.rowType != TimesheetRowType.Equipment
      );

      if (!!this.tablesearch?.length) {
        // This specific data table may be hidden behind a tab and not accessible yet
        // Since we're not using any custom filters, we can use either data table's filter function
        let dataTable = this.$refs.summarydatatable as VDataTable;
        if (!dataTable) dataTable = this.$refs.equipmentdatatable as VDataTable;
        if (!!dataTable) {
          visiblePeopleTimesheetRowsForDay = dataTable.customFilterWithColumns(
            visiblePeopleTimesheetRowsForDay,
            this.tablesearch
          );
        }
      }
      return visiblePeopleTimesheetRowsForDay;
    },
    allEquipmentTimesheetRowsForDay(): TimesheetRow[] {
      let allTimesheetRowsForDay = this.timesheetsForDay
        .map(x => x.timesheetRows)
        .reduce((a, b) => {
          return a.concat(b);
        }, []);
      return allTimesheetRowsForDay.filter(x => x.rowType == TimesheetRowType.Equipment);
    },
    visibleEquipmentTimesheetRowsForDay(): TimesheetRow[] {
      let visibleTimesheetRowsForDay = this.visibleTimesheetsForDay
        .map(x => x.timesheetRows)
        .reduce((a, b) => {
          return a.concat(b);
        }, []);
      let visibleEquipmentTimesheetRowsForDay = visibleTimesheetRowsForDay.filter(
        x => x.rowType == TimesheetRowType.Equipment
      );

      if (!!this.tablesearch?.length) {
        // This specific data table may be hidden behind a tab and not accessible yet
        // Since we're not using any custom filters, we can use either data table's filter function
        let dataTable = this.$refs.equipmentdatatable as VDataTable;
        if (!dataTable) dataTable = this.$refs.summarydatatable as VDataTable;

        if (!!dataTable) {
          visibleEquipmentTimesheetRowsForDay = dataTable.customFilterWithColumns(
            visibleEquipmentTimesheetRowsForDay,
            this.tablesearch
          );
        }
      }

      return visibleEquipmentTimesheetRowsForDay;
    },
    groupColumn(): string | undefined {
      if (this.selectedGroupingType == "groupperson") return "employeeName";
      else if (this.selectedGroupingType == "grouptransactiontype") return "rowType";
      return undefined;
    },
    allGroupsExpanded(): boolean {
      let toggleRefs = Object.keys(this.$refs).filter(x => x.startsWith(`summarygrouptoggle`));
      let anyGroupsClosed = false;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`summarygrouptoggle`, "");
        let isOpen = (this.$refs[`summarydatatable`] as VDataTable).openCache[groupName];
        if (!isOpen) {
          anyGroupsClosed = true;
          break;
        }
      }
      return !anyGroupsClosed;
    },
    perDiemSubType(): WorkSubType | undefined {
      let perDiemTypeID = (this.$store.state.workTypes.fullList as WorkType[]).find(
        x => !!x.isPerDiem
      )?.id;
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      return allWorkSubTypes.find(x => x.workTypeID == perDiemTypeID);
    },
    equipmentSubType(): WorkSubType | undefined {
      let equipmentTypeID = (this.$store.state.workTypes.fullList as WorkType[]).find(
        x => !!x.isEquipment
      )?.id;
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      return allWorkSubTypes.find(x => x.workTypeID == equipmentTypeID);
    },
    allWorkSubTypes(): WorkSubType[] {
      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let allWorkSubTypes = SortAllWorkSubTypes(
        this.$store.state.workSubTypes.fullList as WorkSubType[],
        allWorkTypes
      );
      return allWorkSubTypes;
    },
    usedWorkSubTypes(): WorkSubType[] {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let usedContractorIDs = [...new Set(this.timesheetsForDay.map(x => x.contractorID ?? ""))];
      let usedContractors = allContractors.filter(x => usedContractorIDs.includes(x.id!));
      let allowedWorkTypeIDs = [
        ...new Set(
          usedContractors.reduce((a, b) => {
            if (!b.workTypeIDs) return a;
            return a.concat(b.workTypeIDs);
          }, [] as string[])
        )
      ];
      return this.allWorkSubTypes.filter(
        x =>
          allowedWorkTypeIDs.includes(x.workTypeID!) &&
          x.id != this.perDiemSubType?.id &&
          x.id != this.equipmentSubType?.id
      );
    },
    summaryTableHeaders(): TableHeader[] {
      let headers = [] as TableHeader[];
      if (!!this.groupColumn) {
        headers.push({
          text: "",
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell",
          cellClass: "fd-table-icon-cell"
        });
      }
      headers = headers.concat([
        {
          text: this.$t("timesheets.existing.contractor-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "contractorName"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName"
        },
        {
          text: this.$t("timesheets.number-label") as string | TranslateResult | undefined,
          value: "timesheetNumber"
        },
        {
          text: this.$t("timesheets.existing.work-order-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "workOrderNumber"
        },
        {
          text: this.$t("timesheets.existing.area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "areaName"
        },
        {
          text: this.$t("timesheets.existing.sub-area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "subAreaName"
        }
      ] as TableHeader[]);

      for (let workSubType of this.usedWorkSubTypes) {
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          sortable: false,
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        sortable: false,
        class: "fd-table-column-text-end-override"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          sortable: false,
          class: "fd-table-column-text-end-override"
        });
      }
      // headers.push({
      //   text: this.$t("common.action"),
      //   value: "action",
      //   class: "fd-action-cell",
      //   cellClass: "fd-action-cell"
      // });
      return headers;
    },
    equipmentTableHeaders(): TableHeader[] {
      if (!this.equipmentSubType) return [];

      let headers = [
        // {
        //   value: "icon",
        //   sortable: false,
        //   class: "fd-table-icon-cell",
        //   cellClass: "fd-table-icon-cell"
        // },
        {
          text: "",
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell",
          cellClass: "fd-table-icon-cell"
        },
        {
          text: this.$t("timesheets.existing.contractor-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "contractorName"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName"
        }
      ] as TableHeader[];

      headers.push({
        text: this.$t("timesheets.existing.equipment-days-column-label"),
        value: "equipmentDays",
        sortable: false,
        class: "fd-rotate-header-text"
      });
      headers.push({
        text: this.$t("timesheets.existing.equipment-quantity-column-label"),
        value: "equipmentQuantity",
        sortable: false,
        class: "fd-rotate-header-text"
      });

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        sortable: false,
        class: "fd-table-column-text-end-override"
      });
      return headers;
    }
  },
  watch: {
    selectedDay(newValue, oldValue) {
      if (new Date(newValue).getTime() == new Date(oldValue).getTime()) return;

      this.loadTimesheetsForSelectedDay();
    },
    showNightShift(newValue, oldValue) {
      if (newValue == oldValue) return;

      this.loadTimesheetsForSelectedDay();
    },
    showDayShift(newValue, oldValue) {
      if (newValue == oldValue) return;

      this.loadTimesheetsForSelectedDay();
    },
    selectedGroupingType() {
      this.$nextTick(() => {
        this.toggleTableGroups("summary", true);
      });
    }
  },
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES"
    }),
    async selectPreviousDay(e: Event) {
      e.stopPropagation();
      e.preventDefault();
      this.selectedDay = addDaysToDate(this.selectedDay, -1);
      return false;
    },
    async selectNextDay(e: Event) {
      e.stopPropagation();
      e.preventDefault();
      this.selectedDay = addDaysToDate(this.selectedDay, 1);
      return false;
    },
    toggleTableGroups(tableIdentifier: string, closed: boolean = true) {
      let toggleRefs = Object.keys(this.$refs).filter(x =>
        x.startsWith(`${tableIdentifier}grouptoggle`)
      );
      let datatable = this.$refs[`${tableIdentifier}datatable`] as VDataTable;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`${tableIdentifier}grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if ((closed && isOpen) || (!closed && !isOpen)) {
          datatable.openCache[groupName] = !datatable.openCache[groupName];
        }
      }
    },
    labelForGroup(groupValue: string) {
      if (this.selectedGroupingType == "groupperson")
        return this.$t("timesheets.summary.person-group-label", [groupValue]);
      else if (this.selectedGroupingType == "grouptransactiontype") {
        let rowTypeName = this.$t(`timesheets.row-types.${groupValue}`);
        return `${this.$t("timesheets.summary.transaction-type-group-label", [
          rowTypeName
        ])}`.toUpperCase();
      }
      return undefined;
    },
    countOn(items: any[], propName: string): number {
      let result = items.reduce((a, b) => a + (!!(b as any)[propName] ? 1 : 0), 0);
      return result;
    },
    sum(items: any[], propName: string): string | undefined {
      let result = items.reduce((a, b) => a + Number((b as any)[propName] ?? 0), 0);
      return !result ? undefined : result.toFixed(2);
    },
    calculateTotal(item: any): string {
      return this.calculateTotalForItem(item).toFixed(2);
    },
    calculateTotalForItem(item: any): number {
      var allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let total = allWorkSubTypes.reduce((a: number, b: any) => {
        let val = Number(item[b.id!]) ?? 0;
        if (isNaN(val)) val = 0;
        return a + val;
      }, 0);
      return total;
    },
    calculateTotalForItems(items: any[]): string {
      let total: number =
        items.reduce((a: number, b: any) => {
          return a + this.calculateTotalForItem(b);
        }, 0) ?? 0;
      return total.toFixed(2);
    },

    formatNumber(number: string | number | undefined | null): string | undefined {
      if (!number) return undefined;
      let val = Number(number);
      if (isNaN(val)) return undefined;
      return val.toFixed(2);
    },

    workOrderNumberTextForRow(row: TimesheetRow): string | TranslateResult {
      if (!!row.workOrderNumber) return this.$t("common.work-order-number", [row.workOrderNumber]);
      if (row.rowType == TimesheetRowType.DirectGeneral)
        return this.$t("timesheets.existing.generalized-direct-work-order-placeholder");
      return this.$t("timesheets.existing.indirect-work-order-placeholder");
    },
    // Only indirect work rows can have per diem entry
    isPerDiemApplicable(item: TimesheetRow): boolean {
      return !item.workOrderID;
    },

    async downloadAndPrintPeopleTimesheetSummaryReport(
      showOnlyVisible: boolean,
      reportType: string,
      overviewOnly: boolean
    ) {
      let rows = showOnlyVisible
        ? this.visiblePeopleTimesheetRowsForDay
        : this.allPeopleTimesheetRowsForDay;
      return await this.downloadAndPrintTimesheetSummaryReportForTimesheetRows(
        rows,
        reportType,
        overviewOnly
      );
    },
    async downloadAndPrintEquipmentTimesheetSummaryReport(
      showOnlyVisible: boolean,
      reportType: string,
      overviewOnly: boolean
    ) {
      let rows = showOnlyVisible
        ? this.visibleEquipmentTimesheetRowsForDay
        : this.allEquipmentTimesheetRowsForDay;
      return await this.downloadAndPrintTimesheetSummaryReportForTimesheetRows(
        rows,
        reportType,
        overviewOnly
      );
    },
    getValueForWorkSubTypeFromRow(workSubTypeID: string, row: TimesheetRow): number {
      let value = Number((row as any)[workSubTypeID] ?? 0);
      if (!value || isNaN(value)) {
        value = 0;
      }
      return value;
    },
    totalRows(rows: TimesheetRow[]): TimesheetRow {
      let totalRow = { perdiemCount: 0 } as TimesheetRow & { perdiemCount: number };
      for (let row of rows) {
        if (!totalRow.employeeName) totalRow.employeeName = row.employeeName;
        if (!totalRow.employeeCode) totalRow.employeeCode = row.employeeCode;
        if (!totalRow.classificationDisplayName)
          totalRow.classificationDisplayName = row.classificationDisplayName;
        if (!!row.hasPerDiem) totalRow.perdiemCount += 1;
        if (!!row.removePerDiem) totalRow.perdiemCount -= 1;

        let hoursByWorkSubTypeID = this.getHoursByWorkSubTypeIDForRow(row);
        for (let wstID of Object.keys(hoursByWorkSubTypeID)) {
          let existingValue = this.getValueForWorkSubTypeFromRow(wstID, totalRow);
          let newValue = this.getValueForWorkSubTypeFromRow(wstID, row);
          (totalRow as any)[wstID] = existingValue + newValue;
        }
      }
      if (totalRow.perdiemCount > 0) totalRow.hasPerDiem = true;
      return totalRow;
    },
    summarizeRows(rows: TimesheetRow[]): TimesheetRow[] {
      let rowsByEmployee = rows.reduce((a, b) => {
        let employeeIDForRow = b.employeeID;

        let rowsForEmployee = a[employeeIDForRow] ?? [];
        rowsForEmployee.push(b);
        a[employeeIDForRow] = rowsForEmployee;

        return a;
      }, {} as HashTable<TimesheetRow[]>);

      let summarizedRows = [] as TimesheetRow[];
      for (let employeeID of Object.keys(rowsByEmployee)) {
        let rowsForEmployee = rowsByEmployee[employeeID];
        let totalRowForEmployee = this.totalRows(rowsForEmployee);
        summarizedRows.push(totalRowForEmployee);
      }
      return summarizedRows;
    },
    getHoursByWorkSubTypeIDForRow(row: TimesheetRow): { [key: string]: number } {
      let wstIDs = ParseWorkSubTypeIDsFromRow(row);
      let hoursByWorkSubTypeID = wstIDs.reduce((a, b) => {
        let value = Number((row as any)[b] ?? 0);
        if (!value || isNaN(value)) {
          value = 0;
        }
        a[b] = value;
        return a;
      }, {} as HashTable<number>);
      return hoursByWorkSubTypeID;
    },
    async downloadAndPrintTimesheetSummaryReportForTimesheetRows(
      allTimesheetRows: TimesheetRow[],
      reportType: string,
      overviewOnly: boolean
    ) {
      let timesheetRows = allTimesheetRows;
      if (overviewOnly) {
        timesheetRows = this.summarizeRows(allTimesheetRows);
      }
      this.processing = true;
      try {
        if (timesheetRows.length == 0) {
          this.$store.dispatch("SHOW_SNACKBAR", {
            text: this.$t("timesheets.summary.printing.no-data-message"),
            type: "info"
          });
          return;
        }
        let summaryTimesheetRows = SortItemsWithName(
          timesheetRows.map(
            x =>
              ({
                name: x.employeeName,
                employeeName: x.employeeName,
                employeeBadge: x.employeeCode,
                employeeClassification: x.classificationDisplayName,
                workOrder: this.workOrderNumberTextForRow(x),
                areaName: x.areaName,
                subAreaName: x.subAreaName,
                hoursByWorkSubTypeID: this.getHoursByWorkSubTypeIDForRow(x),
                perDiem: x.hasPerDiem,
                total: this.calculateTotalForItem(x),
                equipmentDays: x.equipmentDays,
                equipmentQuantity: x.equipmentQuantity
              } as SummaryTimesheetRow & HasName)
          )
        );

        let usedWorkSubTypeIDs = summaryTimesheetRows
          .map(x =>
            Object.keys(x.hoursByWorkSubTypeID).filter(
              id => !!x.hoursByWorkSubTypeID[id] && x.hoursByWorkSubTypeID[id] > 0
            )
          )
          .reduce((a, b) => {
            b.forEach(id => {
              if (!a.includes(id)) a.push(id);
            });
            return a;
          }, [] as string[]);
        let usedWorkSubTypes = this.allWorkSubTypes.filter(x => usedWorkSubTypeIDs.includes(x.id!));

        var blob = await reportService.getTimesheetSummaryPrintoutReportContentWithData(
          summaryTimesheetRows,
          usedWorkSubTypes,
          reportType,
          localizedDateTimeString(new Date()),
          overviewOnly
        );
        if (reportType == "xls") {
          downloadBlob(blob, "timesheet-summary-printout.xlsx");
        } else {
          printBlob(blob, "timesheet-summary-printout.pdf", "application/pdf");
        }
      } catch (error) {
      } finally {
        this.processing = false;
      }
    },

    reloadTableData() {
      this.loadTimesheetsForSelectedDay();
    },
    async loadTimesheetsForSelectedDay() {
      this.inlineMessage.message = "";

      this.timesheetsForDay = [];

      this.processing = true;
      try {
        let timesheets = await timesheetService.getAllForDay(
          this.selectedDay,
          this.showDayShift,
          this.showNightShift,
          true,
          false
        );

        let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
        let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
        for (let ts of timesheets) {
          // If the day was changed while loading, we may be loading data for timesheets that aren't for the currently selected day
          if (ts.day?.getTime() != this.selectedDay.getTime()) continue;

          // console.log(`  timesheet: ${ts.ownerName} on ${ts.day}`);
          let entries = await timesheetService.getEntriesForTimesheetID(ts.id!);
          // console.log(`    entries: ${entries?.length}`);
          let timesheet = new UpdatableTimesheetWithTimesheetRows(
            ts,
            entries,
            allWorkTypes,
            allWorkSubTypes
          );

          this.timesheetsForDay.push(timesheet);
          // this.allTimesheetRowsForDay = this.allTimesheetRowsForDay.concat(timesheet.timesheetRows);
        }
        this.$nextTick(() => {
          this.toggleTableGroups("summary", true);
        });
        // console.log(`loadTimesheetsForSelectedDay END`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },
  created: async function() {
    this.setFilteringContext({
      context: "timesheetsummary",
      parentalContext: null,
      selectedTab: this.firstTabKey,
      contractorsForFiltering: [],
      searchStringForFiltering: "",
      contextForFiltering: "all",
      peopleForFiltering: [],
      statusesForFiltering: []
    });

    this.notifyNewBreadcrumb({
      text: this.$t("timesheets.summary.title"),
      to: "/timesheetsummary",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([this.loadContractors(), this.loadWorkTypes(), this.loadWorkSubTypes()]);
      await this.loadTimesheetsForSelectedDay();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

