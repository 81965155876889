var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: function(item) {
                                        return _vm.$router.push("/suppliers/")
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "py-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "pt-3",
                          attrs: { "icons-and-text": "", "show-arrows": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: "#tab-" + _vm.detailsTab.key },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "countsheetgroups.existing.tabs.details"
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.partsTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.partsTab.key,
                                  attrs: {
                                    "data-cy": "parts",
                                    href: "#tab-" + _vm.partsTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.partsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "countsheetgroups.existing.tabs.parts"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-truck-loading")])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: "tab-" + _vm.detailsTab.key }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-0 px-2 mb-3 fd-inline-request-tags-qualifier",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-chip-selector", {
                                        attrs: {
                                          "available-items": _vm.availableTags,
                                          "selected-items": _vm.selectedTags,
                                          "item-label": "name",
                                          disabled: _vm.processing,
                                          "control-label": _vm.$t(
                                            "common.add-tags"
                                          ),
                                          "chip-class":
                                            "keywords-selection-chip-colors",
                                          outlined: false
                                        },
                                        on: {
                                          "update:selectedItems": function(
                                            $event
                                          ) {
                                            _vm.selectedTags = $event
                                          },
                                          "update:selected-items": function(
                                            $event
                                          ) {
                                            _vm.selectedTags = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pb-md-0 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.supplier.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.supplier, "name", $$v)
                                          },
                                          expression: "supplier.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.alias"),
                                          disabled: _vm.processing,
                                          "persistent-hint": "",
                                          hint: _vm.$t("common.alias-hint"),
                                          rules: [
                                            _vm.aliascounter,
                                            _vm.rules.required
                                          ],
                                          maxlength: "20",
                                          counter: ""
                                        },
                                        model: {
                                          value: _vm.supplier.alias,
                                          callback: function($$v) {
                                            _vm.$set(_vm.supplier, "alias", $$v)
                                          },
                                          expression: "supplier.alias"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("fd-rich-textarea", {
                                        attrs: {
                                          label: _vm.$t("common.description"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.supplier.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.supplier,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "supplier.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t("common.archived"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.supplier.archived,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.supplier,
                                              "archived",
                                              $$v
                                            )
                                          },
                                          expression: "supplier.archived"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.partsTab.key,
                              attrs: { value: "tab-" + _vm.partsTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "new-part",
                                  attrs: { flat: "", tile: "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-0 mr-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "12" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  staticClass:
                                                    "pl-3 pr-0 pt-0 pb-0"
                                                },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "suppliers.parts.only-included-parts-label"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.showOnlyIncludedParts,
                                                      callback: function($$v) {
                                                        _vm.showOnlyIncludedParts = $$v
                                                      },
                                                      expression:
                                                        "showOnlyIncludedParts"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-4",
                                                                    attrs: {
                                                                      color:
                                                                        "black",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._v("info")]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "suppliers.parts.parts-selection-sub-menu-information"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "mr-0 ml-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: "Search",
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.partsTableSearch,
                                                  callback: function($$v) {
                                                    _vm.partsTableSearch = $$v
                                                  },
                                                  expression: "partsTableSearch"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-data-table", {
                                    directives: [
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:name",
                                        value: _vm.$t("common.name"),
                                        expression: "$t('common.name')",
                                        arg: "name"
                                      },
                                      {
                                        name: "fd-column",
                                        rawName:
                                          "v-fd-column:description.hide-when-extra-small",
                                        value: _vm.$t("common.description"),
                                        expression: "$t('common.description')",
                                        arg: "description",
                                        modifiers: {
                                          "hide-when-extra-small": true
                                        }
                                      },
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:include.no-sort",
                                        value: _vm.$t("common.include"),
                                        expression: "$t('common.include')",
                                        arg: "include",
                                        modifiers: { "no-sort": true }
                                      }
                                    ],
                                    ref: "partsDataTable",
                                    staticClass:
                                      "fixed-header v-table__overflow",
                                    staticStyle: {
                                      "max-height": "calc(50vh - 100px)",
                                      "backface-visibility": "hidden"
                                    },
                                    attrs: {
                                      items: _vm.parts,
                                      search: _vm.partsTableSearch,
                                      loading: _vm.processing,
                                      "loading-text": _vm.$t(
                                        "common.table-loading-message"
                                      ),
                                      "sort-by": ["name"],
                                      "mobile-breakpoint": "0"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "header.include",
                                        fn: function(ref) {
                                          var header = ref.header
                                          return [
                                            _c("v-checkbox", {
                                              attrs: {
                                                value:
                                                  _vm.allSearchedPartsSelected,
                                                indeterminate:
                                                  _vm.someSearchedPartsSelected,
                                                label: _vm.$t(
                                                  "common." + header.value
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.flipSearchedPartselected()
                                                }
                                              }
                                            })
                                          ]
                                        }
                                      },
                                      {
                                        key: "item.include",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c("v-simple-checkbox", {
                                              attrs: {
                                                value: item.selected,
                                                disabled: _vm.processing
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.flipPartSelected(
                                                    item
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.inlineMessage.message
                        ? _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _vm.inlineMessage.message
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mr-0",
                                          attrs: {
                                            type: _vm.inlineMessage.type
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.inlineMessage.message
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        { staticClass: "mx-1" },
                        [
                          _vm.currentUserCanConfigureSettings
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    link: "",
                                    disabled: _vm.processing,
                                    color: "error",
                                    outlined: "",
                                    small: _vm.$vuetify.breakpoint.xsOnly
                                  },
                                  on: { click: _vm.deleteItem }
                                },
                                [_vm._v(_vm._s(_vm.$t("common.delete")))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                text: "",
                                link: "",
                                disabled: _vm.processing,
                                small: _vm.$vuetify.breakpoint.name == "xs"
                              },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                          ),
                          _vm._v(" "),
                          _c("fd-menu-save", {
                            attrs: {
                              disabled: _vm.processing,
                              loading: _vm.saving,
                              small: _vm.$vuetify.breakpoint.xsOnly
                            },
                            on: {
                              "click:save": function($event) {
                                return _vm.save(false)
                              },
                              "click:save-and-close": function($event) {
                                return _vm.save(true)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }