import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import i18n from "../../../i18n";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import userData from "../../../dataMixins/person";
import { Classification, loginService, ProjectWithParts, userService } from "../../../services";
import { VForm } from "@fd/lib/vue/types";
import Slim from "@fd/lib/vue/components/SlimCropper.vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import fileHandling, { FileData } from "@fd/lib/vue/mixins/fileHandling";
import userAccess from "../../../dataMixins/userAccess";
import { GetPersonName } from "../../../utils/person";

type ProjectWithPartsAndSelected = ProjectWithParts & { selected: boolean };

const PersonNewDialog = FDVue.extend({
  name: "fd-person-new-dialog",
  mixins: [dialogSupport, userData.new, fileHandling, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "slim-cropper": Slim,
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue")
  },

  data: () => ({
    // *** GLOBAL ***
    step: 1,
    // lastStep: 5,

    detailsStep: 1,
    contractorStep: 2,
    projectsStep: 3,
    securityStep: 4,
    // filesStep: 5,

    detailserror: false,
    contractorerror: false,
    roleserror: false,
    projectserror: false,
    permissionserror: false,
    fileserror: false,
    simulatederror: false,

    // The following will control whether or not the save button shows the processing/loading indicator
    saving: false,

    // *** DETAILS ***
    userPhoto: null as File | null,

    // *** CONTRACTOR ***
    contractorIDLocked: false,

    // *** PROJECTS ***
    showOnlyIncludedProjects: false,
    tablesearchprojects: "",
    selectableProjects: [] as Array<ProjectWithPartsAndSelected>,

    // *** SECURITY ***

    // *** FILES ***
    //The following is just to MOCKUP MOBILE NUMBER LISTING
    tablesearchfiles: "",
    files: [] as FileData[]

    // *** DEBUG ***
  }),

  watch: {
    "user.forceReadonlyAccess": function(newValue, oldValue) {
      if (newValue) {
        this.user.canImportWorkOrders = false;
        this.user.canSubmitScaffoldRequests = false;
        this.user.canApproveScaffoldRequests = false;
        this.user.canApproveScaffoldRequestsFromAllAssignedContractors = this.user.canApproveScaffoldRequests;
        this.user.canEditWorkOrderPlannedWorkDate = false;
        this.user.canApproveCountSheets = false;
        this.user.canApproveLEMs = false;
      } else {
        this.updateRoleBasedPrivileges("user.forceReadonlyAccess");
      }
    },
    "user.canEditWorkOrderPlannedWorkDate": function(newValue, oldValue) {
      this.user.canEditWorkOrderPlannedWorkDateForAllAssignedContractors = this.user.canEditWorkOrderPlannedWorkDate;
    },
    "user.canApproveScaffoldRequests": function(newValue, oldValue) {
      this.user.canApproveScaffoldRequestsFromAllAssignedContractors = this.user.canApproveScaffoldRequests;
      if (newValue) {
        this.user.canViewScaffoldRequestApprovals = true;
      }
    },
    "user.contractorID": function(newValue, oldValue) {
      if (!!newValue) {
        let contractorIDs = this.user.contractorIDs ?? [];
        if (!contractorIDs.includes(newValue)) {
          contractorIDs.push(newValue);
          this.user.contractorIDs = contractorIDs;
        }
      }

      if (!!this.user.classificationID) {
        let existingClassificationIndex = this.classificationsForPerson.findIndex(
          x => x.id == this.user.classificationID
        );
        if (existingClassificationIndex < 0) this.user.classificationID = null;
      }
    },
    "user.isLoginActive": function(newValue, oldValue) {
      if (!newValue) {
        this.user.forceReadonlyAccess = false;
        this.user.canViewWorkOrders = false;
        this.user.canImportWorkOrders = false;
        this.user.canSubmitScaffoldRequests = false;
        this.user.canApproveScaffoldRequests = false;
        this.user.canEditWorkOrderPlannedWorkDate = false;
        this.user.canConfigureSettings = false;
        this.user.isPlanner = false;
        // this.user.isCoordinator = false;
        // this.user.isGeneralForeman = false;
        // this.user.isForeman = false;
      }
      this.updateRoleBasedPrivileges("user.isLoginActive");
    },
    "user.canImportWorkOrders": function(newValue, oldValue) {
      if (newValue) {
        this.user.canViewWorkOrders = true;
      }
    },
    "user.canEditWorkOrderSchedule": function(newValue, oldValue) {
      if (newValue) {
        this.user.canViewWorkOrderSchedule = true;
      }
    },
    "user.isDesigner": function(newValue, oldValue) {
      this.updateRoleBasedPrivileges("user.isDesigner");
    },
    "user.isDesignManager": function(newValue, oldValue) {
      this.updateRoleBasedPrivileges("user.isDesignManager");
    },
    "user.isPlanner": function(newValue, oldValue) {
      this.updateRoleBasedPrivileges("user.isPlanner");
    },
    "user.isCoordinator": function(newValue, oldValue) {
      this.updateRoleBasedPrivileges("user.isCoordinator");
    },
    "user.isGeneralForeman": function(newValue, oldValue) {
      this.updateRoleBasedPrivileges("user.isGeneralForeman");
    },
    "user.isForeman": function(newValue, oldValue) {
      this.updateRoleBasedPrivileges("user.isForeman");
    }
  },

  computed: {
    lastStep(): number {
      return this.filesStep;
    },
    filesStep(): number {
      if (this.currentUserCanConfigureSettings) return this.securityStep + 1;
      else return this.detailsStep + 1;
    },
    canEditSecurity(): boolean {
      return this.user.currentUserPermissions.canEditSecurity;
    },
    // *** DETAILS ***
    showUserWillNotBeNotifiedNotice(): boolean {
      // If this person is a user, and is setup with ONLY a phone number (without an email address)
      // Display a warning message that the new user will NOT be notified about their account
      return (
        !!this.user.isLoginActive &&
        !!this.userPhoneNumber?.length &&
        !this.userEmailAddress?.length
      );
    },
    userEmailAddress: {
      get(): string | undefined {
        return this.user.emailAddresses?.length
          ? this.user.emailAddresses[0].emailAddress
          : undefined;
      },
      set(val: string) {
        var userEmailAddress;
        if (this.user.emailAddresses?.length) {
          userEmailAddress = this.user.emailAddresses[0];
        } else {
          userEmailAddress = {
            id: undefined,
            emailAddress: "",
            isVerified: false,
            allowNotifications: true,
            isPrimary: true
          };
          this.user.emailAddresses = [userEmailAddress];
        }
        userEmailAddress.emailAddress = val;
      }
    },
    userPhoneNumber: {
      get(): string | undefined {
        return this.user.phoneNumbers?.length ? this.user.phoneNumbers[0].phoneNumber : undefined;
      },
      set(val: string) {
        var userPhoneNumber;
        if (this.user.phoneNumbers?.length) {
          userPhoneNumber = this.user.phoneNumbers[0];
        } else {
          userPhoneNumber = {
            id: undefined,
            phoneNumber: "",
            type: "",
            isVerified: false,
            allowNotifications: true,
            isPrimary: true
          };
          this.user.phoneNumbers = [userPhoneNumber];
        }
        userPhoneNumber.phoneNumber = val;
      }
    },

    // *** CONTRACTORS TAB ***
    classificationsForPerson(): Classification[] {
      let personContractor = this.userValues.contractorID.find(x => x.id == this.user.contractorID);
      if (!personContractor) return [];

      let classificationIDs = personContractor.classifications?.map(x => x.classificationID!);
      if (!classificationIDs?.length) return [];

      return this.userValues.classifications.filter(x => classificationIDs!.includes(x.id!));
    },

    // *** PROJECTS ***
    projectUsageType: {
      get(): string {
        return this.user.includesAllProjects ? "entire" : "selection";
      },
      set(val: string) {
        this.user.includesAllProjects = val == "entire";
      }
    },

    projects(): Array<ProjectWithPartsAndSelected> {
      let returnValue = this.selectableProjects;
      if (this.showOnlyIncludedProjects) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedProjectIDs(): string[] {
      return this.selectableProjects.filter(x => x.selected).map(x => x.id!);
    },

    searchedProjects(): Array<ProjectWithPartsAndSelected> {
      // This is a hack because the projects list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .projectsDataTable as any)?.customFilter;
      if (this.tablesearchprojects && customFilter) {
        return this.projects.filter(
          x =>
            customFilter(x.name!, this.tablesearchprojects, x) ||
            customFilter(x.description!, this.tablesearchprojects, x)
        );
      } else {
        return this.projects;
      }
      return [];
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedProjectsSelected(): boolean {
      return this.searchedProjects.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedProjectsSelected(): boolean {
      var searchedProjects = this.searchedProjects;
      return (
        searchedProjects.findIndex(x => x.selected) !== -1 &&
        searchedProjects.findIndex(x => !x.selected) !== -1
      );
    },

    // *** SECURITY ***
    enableCanViewWorkOrders(): boolean {
      return !this.user.canImportWorkOrders;
    },
    enableCanViewWorkOrderSchedule(): boolean {
      return !this.user.canEditWorkOrderSchedule;
    }
  },

  methods: {
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.detailserror = !(this.$refs.detailsform as VForm).validate();
      if (!!this.$refs.contractorform)
        this.contractorerror = !(this.$refs.contractorform as VForm).validate();
      if (!!this.$refs.projectsform)
        this.projectserror = !(this.$refs.projectsform as VForm).validate();
      if (!!this.$refs.securityform)
        this.permissionserror = !(this.$refs.securityform as VForm).validate();
      if (!!this.$refs.filesform) this.fileserror = !(this.$refs.filesform as VForm).validate();

      return !(
        this.detailserror ||
        this.contractorerror ||
        this.projectserror ||
        this.permissionserror ||
        this.fileserror
      );
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      this.user.projectIDs = this.user.includesAllProjects ? [] : this.selectedProjectIDs;

      if (!this.validate()) {
        var message = i18n.t("users.new-user.error-message");
        if (this.detailserror) message += "\n\t- " + i18n.t("users.new-user.steps.details");
        if (this.contractorerror)
          message += "\n\t- " + i18n.t("users.new-user.steps.contractor-selection");
        if (this.projectserror)
          message += "\n\t- " + i18n.t("users.new-user.steps.projects-selection");
        if (this.permissionserror) message += "\n\t- " + i18n.t("users.new-user.steps.security");
        if (this.fileserror) message += "\n\t- " + i18n.t("users.new-user.steps.files");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return;
      }
      if (!this.user.emailAddresses) {
        console.log(`no email address entered`);
        return;
      }

      this.saving = true;
      this.processing = true;

      try {
        var newUserID = await this.saveUser();

        if (this.userPhoto) {
          await userService.uploadUserPhoto(newUserID, this.userPhoto);
        }

        if (this.files.length) {
          for (let index = 0; index < this.files.length; index++) {
            const file = this.files[index];
            await userService.uploadUserFile(newUserID, file.name, file.file as Blob);
          }
        }

        // If the new person is a user and has an email address saved then send a new account notice email
        // If it fails, continue the navigation but show an error toast
        if (this.user.isLoginActive) {
          if (!!this.userEmailAddress?.length) {
            try {
              await loginService.sendNewAccountNotice(
                `${document.location.protocol}//${document.location.host}`,
                newUserID!
              );
            } catch {
              var snackbarPayload = {
                text: this.$t("people.existing-person.new-account-not-notified-snackbar-message", [
                  GetPersonName(this.user)
                ]),
                type: "error"
              };
              this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
            }
          }
        }

        this.closeDialog(true);
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    ...mapActions({
      loadProjects: "LOAD_PROJECTS"
    }),

    // *** PROJECTS ***
    flipProjectSelected(item: ProjectWithPartsAndSelected & { selected: boolean }) {
      item.selected = !item.selected;
    },

    flipSearchedProjectselected() {
      let selected = !this.allSearchedProjectsSelected;
      let changedProjectIDs = [] as string[];
      for (let project of this.searchedProjects) {
        if (project.selected !== selected) {
          project.selected = selected;
          changedProjectIDs.push(project.id!);
        }
      }
    },

    // *** FILES ***
    async addFile(file: any) {
      var fileData = await this.optimizedFileDataForUpload(file, this.files);
      if (!fileData) return;
      this.files.push(fileData);
    },
    viewFile(fileData: FileData) {
      this.imageName = fileData.name;
      this.imageSource = this.covertFileToDataURL(fileData.file);
    },
    removeFile(file: any) {
      var fileIndex = this.files.indexOf(file);
      if (fileIndex == undefined) return;

      this.files.splice(fileIndex, 1);
    },
    updateRoleBasedPrivileges(calledBy: String) {
      console.log(`updateRoleBasedPrivileges called by: ${calledBy}`);
      // NOTE: FM/GF/CO flags can be updated without login being active (as they're not ONLY used as security roles).  However we don't want to give them any security if they don't have a login
      // *** User controlled flags ***
      this.user.canApproveScaffoldRequests =
        this.user.isLoginActive &&
        !this.user.forceReadonlyAccess &&
        (this.user.canApproveScaffoldRequests! || this.user.isPlanner!);

      this.user.canEditWorkOrderPlannedWorkDate =
        this.user.isLoginActive &&
        !this.user.forceReadonlyAccess &&
        (this.user.canEditWorkOrderPlannedWorkDate! || this.user.isPlanner!);

      // *** Role controlled flags ***
      // NOTE: The forceReadonlyAccess flag DOES NOT affect these flags at this point.  It is used server-side to override these flags

      this.user.canViewScaffoldDesigns =
        this.user.isLoginActive && (this.user.isDesigner! || this.user.isDesignManager!);
      this.user.canCreateScaffoldDesigns =
        this.user.isLoginActive && (this.user.isDesigner! || this.user.isDesignManager!);
      this.user.canReleaseScaffoldDesigns = this.user.isLoginActive && this.user.isDesignManager!;

      this.user.canUpdateAssociatedWorkOrdersWithoutBeingAssigned =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);

      this.user.canWalkDownUnassignedWorkOrders = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canApproveWalkdowns =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator)!;

      this.user.canApproveCountSheets = this.user.isLoginActive && this.user.isPlanner!;

      this.user.canViewWorkOrderEstimateDetails = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderEstimateDetails = this.user.isLoginActive && this.user.isPlanner!;

      this.user.canEditWorkOrderArea = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderAreaForAllAssignedContractors =
        this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderLocation = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderLocationForAllAssignedContractors =
        this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderWorkDescription = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderWorkDescriptionForAllAssignedContractors =
        this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderPriority =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);
      this.user.canEditWorkOrderPriorityForAllAssignedContractors =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);
      this.user.canEditWorkOrderProgress =
        this.user.isLoginActive &&
        (this.user.isPlanner! || this.user.isGeneralForeman! || this.user.isForeman!);
      this.user.canEditWorkOrderProgressForAllAssignedContractors =
        this.user.isLoginActive && this.user.isPlanner!;

      this.user.canCreateTransfers = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canOverrideTransferValues = this.user.isLoginActive && this.user.isPlanner!;

      this.user.canSubmitMaterialOrders = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canApproveMaterialOrders = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canFulfillMaterialOrders = this.user.isLoginActive && this.user.isPlanner!;

      this.user.canEditWorkOrderStatus =
        this.user.isLoginActive &&
        (this.user.isPlanner! ||
          this.user.isCoordinator! ||
          this.user.isGeneralForeman! ||
          this.user.isForeman!);
      this.user.canEditWorkOrderStatusForAllAssignedContractors =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);
      this.user.canEditWorkOrderWorkPackages = this.user.isLoginActive && this.user.isPlanner!;

      this.user.canCancelWorkOrder =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);
      this.user.canCancelWorkOrderForAllAssignedContractors =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);

      this.user.canEditWorkOrderRequiredDate =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);
      this.user.canEditWorkOrderRequiredDateForAllAssignedContractors =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);

      this.user.canEditWorkOrderContractor = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderCoordinator = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderCoordinatorForAllAssignedContractors =
        this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderGeneralForeman =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);
      this.user.canEditWorkOrderGeneralForemanForAllAssignedContractors =
        this.user.isLoginActive && this.user.isPlanner!;
      this.user.canEditWorkOrderForeman =
        this.user.isLoginActive &&
        (this.user.isPlanner! || this.user.isCoordinator! || this.user.isGeneralForeman!);
      this.user.canEditWorkOrderForemanForAllAssignedContractors =
        this.user.isLoginActive && this.user.isPlanner!;

      // Approve Request permission is automatic if Planner, otherwise is manual
      // View is guaranteed for ALL planners and Coordinators
      this.user.canViewScaffoldRequestApprovals =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);
      this.user.canViewWorkOrderEstimates =
        this.user.isLoginActive && (this.user.isPlanner! || this.user.isCoordinator!);
      this.user.canViewWorkOrderSchedule =
        this.user.isLoginActive &&
        (this.user.isPlanner! || this.user.isCoordinator! || this.user.isGeneralForeman!);
      this.user.canEditWorkOrderSchedule =
        this.user.isLoginActive &&
        (this.user.isPlanner! || this.user.isCoordinator! || this.user.isGeneralForeman!);
      this.user.canViewToDoList =
        this.user.isLoginActive &&
        (this.user.isPlanner! ||
          this.user.isCoordinator! ||
          this.user.isGeneralForeman! ||
          this.user.isForeman!);
      this.user.canViewScaffolds =
        this.user.isLoginActive &&
        (this.user.isPlanner! ||
          this.user.isCoordinator! ||
          this.user.isGeneralForeman! ||
          this.user.isForeman!);
      this.user.canViewMaterialApproval = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canViewTransfers = this.user.isLoginActive && this.user.isPlanner!;
      this.user.canViewMaterialOrders = this.user.isLoginActive && this.user.isPlanner!;
    }
  },

  created: async function() {
    this.processing = true;

    // *** PROJECTS ***
    await Promise.all([this.loadProjects()]);

    // This user is new and therefore CANNOT have projects yet.
    this.projectUsageType = "entire";
    this.selectableProjects = this.$store.state.projects.fullList.map((x: ProjectWithParts) => ({
      ...x,
      selected: false
    }));

    this.processing = false;
  }
});

export default PersonNewDialog;

export async function createNewPerson(options?: { contractorID?: string }): Promise<boolean> {
  let dialog = createDialog(PersonNewDialog);
  if (options?.contractorID) {
    dialog.user.contractorID = options?.contractorID;
    dialog.contractorIDLocked = true;
  }
  return await dialog.showDialog!();
}

