import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import {
  Classification,
  contractorService,
  ContractorWithTags,
  CrewWithEmployees,
  TimesheetEntryWithDetails,
  timesheetService,
  TimesheetWithDetails,
  PersonWithDetails,
  ProjectCostCode,
  ProjectLocation,
  projectLocationService,
  ScaffoldRequestTypes,
  WorkOrderSearchResult,
  workOrderService,
  WorkSubType,
  WorkType,
  TimesheetType
} from "@fd/current/client/services";
import { mapActions } from "vuex";
import {
  GetPersonName,
  PersonHasEquipmentClassification,
  PersonWithDetailsAndName,
  SortItemsWithName
} from "@fd/current/client/utils/person";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { VDataTable } from "@fd/lib/vue/types";
import {
  UpdatableTimesheetEntryWithDetails,
  UpdatableTimesheetWithEntries
} from "../../../utils/timesheet";
import { SelectListOption } from "@fd/lib/vue/utility/select";
import userAccess from "../../../dataMixins/userAccess";
import { SortCrewEmployees } from "./CrewDetailsBottomDialog.vue";

function CompareWorkTypes<T extends WorkType>(a: T, b: T): number {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}
function SortWorkTypes<T extends WorkType>(items: T[] | null | undefined): T[] {
  if (!items) return [];
  return items.sort(CompareWorkTypes);
}
type FormattedWorkOrder = WorkOrderSearchResult & { details: string; description: string };
// type TimesheetWithEntries = TimesheetWithDetails & { entries: TimesheetEntryWithDetails[] };
const TimesheetEntriesAddDialog = FDVue.extend({
  name: "fd-add-timesheet-entries-dialog",

  mixins: [dialogSupport, rules, userAccess],

  components: {
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      tablepage: 1,
      timesheet: { currentUserPermissions: {} } as UpdatableTimesheetWithEntries,
      contractor: {} as ContractorWithTags,
      allAreas: [] as ProjectLocation[],
      selectedAreaID: null as string | null,
      allSubAreas: [] as ProjectLocation[],
      selectedSubAreaID: null as string | null,
      selectedWorkTypeID: null as string | null,
      selectedWorkSubTypeID: null as string | null,
      selectedCostCodeID: null as string | null,
      selectedEmployeeID: null as string | null,
      selectedCrewID: null as string | null,
      availableWorkOrders: [] as FormattedWorkOrder[],
      selectedWorkOrder: null as FormattedWorkOrder | null
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return [
        "saveDialog",
        "getFieldRef",
        "focusFieldForVisibleItemAtIndex",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "clearValues",
        "canEditRegularTimeHoursForItem",
        "canEditDoubleTimeHoursForItem",
        "canEditPerDiemUnitsForItem",
        "canEditEquipmentUnitsForItem",
        "workTypeSelectable"
      ];
    },
    timesheetRules(): any {
      return {
        workOrderID: [],
        areaID: [],
        subAreaID: [],
        workTypeID: [this.rules.required],
        workSubTypeID: [this.rules.required],
        costCodeID: [this.rules.required]
      };
    },
    canSelectWorkOrder(): boolean {
      if (this.timesheet.timesheetTypeID != TimesheetType.Direct) return false;

      if (
        !!this.selectedActiveWorkType &&
        (this.selectedActiveWorkType.isPerDiem || !this.selectedActiveWorkType.isDirect)
      )
        return false;

      if (!!this.selectedActiveWorkSubType && !this.selectedActiveWorkSubType.isWorkOrderRelated)
        return false;

      return true;
    },
    scaffoldNumber(): string | null | undefined {
      return this.selectedWorkOrder?.tagNumber;
    },
    selectableAreas(): ProjectLocation[] {
      if (!this.contractor.areaIDs?.length) return [];
      return this.allAreas
        .filter(x => this.contractor.areaIDs!.includes(x.id!))
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });
    },
    selectedArea(): ProjectLocation | undefined {
      return this.selectableAreas.find(x => x.id == this.selectedAreaID);
    },
    selectableSubAreas(): ProjectLocation[] {
      return this.allSubAreas
        .filter(x => x.parentLocationID == this.selectedArea?.id)
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });
    },
    selectedSubArea(): ProjectLocation | undefined {
      return this.selectableSubAreas.find(x => x.id == this.selectedSubAreaID);
    },
    currentWorkTypeSelectable(): boolean {
      return this.workTypeSelectable(this.selectedActiveWorkType);
    },
    canAddEntriesWithCurrentSelections(): boolean {
      if (!this.selectedEmployeeID && !this.selectedCrewID) return false;
      return this.currentWorkTypeSelectable;
    },
    selectedEmployeeOrCrewHasPerDiemRecord(): boolean {
      if (!this.contractor.workTypeIDs?.length) return false;
      if (!this.selectedEmployeeID && !this.selectedCrewID) return false;
      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!perDiemWorkType) return false;

      let employeeIDs = !!this.selectedEmployeeID
        ? [this.selectedEmployeeID]
        : this.selectedCrew?.employees?.map(ce => ce.employeeID!);
      if (!employeeIDs) return false;
      return (
        this.timesheet.entries.findIndex(
          x => x.workTypeID == perDiemWorkType!.id && employeeIDs?.includes(x.employeeID!)
        ) !== -1
      );
    },
    selectableWorkTypes(): SelectListOption<WorkType>[] {
      if (!this.contractor.workTypeIDs?.length) return [];

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      return SortWorkTypes(
        allWorkTypes
          .filter(
            x =>
              (this.timesheet.timesheetTypeID != TimesheetType.Equipment &&
                (this.contractor.workTypeIDs?.includes(x.id!) || x.isPerDiem)) ||
              (this.timesheet.timesheetTypeID == TimesheetType.Equipment && x.isEquipment)
          )
          .map(x => {
            let canEdit = this.workTypeSelectable(x);
            return { ...x, disabled: !canEdit };
          })
      );
    },
    selectedActiveWorkType(): WorkType | undefined {
      return this.selectableWorkTypes.find(
        x => !x.disabled && (x as WorkType).id == this.selectedWorkTypeID
      ) as WorkType;
    },
    selectableWorkSubTypes(): SelectListOption<WorkSubType>[] {
      var allWorkSubTypes = (this.$store.state.workSubTypes.fullList as WorkSubType[])
        .map(x => {
          let canEdit = this.workSubTypeSelectable(x);
          return { ...x, disabled: !canEdit };
        })
        .sort((a, b) => {
          let aOrder = a.order ?? 0;
          let bOrder = b.order ?? 0;
          if (aOrder != bOrder) return aOrder - bOrder;

          let aName = a.name!.toLocaleLowerCase();
          let bName = b.name!.toLocaleLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        });
      return allWorkSubTypes.filter(x => x.workTypeID == this.selectedActiveWorkType?.id);
    },
    selectedActiveWorkSubType(): WorkSubType | undefined {
      return this.selectableWorkSubTypes.find(
        x => !x.disabled && x.id == this.selectedWorkSubTypeID
      );
    },
    selectableCostCodes(): ProjectCostCode[] {
      if (!this.contractor.costCodeIDs?.length) return [];
      return (this.$store.state.projectCostCodes.fullList as ProjectCostCode[])
        .filter(x => this.contractor.costCodeIDs?.includes(x.id!))
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });
    },
    selectedCostCode(): ProjectCostCode | undefined {
      return this.selectableCostCodes.find(x => x.id == this.selectedCostCodeID);
    },
    currentTimesheetIsEquipment(): boolean {
      return (this.timesheet.timesheetTypeID ?? 0) == TimesheetType.Equipment;
    },
    selectableEmployees(): PersonWithDetailsAndName[] {
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let selectableEmployees = allPeople.filter(
        x => !PersonHasEquipmentClassification(x) && x.contractorID == this.timesheet.contractorID
      );
      if (this.currentTimesheetIsEquipment) {
        selectableEmployees = allPeople.filter(
          x =>
            !!PersonHasEquipmentClassification(x) && x.contractorID == this.timesheet.contractorID
        );
      }
      return SortItemsWithName(
        selectableEmployees.map(x => ({
          ...x,
          name: GetPersonName(x)
        }))
      );
    },
    selectedEmployee(): PersonWithDetailsAndName | undefined {
      return this.selectableEmployees.find(x => x.id == this.selectedEmployeeID);
    },
    selectableCrews(): SelectListOption<CrewWithEmployees>[] {
      return (this.$store.state.crews.fullList as CrewWithEmployees[])
        .filter(
          x =>
            x.contractorID == this.timesheet.contractorID &&
            (!x.ownerID || x.ownerID == this.curUserID)
        )
        .map(x => ({ ...x, disabled: !x.employees?.length }))
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });
    },
    selectedCrew(): CrewWithEmployees | undefined {
      return this.selectableCrews.find(x => x.id == this.selectedCrewID);
    },
    selectedEmployees(): PersonWithDetailsAndName[] {
      var selectedEmployeeIDs = [] as string[];
      if (!!this.selectedEmployeeID) selectedEmployeeIDs.push(this.selectedEmployeeID);
      if (!!this.selectedCrew)
        selectedEmployeeIDs = selectedEmployeeIDs.concat(
          SortCrewEmployees(this.selectedCrew.employees).map(x => x.employeeID!)
        );

      return this.selectableEmployees.filter(x => selectedEmployeeIDs.includes(x.id!));
    }
  },

  watch: {
    selectedAreaID() {
      this.selectedSubAreaID = this.selectedSubArea?.id ?? null;
    },
    selectedWorkTypeID() {
      this.selectedWorkSubTypeID = this.selectedActiveWorkSubType?.id ?? null;
      if (!this.selectedWorkSubTypeID && this.selectableWorkSubTypes.length == 1)
        this.selectedWorkSubTypeID = this.selectableWorkSubTypes[0].id!;
    },
    selectedWorkSubTypeID() {
      let selectedWorkSubType = this.selectedActiveWorkSubType;
      if (!selectedWorkSubType) return;
      var costCodeID = selectedWorkSubType.useWorkOrderCostCode
        ? this.selectedWorkOrder?.costCodeID
        : selectedWorkSubType.defaultCostCodeID;
      this.selectedCostCodeID = costCodeID ?? this.selectedCostCodeID;
    },
    selectedWorkOrder() {
      this.selectedAreaID = this.selectedWorkOrder?.areaID ?? null;
      this.selectedSubAreaID = this.selectedWorkOrder?.subAreaID ?? null;
      if (!!this.selectedWorkOrder) {
        this.selectedCostCodeID = this.selectedWorkOrder.costCodeID ?? null;

        if (
          !this.selectedActiveWorkSubType ||
          this.selectedActiveWorkSubType.useWorkOrderCostCode
        ) {
          this.selectedCostCodeID = this.selectedWorkOrder.costCodeID ?? this.selectedCostCodeID;
        }
      }
    }
  },

  methods: {
    ...mapActions({
      loadUsers: "LOAD_USERS",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadEmployees: "LOAD_USERS",
      loadCrews: "LOAD_CREWS",
      loadClassifications: "LOAD_CLASSIFICATIONS"
    }),
    workTypeSelectable(workType: WorkType | undefined): boolean {
      console.log(`workTypeSelectable: ${workType?.name}`);
      if (!workType) return false;

      if (this.timesheet.timesheetTypeID == TimesheetType.Equipment) {
        if (workType.isPerDiem) {
          console.log(`  TS is equipment but WT is per diem.`);
          return false;
        }
        if (!workType.isEquipment) {
          console.log(`  TS is equipment but WT is NOT equipment.`);
          return false;
        }
      }

      if (this.timesheet.timesheetTypeID == TimesheetType.Indirect) {
        if (workType.isDirect && !workType.isPerDiem) {
          console.log(`  TS is indirect but WT is direct.`);
          return false;
        }
      }

      let canEdit = false;
      if (workType.isPerDiem) {
        canEdit = !this.selectedEmployeeOrCrewHasPerDiemRecord && !this.selectedWorkOrder;
      } else {
        var allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
        let workSubTypes = allWorkSubTypes.filter(x => x.workTypeID == workType.id);
        let hasWorkOrderSubTypes = workSubTypes.findIndex(x => !!x.isWorkOrderRelated) !== -1;
        let canSelectWithWorkOrder = (workType.isDirect && hasWorkOrderSubTypes) ?? false;

        let hasIndirectSubTypes = workSubTypes.findIndex(x => !x.isWorkOrderRelated) !== -1;
        let canSelectWithoutWorkOrder = !workType.isDirect || hasIndirectSubTypes;

        let hasWorkOrder = !!this.selectedWorkOrder ?? false;
        canEdit =
          (canSelectWithWorkOrder && hasWorkOrder) || (canSelectWithoutWorkOrder && !hasWorkOrder);
      }
      return canEdit;
    },
    workSubTypeSelectable(workSubType: WorkSubType | undefined): boolean {
      if (!workSubType) return false;

      var allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let parentWorkType = allWorkTypes.find(x => x.id == workSubType.workTypeID);
      let canEdit = false;
      if (parentWorkType?.isPerDiem) {
        canEdit = !this.selectedEmployeeOrCrewHasPerDiemRecord && !this.selectedWorkOrder;
      } else {
        let canSelectWithWorkOrder =
          (parentWorkType?.isDirect && workSubType.isWorkOrderRelated) ?? false;

        let canSelectWithoutWorkOrder =
          !parentWorkType?.isDirect || !workSubType.isWorkOrderRelated;

        let hasWorkOrder = !!this.selectedWorkOrder ?? false;
        canEdit =
          (canSelectWithWorkOrder && hasWorkOrder) || (canSelectWithoutWorkOrder && !hasWorkOrder);
      }
      return canEdit;
    },
    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },
    async loadWorkOrders(workOrderSearchString: string | undefined) {
      this.optOutOfErrorHandling();
      let workOrders = await workOrderService.search(
        this.timesheet.day,
        workOrderSearchString,
        undefined,
        this.timesheet.contractorID,
        undefined,
        this.timesheet.ownerID
      );
      workOrders = workOrders.filter(x => !!x.foremanID && x.foremanID == this.timesheet.ownerID);
      this.availableWorkOrders = workOrders.map(x => {
        let formattedWorkOrder = {
          ...x,
          description: "WO-" + `00000${x.legacyID}`.slice(-5),
          details: ScaffoldRequestTypes[x.scaffoldRequestType!]
        } as FormattedWorkOrder;
        return formattedWorkOrder;
      });
    },
    // async loadTimesheet(timesheetID: string) {
    //   let timesheet = await timesheetService.getByID(timesheetID);
    //   this.timesheet = new UpdatableTimesheetWithEntries(timesheet, []);
    // },
    async loadData() {
      this.optOutOfErrorHandling();
      this.processing = true;
      try {
        await Promise.all([
          this.loadUsers(),
          this.loadAreas(),
          this.loadSubAreas(),
          this.loadWorkTypes(),
          this.loadWorkSubTypes(),
          this.loadCostCodes(),
          this.loadEmployees(),
          this.loadCrews(),
          this.loadClassifications()
        ]);
        // await this.loadTimesheet(timesheetID);
        this.contractor = await contractorService.getByID(this.timesheet.contractorID!);
        await this.loadWorkOrders(undefined);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(timesheet: TimesheetWithDetails) {
      this.timesheet = new UpdatableTimesheetWithEntries(timesheet, []);
      this.loadData();
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    async clearValues() {
      this.optOutOfErrorHandling();
      this.selectedWorkOrder = null;
      this.selectedWorkSubTypeID = null;
      this.selectedWorkTypeID = null;
      this.selectedSubAreaID = null;
      this.selectedAreaID = null;
      this.selectedCostCodeID = null;
      this.selectedCrewID = null;
      this.selectedEmployeeID = null;
    },
    async addEntriesToWorkspace() {
      this.optOutOfErrorHandling();
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.addform as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        this.selectedEmployees.forEach(x => {
          this.timesheet.entries.push(
            new UpdatableTimesheetEntryWithDetails({
              id: this.timesheet.entries.length.toString(),
              timesheetID: this.timesheet.id,
              workOrderID: this.selectedWorkOrder?.id,
              workOrderNumber: this.selectedWorkOrder?.legacyID?.toString(),
              scaffoldID: this.selectedWorkOrder?.scaffoldID,
              scaffoldNumber: this.scaffoldNumber,
              employeeID: x.id,
              employeeName: x.name,
              employeeCode: x.employeeCode,
              areaID: this.selectedArea?.id,
              areaName: this.selectedArea?.name,
              subAreaID: this.selectedSubArea?.id,
              subAreaName: this.selectedSubArea?.name,
              workTypeID: this.selectedActiveWorkType?.id,
              workTypeName: this.selectedActiveWorkType?.name,
              workSubTypeID: this.selectedActiveWorkSubType?.id,
              workSubTypeName: this.selectedActiveWorkSubType?.name,
              classificationID: x.classificationID,
              classificationName: this.getClassificationForID(x.classificationID)?.name,
              classificationAlias: this.getClassificationForID(x.classificationID)?.alias,
              costCodeID: this.selectedCostCode?.id,
              costCodeName: this.selectedCostCode?.name,
              regularTime: 0,
              overTime: 0,
              doubleTime: 0,
              units: 0,
              created: new Date()
            } as TimesheetEntryWithDetails)
          );
        });
        // debugger;
        this.timesheet.entries.sort((a, b) => {
          // Sort with newest at top
          let aCreated = a.created!;
          let bCreated = b.created!;
          return bCreated.getTime() - aCreated.getTime();
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    removeEntry(entry: UpdatableTimesheetEntryWithDetails) {
      const index = this.timesheet.entries.indexOf(entry);
      if (index < 0) {
        return;
      }
      this.timesheet.entries.splice(index, 1);
    },
    addFormOnSubmit(e: Event) {
      e.preventDefault();
      this.addEntriesToWorkspace;
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    async saveDialog() {
      if (!this.timesheet.entries.length) return;

      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        var errorEntries = this.timesheet.entries.filter(
          x => !x.regularTime && !x.overTime && !x.doubleTime && !x.units
        );
        if (errorEntries.length) {
          this.inlineMessage.message = this.$t(
            "timesheets.entries.entries-missing-data-error-message"
          );
          this.processing = false;
          return;
        }
        await timesheetService.addEntriesToTimesheetWithID(
          this.timesheet.id!,
          this.timesheet.entries.map(
            x =>
              ({
                ...x,
                ...x.modifiedData,
                id: undefined,
                created: undefined
              } as TimesheetEntryWithDetails)
          )
        );
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    getClassificationForID(
      classificationID: string | null | undefined
    ): Classification | undefined {
      return (this.$store.state.classifications.fullList as Classification[]).find(
        x => x.id == classificationID
      );
    },
    getPersonNameForID(userID: string | null | undefined): string | undefined {
      let person = (this.$store.state.users.fullList as PersonWithDetails[]).find(
        x => x.id == userID
      );
      return !!person ? GetPersonName(person) : undefined;
    },
    canEditRegularTimeHoursForItem(item: TimesheetEntryWithDetails): boolean {
      if (!this.timesheet.currentUserPermissions.canEditExistingEntries) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!!perDiemWorkType) {
        if (perDiemWorkType.id == item.workTypeID) return false;
      }

      return true;
    },
    canEditDoubleTimeHoursForItem(item: TimesheetEntryWithDetails): boolean {
      if (!this.timesheet.currentUserPermissions.canEditExistingEntries) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let equipmentWorkType = allWorkTypes.find(x => !!x.isEquipment);
      if (!!equipmentWorkType) {
        if (equipmentWorkType.id != item.workTypeID) return false;
      }

      return true;
    },
    canEditPerDiemUnitsForItem(item: TimesheetEntryWithDetails): boolean {
      if (!this.timesheet.currentUserPermissions.canEditExistingEntries) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!!perDiemWorkType) {
        if (perDiemWorkType.id != item.workTypeID) return false;
      }

      return true;
    },
    canEditEquipmentUnitsForItem(item: TimesheetEntryWithDetails): boolean {
      if (!this.timesheet.currentUserPermissions.canEditExistingEntries) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let equipmentWorkType = allWorkTypes.find(x => !!x.isEquipment);
      if (!!equipmentWorkType) {
        if (equipmentWorkType.id != item.workTypeID) return false;
      }

      return true;
    },

    // *** INLINE NAVIGATION ***
    getFieldRef(fieldName: string, item: TimesheetEntryWithDetails) {
      let id = item.id!.replace("-", "").replace("-", "");
      return `${fieldName}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, index: number) {
      let visibleItems = (this.$refs.datatable as VDataTable).internalCurrentItems;
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(fieldName: string, item: TimesheetEntryWithDetails) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.tablepage <= 1) return;
        this.tablepage -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, datatable.computedItemsPerPage);
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex);
    },
    async selectNextField(fieldName: string, item: TimesheetEntryWithDetails) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex >= visibleParts.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(this.timesheet.entries.length / datatable.computedItemsPerPage);
        if (this.tablepage >= maxPage) return;

        this.tablepage += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, 0);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex);
    },
    async enterPressed(e: KeyboardEvent, fieldName: string, item: TimesheetEntryWithDetails) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    }
  }
});

export default TimesheetEntriesAddDialog;

export async function addTimesheetEntries(
  timesheet: TimesheetWithDetails
): Promise<string | boolean> {
  let dialog = createDialog(TimesheetEntriesAddDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(timesheet);
}

