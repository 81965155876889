import { mapMutations, mapState, mapActions } from "vuex";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { PartWithTags, SupplierWithTags, partService, supplierService } from "../services";
import rules from "@fd/lib/vue/rules";
import userAccess from "../dataMixins/userAccess";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { FDColumnDirective } from "../../../lib/vue/utility/dataTable";
import { TranslateResult } from "vue-i18n";

type PartWithTagsAndSelected = PartWithTags & { selected: boolean };
type SupplierWithArchived = SupplierWithTags & {
  archived: boolean;
};

export default FDVue.extend({
  name: "fd-supplier-existing",

  mixins: [errorHandling, rules, userAccess, tabbedView],
  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue")
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,

      slidein: false,

      supplier: {} as SupplierWithArchived,

      selectedTags: [],

      firstTabKey: `0`,
      detailsTab: {
        tabname: "Details",
        key: "0",
        visible: true
      } as Tab,
      partsTab: {
        tabname: "Parts",
        key: "1",
        visible: false
      } as Tab,

      // *** PARTS ***
      showOnlyIncludedParts: false,
      partsTableSearch: "",
      selectableParts: [] as Array<PartWithTagsAndSelected>
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.partsTab] as Tab[];
    },
    availableTags() {
      return this.$store.getters.getSortedEnabledInUseTags(this.selectedTags);
    },

    parts(): Array<PartWithTagsAndSelected> {
      let returnValue = this.selectableParts;
      if (this.showOnlyIncludedParts) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedPartIDs(): string[] {
      return this.selectableParts.filter(x => x.selected).map(x => x.id!);
    },

    searchedParts(): Array<PartWithTagsAndSelected> {
      // This is a hack because the parts list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .partsDataTable as any)?.customFilter;
      if (this.partsTableSearch && customFilter) {
        return this.parts.filter(
          x =>
            customFilter(x.name!, this.partsTableSearch, x) ||
            customFilter(x.description!, this.partsTableSearch, x)
        );
      } else {
        return this.parts;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedPartsSelected(): boolean {
      return this.searchedParts.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedPartsSelected(): boolean {
      var searchedParts = this.searchedParts;
      return (
        searchedParts.findIndex(x => x.selected) !== -1 &&
        searchedParts.findIndex(x => !x.selected) !== -1
      );
    }
  },

  methods: {
    aliascounter(value: any): boolean | string | TranslateResult {
      return !value || value.length <= 20 || this.$t("common.rule-alias-counter", "20");
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },
    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        this.inlineMessage.message = this.$t("suppliers.existing.details-error-message");
        this.inlineMessage.type = "error";
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.supplier.archived) {
          this.supplier.archivedDate = null;
        } else if (this.supplier.archived && !this.supplier.archivedDate) {
          this.supplier.archivedDate = new Date(new Date().toUTCString());
        }
        this.supplier.partIDs = this.selectedPartIDs;

        await this.updateSupplier({
          ...this.supplier,
          tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map((x: any) => x.id) : null
        });
        if (closeOnComplete) {
          this.$router.push("/suppliers");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteSupplier({ id: this.$route.params.id, name: this.supplier.name });
        this.$router.push("/suppliers");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/suppliers");
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadSupplier: "LOAD_SUPPLIER",
      loadTags: "LOAD_TAGS",
      loadParts: "LOAD_PARTS",
      updateSupplier: "UPDATE_SUPPLIER",
      deleteSupplier: "DELETE_SUPPLIER"
    }),

    // *** PARTS
    flipPartSelected(item: PartWithTagsAndSelected & { selected: boolean }) {
      item.selected = !item.selected;
    },

    flipSearchedPartselected() {
      let selected = !this.allSearchedPartsSelected;
      let changedPartIDs = [] as string[];
      for (let part of this.searchedParts) {
        if (part.selected !== selected) {
          part.selected = selected;
          changedPartIDs.push(part.id!);
        }
      }
    }
  },

  watch: {
    supplier(newValue) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/suppliers") {
        this.notifyNewBreadcrumb({
          text: this.$t("suppliers.list.title"),
          to: "/suppliers",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/suppliers/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/suppliers") {
      this.notifyNewBreadcrumb({
        text: this.$t("suppliers.list.title"),
        to: "/suppliers",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }
    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true,
      history: [
        {
          text: this.$t("suppliers.list.title"),
          disabled: true
        }
      ]
    });

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "supplierexisting",
      parentalContext: "suppliers",
      selectedTab: this.firstTabKey
    });

    this.processing = true;
    try {
      await Promise.all([this.loadSupplier(this.$route.params.id), this.loadTags()]);

      let supplier = this.$store.state.suppliers.fullList.find(
        (x: any) => x.id == this.$route.params.id
      );
      this.supplier = { ...supplier, archived: !!supplier.archivedDate };
      if (supplier.tagIDs) {
        this.selectedTags = supplier.tagIDs
          .map((x: any) => this.$store.state.tags.fullList.find((y: any) => y.id == x))
          .filter((x: any) => x);
      } else {
        this.selectedTags = [];
      }
      await this.loadParts();

      // *** PARTS ***
      this.selectableParts = this.$store.state.parts.fullList.map((x: PartWithTags) => ({
        ...x,
        selected: this.supplier.partIDs?.length ? this.supplier.partIDs.indexOf(x.id!) > -1 : false
      }));
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

