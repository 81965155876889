var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-0",
      attrs: { fluid: "", "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "fd-subheader-with-control mx-0 px-2",
          style:
            !!_vm.value || !!_vm.showHighlightColor
              ? "background: " + _vm.onHighlightColor + ";"
              : "",
          attrs: { align: "center" }
        },
        [
          !!_vm.title
            ? _c(
                "v-col",
                { attrs: { align: "center", cols: "12", sm: "8" } },
                [
                  _c(
                    "v-subheader",
                    { staticStyle: { "font-size": "1em", height: "auto" } },
                    [
                      !!_vm.icon
                        ? _c("v-icon", {
                            domProps: { textContent: _vm._s(_vm.icon) }
                          })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.title))
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            {
              attrs: { align: "center", cols: "12", sm: !!_vm.title ? 4 : 12 }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "pr-3",
                  class: { "justify-sm-end": !!_vm.title }
                },
                [
                  _vm.showSwitch
                    ? _c(
                        "v-switch",
                        _vm._b(
                          {
                            staticClass: "pl-3 py-2 my-0",
                            class: { "pl-sm-0": !!_vm.title },
                            attrs: {
                              "prepend-icon":
                                !_vm.title && !!_vm.icon ? _vm.icon : undefined,
                              color: !!_vm.value ? _vm.onColor : undefined,
                              "data-cy":
                                (!!_vm.cy ? _vm.cy : _vm.dataCy) + "switch",
                              "input-value": _vm.value,
                              label: _vm.label,
                              "hide-details": "",
                              disabled: _vm.disabled
                            },
                            on: {
                              change: function(v) {
                                return _vm.$emit("input", v)
                              }
                            }
                          },
                          "v-switch",
                          _vm.switchOptions,
                          false
                        )
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          class: {
            "mx-2": !_vm.value && !_vm.showHighlightColor,
            "mx-0": !!_vm.value || !!_vm.showHighlightColor
          },
          attrs: { align: "center" }
        },
        [
          _c("v-divider", {
            staticClass: "mt-0",
            attrs: {
              color:
                !!_vm.value || !!_vm.showHighlightColor
                  ? _vm.onHighlightColor
                  : undefined
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }