var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "timesheetweekendingsummary" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { loading: _vm.processing } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "ml-0 mr-0 fd-primary-context-qualifier-background",
              attrs: { align: "center" }
            },
            [
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "6", sm: "6" } },
                [
                  _c("v-row", { attrs: { align: "center" } }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$vuetify.breakpoint.smAndUp
                            ? "fd-timesheet-summary-date-select-wrapper"
                            : "fd-timesheet-summary-date-select-wrapper-mobile",
                          "fd-position-relative",
                          "my-2"
                        ]
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              top: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "d-none d-sm-flex fd-timesheet-summary-date-selector-button-position-left",
                                          attrs: {
                                            icon: "",
                                            fab: "",
                                            disabled: _vm.processing
                                          },
                                          on: {
                                            click: _vm.selectPreviousWeekending
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-circle-caret-left")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "timesheets.weekending-summary.previous-weekending"
                                  )
                                )
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("fd-date-picker", {
                          attrs: {
                            "hide-details": "",
                            dense: "",
                            max: new Date(),
                            disabled: _vm.processing
                          },
                          model: {
                            value: _vm.selectedDay,
                            callback: function($$v) {
                              _vm.selectedDay = $$v
                            },
                            expression: "selectedDay"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              top: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "d-none d-sm-flex fd-timesheet-summary-date-selector-button-position-right",
                                          attrs: {
                                            icon: "",
                                            fab: "",
                                            disabled: _vm.processing
                                          },
                                          on: {
                                            click: _vm.selectNextWeekending
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-circle-caret-right")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "timesheets.weekending-summary.next-weekending"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6", sm: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "end" } },
                    [
                      _c("fd-select", {
                        staticClass: "mr-4",
                        attrs: {
                          outlined: "",
                          dense: "",
                          items: _vm.selectableContractors,
                          label: _vm.$t("users.filter-by-contractor-label"),
                          "item-text": "alias",
                          "item-value": "id",
                          "hide-details": "",
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.selectedContractorID,
                          callback: function($$v) {
                            _vm.selectedContractorID = $$v
                          },
                          expression: "selectedContractorID"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-2 order-sm-1",
                      attrs: { cols: "12", sm: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _vm.$vuetify.breakpoint.lgAndUp
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mr-3",
                                                staticStyle: {
                                                  "min-width": "110px"
                                                },
                                                attrs: {
                                                  small: "",
                                                  rounded: "",
                                                  outlined: "",
                                                  disabled: _vm.processing,
                                                  color: "#7a7a7a"
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    left:
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-printer\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.$vuetify.breakpoint.smAndUp
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t("common.print")
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-left":
                                                      "2px !important"
                                                  },
                                                  attrs: { right: "" }
                                                },
                                                [_vm._v("mdi-menu-down")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { attrs: { subheader: "" } },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticClass: "fd-drop-down-button-badges"
                                    },
                                    [
                                      _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            inline: "",
                                            color: "primary",
                                            content:
                                              "" + _vm.visibleSummaries.length
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.weekending-summary.printing.visible-timesheet-rows"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-group",
                                    { staticClass: "fd-drop-down-item-group" },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadAndPrintTimesheetWeekendingReport(
                                                true,
                                                "pdf"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.weekending-summary.printing.pdf"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadAndPrintTimesheetWeekendingReport(
                                                true,
                                                "xls"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.weekending-summary.printing.excel"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider", { staticClass: "my-3" }),
                                  _vm._v(" "),
                                  _c(
                                    "v-subheader",
                                    {
                                      staticClass: "fd-drop-down-button-badges"
                                    },
                                    [
                                      _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            inline: "",
                                            color: "primary",
                                            content:
                                              "" +
                                              _vm.employeeWeekendingSummaries
                                                .length
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.weekending-summary.printing.all-timesheet-rows"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-group",
                                    { staticClass: "fd-drop-down-item-group" },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadAndPrintTimesheetWeekendingReport(
                                                false,
                                                "pdf"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.weekending-summary.printing.pdf"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadAndPrintTimesheetWeekendingReport(
                                                false,
                                                "xls"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.weekending-summary.printing.excel"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                disabled: _vm.processing,
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-simple-table",
            { staticClass: "fd-timesheet-weekending-summary-table" },
            [
              _c(
                "colgroup",
                [
                  _c("col", { attrs: { span: "1" } }),
                  _vm._v(" "),
                  _c("col", { attrs: { span: "1" } }),
                  _vm._v(" "),
                  _c("col", { attrs: { span: "1" } }),
                  _vm._v(" "),
                  _vm._l(_vm.daysOfWeek, function(day) {
                    return [
                      _c("col", {
                        key: day.displayNumber,
                        attrs: { span: "3" }
                      })
                    ]
                  }),
                  _vm._v(" "),
                  _c("col", {
                    staticClass: "fd-table-row-summary-column",
                    attrs: { span: "4" }
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("thead", [
                _c(
                  "tr",
                  [
                    _c("th"),
                    _vm._v(" "),
                    _c("th"),
                    _vm._v(" "),
                    _c("th"),
                    _vm._v(" "),
                    _vm._l(_vm.daysOfWeek, function(day) {
                      return _c(
                        "th",
                        {
                          key: day.displayNumber,
                          staticClass: "fd-column-group-start",
                          attrs: { colspan: "3" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              style: _vm.dateIsToday(day.date)
                                ? "font-weight: bold;"
                                : "",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c("div", { staticClass: "fd-item-detail" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.dayName(day.dayNumber)) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _vm.dateIsToday(day.date)
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "0.6rem",
                                        color: "rgba(0, 0, 0, 0.6)"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                 (" +
                                          _vm._s(_vm.$t("common.today")) +
                                          ")\n              "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "fd-item-detail-light",
                              style: _vm.dateIsToday(day.date)
                                ? "font-weight: bold;"
                                : ""
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.formatDate(day.date)) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "fd-column-group-start fd-column-group-bottom fd-item-detail",
                        attrs: { colspan: "4" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "timesheets.weekending-summary.summary-column-label"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "tr",
                  [
                    _c("th", { staticClass: "fd-column-group-bottom" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "timesheets.weekending-summary.employee-name-column-label"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "fd-column-group-bottom" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "timesheets.weekending-summary.employee-badge-column-label"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "fd-column-group-bottom" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "timesheets.weekending-summary.employee-classification-column-label"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.daysOfWeek, function(day) {
                      return [
                        _c(
                          "th",
                          {
                            key: day.dayNumber + "ST",
                            staticClass:
                              "fd-column-group-start fd-column-group-bottom"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "timesheets.weekending-summary.regular-time-column-label"
                                  )
                                ) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            key: day.dayNumber + "OT",
                            staticClass: "fd-column-group-bottom"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "timesheets.weekending-summary.over-time-column-label"
                                  )
                                ) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            key: day.dayNumber + "PD",
                            staticClass: "fd-column-group-bottom"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "timesheets.weekending-summary.per-diem-column-label"
                                  )
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      ]
                    }),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "fd-column-group-start fd-column-group-bottom"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "timesheets.weekending-summary.regular-time-column-label"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("th", { staticClass: "fd-column-group-bottom" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "timesheets.weekending-summary.over-time-column-label"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "fd-column-group-bottom" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "timesheets.weekending-summary.total-time-column-label"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "fd-column-group-bottom" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "timesheets.weekending-summary.per-diem-column-label"
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _c("tr", [
                  _vm.processing
                    ? _c(
                        "td",
                        {
                          staticClass: "px-0 pb-0",
                          staticStyle: {
                            height: "4px",
                            position: "absolute",
                            width: "100%"
                          },
                          attrs: { colspan: "99" }
                        },
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  !_vm.visibleSummaries || _vm.visibleSummaries.length == 0
                    ? _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "fd-item-detail-light",
                            staticStyle: { "text-align": "center" },
                            attrs: { colspan: "99" }
                          },
                          [
                            _vm.processing
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      this.$t("common.table-loading-message")
                                    )
                                  )
                                ])
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      this.$t("common.table-no-data-message")
                                    )
                                  )
                                ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.visibleSummaries, function(employee) {
                    return _c(
                      "tr",
                      { key: employee.employeeID },
                      [
                        _c("td", [_vm._v(_vm._s(employee.employeeName))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(employee.employeeCode))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                !!employee.classificationAlias
                                  ? employee.classificationAlias
                                  : employee.classificationName
                              ) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.daysOfWeek, function(day) {
                          return [
                            _c(
                              "td",
                              {
                                key: day.dayNumber + "ST",
                                staticClass: "fd-column-group-start"
                              },
                              [
                                _c("fd-value-display", {
                                  staticStyle: { display: "inline" },
                                  attrs: {
                                    value: _vm.formatNumber(
                                      _vm.parseRegularTime(employee, day.date)
                                    ),
                                    "no-value-key":
                                      "timesheets.list.no-time-value"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { key: day.dayNumber + "OT" },
                              [
                                _c("fd-value-display", {
                                  staticStyle: { display: "inline" },
                                  attrs: {
                                    value: _vm.formatNumber(
                                      _vm.parseOverTime(employee, day.date)
                                    ),
                                    "no-value-key":
                                      "timesheets.list.no-time-value"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { key: day.dayNumber + "PD" },
                              [
                                _c("fd-value-display", {
                                  staticStyle: { display: "inline" },
                                  attrs: {
                                    value: _vm.formatNumber(
                                      _vm.parsePerDiem(employee, day.date),
                                      0
                                    ),
                                    "no-value-key":
                                      "timesheets.list.no-entries-value"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "fd-column-group-start" },
                          [
                            _c("fd-value-display", {
                              staticStyle: { display: "inline" },
                              attrs: {
                                value: _vm.formatNumber(
                                  employee.totalRegularTime
                                ),
                                "no-value-key": "timesheets.list.no-time-value",
                                "no-value-class": "black--text"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { display: "inline" },
                              attrs: {
                                value: _vm.formatNumber(employee.totalOverTime),
                                "no-value-key": "timesheets.list.no-time-value",
                                "no-value-class": "black--text"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { display: "inline" },
                              attrs: {
                                value: _vm.formatNumber(employee.totalTime),
                                "no-value-key": "timesheets.list.no-time-value",
                                "no-value-class": "black--text"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { display: "inline" },
                              attrs: {
                                value: _vm.formatNumber(
                                  employee.totalPerDiemCount,
                                  0
                                ),
                                "no-value-key":
                                  "timesheets.list.no-entries-value",
                                "no-value-class": "black--text"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("tfoot", [
                _c(
                  "tr",
                  { staticClass: "fd-table-summary-row" },
                  [
                    _c(
                      "td",
                      { attrs: { colspan: "3" } },
                      [
                        _c("fd-alert", {
                          staticClass: "pa-0 mt-0",
                          attrs: {
                            dense: true,
                            label: _vm.$t(
                              "timesheets.existing.grand-total-label"
                            ),
                            type: "hint",
                            white: ""
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.daysOfWeek, function(day) {
                      return [
                        _c(
                          "td",
                          {
                            key: day.dayNumber + "ST",
                            staticClass: "fd-column-group-start"
                          },
                          [
                            _c("fd-value-display", {
                              staticStyle: { display: "inline" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.parseRegularTime(
                                    _vm.totalSummary,
                                    day.date
                                  )
                                ),
                                "no-value-key": "timesheets.list.no-time-value",
                                "no-value-class": "white--text"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { key: day.dayNumber + "OT" },
                          [
                            _c("fd-value-display", {
                              staticStyle: { display: "inline" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.parseOverTime(_vm.totalSummary, day.date)
                                ),
                                "no-value-key": "timesheets.list.no-time-value",
                                "no-value-class": "white--text"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { key: day.dayNumber + "PD" },
                          [
                            _c("fd-value-display", {
                              staticStyle: { display: "inline" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.parsePerDiem(_vm.totalSummary, day.date),
                                  0
                                ),
                                "no-value-key":
                                  "timesheets.list.no-entries-value",
                                "no-value-class": "white--text"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "fd-column-group-start" },
                      [
                        _c("fd-value-display", {
                          staticStyle: { display: "inline" },
                          attrs: {
                            value: _vm.formatNumber(
                              _vm.totalSummary.totalRegularTime
                            ),
                            "no-value-key": "timesheets.list.no-time-value",
                            "no-value-class": "white--text"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("fd-value-display", {
                          staticStyle: { display: "inline" },
                          attrs: {
                            value: _vm.formatNumber(
                              _vm.totalSummary.totalOverTime
                            ),
                            "no-value-key": "timesheets.list.no-time-value",
                            "no-value-class": "white--text"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("fd-value-display", {
                          staticStyle: { display: "inline" },
                          attrs: {
                            value: _vm.formatNumber(_vm.totalSummary.totalTime),
                            "no-value-key": "timesheets.list.no-time-value",
                            "no-value-class": "white--text"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("fd-value-display", {
                          staticStyle: { display: "inline" },
                          attrs: {
                            value: _vm.formatNumber(
                              _vm.totalSummary.totalPerDiemCount,
                              0
                            ),
                            "no-value-key": "timesheets.list.no-entries-value",
                            "no-value-class": "white--text"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }