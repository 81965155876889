var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mx-0", staticStyle: { width: "100%" } },
    [
      _c(
        "v-col",
        {
          staticClass: "fd-remove-left-padding-cols-below pl-0",
          attrs: { cols: "12" }
        },
        [
          _c(
            "v-row",
            { staticClass: "mx-0 px-0", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { align: "center", cols: "12" } },
                [
                  _c(
                    "v-subheader",
                    {
                      staticClass: "pa-0 mt-3",
                      staticStyle: { "font-size": "1em", height: "32px" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("scheduler.scaffold-request-notes-sub-header")
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 pl-0", attrs: { align: "center" } },
            [_c("v-divider", { staticClass: "mx-0 mt-0 px-0" })],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 pl-0 pt-2 pb-3", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12" } },
                [
                  _c("fd-value-display", {
                    attrs: { value: _vm.workOrder.notes }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }