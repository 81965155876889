import FDVue from "@fd/lib/vue";
import { workOrderService } from "../../services";
import { openLabourEntryDialog } from "../components/dialogs/LabourEntryDialog.vue";
import { openForemanTimesheetDialog } from "../components/dialogs/ForemanTimesheetDialog.vue";
import {
  FormattedWorkOrder,
  FormattedWorkOrderFromWorkOrderWithLegacyDetails
} from "../components/KanbanItem.vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";

export default FDVue.extend({
  name: "fd-labour-entry-test",
  mixins: [serviceErrorHandling],
  data: function() {
    return {
      workOrderID: "1c144527-6d25-ed11-9440-000d3ae989de",
      workOrder: undefined as FormattedWorkOrder | undefined,
      isShowingDialog: false
    };
  },
  methods: {
    async showLabourEntryDialog() {
      await this.loadWorkOrder();
      if (!this.workOrder) return;
      if (this.isShowingDialog) return;

      this.isShowingDialog = true;
      await openLabourEntryDialog(this.workOrder);
      this.isShowingDialog = false;
    },
    async loadWorkOrder() {
      this.processing = true;
      try {
        let workOrder = await workOrderService.getWorkOrderByID(this.workOrderID);
        this.workOrder = FormattedWorkOrderFromWorkOrderWithLegacyDetails(workOrder);
        if (!this.isShowingDialog) this.showLabourEntryDialog();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async showForemanTimesheetDialog() {
      if (this.isShowingDialog) return;

      this.isShowingDialog = true;
      await openForemanTimesheetDialog(
        "673cf66b-6c25-ed11-9440-000d3ae989de",
        new Date(2022, 7, 30),
        undefined
      );
      this.isShowingDialog = false;
    }
  },
  mounted: async function() {},
  created: async function() {}
});

