import FDVue from "@fd/lib/vue";
import userAccess from "../dataMixins/userAccess";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { mapActions, mapMutations } from "vuex";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import rules from "@fd/lib/vue/rules";
import {
  BuildDismantleRatio,
  contractorService,
  ContractorWithTags,
  Environment,
  environmentService,
  personService,
  ProjectCostCode,
  ScaffoldDistanceModifier,
  ScaffoldElevationModifier,
  ScaffoldHeightModifier,
  ScaffoldTypeModifier,
  Yard
} from "../services";
import { PersonWithName, GetPersonName, SortItemsWithName } from "../utils/person";
import { GroupableSelectListOption, SelectListOption } from "@fd/lib/vue/utility/select";
import { getNameOfDayFromNumber } from "../../../lib/client-util/datetime";

export default FDVue.extend({
  name: "fd-environment-configuration",
  mixins: [userAccess, serviceErrorHandling, rules, tabbedView],
  components: {},
  data: function() {
    return {
      environment: {} as Environment,
      saving: false,

      // Form data errors
      detailsTabError: false,
      workflowTabError: false,

      firstTabKey: `0`,
      detailsTab: {
        tabname: this.$t("configuration.tabs.site-info"),
        key: "0",
        visible: true
      } as Tab,
      notificationsTab: {
        tabname: this.$t("configuration.tabs.notifications"),
        key: "1",
        visible: false
      } as Tab,
      workflowTab: {
        tabname: this.$t("configuration.tabs.workflow"),
        key: "2",
        visible: false
      } as Tab,
      defaultsTab: {
        tabname: this.$t("configuration.tabs.defaults"),
        key: "3",
        visible: false
      } as Tab,
      labourTab: {
        tabname: this.$t("configuration.tabs.labour"),
        key: "4",
        visible: false
      } as Tab,

      // *** DETAILS ***
      siteProductivityOptions: Array.from(Array(11).keys()).map(k => {
        let value = k * 0.05 + 0.5;
        let percent = `${(value * 100).toFixed(0)}%`;
        return {
          text: percent,
          value: value
        };
      }),

      // *** DEFAULTS ***
      allContractors: [] as ContractorWithTags[],
      allCoordinators: [] as PersonWithName[],
      allForemen: [] as PersonWithName[],
      allGeneralForemen: [] as PersonWithName[]
    };
  },
  computed: {
    weekendingDayOptions(): any[] {
      return Array.from(Array(7).keys()).map(x => ({
        text: getNameOfDayFromNumber(x),
        value: x
      }));
    },
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.notificationsTab, this.workflowTab, this.defaultsTab, this.labourTab] as Tab[];
    },
    configurationRules(): any {
      return {
        defaultWorkOrderAssignedContractorID: this.environment.automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        defaultWorkOrderAssignedCoordinatorID: this.environment.automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        defaultWorkOrderAssignedGeneralForemanID: this.environment
          .automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        defaultWorkOrderAssignedForemanID: this.environment.automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        defaultCountSheetFromYardID: this.environment.automaticallyApproveCountSheets
          ? [this.rules.required]
          : [],
        defaultCountSheetToYardID: []
      };
    },

    allYards(): Yard[] {
      return (this.$store.state.yards.fullList as Yard[]).filter(x => !x.isSystemYard);
    },

    allCostCodes(): ProjectCostCode[] {
      return this.$store.state.projectCostCodes.fullList as ProjectCostCode[];
    },

    allScaffoldTypeModifiers(): ScaffoldTypeModifier[] {
      return (this.$store.state.scaffoldTypeModifiers.fullList as ScaffoldTypeModifier[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },
    allScaffoldDistanceModifiers(): ScaffoldDistanceModifier[] {
      return (this.$store.state.scaffoldDistanceModifiers
        .fullList as ScaffoldDistanceModifier[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },
    allScaffoldElevationModifiers(): ScaffoldElevationModifier[] {
      return (this.$store.state.scaffoldElevationModifiers
        .fullList as ScaffoldElevationModifier[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },
    allScaffoldHeightModifiers(): ScaffoldHeightModifier[] {
      return (this.$store.state.scaffoldHeightModifiers.fullList as ScaffoldHeightModifier[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },
    allBuildDismantleRatios(): BuildDismantleRatio[] {
      return (this.$store.state.buildDismantleRatios.fullList as BuildDismantleRatio[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },

    selectableCoordinators(): GroupableSelectListOption<PersonWithName>[] {
      var contractorID = this.environment.defaultWorkOrderAssignedContractorID;

      var coordinatorsForSelectedContractor = !!contractorID
        ? this.coordinatorsInContractor(contractorID)
        : [];

      var allProxyCoordinators: GroupableSelectListOption<PersonWithName>[] = [];
      var proxyContractors = this.allContractors.filter(
        x => x.id != contractorID && x.canActAsProxy == true
      );
      if (proxyContractors.length > 0) {
        proxyContractors.forEach(x => {
          var proxyCoordinatorsForContractor = this.proxyCoordinatorsForContractor(
            contractorID!,
            x.id!
          );
          if (proxyCoordinatorsForContractor.length == 0) return;
          if (allProxyCoordinators.length > 0) {
            allProxyCoordinators.push({
              divider: true
            });
          }
          allProxyCoordinators.push({
            header: x.name ?? ""
          });
          allProxyCoordinators = allProxyCoordinators.concat(proxyCoordinatorsForContractor);
        });
        if (!!coordinatorsForSelectedContractor?.length && !!allProxyCoordinators?.length) {
          allProxyCoordinators.push({ divider: true });
          let selectedContractor = this.allContractors.find(x => x.id == contractorID);
          var header =
            selectedContractor?.name ??
            `${this.$t("configuration.defaults.selected-default-contractor")}`;
          allProxyCoordinators.push({
            header: header
          });
        }
      }

      var selectableCoordinators = allProxyCoordinators.concat(coordinatorsForSelectedContractor);

      return selectableCoordinators;
    },

    selectableGeneralForemen(): SelectListOption<PersonWithName>[] {
      var generalForemenPeopleForContractor = SortItemsWithName(
        this.allGeneralForemen
          // TODO: Remove the restriction where this person has to be mapped to a legacy employee
          .filter(
            x =>
              !!x.legacyID &&
              ((this.curUserCanViewAllContractors && !x.contractorID) ||
                x.contractorID == this.environment.defaultWorkOrderAssignedContractorID)
          )
      );
      return generalForemenPeopleForContractor;
    },

    selectableForemen(): SelectListOption<PersonWithName>[] {
      var foremenPeopleForContractor = SortItemsWithName(
        this.allForemen
          // TODO: Remove the restriction where this person has to be mapped to a legacy employee
          .filter(
            x =>
              !!x.legacyID &&
              ((this.curUserCanViewAllContractors && !x.contractorID) ||
                x.contractorID == this.environment.defaultWorkOrderAssignedContractorID)
          )
      );
      return foremenPeopleForContractor;
    }
  },
  watch: {
    "environment.trackScaffoldVLF": function() {
      if (!this.environment.trackScaffoldVLF) {
        this.environment.walkdownVLFRequired = false;
        this.environment.workOrderActualVLFRequired = false;
      }
    },
    "environment.automaticallyApproveScaffoldRequests": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedDefaults();
    },
    "environment.defaultWorkOrderAssignedContractorID": function() {
      this.verifySelectedDefaults();
    },
    "environment.automaticallyApproveCountSheets": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedDefaults();
    }
  },
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadYards: "LOAD_YARDS",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadScaffoldTypeModifiers: "LOAD_SCAFFOLD_TYPE_MODIFIERS",
      loadScaffoldDistanceModifiers: "LOAD_SCAFFOLD_DISTANCE_MODIFIERS",
      loadScaffoldElevationModifiers: "LOAD_SCAFFOLD_ELEVATION_MODIFIERS",
      loadScaffoldHeightModifiers: "LOAD_SCAFFOLD_HEIGHT_MODIFIERS",
      loadBuildDismantleRatios: "LOAD_BUILD_DISMANTLE_RATIOS"
    }),
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.detailsTabError = !((this.$refs.detailsform as HTMLFormElement)?.validate() ?? false);
      this.workflowTabError = !((this.$refs.workflowform as HTMLFormElement)?.validate() ?? true);
      return !(this.detailsTabError || this.workflowTabError);
    },

    async save() {
      this.inlineMessage.message = null;
      if (!this.validate()) {
        var message = this.$t("configuration.error-message");
        if (this.detailsTabError) message += "\n\t- " + this.detailsTab.tabname;
        if (this.workflowTabError) message += "\n\t- " + this.workflowTab.tabname;

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return false;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!!this.environment.blendedLabourRate)
          this.environment.blendedLabourRate = +this.environment.blendedLabourRate;
        if (!!this.environment.factor1) this.environment.factor1 = +this.environment.factor1;
        if (!!this.environment.factor2) this.environment.factor2 = +this.environment.factor2;

        if (!!this.environment.defaultCrewSize)
          this.environment.defaultCrewSize = +this.environment.defaultCrewSize;
        if (!!this.environment.defaultFactor1)
          this.environment.defaultFactor1 = +this.environment.defaultFactor1;
        if (!!this.environment.defaultFactor2)
          this.environment.defaultFactor2 = +this.environment.defaultFactor2;

        if (!!this.environment.defaultEmployeeOvertimeHoursThreshold)
          this.environment.defaultEmployeeOvertimeHoursThreshold = +this.environment
            .defaultEmployeeOvertimeHoursThreshold;
        await environmentService.updateItemWithLegacySync(this.environment.id!, this.environment);
        this.$store.commit("SET_CUR_ENVIRONMENT", this.environment);

        var snackbarPayload = {
          text: this.$t("configuration.snack-bar-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.saving = false;
        this.processing = false;
      }
    },

    // *** WORKFLOW ***
    // DOES NOT manage processing or error message logic
    async loadDefaultsReferenceData() {
      await Promise.all([
        this.loadContractors(),
        this.loadCoordinators(),
        this.loadGeneralForemen(),
        this.loadForemen(),
        this.loadYards(),
        this.loadCostCodes(),
        this.loadScaffoldTypeModifiers(),
        this.loadScaffoldHeightModifiers(),
        this.loadScaffoldElevationModifiers(),
        this.loadScaffoldDistanceModifiers(),
        this.loadBuildDismantleRatios()
      ]);
    },
    async loadContractors(): Promise<void> {
      this.allContractors = await contractorService.getAll(false, null, null);
    },

    // DOES NOT manage processing or error message logic
    async loadCoordinators(): Promise<void> {
      let coordinators = await personService.getAllCoordinators();
      this.allCoordinators = coordinators.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadGeneralForemen(): Promise<void> {
      let generalForemen = await personService.getAllGeneralForemen();
      this.allGeneralForemen = generalForemen.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadForemen(): Promise<void> {
      let foremen = await personService.getAllForemen();
      this.allForemen = foremen.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    coordinatorsInContractor(contractorID: string) {
      var coordinatorsForContractor = SortItemsWithName(
        this.allCoordinators.filter(x => !!x.contractorID && x.contractorID == contractorID)
      );
      return coordinatorsForContractor;
    },

    proxyCoordinatorsForContractor(assignedContractorID: string, homeContractorID: string): any[] {
      // Since a proxy contractor is a proxy for all contractors, we don't need to restrict proxy contractors
      var proxyCoordinatorsInContractor = this.coordinatorsInContractor(homeContractorID);
      if (!proxyCoordinatorsInContractor?.length) return [];

      let proxyCoordinatorsForContractor = [] as any[];
      proxyCoordinatorsInContractor.forEach(coordinator => {
        if (!!coordinator.includesAllContractors && coordinator.includesAllContractors == true) {
          proxyCoordinatorsForContractor.push(coordinator);
          return;
        }
        if (!coordinator.contractorIDJson) return;

        let coordinatorVisibleContractorIDs = JSON.parse(coordinator.contractorIDJson) as string[];
        if (!coordinatorVisibleContractorIDs?.length) return;
        if (!coordinatorVisibleContractorIDs.includes(assignedContractorID)) return;

        proxyCoordinatorsForContractor.push(coordinator);
      });

      return proxyCoordinatorsForContractor;
    },

    verifySelectedDefaults() {
      let existingContractor = this.allContractors.find(
        x => x.id == this.environment.defaultWorkOrderAssignedContractorID
      );
      if (!existingContractor) this.environment.defaultWorkOrderAssignedContractorID = null;

      let existingCoordinator = this.selectableCoordinators.find(
        x => (x as PersonWithName)?.id == this.environment.defaultWorkOrderAssignedCoordinatorID
      );
      if (!existingCoordinator) this.environment.defaultWorkOrderAssignedCoordinatorID = null;

      let existingGeneralForeman = this.selectableGeneralForemen.find(
        x => (x as PersonWithName)?.id == this.environment.defaultWorkOrderAssignedGeneralForemanID
      );
      if (!existingGeneralForeman) this.environment.defaultWorkOrderAssignedGeneralForemanID = null;

      let existingForeman = this.selectableForemen.find(
        x => (x as PersonWithName)?.id == this.environment.defaultWorkOrderAssignedForemanID
      );
      if (!existingForeman) this.environment.defaultWorkOrderAssignedForemanID = null;

      let existingFromYard = this.allYards.find(
        x => x.id == this.environment.defaultCountSheetFromYardID
      );
      if (!existingFromYard) this.environment.defaultCountSheetFromYardID = null;
      let existingToYard = this.allYards.find(
        x => x.id == this.environment.defaultCountSheetToYardID
      );
      if (!existingToYard) this.environment.defaultCountSheetToYardID = null;
    }
  },

  mounted: function() {
    // The following is used to compensate for the apparent bug in vuetify that is preventing the underline from appearing
    // on the initial tab that is in focus within the view. This kicks it in the butt to cause it to render appropriately.
    // Without it the line under the initial tab that has focus will NOT show up until you resize the screen which then
    // causes it to re-render and show up.
    const initial = (this.$refs.tab as any).$el.offsetWidth;
    const interval = setInterval(() => {
      if (this.$refs.tab) {
        if ((this.$refs.tab as any).$el.offsetWidth !== initial) {
          clearInterval(interval);
          (this.$refs.tabs as any).callSlider();
        }
      }
    }, 100);
  },

  created: async function() {
    this.notifyNewBreadcrumb({
      text: this.$t("configuration.title"),
      to: "/configuration",
      resetHistory: true
    });

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: this.$t("configuration.title"),
      parentalContext: null,
      selectedTab: this.firstTabKey
    });

    this.processing = true;
    try {
      await this.loadDefaultsReferenceData();

      let environment = this.$store.state.curEnvironment;
      if (!!environment) {
        this.environment = {
          ...environment,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        };
      }
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

