var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "timesheetsummary" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { loading: _vm.processing } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs",
            {
              ref: "tabs",
              staticClass: "mt-2",
              attrs: { "icons-and-text": "" },
              model: {
                value: _vm.active_tab,
                callback: function($$v) {
                  _vm.active_tab = $$v
                },
                expression: "active_tab"
              }
            },
            [
              _c(
                "v-tab",
                {
                  key: _vm.generalTab.key,
                  ref: "tab",
                  attrs: { href: "#tab-" + _vm.generalTab.key },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.generalTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.generalTab.tabname) + "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-calculator-simple")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.generalTab.key,
                  attrs: { value: "tab-" + _vm.generalTab.key }
                },
                [
                  _c("fd-estimate-general-settings-form", {
                    attrs: {
                      "parent-context": "estimatesetup",
                      collarPartID: _vm.estimateSettings.collarPartID,
                      mudsillPartID: _vm.estimateSettings.mudsillPartID,
                      screwJackPartID: _vm.estimateSettings.screwJackPartID,
                      ladderGatePartID: _vm.estimateSettings.ladderGatePartID,
                      clampPartID: _vm.estimateSettings.clampPartID,
                      crazyLegPartID: _vm.estimateSettings.crazyLegPartID,
                      davitArmPartID: _vm.estimateSettings.davitArmPartID,
                      standard3_0mPartID:
                        _vm.estimateSettings.standard3_0mPartID,
                      standard2_5mPartID:
                        _vm.estimateSettings.standard2_5mPartID,
                      standard2_0mPartID:
                        _vm.estimateSettings.standard2_0mPartID,
                      standard1_5mPartID:
                        _vm.estimateSettings.standard1_5mPartID,
                      standard1_0mPartID:
                        _vm.estimateSettings.standard1_0mPartID,
                      standard0_5mPartID:
                        _vm.estimateSettings.standard0_5mPartID,
                      starterBracketPartID:
                        _vm.estimateSettings.starterBracketPartID,
                      ladder10ftPartID: _vm.estimateSettings.ladder10ftPartID,
                      ladder06ftPartID: _vm.estimateSettings.ladder06ftPartID,
                      ladder05ftPartID: _vm.estimateSettings.ladder05ftPartID,
                      ladder03ftPartID: _vm.estimateSettings.ladder03ftPartID,
                      yoyo100ftPartID: _vm.estimateSettings.yoyo100ftPartID,
                      yoyo050ftPartID: _vm.estimateSettings.yoyo050ftPartID,
                      yoyo030ftPartID: _vm.estimateSettings.yoyo030ftPartID,
                      yoyo020ftPartID: _vm.estimateSettings.yoyo020ftPartID,
                      steelTube13ftPartID:
                        _vm.estimateSettings.steelTube13ftPartID,
                      steelTube10ftPartID:
                        _vm.estimateSettings.steelTube10ftPartID,
                      steelTube08ftPartID:
                        _vm.estimateSettings.steelTube08ftPartID,
                      steelTube06ftPartID:
                        _vm.estimateSettings.steelTube06ftPartID,
                      steelTube04ftPartID:
                        _vm.estimateSettings.steelTube04ftPartID
                    },
                    on: {
                      "update:collarPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "collarPartID",
                          $event
                        )
                      },
                      "update:collar-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "collarPartID",
                          $event
                        )
                      },
                      "update:mudsillPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "mudsillPartID",
                          $event
                        )
                      },
                      "update:mudsill-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "mudsillPartID",
                          $event
                        )
                      },
                      "update:screwJackPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "screwJackPartID",
                          $event
                        )
                      },
                      "update:screw-jack-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "screwJackPartID",
                          $event
                        )
                      },
                      "update:ladderGatePartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "ladderGatePartID",
                          $event
                        )
                      },
                      "update:ladder-gate-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "ladderGatePartID",
                          $event
                        )
                      },
                      "update:clampPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "clampPartID",
                          $event
                        )
                      },
                      "update:clamp-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "clampPartID",
                          $event
                        )
                      },
                      "update:crazyLegPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "crazyLegPartID",
                          $event
                        )
                      },
                      "update:crazy-leg-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "crazyLegPartID",
                          $event
                        )
                      },
                      "update:davitArmPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "davitArmPartID",
                          $event
                        )
                      },
                      "update:davit-arm-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "davitArmPartID",
                          $event
                        )
                      },
                      "update:standard3_0mPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard3_0mPartID",
                          $event
                        )
                      },
                      "update:standard3_0m-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard3_0mPartID",
                          $event
                        )
                      },
                      "update:standard2_5mPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard2_5mPartID",
                          $event
                        )
                      },
                      "update:standard2_5m-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard2_5mPartID",
                          $event
                        )
                      },
                      "update:standard2_0mPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard2_0mPartID",
                          $event
                        )
                      },
                      "update:standard2_0m-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard2_0mPartID",
                          $event
                        )
                      },
                      "update:standard1_5mPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard1_5mPartID",
                          $event
                        )
                      },
                      "update:standard1_5m-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard1_5mPartID",
                          $event
                        )
                      },
                      "update:standard1_0mPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard1_0mPartID",
                          $event
                        )
                      },
                      "update:standard1_0m-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard1_0mPartID",
                          $event
                        )
                      },
                      "update:standard0_5mPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard0_5mPartID",
                          $event
                        )
                      },
                      "update:standard0_5m-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "standard0_5mPartID",
                          $event
                        )
                      },
                      "update:starterBracketPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "starterBracketPartID",
                          $event
                        )
                      },
                      "update:starter-bracket-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "starterBracketPartID",
                          $event
                        )
                      },
                      "update:ladder10ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "ladder10ftPartID",
                          $event
                        )
                      },
                      "update:ladder10ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "ladder10ftPartID",
                          $event
                        )
                      },
                      "update:ladder06ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "ladder06ftPartID",
                          $event
                        )
                      },
                      "update:ladder06ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "ladder06ftPartID",
                          $event
                        )
                      },
                      "update:ladder05ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "ladder05ftPartID",
                          $event
                        )
                      },
                      "update:ladder05ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "ladder05ftPartID",
                          $event
                        )
                      },
                      "update:ladder03ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "ladder03ftPartID",
                          $event
                        )
                      },
                      "update:ladder03ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "ladder03ftPartID",
                          $event
                        )
                      },
                      "update:yoyo100ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "yoyo100ftPartID",
                          $event
                        )
                      },
                      "update:yoyo100ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "yoyo100ftPartID",
                          $event
                        )
                      },
                      "update:yoyo050ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "yoyo050ftPartID",
                          $event
                        )
                      },
                      "update:yoyo050ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "yoyo050ftPartID",
                          $event
                        )
                      },
                      "update:yoyo030ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "yoyo030ftPartID",
                          $event
                        )
                      },
                      "update:yoyo030ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "yoyo030ftPartID",
                          $event
                        )
                      },
                      "update:yoyo020ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "yoyo020ftPartID",
                          $event
                        )
                      },
                      "update:yoyo020ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "yoyo020ftPartID",
                          $event
                        )
                      },
                      "update:steelTube13ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "steelTube13ftPartID",
                          $event
                        )
                      },
                      "update:steel-tube13ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "steelTube13ftPartID",
                          $event
                        )
                      },
                      "update:steelTube10ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "steelTube10ftPartID",
                          $event
                        )
                      },
                      "update:steel-tube10ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "steelTube10ftPartID",
                          $event
                        )
                      },
                      "update:steelTube08ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "steelTube08ftPartID",
                          $event
                        )
                      },
                      "update:steel-tube08ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "steelTube08ftPartID",
                          $event
                        )
                      },
                      "update:steelTube06ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "steelTube06ftPartID",
                          $event
                        )
                      },
                      "update:steel-tube06ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "steelTube06ftPartID",
                          $event
                        )
                      },
                      "update:steelTube04ftPartID": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "steelTube04ftPartID",
                          $event
                        )
                      },
                      "update:steel-tube04ft-part-i-d": function($event) {
                        return _vm.$set(
                          _vm.estimateSettings,
                          "steelTube04ftPartID",
                          $event
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    { ref: "generalform", on: { submit: _vm.onSubmit } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mr-0",
                                      attrs: { type: _vm.inlineMessage.type }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.saving,
                                disabled:
                                  _vm.processing ||
                                  !_vm.currentUserCanConfigureSettings
                              },
                              on: { click: _vm.save }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.save")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.scaffoldTypesTab.key,
                  attrs: { href: "#tab-" + _vm.scaffoldTypesTab.key },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.scaffoldTypesTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.scaffoldTypesTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-layer-group")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.scaffoldTypesTab.key,
                  attrs: { value: "tab-" + _vm.scaffoldTypesTab.key }
                },
                [
                  _c("fd-estimate-setup-scaffold-type-modifier-form", {
                    attrs: { "parent-context": "estimatesetup" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.mobilizationDistanceTab.key,
                  attrs: { href: "#tab-" + _vm.mobilizationDistanceTab.key },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.mobilizationDistanceTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.mobilizationDistanceTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-truck")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.mobilizationDistanceTab.key,
                  attrs: { value: "tab-" + _vm.mobilizationDistanceTab.key }
                },
                [
                  _c("fd-estimate-setup-scaffold-distance-modifier-form", {
                    attrs: { "parent-context": "estimatesetup" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.scaffoldElevationTab.key,
                  attrs: { href: "#tab-" + _vm.scaffoldElevationTab.key },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.scaffoldElevationTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.scaffoldElevationTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-ruler-combined")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.scaffoldElevationTab.key,
                  attrs: { value: "tab-" + _vm.scaffoldElevationTab.key }
                },
                [
                  _c("fd-estimate-setup-scaffold-elevation-modifier-form", {
                    attrs: { "parent-context": "estimatesetup" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.scaffoldHeightTab.key,
                  attrs: { href: "#tab-" + _vm.scaffoldHeightTab.key },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.scaffoldHeightTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.scaffoldHeightTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-ruler-combined")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.scaffoldHeightTab.key,
                  attrs: { value: "tab-" + _vm.scaffoldHeightTab.key }
                },
                [
                  _c("fd-estimate-setup-scaffold-height-modifier-form", {
                    attrs: { "parent-context": "estimatesetup" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.buildDismantleRatioTab.key,
                  attrs: { href: "#tab-" + _vm.buildDismantleRatioTab.key },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.buildDismantleRatioTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.buildDismantleRatioTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-percent")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.buildDismantleRatioTab.key,
                  attrs: { value: "tab-" + _vm.buildDismantleRatioTab.key }
                },
                [
                  _c("fd-estimate-setup-build-dismantle-ratio-form", {
                    attrs: { "parent-context": "estimatesetup" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.congestionFactorTab.key,
                  attrs: { href: "#tab-" + _vm.congestionFactorTab.key },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.congestionFactorTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.congestionFactorTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-send-back")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.congestionFactorTab.key,
                  attrs: { value: "tab-" + _vm.congestionFactorTab.key }
                },
                [
                  _c("fd-estimate-setup-scaffold-congestion-factor-form", {
                    attrs: { "parent-context": "estimatesetup" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.scaffoldBaysTab.key,
                  attrs: { href: "#tab-" + _vm.scaffoldBaysTab.key },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.scaffoldBaysTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.scaffoldBaysTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-shelves-empty")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.scaffoldBaysTab.key,
                  attrs: { value: "tab-" + _vm.scaffoldBaysTab.key }
                },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      attrs: { accordion: "" },
                      model: {
                        value: _vm.bayspanel,
                        callback: function($$v) {
                          _vm.bayspanel = $$v
                        },
                        expression: "bayspanel"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        {
                          staticClass: "secondary-section-style",
                          attrs: { disabled: _vm.processing }
                        },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$t(
                                    "estimate-setup.tabs.scaffold-bays.bay-lengths.title"
                                  )
                                  .toUpperCase()
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("fd-estimate-setup-scaffold-bay-length-form", {
                                attrs: { "parent-context": "estimatesetup" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        {
                          staticClass: "secondary-section-style",
                          attrs: { disabled: _vm.processing }
                        },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$t(
                                    "estimate-setup.tabs.scaffold-bays.bay-widths.title"
                                  )
                                  .toUpperCase()
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("fd-estimate-setup-scaffold-bay-width-form", {
                                attrs: { "parent-context": "estimatesetup" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        {
                          staticClass: "secondary-section-style",
                          attrs: { disabled: _vm.processing }
                        },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$t(
                                    "estimate-setup.tabs.scaffold-bays.bay-heights.title"
                                  )
                                  .toUpperCase()
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("fd-estimate-setup-scaffold-bay-height-form", {
                                attrs: { "parent-context": "estimatesetup" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.internalTab.key,
                  attrs: { href: "#tab-" + _vm.internalTab.key },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.internalTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.internalTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("far fa-square-person-confined")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.internalTab.key,
                  attrs: { value: "tab-" + _vm.internalTab.key }
                },
                [
                  _c("fd-estimate-setup-internal-modifier-form", {
                    attrs: { "parent-context": "estimatesetup" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.hoardingTab.key,
                  attrs: { href: "#tab-" + _vm.hoardingTab.key },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.hoardingTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.hoardingTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-goal-net")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.hoardingTab.key,
                  attrs: { value: "tab-" + _vm.hoardingTab.key }
                },
                [
                  _c("fd-estimate-setup-hoarding-modifier-form", {
                    attrs: { "parent-context": "estimatesetup" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }