var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-0 fd-high-contrast",
      attrs: { fluid: "", "data-cy": "countsheetyardselectionform" }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "align-center fd-subtle-callout",
              attrs: { cols: "12" }
            },
            [
              _c(
                "v-subheader",
                [
                  _c("span", { staticStyle: { "font-size": "1.1rem" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$vuetify.breakpoint.smAndUp
                          ? _vm.$t("countsheet.approval.assign-source-yard")
                          : _vm.$t(
                              "countsheet.approval.assign-source-yard-short"
                            )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-divider", {
                    staticClass: "mx-4 fd-regular-contrast",
                    attrs: { inset: "", vertical: "" }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "fd-regular-contrast",
                      staticStyle: { "font-size": "1em" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "countsheet.approval.assign-source-yard-explanation"
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-select", {
                staticClass: "px-4",
                attrs: {
                  outlined: "",
                  "data-cy": "sourceyard",
                  label: _vm.$t("countsheet.approval.yard-label"),
                  value: _vm.sourceYardID,
                  items: _vm.visibleYards,
                  "item-text": "name",
                  "item-value": "id",
                  rules: _vm.selectSourceYard ? [_vm.rules.required] : [],
                  disabled: _vm.processing || !_vm.selectSourceYard,
                  "hide-details": !_vm.selectSourceYard
                },
                on: {
                  input: function(v) {
                    return _vm.$emit("update:sourceYardID", v)
                  }
                }
              }),
              _vm._v(" "),
              !_vm.selectSourceYard
                ? _c("fd-alert", {
                    staticClass: "mx-4",
                    staticStyle: { "margin-bottom": "12px !important" },
                    attrs: {
                      label: _vm.$t(
                        "countsheet.approval.assign-source-yard-disabled-explanation"
                      ),
                      type: "info",
                      dense: ""
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-0 pl-0", attrs: { align: "center" } },
        [_c("v-divider", { staticClass: "mx-0 mt-0 px-0" })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "align-center", attrs: { cols: "12" } },
            [
              _c(
                "v-subheader",
                { staticClass: "mt-3" },
                [
                  _c("span", { staticStyle: { "font-size": "1.1rem" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$vuetify.breakpoint.smAndUp
                          ? _vm.$t(
                              "countsheet.approval.assign-destination-yard"
                            )
                          : _vm.$t(
                              "countsheet.approval.assign-destination-yard-short"
                            )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-divider", {
                    staticClass: "mx-4 fd-regular-contrast",
                    attrs: { inset: "", vertical: "" }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "fd-regular-contrast",
                      staticStyle: { "font-size": "1em" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "countsheet.approval.assign-destination-yard-explanation"
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-select", {
                staticClass: "px-4",
                attrs: {
                  outlined: "",
                  "data-cy": "destinationyard",
                  label: _vm.$t("countsheet.approval.yard-label"),
                  value: _vm.destinationYardID,
                  items: _vm.visibleYards,
                  "item-text": "name",
                  "item-value": "id",
                  rules: _vm.selectDestinationYard ? [_vm.rules.required] : [],
                  disabled: _vm.processing || !_vm.selectDestinationYard,
                  "hide-details": !_vm.selectDestinationYard
                },
                on: {
                  input: function(v) {
                    return _vm.$emit("update:destinationYardID", v)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-row", { attrs: { "no-gutters": "" } }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.name) +
                                      "\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.returnedPartSources.length > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "fd-item-detail-light pb-2"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "countsheet.approval.yard-part-source-detail",
                                                [
                                                  _vm.numberOfPartsSourcedByYardID(
                                                    item.id
                                                  )
                                                ]
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              !_vm.selectDestinationYard
                ? _c("fd-alert", {
                    staticClass: "mx-4",
                    attrs: {
                      label: _vm.$t(
                        "countsheet.approval.assign-destination-yard-disabled-explanation"
                      ),
                      type: "info",
                      dense: ""
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }