import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import fileHandling, {
  componentsFromFileName,
  confirmUniqueName,
  canOpenFileInNewWindow,
  isFilePreviewable,
  FileData,
  isFilePhoto
} from "@fd/lib/vue/mixins/fileHandling";
import {
  LegacyArea,
  LegacySubArea,
  LegacyDisciplineWithEmployeeIDs,
  LegacyContractorWithChildIDs,
  LegacyEmployee,
  LegacyWorkOrderWithRequestDetails,
  workPackageService,
  Tag,
  LegacyScaffoldSearchResult,
  LegacyContractor,
  contractorService,
  messageService,
  noteService,
  workOrderService,
  ScaffoldRequestTypes,
  ExternalLink,
  externalLinkService,
  ScaffoldRequestTypeWithLegacyID,
  ScaffoldRequestSubTypeWithLegacyID,
  scaffoldRequestTypeHelper,
  scaffoldRequestSubTypeHelper,
  scaffoldDesignService,
  ScaffoldDesignWithDetails,
  ProjectLocation,
  projectLocationService
} from "../services";
import {
  scaffoldRequestService,
  legacyReferenceDataService,
  legacyScaffoldService
} from "../services";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import scaffoldRequest, {
  ParseScaffoldRequestWithExtraDetails,
  WorkPackageWithNameCode
} from "../dataMixins/scaffoldRequest";
import {
  openActiveWorkForScaffoldDialog,
  WorkForScaffoldDetails
} from "./components/ActiveWorkForScaffoldDialog.vue";
import messaging, {
  ParseMessageWithSenderDetails,
  SortMessagesArray
} from "../dataMixins/messaging";
import notes, { ParseNoteWithSenderDetails, SortNotesArray } from "../dataMixins/notes";
import { WorkForScaffoldDetailsFromScaffoldRequest } from "../dataMixins/scaffoldRequestList";
import {
  ParseWorkOrderWithLegacyDetails,
  WorkForScaffoldDetailsFromWorkOrder
} from "../dataMixins/workOrderList";
import { openExternalLinkDetails } from "./components/ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import { Attachment } from "../dataMixins/attachment";
import * as DateUtil from "@fd/lib/client-util/datetime";

type Keyword = Tag;

type FormattedScaffoldDesign = ScaffoldDesignWithDetails & {
  formattedScaffoldNumber: string;
};

export default FDVue.extend({
  name: "fd-legacy-scaffold-request-existing",

  mixins: [serviceErrorHandling, tabbedView, fileHandling, scaffoldRequest, messaging, notes],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue"),
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "fd-duration-picker": () => import("@fd/lib/vue/components/DurationPicker.vue"),
    "fd-work-package-selector": () => import("./components/WorkPackageSelectionDialog.vue"),
    "fd-subheader": () => import("@fd/lib/vue/components/layout/Subheader.vue")
  },

  data: function() {
    return {
      // *** GLOBAL ***
      detailsReadonly: false,
      slidein: false,
      screenLoaded: false,

      // Form data errors
      detailsTabError: false,
      notesTabError: false,
      optionsTabError: false,
      photosTabError: false,
      messagingTabError: false,

      firstTabKey: `1`,
      detailsTab: {
        tabname: "Basic",
        key: `1`,
        visible: true
      } as Tab,
      photosTab: {
        tabname: "Photos",
        key: `2`,
        visible: false
      } as Tab,
      notesTab: {
        tabname: "Notes",
        key: `3`,
        visible: true
      } as Tab,
      scopeTab: {
        tabname: this.$t("scaffold-requests.existing-scaffold-request.tabs.scope-change"),
        key: `4`,
        visible: false
      } as Tab,
      optionsTab: {
        tabname: "Options",
        key: `5`,
        visible: false
      } as Tab,
      messagingTab: {
        tabname: "Messaging",
        key: `6`,
        visible: false
      } as Tab,
      attachmentsTab: {
        tabname: "Attachments",
        key: "9",
        visible: false
      } as Tab,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      submitting: false,
      cancelling: false,
      approving: false,
      declining: false,

      /*** DATA ***/
      // Reference
      requestTypes: [] as ScaffoldRequestTypeWithLegacyID[],
      allRequestSubTypes: [] as ScaffoldRequestSubTypeWithLegacyID[],
      requestingContractors: [] as LegacyContractorWithChildIDs[],
      allRequestors: [] as LegacyEmployee[],
      allDisciplines: [] as LegacyDisciplineWithEmployeeIDs[],
      allAreas: [] as LegacyArea[],
      allSubAreas: [] as LegacySubArea[],
      allVisibleAreas: [] as ProjectLocation[],
      allVisibleSubAreas: [] as ProjectLocation[],
      // Currently there are only two access types supported: None = 0, Ladder = 1.  Other access types exist but are not currently supported by the estimation logic
      accessTypes: [
        {
          id: 0,
          name: this.$t("scaffold-requests.existing-scaffold-request.walkdown.access-type-none")
        },
        {
          id: 1,
          name: this.$t("scaffold-requests.existing-scaffold-request.walkdown.access-type-ladder")
        }
      ],

      /*** TAG NUMBER ***/
      availableScaffolds: [] as LegacyScaffoldSearchResult[],
      selectedScaffold: null as LegacyScaffoldSearchResult | null | undefined,
      // Determines whether the area/subarea/etc. have been set via scaffold selection
      dataSetByScaffold: false,

      /*** Design NUMBER ***/
      availableDesigns: [] as FormattedScaffoldDesign[],
      selectedDesign: null as FormattedScaffoldDesign | null | undefined,
      // Determines whether the area/subarea/etc. have been set via ScaffoldDesign selection
      dataSetByDesign: false,

      /*** KEYWORDS ***/
      selectedKeywords: [] as Keyword[],

      /*** IWPs ***/
      availableIWPs: [] as WorkPackageWithNameCode[],
      selectedIWPs: [] as WorkPackageWithNameCode[],

      // Main entity

      // *** ATTACHMENTS ***
      touchedFileName: "",
      showPhotoTabAttachmentAlert: false,
      showAttachmentTabPhotoAlert: false,
      tablesearchfiles: "",
      allFiles: [] as FileData[],
      externalLinks: [] as ExternalLink[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    // *** GLOBAL ***
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [
        this.notesTab,
        this.scopeTab,
        this.optionsTab,
        this.photosTab,
        this.messagingTab,
        this.attachmentsTab
      ] as Tab[];
    },

    currentUserCanAddMessage(): boolean {
      return true;
    },
    currentUserCanAddNote(): boolean {
      return true;
    },

    requestWillBeArchived(): boolean {
      if (!this.legacyRequest.archivedDate) return false;
      return this.legacyRequest.archivedDate.getTime() > new Date().getTime();
    },

    requestIsForScaffold(): boolean {
      let requestTypeID = this.requestTypes.find(
        x => x.legacyID == this.legacyRequest.requestTypeID
      )?.value;
      return (
        requestTypeID == ScaffoldRequestTypes.Erect ||
        requestTypeID == ScaffoldRequestTypes.Dismantle ||
        requestTypeID == ScaffoldRequestTypes.Modify
      );
    },
    requestIsForMaintenance(): boolean {
      return (
        this.requestTypes.find(x => x.legacyID == this.legacyRequest.requestTypeID)?.value ==
        ScaffoldRequestTypes.Maintenance
      );
    },
    requestIsForPaint(): boolean {
      return (
        this.requestTypes.find(x => x.legacyID == this.legacyRequest.requestTypeID)?.value ==
        ScaffoldRequestTypes.Paint
      );
    },
    requestIsForInsulation(): boolean {
      return (
        this.requestTypes.find(x => x.legacyID == this.legacyRequest.requestTypeID)?.value ==
        ScaffoldRequestTypes.Insulation
      );
    },

    requestCanBeSaved(): boolean {
      // Request can be saved if:
      // Current user has permission to submit requests, and the request status is either New, Draft or Declined
      // If the current status is "Walkdown" and the current user is assigned to perform said walkdown
      if (
        this.currentUserCanSubmitScaffoldRequests &&
        !!this.legacyRequest.requestStatusID &&
        (this.legacyRequest.requestStatusID <= 1 || this.legacyRequest.requestStatusID == 4)
      )
        return true;

      return false;
    },

    requestCanBeApproved(): boolean {
      return (
        this.legacyRequest.requestStatusID == 2 &&
        this.legacyRequest.currentUserPermissions.canUpdateApproval
      );
    },

    requestCanBeDeclined(): boolean {
      return (
        this.legacyRequest.requestStatusID == 2 &&
        this.legacyRequest.currentUserPermissions.canUpdateApproval
      );
    },

    // *** DETAILS ***
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    allKeywords(): Keyword[] {
      return this.$store.state.tags.fullList as Keyword[];
    },
    requestSubTypes(): ScaffoldRequestSubTypeWithLegacyID[] {
      let legacyRequestTypeID = this.legacyRequest.requestTypeID;
      if (!legacyRequestTypeID) return [];

      var requestSubTypes = this.allRequestSubTypes.filter(x => {
        return x.parentRequestTypeLegacyID == legacyRequestTypeID;
      });

      if (
        !this.legacyRequest.requestSubTypeID &&
        !this.detailsReadonly &&
        requestSubTypes.length == 1
      ) {
        this.legacyRequest.requestSubTypeID = requestSubTypes[0].legacyID;
      }

      return requestSubTypes;
    },
    areas(): LegacyArea[] {
      let contractorID = this.legacyRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      var areas = this.allAreas.filter(x => {
        return !!selectedContractor?.areaIDs?.length && selectedContractor!.areaIDs.includes(x.id!);
      });
      if (!this.legacyRequest.areaID && !this.detailsReadonly && areas.length == 1) {
        this.$nextTick(() => {
          this.legacyRequest.areaID = areas[0].id;
        });
      }
      return areas;
    },
    subAreas(): LegacySubArea[] {
      let areaID = this.legacyRequest.areaID;
      if (!areaID) return [];

      let subAreas = this.allSubAreas.filter(x => {
        return x.areaID == areaID;
      });
      if (!this.legacyRequest.subAreaID && !this.detailsReadonly && subAreas.length == 1) {
        this.$nextTick(() => {
          this.legacyRequest.subAreaID = subAreas[0].id;
        });
      }
      return subAreas;
    },
    disciplines(): LegacyDisciplineWithEmployeeIDs[] {
      let contractorID = this.legacyRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      var disciplines = this.allDisciplines.filter(x => {
        return (
          !!selectedContractor?.disciplineIDs?.length &&
          selectedContractor!.disciplineIDs.includes(x.id!)
        );
      });
      if (!this.legacyRequest.disciplineID && !this.detailsReadonly && disciplines.length == 1) {
        this.$nextTick(() => {
          this.legacyRequest.disciplineID = disciplines[0].id;
        });
      }
      return disciplines;
    },
    selectedRequestingContractorName(): string | undefined {
      return this.requestingContractors.find(x => x.id == this.legacyRequest.requestingContractorID)
        ?.name;
    },
    requestors(): LegacyEmployee[] {
      let contractorID = this.legacyRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      let disciplineID = this.legacyRequest.disciplineID;
      if (!disciplineID) return [];

      let selectedDiscipline = this.disciplines.find(x => x.id == disciplineID);
      if (!selectedDiscipline) return [];

      var requestors = this.allRequestors.filter(x => {
        return (
          !!selectedContractor?.employeeIDs?.length &&
          selectedContractor!.employeeIDs.includes(x.id!) &&
          !!selectedDiscipline?.employeeIDs?.length &&
          selectedDiscipline!.employeeIDs.includes(x.id!)
        );
      });
      if (!this.legacyRequest.requestorID && !this.detailsReadonly && requestors.length == 1) {
        this.$nextTick(() => {
          this.legacyRequest.requestorID = requestors[0].id;
        });
      }
      return requestors;
    },
    missingRequiredClientWorkOrderData(): boolean {
      return (
        !!this.legacyRequest.isClientWorkOrder &&
        !this.legacyRequest.clientWorkOrderReferenceNumber?.length
      );
    },
    missingRequiredChangeOrderData(): boolean {
      return (
        !!this.legacyRequest.isChangeOrder && !this.legacyRequest.changeOrderReferenceNumber?.length
      );
    },
    missingRequiredReworkData(): boolean {
      return !!this.legacyRequest.isRework && !this.legacyRequest.reworkReferenceNumber?.length;
    },
    scopeTabError(): boolean {
      return (
        this.missingRequiredClientWorkOrderData ||
        this.missingRequiredChangeOrderData ||
        this.missingRequiredReworkData
      );
    },
    nonPhotoAttachments(): Attachment[] {
      let attachments = [] as Attachment[];

      this.allFiles.forEach(file => {
        attachments.push({
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments.filter(x => !x.isPhoto);
    },
    photoFiles(): FileData[] {
      return this.allFiles.filter(x => x.isPhoto);
    }
  },

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({}),

    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/scaffoldrequests");
    },
    validateScopeForm(): boolean {
      let scopeForm = this.$refs.scopechangeform as HTMLFormElement;
      if (!scopeForm) {
        return !this.scopeTabError;
      }
      return scopeForm.validate() && !this.scopeTabError;
    },

    validate(): boolean {
      console.log(`Validating request details forms.`);
      this.detailsTabError = !((this.$refs.detailsform as HTMLFormElement)?.validate() ?? false);
      this.notesTabError = !((this.$refs.notesform as HTMLFormElement)?.validate() ?? true);
      this.optionsTabError = !((this.$refs.optionsform as HTMLFormElement)?.validate() ?? true);
      this.photosTabError = !((this.$refs.filesform as HTMLFormElement)?.validate() ?? true);
      return (
        this.validateScopeForm() &&
        !(this.detailsTabError || this.notesTabError || this.optionsTabError || this.photosTabError)
      );
    },

    async save(closeOnComplete: boolean) {
      this.processing = true;
      this.saving = true;
      try {
        if (!(await this.saveRequestData(true))) {
          this.processing = false;
          this.saving = false;
          return;
        }
        if (closeOnComplete) {
          this.$router.push(this.$store.getters.backBreadcrumb?.to || "/scaffoldrequests");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    formatDate(item: Date | string | undefined | null): string {
      return DateUtil.stripTimeFromLocalizedDateTime(item);
    },

    async openWorkDetailsForScaffoldDialog(): Promise<boolean> {
      this.processing = true;
      var allRequests = (
        await scaffoldRequestService.getSubmittedRequestsForScaffoldTag(
          `${this.legacyRequest.applyToScaffoldID}`
        )
      ).map(x => ParseScaffoldRequestWithExtraDetails(x));
      var convertedRequests = allRequests.map(x => WorkForScaffoldDetailsFromScaffoldRequest(x));

      var allWorkOrders = (
        await workOrderService.getActiveWorkOrdersForScaffoldTag(
          null,
          `${this.legacyRequest.applyToScaffoldID}`
        )
      ).map(x => ParseWorkOrderWithLegacyDetails(x));
      let convertedWorkOrders = allWorkOrders.map(x => WorkForScaffoldDetailsFromWorkOrder(x));

      var allScaffoldWork: WorkForScaffoldDetails[] = convertedRequests.concat(convertedWorkOrders);
      let dismantleScaffoldWork = allScaffoldWork.filter(
        x => x.requestType == ScaffoldRequestTypes.Dismantle
      );
      let otherDismantleIDs = dismantleScaffoldWork.filter(
        x => x.scaffoldRequestId != this.legacyRequest.scaffoldRequestID
      );

      let result = true;
      if (otherDismantleIDs.length > 0) {
        console.log("WO's Scaffold Has Other WOs.  Show Popup!");
        result = await openActiveWorkForScaffoldDialog(
          this.legacyRequest.applyToScaffoldID,
          allScaffoldWork,
          this.legacyRequest.scaffoldRequestID,
          false,
          true,
          true
        );
      }
      this.processing = false;
      return result;
    },

    async saveRequestData(isDraft: boolean): Promise<boolean> {
      var requestSaved = await this.saveRequestDetails(isDraft);
      if (requestSaved) {
        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.save-success", [
            this.legacyRequest.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
      return requestSaved;
    },

    async saveRequestDetails(isDraft: boolean): Promise<boolean> {
      if (this.detailsReadonly) return false;
      console.log(`Saving request details...`);

      await this.updateIsDraft(isDraft);
      if (!this.validate()) {
        var message = this.$t("scaffold-requests.existing-scaffold-request.error-message");
        if (this.detailsTabError)
          message += "\n\t- " + this.$t("scaffold-requests.existing-scaffold-request.tabs.details");
        if (this.notesTabError)
          message += "\n\t- " + this.$t("scaffold-requests.existing-scaffold-request.tabs.notes");
        if (this.scopeTabError)
          message +=
            "\n\t- " + this.$t("scaffold-requests.existing-scaffold-request.tabs.scope-change");
        if (this.optionsTabError)
          message += "\n\t- " + this.$t("scaffold-requests.existing-scaffold-request.tabs.options");
        if (this.photosTabError)
          message += "\n\t- " + this.$t("scaffold-requests.existing-scaffold-request.tabs.files");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return false;
      }

      // this isn't ever updated on this screen and we don't want to send it in.
      this.legacyRequest.startDate = undefined;

      // if this exists but is an "invalid" value, it shouldn't actually exist
      if (
        !!this.legacyRequest.requiredUntilDate &&
        this.legacyRequest.requiredUntilDate.getTime() ==
          new Date("Mon Jan 01 1900 00:00:00").getTime()
      ) {
        this.legacyRequest.requiredUntilDate = undefined;
      }

      // Save web request
      // The following are numeric values entered as strings due to being text field entry.
      if (this.legacyRequest.applyToScaffoldID)
        this.legacyRequest.applyToScaffoldID = +this.legacyRequest.applyToScaffoldID;

      this.legacyRequest.tagIDs =
        this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;

      if (!this.legacyRequest.archived) {
        this.legacyRequest.archivedDate = null;
      } else if (this.legacyRequest.archived && !this.legacyRequest.archivedDate) {
        this.legacyRequest.archivedDate = new Date(new Date().toUTCString());
      }

      // Get the names of the related data to pass in.  This is because it's SP1 data and we may not have it in the 2.0 database yet.  If we don't have it, we need to insert it
      this.legacyRequest.requestTypeName = this.requestTypes.find(
        x => x.legacyID == this.legacyRequest.requestTypeID
      )?.name;

      this.legacyRequest.requestingContractorName = this.selectedRequestingContractorName;
      this.legacyRequest.disciplineName = this.disciplines.find(
        x => x.id == this.legacyRequest.disciplineID
      )?.name;

      let selectedRequestor = this.requestors.find(x => x.id == this.legacyRequest.requestorID);
      this.legacyRequest.requestingEmployeeFirstName = selectedRequestor?.firstName;
      this.legacyRequest.requestingEmployeeLastName = selectedRequestor?.lastName;

      this.legacyRequest.areaName = this.areas.find(x => x.id == this.legacyRequest.areaID)?.name;
      this.legacyRequest.subAreaName = this.subAreas.find(
        x => x.id == this.legacyRequest.subAreaID
      )?.name;

      // Get the list of selected IWP IDs
      this.legacyRequest.workPackageIDs = this.selectedIWPs.map(iwp => iwp.id!);

      if (!!this.selectedScaffold) {
        this.legacyRequest.applyToScaffoldID = this.selectedScaffold.id;
      } else {
        this.legacyRequest.applyToScaffoldID = undefined;
      }

      await scaffoldRequestService.updateScaffoldRequestDetailsByLegacyWorkOrder(
        this.legacyRequest
      );

      return true;
    },

    /*** REQUEST ACTIONS ***/
    async cancelRequest() {
      this.processing = true;
      this.cancelling = true;
      try {
        if (!(await this.saveRequestData(true))) {
          this.cancelling = false;
          this.processing = false;
          return;
        }
        // get reason
        var title = this.$t("scaffold-request-approvals.cancel-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.cancelling = false;
          this.processing = false;
          return false;
        }

        let cancelled = await scaffoldRequestService.cancelScaffoldRequestByLegacyWorkOrder(
          this.legacyRequest.id!,
          reason
        );

        if (cancelled) {
          var snackbarPayload = {
            text: this.$t("scaffold-requests.existing-scaffold-request.cancel-success", [
              this.legacyRequest.internalRequestNumber
            ]),
            type: "success",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

          this.$router.push(this.$store.getters.backBreadcrumb?.to || "/scaffoldrequests");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.cancelling = false;
        this.processing = false;
      }
    },
    async submitRequest() {
      this.processing = true;
      this.submitting = true;
      try {
        if (!(await this.saveRequestData(false))) {
          this.submitting = false;
          this.processing = false;
          return;
        }

        let submitted = await scaffoldRequestService.submitScaffoldRequestByLegacyWorkOrder(
          this.legacyRequest.id!
        );

        if (submitted) {
          var snackbarPayload = {
            text: this.$t("scaffold-requests.existing-scaffold-request.submit-success", [
              this.legacyRequest.internalRequestNumber
            ]),
            type: "success",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

          this.$router.push(this.$store.getters.backBreadcrumb?.to || "/scaffoldrequests");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },
    async approveRequest() {
      var proceed = await this.openWorkDetailsForScaffoldDialog();
      if (!proceed) {
        return;
      }

      this.processing = true;
      this.approving = true;
      try {
        var allContractors = await contractorService.getAll(false, null, null);
        var scaffoldContractors = allContractors.filter(
          x => !!x.isScaffoldCompany && x.isScaffoldCompany
        );

        var title = this.$t("scaffold-request-approvals.assign-contractor");
        var contractorID = await showItemSelectionDialog(
          title,
          this.$t("scaffold-request-approvals.contractor-label"),
          [this.rules.required],
          scaffoldContractors,
          "name",
          "id"
        );

        // If details is undefined the dialog was cancelled
        if (!contractorID) {
          // Change the value to something else and then back to its current to force a rebind
          this.approving = false;
          this.processing = false;
          return false;
        }

        await scaffoldRequestService.updateScaffoldRequestApproval(
          this.legacyRequest.scaffoldRequestID!,
          true,
          contractorID,
          ""
        );
        await this.loadRequestDetails();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.approve-success", [
            this.legacyRequest.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },
    async declineRequest() {
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("scaffold-request-approvals.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.declining = false;
          this.processing = false;
          return false;
        }

        await scaffoldRequestService.updateScaffoldRequestApproval(
          this.legacyRequest.scaffoldRequestID!,
          false,
          null,
          reason
        );
        await this.loadRequestDetails();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.decline-success", [
            this.legacyRequest.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    },

    /*** LOADING ***/
    // DOES NOT manage processing or error message logic
    async loadRequestDetails() {
      var legacyRequest = await scaffoldRequestService.getByLegacyWorkOrderID(
        +this.$route.params.id
      );
      this.legacyRequest = {
        ...legacyRequest,
        archived: !!legacyRequest.archivedDate
      };
      this.detailsReadonly = !this.legacyRequest.canEditRequestDetails;
    },
    // DOES NOT manage processing or error message logic
    async loadRequestTypes(): Promise<void> {
      this.requestTypes = await scaffoldRequestTypeHelper.getAllRequestTypesWithLegacyID();
    },
    // DOES NOT manage processing or error message logic
    async loadRequestSubTypes(): Promise<void> {
      this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllRequestSubTypesWithLegacyID();
    },
    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      this.allAreas = await legacyReferenceDataService.getVisibleAreas();
    },
    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      this.allSubAreas = await legacyReferenceDataService.getVisibleSubAreas();
    },
    // DOES NOT manage processing or error message logic
    async loadVisibleAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allVisibleAreas = areas;
    },
    // DOES NOT manage processing or error message logic
    async loadVisibleSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allVisibleSubAreas = subAreas;
    },
    // DOES NOT manage processing or error message logic
    async loadRequestingContractors(): Promise<void> {
      this.requestingContractors = (
        await legacyReferenceDataService.getScaffoldRequestingContractors()
      ).sort((a: LegacyContractor, b: LegacyContractor) => {
        let desc: Boolean = false;
        let val1 = a.name!;
        let val2 = b.name!;
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });
    },
    // DOES NOT manage processing or error message logic
    async loadDisciplines(): Promise<void> {
      this.allDisciplines = await legacyReferenceDataService.getAllDisciplines();
    },
    // DOES NOT manage processing or error message logic
    async loadRequestors(): Promise<void> {
      let requestors = await legacyReferenceDataService.getAllRequestors();
      this.allRequestors = requestors.map(x => {
        return {
          ...x,
          name: `${x.firstName} ${x.lastName}`
        };
      });
    },
    async loadScaffolds(searchString: string) {
      if (!searchString?.length) this.availableScaffolds = [];
      else {
        let scaffolds = await legacyScaffoldService.searchAll(searchString, false);
        this.availableScaffolds = scaffolds.map(x => {
          return {
            ...x,
            description: this.getScaffoldDescription(x),
            details: this.getScaffoldDetails(x)
          } as LegacyScaffoldSearchResult;
        });
      }
    },
    async loadScaffoldDesigns() {
      if (!!this.availableDesigns?.length) return;

      let designs = await scaffoldDesignService.getAllReleased();
      this.availableDesigns = designs.map(
        d =>
          ({
            ...d,
            formattedScaffoldNumber: `T-${`00000${d.scaffoldNumber}`.slice(-5)}`
          } as FormattedScaffoldDesign)
      );
    },

    // *** IWPs ***
    async loadWorkPackages(searchString: string) {
      if (!searchString?.length) this.availableIWPs = [];
      else {
        let allIWPs = await workPackageService.searchAll(searchString);
        this.availableIWPs = allIWPs.map(x => {
          return {
            ...x,
            nameCode: `${x.name} | ${x.activityID}`
          };
        });
      }
    },

    /*** NOTES ***/
    async addNewNote() {
      if (!this.newNoteText.length || !this.legacyRequest?.scaffoldRequestID?.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newNote = await noteService.addNewNoteForScaffoldRequest(
          this.newNoteText,
          this.legacyRequest.scaffoldRequestID
        );
        this.inlineMessage.message = "";
        let noteToAdd = ParseNoteWithSenderDetails(newNote);
        noteToAdd.isNew = true;
        this.notes.push(noteToAdd);
        this.notes = SortNotesArray(this.notes);
        this.newNoteText = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    /*** MESSAGES ***/
    async addNewMessage() {
      if (!this.newMessageText.length || !this.legacyRequest?.scaffoldRequestID?.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newMessage = await messageService.addNewMessageForScaffoldRequest(
          this.newMessageText,
          this.legacyRequest.scaffoldRequestID
        );
        this.inlineMessage.message = "";
        this.messages.push(ParseMessageWithSenderDetails(newMessage, this.curUserID));
        this.messages = SortMessagesArray(this.messages);
        this.newMessageText = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.file) {
        await this.deleteFile(item.file);
      }
    },

    // Links
    async loadLinks() {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        var links = await externalLinkService.getByScaffoldRequestID(
          this.legacyRequest.scaffoldRequestID!
        );
        this.externalLinks = links;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        newLink.scaffoldRequestID = this.legacyRequest.scaffoldRequestID;
        await externalLinkService.addItem(newLink);
        this.externalLinks.push(newLink);

        this.showAttachmentTabPhotoAlert = false;
        this.showPhotoTabAttachmentAlert = false;

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.save-link-success", [
            newLink.name
          ]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = newLink.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        let currentProcessing = this.processing;
        this.processing = true;
        try {
          await externalLinkService.updateItem(link.id!, {
            ...link,
            name: editedLink.name,
            address: editedLink.address
          });
          link.name = editedLink.name;
          link.address = editedLink.address;

          var snackbarPayload = {
            text: this.$t("scaffold-requests.existing-scaffold-request.update-link-success", [
              link.name
            ]),
            type: "success"
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.touchedFileName = link.name ?? "";
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
        }
      }
    },
    async deleteLink(link: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        await externalLinkService.deleteItem(link.id!);
        this.externalLinks.splice(this.externalLinks.indexOf(link), 1);

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.delete-link-success", [
            link.name
          ]),
          type: "info",
          undoCallback: async () => {
            await this.saveNewExternalLink(link);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = link.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },

    // Files & Photos
    async loadFiles() {
      var fileNames = await scaffoldRequestService.getScaffoldRequestFileList(
        this.legacyRequest.scaffoldRequestID!
      );
      this.allFiles = fileNames.map(function(fileName) {
        return {
          name: fileName,
          isPreviewable: isFilePreviewable(fileName),
          isPhoto: isFilePhoto(fileName)
        } as FileData;
      });
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;

      // GIF files with animations will lose their animation during this process
      // Both due to the quality compression done above (resizing the dimensions of an animated GIF does nothing), and also going through the edit image process
      // This is OK as we shouldn't need animations for any reason
      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: FileData, originalFileName: string) {
      if (!fileData) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
            this.legacyRequest.scaffoldRequestID!,
            fileNameToDownload
          );
        }
        await scaffoldRequestService.uploadScaffoldRequestFile(
          this.legacyRequest.scaffoldRequestID!,
          fileData.name,
          fileData.file as Blob
        );
        await scaffoldRequestService.uploadScaffoldRequestLegacyFile(
          this.legacyRequest.scaffoldRequestID!,
          fileData.name,
          fileData.file as Blob
        );

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await scaffoldRequestService.deleteScaffoldRequestFile(
            this.legacyRequest.scaffoldRequestID!,
            originalFileName
          );
        }
        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.update-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.update-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      if (!fileData) return;

      this.processing = true;
      try {
        await scaffoldRequestService.uploadScaffoldRequestFile(
          this.legacyRequest.scaffoldRequestID!,
          fileData.name,
          fileData.file as Blob
        );
        await scaffoldRequestService.uploadScaffoldRequestLegacyFile(
          this.legacyRequest.scaffoldRequestID!,
          fileData.name,
          fileData.file as Blob
        );

        this.allFiles.push(fileData);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.save-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.save-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.touchedFileName = fileData.name;
        this.showPhotoTabAttachmentAlert = this.selectedTab == this.photosTab && !fileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.selectedTab == this.attachmentsTab && fileData.isPhoto == true;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPhoto) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      if (!!fileData.file) {
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.editImageSource = `/services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile?requestId=${this.legacyRequest.scaffoldRequestID}&fileName=${fileData.name}`;
      }
    },
    async downloadFile(fileData: FileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.legacyRequest.scaffoldRequestID!,
          fileName
        );
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        // the data probably hasn't been downloaded yet
        fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.legacyRequest.scaffoldRequestID!,
          fileData.name
        );
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async viewFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.legacyRequest.scaffoldRequestID!,
          fileData.name
        );
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.imageSource = `/services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile?requestId=${this.legacyRequest.scaffoldRequestID}&fileName=${fileData.name}`;
      }
    },
    async deleteFile(fileData: FileData) {
      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
            this.legacyRequest.scaffoldRequestID!,
            fileData.name
          );
        }
        await scaffoldRequestService.deleteScaffoldRequestFile(
          this.legacyRequest.scaffoldRequestID!,
          fileData.name
        );

        this.allFiles.splice(this.allFiles.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.delete-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.delete-file-success", [
              fileData.name
            ]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  watch: {
    selectedScaffold: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.legacyRequest.applyToScaffoldID = undefined;
        this.legacyRequest.areaID = undefined;
        this.legacyRequest.subAreaID = undefined;
        this.legacyRequest.location = undefined;
        this.dataSetByScaffold = false;
      } else if (!!newValue) {
        let scaffold: LegacyScaffoldSearchResult = newValue;
        this.legacyRequest.applyToScaffoldID = scaffold.id;
        if (this.screenLoaded) {
          var proceed = await this.openWorkDetailsForScaffoldDialog();
          if (!proceed) {
            this.legacyRequest.applyToScaffoldID = undefined;
            let self = this;
            this.$nextTick(() => {
              self.selectedScaffold = null;
            });
            return;
          }
        }
        this.legacyRequest.areaID = scaffold.projectID;
        this.legacyRequest.subAreaID = scaffold.areaID;
        this.legacyRequest.location = scaffold.location;
        this.dataSetByScaffold = true;
        this.dataSetByDesign = false;
      }
    },
    selectedDesign: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.legacyRequest.applyToScaffoldID = undefined;
        this.legacyRequest.scaffoldDesignID = undefined;
        this.legacyRequest.areaID = undefined;
        this.legacyRequest.subAreaID = undefined;
        this.legacyRequest.location = undefined;
        this.dataSetByDesign = false;
      } else if (!!newValue) {
        let design: ScaffoldDesignWithDetails = newValue;
        this.legacyRequest.applyToScaffoldID = Number(design.scaffoldNumber);
        this.legacyRequest.scaffoldDesignID = design.id;

        this.legacyRequest.areaID = this.allVisibleAreas.find(x => x.id == design.areaID)?.legacyID;
        this.legacyRequest.subAreaID = this.allVisibleSubAreas.find(
          x => x.id == design.subAreaID
        )?.legacyID;
        this.legacyRequest.location = design.specificWorkLocation;
        this.dataSetByDesign = true;
        this.dataSetByScaffold = false;
      }
    },
    "legacyRequest.requestTypeID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (newValue == 1) this.selectedScaffold = null;
    },
    legacyRequest(newValue: LegacyWorkOrderWithRequestDetails) {
      var breadcrumbText = `#${newValue.internalRequestNumber}`;
      if (newValue.requestStatusID == 3 || newValue.requestStatusID! > 5) {
        breadcrumbText = `WO #${newValue.id} (${this.$t("scaffold-requests.request")} # ${
          newValue.internalRequestNumber
        })`;
      }

      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if (this.$route.name == "RequestExisting") {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffoldrequests") {
          this.notifyNewBreadcrumb({
            text: this.$t("scaffold-requests.list-title"),
            to: "/scaffoldrequests",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        this.notifyNewBreadcrumb({
          text: breadcrumbText,
          to: `/scaffoldrequests/${newValue.id}`
        });
      } else if (this.$route.name == "RequestApprovalExisting") {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffoldrequestapprovals") {
          this.notifyNewBreadcrumb({
            text: this.$t("scaffold-request-approvals.list-title"),
            to: "/scaffoldrequestapprovals",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        this.notifyNewBreadcrumb({
          text: breadcrumbText,
          to: `/scaffoldrequestapprovals/${newValue.id}`
        });
      }
    },
    "legacyRequest.areaID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.legacyRequest.areaID &&
        !this.areas.map(x => x.id).includes(this.legacyRequest.areaID)
      ) {
        this.legacyRequest.areaID = null;
      }
      if (
        !!this.legacyRequest.subAreaID &&
        !this.subAreas.map(x => x.id).includes(this.legacyRequest.subAreaID)
      ) {
        this.legacyRequest.subAreaID = null;
      }
    },
    "legacyRequest.subAreaID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.legacyRequest.subAreaID &&
        !this.subAreas.map(x => x.id).includes(this.legacyRequest.subAreaID)
      ) {
        this.legacyRequest.subAreaID = null;
      }
    },
    "legacyRequest.requestingContractorID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.legacyRequest.disciplineID &&
        !this.disciplines.map(x => x.id).includes(this.legacyRequest.disciplineID)
      ) {
        this.legacyRequest.disciplineID = null;
      }

      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.legacyRequest.requestorID &&
        !this.requestors.map(x => x.id).includes(this.legacyRequest.requestorID)
      ) {
        this.legacyRequest.requestorID = null;
      }
    },
    "legacyRequest.disciplineID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.legacyRequest.requestorID &&
        !this.requestors.map(x => x.id).includes(this.legacyRequest.requestorID)
      ) {
        this.legacyRequest.requestorID = null;
      }
    }
  },

  created: async function() {
    // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
    // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
    if (this.$route.name == "RequestExisting") {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffoldrequests") {
        this.notifyNewBreadcrumb({
          text: this.$t("scaffold-requests.list-title"),
          to: "/scaffoldrequests",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: ``,
        to: `/scaffoldrequests/${this.$route.params.id}`
      });

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "legacy-scaffold-request-existing",
        parentalContext: "scaffoldrequests",
        searchStringForFiltering: "",
        selectedTab: this.firstTabKey
      });
    } else if (this.$route.name == "RequestApprovalExisting") {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffoldrequestapprovals") {
        this.notifyNewBreadcrumb({
          text: this.$t("scaffold-request-approvals.list-title"),
          to: "/scaffoldrequestapprovals",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: ``,
        to: `/scaffoldrequestapprovals/${this.$route.params.id}`
      });

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "scaffold-request-approvals-existing",
        parentalContext: "scaffold-request-approvals",
        searchStringForFiltering: "",
        selectedTab: this.firstTabKey
      });
    }

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    this.processing = true;

    try {
      await Promise.all([
        this.loadVisibleAreas(),
        this.loadVisibleSubAreas(),
        this.loadRequestingContractors(),
        this.loadDisciplines(),
        this.loadRequestors(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.$store.dispatch("LOAD_TAGS"),
        this.loadRequestTypes(),
        this.loadRequestSubTypes()
      ]);

      // processing has been set to false after the reference data loaded.
      this.processing = true;
      await this.loadRequestDetails();
      // Processing has automatically been set to false after this load happens
      this.processing = true;

      // Requested By ID is required (which in turn requires a discipline and contractor) so this should never happen
      // However, contractor is required and can be hidden and therefore if empty the user cannot fix it themselves.
      if (
        !this.legacyRequest.requestingContractorID &&
        !this.detailsReadonly &&
        !!this.curUserAccess.homeContractorLegacyID
      )
        this.legacyRequest.requestingContractorID = this.curUserAccess.homeContractorLegacyID;

      if (!!this.legacyRequest.applyToScaffoldID) {
        await this.loadScaffolds(`${this.legacyRequest.applyToScaffoldID}`);
        this.selectedScaffold = this.availableScaffolds.find(
          x => x.id == this.legacyRequest.applyToScaffoldID
        );
        this.dataSetByScaffold = true;
      }

      if (!!this.legacyRequest.scaffoldDesignID) {
        await this.loadScaffoldDesigns();
        this.selectedDesign = this.availableDesigns.find(
          x => x.id == this.legacyRequest.scaffoldDesignID
        );
        this.dataSetByDesign = true;
      }

      this.selectedKeywords = this.legacyRequest.tagIDs
        ? (this.legacyRequest.tagIDs
            .map(x => this.allKeywords.find(y => y.id == x))
            .filter(x => !!x) as Keyword[])
        : [];

      // The control displaying Work Packages NEEDS data to exist in its source in order to be displayed in the control
      // Therefore, we start by loading all referenced IWPs from the request into the source data
      if (this.legacyRequest.workPackageIDs?.length) {
        let iwps = [] as WorkPackageWithNameCode[];
        for (const iwpID of this.legacyRequest.workPackageIDs) {
          let iwpsForID = await workPackageService.searchAll(iwpID);
          iwps = iwps.concat(
            iwpsForID.map(x => {
              return { ...x, nameCode: `${x.name} | ${x.activityID}` };
            })
          );
        }
        this.availableIWPs = iwps;

        this.selectedIWPs = this.availableIWPs.filter(iwp => {
          return (
            this.legacyRequest.workPackageIDs && this.legacyRequest.workPackageIDs.includes(iwp.id!)
          );
        }, this);
      }

      this.processing = true;
      let messages = await messageService.getForScaffoldRequest(
        this.legacyRequest.scaffoldRequestID!
      );
      this.messages = SortMessagesArray(messages).map(x =>
        ParseMessageWithSenderDetails(x, this.curUserID)
      );
      let notes = await noteService.getForScaffoldRequest(this.legacyRequest.scaffoldRequestID!);
      this.notes = notes.map(x => ParseNoteWithSenderDetails(x));
      if (!!this.legacyRequest.notes?.length) {
        this.notes.push({
          isPinned: true,
          isNew: false,
          initials: "",
          name: `${this.$t("scaffold-requests.notes")}`,
          role: "",
          date: "",
          time: "",
          text: this.legacyRequest.notes,
          sender: undefined,
          id: undefined,
          noteThreadID: undefined,
          personID: undefined,
          sentTime: new Date(0),
          archivedDate: undefined,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        });
      }
      this.notes = SortNotesArray(this.notes);

      await this.loadFiles();
      await this.loadLinks();

      this.screenLoaded = true;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

