var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "uploadForm" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "mx-2" },
            [
              _c(
                "v-col",
                { staticClass: "pt-0", attrs: { cols: "12" } },
                [
                  _c("v-row", { staticClass: "mt-0" }, [
                    _c("span", { staticClass: "inline-extra-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("parts.bulk-upload-explanation-1")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("v-row", { staticClass: "mt-3" }, [
                    _c("span", { staticClass: "inline-extra-info-text" }, [
                      _vm._v(_vm._s(_vm.$t("parts.bulk-upload-explanation-2")))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-file-input", {
                staticClass: "mt-4 pr-0 pl-0",
                staticStyle: { "margin-bottom": "-10px" },
                attrs: {
                  color: "primary",
                  counter: "",
                  label: _vm.$t("common.file-upload"),
                  placeholder: _vm.$t("common.select-your-file"),
                  "prepend-icon": "mdi-paperclip",
                  outlined: "",
                  "show-size": 1000,
                  rules: _vm.$props.partRules.fileInput,
                  disabled: _vm.processing
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var index = ref.index
                      var text = ref.text
                      return [
                        index < 2
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "keywords-selection-chip-colors",
                                attrs: { dark: "", label: "", small: "" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(text) +
                                    "\n          "
                                )
                              ]
                            )
                          : index === 2
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "overline grey--text text--darken-3 mx-2"
                              },
                              [
                                _vm._v(
                                  "\n            +" +
                                    _vm._s(_vm.files.length - 2) +
                                    " File(s)\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.file,
                  callback: function($$v) {
                    _vm.file = $$v
                  },
                  expression: "file"
                }
              }),
              _vm._v(" "),
              _c(
                "v-expansion-panels",
                { staticClass: "mt-3", attrs: { accordion: "", tile: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "common.bulk-upload-advanced-import-settings.title"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        {
                          staticStyle: {
                            "max-height": "230px",
                            overflow: "auto"
                          }
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "pt-0" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mt-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-extra-info-text",
                                          staticStyle: {
                                            "font-size": "0.8em",
                                            "font-weight": "bold"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "common.bulk-upload-advanced-import-settings.explanation"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "span",
                                      { staticClass: "inline-extra-info-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common.bulk-upload-advanced-import-settings.identifying-column-explanation-1"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-extra-info-text",
                                          staticStyle: { "font-weight": "bold" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "common.bulk-upload-advanced-import-settings.identifying-column-explanation-2"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: _vm.$t(
                                            "parts.identifying-column"
                                          ),
                                          items:
                                            _vm.partsImportColumnMapping
                                              .columnFriendlyNames
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .identifyingColumnFriendlyName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "identifyingColumnFriendlyName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.identifyingColumnFriendlyName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-extra-info-text"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "common.bulk-upload-advanced-import-settings.column-mapping-explanation-1"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-extra-info-text",
                                          staticStyle: { "font-weight": "bold" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "common.bulk-upload-advanced-import-settings.column-mapping-explanation-2"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: _vm.$t("parts.name") },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.public-id")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .publicID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "publicID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.publicID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.description")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "parts.long-description"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .longDescription,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "longDescription",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.longDescription"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.weight")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping.weight,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "weight",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.weight"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: _vm.$t("parts.mpp") },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping.mpp,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "mpp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.mpp"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.lashing")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .lashingMPP,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "lashingMPP",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.lashingMPP"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.cleating")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .cleatingMPP,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "cleatingMPP",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.cleatingMPP"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.carpentry")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .carpentryMPP,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "carpentryMPP",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.carpentryMPP"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: _vm.$t("parts.other") },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .otherMPP,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "otherMPP",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.otherMPP"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.rental-rate")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .rentalRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "rentalRate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.rentalRate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "parts.count-sheet-group-id"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .countSheetGroupID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "countSheetGroupID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.countSheetGroupID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.cost-used")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .costUsed,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "costUsed",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.costUsed"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.cost-new")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .costNew,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "costNew",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.costNew"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.designation")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .designation,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "designation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.designation"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: _vm.$t("parts.tags") },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .tagNames,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "tagNames",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.tagNames"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.suppliers")
                                        },
                                        model: {
                                          value:
                                            _vm.partsImportColumnMapping
                                              .supplierNames,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.partsImportColumnMapping,
                                              "supplierNames",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "partsImportColumnMapping.supplierNames"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessageBulkSP1Upload.message
                ? _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-0",
                          attrs: { type: _vm.inlineMessageBulkSP1Upload.type }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.inlineMessageBulkSP1Upload.message) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.sp1ImportResults
                ? _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        { staticClass: "mb-0", attrs: { type: "success" } },
                        [
                          _c("h3", { staticClass: "headline" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "common.bulk-upload-results.sp1-import-success"
                                  )
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.import-results-spreadsheet-row-count"
                                )
                              ) +
                              ":\n          " +
                              _vm._s(_vm.sp1ImportResults.foundRowCount)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.import-results-updated-row-count"
                                )
                              ) +
                              ":\n          " +
                              _vm._s(_vm.sp1ImportResults.updatedRowCount)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.import-results-added-row-count"
                                )
                              ) +
                              ":\n          " +
                              _vm._s(_vm.sp1ImportResults.insertedRowCount)
                          ),
                          _c("br")
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.inlineMessageBulkSP2Upload.message
                ? _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-0",
                          attrs: { type: _vm.inlineMessageBulkSP2Upload.type }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.inlineMessageBulkSP2Upload.message) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.sp2ImportResults
                ? _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        { staticClass: "mb-0", attrs: { type: "success" } },
                        [
                          _c("h3", { staticClass: "headline" }, [
                            _vm.shouldImportToSP1
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "common.bulk-upload-results.sp2-import-success"
                                      )
                                    )
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "common.bulk-upload-results.import-success"
                                      )
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.import-results-spreadsheet-row-count"
                                )
                              ) +
                              ":\n          " +
                              _vm._s(+_vm.sp2ImportResults.foundRowCount)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.import-results-updated-row-count"
                                )
                              ) +
                              ":\n          " +
                              _vm._s(+_vm.sp2ImportResults.updatedRowCount)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.import-results-added-row-count"
                                )
                              ) +
                              ":\n          " +
                              _vm._s(+_vm.sp2ImportResults.insertedRowCount)
                          ),
                          _c("br")
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pl-0 pr-0 pt-0 pb-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mt-5 mb-0",
                      attrs: {
                        text: "",
                        color: "error",
                        value: !!_vm.returnFile,
                        transition: "scale-transition"
                      }
                    },
                    [
                      _c("h3", { staticClass: "headline" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "common.bulk-upload-results.bulk-upload-errors-during-upload-title"
                              )
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "common.bulk-upload-results.bulk-upload-errors-during-upload-inline-1"
                              )
                            )
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.bulk-upload-errors-during-upload-inline-2"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-divider", {
                        staticClass: "my-4",
                        staticStyle: { opacity: "0.22" },
                        attrs: { color: "error" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c("v-col", { staticClass: "grow pr-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.bulk-upload-errors-during-upload-inline-3"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "shrink" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "black",
                                    outlined: "",
                                    loading: _vm.downloadingerrors,
                                    disabled: _vm.processing
                                  },
                                  on: { click: _vm.downloadReturnFile }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.save-file")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pb-0 pt-6", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "end" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          disabled: _vm.processing,
                          label: _vm.$t(
                            "common.bulk-upload-general.import-into-swiftplant-v1"
                          )
                        },
                        model: {
                          value: _vm.shouldImportToSP1,
                          callback: function($$v) {
                            _vm.shouldImportToSP1 = $$v
                          },
                          expression: "shouldImportToSP1"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", disabled: _vm.processing },
                  on: { click: _vm.closeDialog }
                },
                [_vm._v(_vm._s(_vm.$t("common.close")))]
              ),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  text: "",
                                  color: "primary",
                                  loading: _vm.downloading,
                                  disabled: _vm.processing
                                }
                              },
                              on
                            ),
                            [_vm._v(_vm._s(_vm.$t("common.download")))]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { on: { click: _vm.downloadTemplate } },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-general.download-empty-template"
                                )
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.downloadFullPartsList } },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(_vm.$t("parts.download-full-catalog"))
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    elevation: "1",
                    color: "primary",
                    loading: _vm.uploading,
                    disabled: _vm.processing
                  },
                  on: { click: _vm.uploadFiles }
                },
                [_vm._v(_vm._s(_vm.$t("common.upload")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }