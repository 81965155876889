import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import {
  CrewWithEmployees,
  personService,
  PersonWithDetails,
  TimesheetEntryWithDetails,
  timesheetService,
  TimesheetStatus,
  TimesheetType,
  TimesheetWithDetails,
  WorkSubType,
  WorkType
} from "../../../services";
import { SortTimesheetRows, UpdatableTimesheetWithTimesheetRows } from "../../../utils/timesheet";
import { GetPersonName, SortItemsWithName } from "../../../utils/person";
import { mapActions } from "vuex";
import { stripTimeFromLocalizedDateTime } from "@fd/lib/client-util/datetime";
import { showItemSelectionBottomDialog } from "../ItemSelectionBottomDialog.vue";
import { createNewCrew, updateExistingCrew } from "./CrewDetailsBottomDialog.vue";
import Vue from "vue";
import userAccess from "../../../dataMixins/userAccess";
import rules from "@fd/lib/vue/rules";

const ForemanTimesheetDialog = FDVue.extend({
  name: "fd-foreman-timesheet-dialog",
  mixins: [dialogSupport, userAccess, rules],
  components: {
    "fd-foreman-timesheet-form": () => import("../forms/ForemanTimesheetForm.vue")
  },
  data: function() {
    return {
      currentTimesheet: {} as UpdatableTimesheetWithTimesheetRows,
      isReadonly: false,
      foremanID: "" as string,
      day: new Date(),
      parentContext: undefined as string | undefined
    };
  },

  computed: {
    perDiemSubType(): WorkSubType | undefined {
      let perDiemTypeID = (this.$store.state.workTypes.fullList as WorkType[]).find(
        x => !!x.isPerDiem
      )?.id;
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      return allWorkSubTypes.find(x => x.workTypeID == perDiemTypeID);
    },
    equipmentSubType(): WorkSubType | undefined {
      let equipmentTypeID = (this.$store.state.workTypes.fullList as WorkType[]).find(
        x => !!x.isEquipment
      )?.id;
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      return allWorkSubTypes.find(x => x.workTypeID == equipmentTypeID);
    },
    canEditCurrentTimesheet(): boolean {
      return this.canEditTimesheet(this.currentTimesheet);
    },
    canSave(): boolean {
      if (!this.currentTimesheet) return false;

      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      return (
        this.canEditCurrentTimesheet &&
        this.currentTimesheet.checkIsDirty(
          this.perDiemSubType,
          this.equipmentSubType,
          allWorkSubTypes,
          allPeople
        )
      );
    },
    canSubmit(): boolean {
      return (
        !!this.currentTimesheet &&
        (this.currentTimesheet.timesheetStatusID == TimesheetStatus.New ||
          this.currentTimesheet.timesheetStatusID == TimesheetStatus.Declined)
      );
    },
    formattedDay(): string {
      return stripTimeFromLocalizedDateTime(this.day);
    },
    timesheetIsSubmitted(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Submitted;
    },
    timesheetIsApproved(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Approved;
    },
    timesheetIsCancelled(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Cancelled;
    }
  },

  methods: {
    ...mapActions({
      loadEmployees: "LOAD_USERS",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES"
    }),
    canEditTimesheet(timesheet: UpdatableTimesheetWithTimesheetRows | undefined): boolean {
      if (!timesheet) return false;

      let hasPermission =
        timesheet.isNew || !!timesheet?.currentUserPermissions?.canEditExistingEntries;
      let locked = timesheet.isLocked ?? false;
      let currentDay =
        new Date(timesheet.day!.toDateString()).getTime() ==
        new Date(new Date().toDateString()).getTime();
      return !locked && currentDay && hasPermission;
    },
    async loadTimesheet() {
      this.optOutOfErrorHandling();
      this.processing = true;
      try {
        let timesheet = await timesheetService.getByOwnerIDAndDate(
          this.foremanID!,
          this.day as Date,
          false
        );

        if (!timesheet) {
          await Promise.all([this.loadEmployees()]);
          let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
          let owner = allPeople.find(x => x.id == this.foremanID);
          let ownerName = GetPersonName(owner);
          timesheet = {
            ownerID: this.foremanID!,
            ownerName: ownerName,
            contractorID: owner?.contractorID,
            day: this.day,
            timesheetStatusID: TimesheetStatus.New
          } as TimesheetWithDetails;
        }

        let entries = [] as TimesheetEntryWithDetails[];
        if (!!timesheet.id) {
          entries = await timesheetService.getEntriesForTimesheetID(timesheet.id);
        }

        await Promise.all([this.loadWorkTypes(), this.loadWorkSubTypes()]);
        let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
        let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
        let timesheetWithEntries = new UpdatableTimesheetWithTimesheetRows(
          timesheet,
          entries,
          allWorkTypes,
          allWorkSubTypes
        );
        timesheetWithEntries.timesheetRows = SortTimesheetRows(timesheetWithEntries.timesheetRows);
        this.currentTimesheet = timesheetWithEntries;
        this.isReadonly =
          this.currentTimesheet.isLocked ||
          (!this.currentUserCanCreateIndirectTimesheets &&
            (this.currentTimesheet.timesheetTypeID == TimesheetType.Indirect ||
              this.currentTimesheet.timesheetTypeID == TimesheetType.Equipment));
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    // *** NAVIGATION ***
    async open(foremanID: string, day: Date, parentContext: string | undefined) {
      this.parentContext = parentContext;
      this.foremanID = foremanID;
      this.day = day;
      this.loadTimesheet();
      this.optOutOfErrorHandling();
      return await this.showDialog();
    },
    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        let timesheet = this.currentTimesheet;
        if (!timesheet || !this.canSave) {
          this.processing = false;
          var snackbarPayload = {
            text: this.$t("timesheets.entries.no-changes-to-save-message"),
            type: "info",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          return false;
        }

        if (!(await this.saveTimesheet())) {
          return;
        }
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async submitDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      try {
        var saved = await this.saveTimesheet();
        if (saved) {
          var submitted = await this.submitTimesheet();
          if (submitted) this.closeDialog!(true);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // *** DATA ACTIONS
    async saveTimesheet(): Promise<boolean> {
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];

      let timesheet = this.currentTimesheet;
      if (!timesheet) return false;

      var errorEntries = timesheet
        .getEntries(this.perDiemSubType, this.equipmentSubType, allWorkSubTypes, allPeople)
        .filter(x => !x.regularTime && !x.overTime && !x.doubleTime && !x.units);

      if (errorEntries.length) {
        this.inlineMessage.message = this.$t(
          "timesheets.entries.entries-missing-data-for-day-error-message",
          [stripTimeFromLocalizedDateTime(timesheet.day)]
        );
        return false;
      }

      if (timesheet.isNew) {
        let newID = await timesheetService.addItem(timesheet);
        timesheet.id = newID;
        timesheet
          .getNewEntries(this.perDiemSubType, this.equipmentSubType, allWorkSubTypes, allPeople)
          .forEach(x => (x.timesheetID = newID));
      }
      // else if (timesheet.isModified) {
      //   await timesheetService.updateItem(timesheet.id!, timesheet);
      // }

      if (
        timesheet.checkHasNewEntries(
          this.perDiemSubType,
          this.equipmentSubType,
          allWorkSubTypes,
          allPeople
        )
      ) {
        await timesheetService.addEntriesToTimesheetWithID(
          timesheet.id!,
          timesheet.getSanitizedNewEntries(
            this.perDiemSubType,
            this.equipmentSubType,
            allWorkSubTypes,
            allPeople
          )
        );
      }
      if (
        timesheet.checkHasModifiedEntries(
          this.perDiemSubType,
          this.equipmentSubType,
          allWorkSubTypes,
          allPeople
        )
      ) {
        await timesheetService.updateEntriesForTimesheetWithID(
          timesheet.id!,
          timesheet.getModifiedExistingEntryData(
            this.perDiemSubType,
            this.equipmentSubType,
            allWorkSubTypes,
            allPeople
          )
        );
      }
      if (
        timesheet.checkHasRemovedEntries(
          this.perDiemSubType,
          this.equipmentSubType,
          allWorkSubTypes,
          allPeople
        )
      ) {
        await timesheetService.removeEntriesFromTimesheetWithID(
          timesheet.id!,
          timesheet.getRemovedEntryIDs(
            this.perDiemSubType,
            this.equipmentSubType,
            allWorkSubTypes,
            allPeople
          )
        );
      }
      return true;
    },

    async submitTimesheet(): Promise<boolean> {
      this.optOutOfErrorHandling();
      let timesheet = this.currentTimesheet;
      if (!timesheet || !this.canEditCurrentTimesheet) {
        return false;
      }

      let submittedToID = undefined as string | undefined;

      if (
        timesheet.timesheetTypeID == TimesheetType.Indirect ||
        timesheet.timesheetTypeID == TimesheetType.Equipment
      ) {
        let visibleTimeManagers = SortItemsWithName(
          (await personService.getVisibleTimeManagers())
            .filter(x => x.contractorID == timesheet.contractorID)
            .map(x => ({
              ...x,
              name: GetPersonName(x)
            }))
        );

        var title = this.$t("timesheets.entries.submit-to-time-manager-label");
        submittedToID = await showItemSelectionBottomDialog(
          title,
          this.$t("timesheets.entries.time-manager-label"),
          [this.rules.required],
          visibleTimeManagers,
          "name",
          "id"
        );
        // If details is undefined the dialog was cancelled, if empty we somehow bypassed selecting a TM
        if (!submittedToID) {
          return false;
        }
      } else {
        let visibleGeneralForeman = SortItemsWithName(
          (await personService.getVisibleGeneralForemen())
            .filter(x => x.contractorID == timesheet!.contractorID)
            .map(x => ({
              ...x,
              name: GetPersonName(x)
            }))
        );
        var title = this.$t("timesheets.entries.submit-to-gen-foreman-label");
        submittedToID = await showItemSelectionBottomDialog(
          title,
          this.$t("timesheets.entries.general-foreman-label"),
          [this.rules.required],
          visibleGeneralForeman,
          "name",
          "id",
          this.$refs.content as Vue
        );
      }

      // If details is undefined the dialog was cancelled
      if (!submittedToID) {
        return false;
      }

      timesheet.submittedTo = submittedToID;

      await timesheetService.submitTimesheet(timesheet.id!, submittedToID);
      timesheet.timesheetStatusID = TimesheetStatus.Submitted;

      // if (!!this.workOrder?.id) {
      //   let updatedWorkOrder = await workOrderService.getWorkOrderByID(this.workOrder.id);

      //   this.workOrder.workOrderStatus = updatedWorkOrder.workOrderStatus;
      //   this.workOrder.workOrderStatusName = updatedWorkOrder.workOrderStatusName;
      //   this.workOrder.isArchived = updatedWorkOrder.isArchived;
      //   this.workOrder.archivedDate = updatedWorkOrder.archivedDate;
      //   this.workOrder.completedDate = updatedWorkOrder.completedDate;
      //   this.workOrder.progress = updatedWorkOrder.progress;
      //   this.workOrder.isTimeEntryCompleted = updatedWorkOrder.isTimeEntryCompleted;
      // }

      return true;
    },

    // *** CREWS ***
    async addNewCrew() {
      this.optOutOfErrorHandling();
      if (!this.currentTimesheet) return;
      if (this.isReadonly) return;

      await createNewCrew(
        this.currentTimesheet.contractorID!,
        this.curUserID,
        "",
        this.$refs.content as Vue
      );
    },

    async editCrew(crew: CrewWithEmployees | undefined) {
      this.optOutOfErrorHandling();
      if (!crew) return;
      await updateExistingCrew(crew, this.$refs.content as Vue);
    },

    async deleteCrew(crew: CrewWithEmployees | undefined) {
      this.optOutOfErrorHandling();
      if (!crew?.id) return;
      this.processing = true;
      try {
        await this.$store.dispatch("DELETE_CREW", crew);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  }
});
export default ForemanTimesheetDialog;
export async function openForemanTimesheetDialog(
  foremanID: string,
  day: Date,
  parentContext: string | undefined
): Promise<string | boolean> {
  let dialog = createDialog(ForemanTimesheetDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(foremanID, day, parentContext);
}

