import { TranslateResult } from "vue-i18n";
import {
  Timesheet,
  TimesheetEntry,
  TimesheetEntryWithDetails,
  TimesheetStatus,
  TimesheetStatusLogWithDetails,
  TimesheetWithDetails,
  PersonWithDetails,
  SummarizedTimesheetPermissions,
  WorkSubType,
  WorkType,
  TimesheetExplanationWithWorkOrderDetails,
  TimesheetExplanation,
  Contractor,
  TimesheetType
} from "../services";

function SanitizeNumericValue(val: number | string | undefined | null): number | null {
  return !!val ? +val : null;
}
function CompareWorkTypes(a: WorkType, b: WorkType): number {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}
export function SortWorkTypes(items: WorkType[] | null | undefined): WorkType[] {
  if (!items) return [];
  return items.sort(CompareWorkTypes);
}
function CompareWorkSubTypes(a: WorkSubType, b: WorkSubType): number {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}
/// Sorts a list of Work Sub Types by order and name.  To be used only with work sub types all under the same work type
export function SortWorkSubTypes(items: WorkSubType[] | null | undefined): WorkSubType[] {
  if (!items) return [];
  return items.sort(CompareWorkSubTypes);
}
/// Sorts of a list of Work Sub Types under multiple work types.  Sorts work types, then sorts sub types for each work type individually
export function SortAllWorkSubTypes(
  items: WorkSubType[] | null | undefined,
  parents: WorkType[] | null | undefined
): WorkSubType[] {
  if (!items?.length) return [];
  if (!parents?.length) return items.sort(CompareWorkSubTypes);

  let sortedParents = SortWorkTypes(parents);
  let allSortedItems = [] as WorkSubType[];
  for (let parent of sortedParents) {
    let itemsForParent = SortWorkSubTypes(items.filter(x => x.workTypeID == parent.id));
    allSortedItems = allSortedItems.concat(itemsForParent);
  }
  return allSortedItems;
}
export type TableHeader = {
  text: string | TranslateResult | undefined | null;
  value: string | undefined;
  align?: "start" | "center" | "end";
  sortable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  divider?: boolean;
  class?: string | string[];
  cellClass?: string | string[];
  width?: string | number;
  filter?: (value: any, search: string, item: any) => boolean;
  sort?: (a: any, b: any) => number;
};
export interface HashTable<T> {
  [key: string]: T;
}
export enum TimesheetRowType {
  DirectWorkOrderRelated = 1,
  DirectGeneral = 2,
  Indirect = 3,
  Equipment = 4
}
export type TimesheetRow = {
  timesheetNumber: string;
  contractorName: string;
  employeeID: string;
  employeeName: string;
  employeeCode?: string | undefined;
  classificationID: string | null | undefined;
  classificationDisplayName?: string | undefined;
  rowType: TimesheetRowType;
  workOrderID: string | null | undefined;
  workOrderNumber: string | null | undefined;
  workOrderClientWorkOrderNumber: string | null | undefined;
  workOrderChangeOrderNumber: string | null | undefined;
  workOrderReworkNumber: string | null | undefined;
  scaffoldID: string | null | undefined;
  workOrderCostCodeID: string | null | undefined;
  areaID: string | null | undefined;
  areaName: string | null | undefined;
  subAreaID: string | null | undefined;
  subAreaName: string | null | undefined;
  hasPerDiem: boolean;
  isCorrectionRow: boolean;
  rowNumber: number;
  removePerDiem: boolean;
  errorMessage: string;
  equipmentDays: number | null | undefined;
  equipmentQuantity: number | null | undefined;
};
/// List of properties that exist as part of the base object so that we can differentiate between "real" properties and the WST IDs
let TimesheetRowKeys = [
  "timesheetNumber",
  "rowNumber",
  "rowType",
  "contractorName",
  "employeeID",
  "workOrderID",
  "workOrderNumber",
  "workOrderClientWorkOrderNumber",
  "workOrderChangeOrderNumber",
  "workOrderReworkNumber",
  "scaffoldID",
  "workOrderCostCodeID",
  "areaID",
  "areaName",
  "subAreaID",
  "subAreaName",
  "costCodeID",
  "employeeName",
  "employeeCode",
  "classificationID",
  "classificationDisplayName",
  "hasPerDiem",
  "isCorrectionRow",
  "removePerDiem",
  "errorMessage",
  "equipmentDays",
  "equipmentQuantity"
];
export function areTimesheetRowsEqual(a: TimesheetRow, b: TimesheetRow): boolean {
  return (
    a.isCorrectionRow == b.isCorrectionRow &&
    a.rowType == b.rowType &&
    a.employeeID == b.employeeID &&
    a.workOrderID == b.workOrderID &&
    (a.areaID ?? "") == (b.areaID ?? "") &&
    (a.subAreaID ?? "") == (b.subAreaID ?? "")
  );
}
export function ParseWorkSubTypeIDsFromRow(row: TimesheetRow): string[] {
  // We need an entry for each work sub type with a value for this row
  // These work sub types are added as keys to the object, but we don't want to create entries for the non-wst keys (like employee, sub area, etc.)
  return Object.keys(row).filter(key => !TimesheetRowKeys.includes(key));
}
function CompareExplanationArrays(
  rawArray1: TimesheetExplanation[] | undefined,
  rawArray2: TimesheetExplanation[] | undefined
) {
  // console.log(`CompareExplanationArrays`);
  if (rawArray1?.length != rawArray2?.length) {
    return false;
  }

  var compareExplanations = (a: TimesheetExplanation, b: TimesheetExplanation) => {
    let workOrderIDA = a.workOrderID?.toLowerCase() ?? "";
    let workOrderIDB = b.workOrderID?.toLowerCase() ?? "";
    if (workOrderIDA != workOrderIDB) {
      if (workOrderIDA < workOrderIDB) return -1;
      else return 1;
    }

    let workSubTypeIDA = a.workSubTypeID?.toLowerCase() ?? "";
    let workSubTypeIDB = b.workSubTypeID?.toLowerCase() ?? "";
    if (workSubTypeIDA != workSubTypeIDB) {
      if (workSubTypeIDA < workSubTypeIDB) return -1;
      else return 1;
    }

    let explanationA = a.explanation?.toLowerCase() ?? "";
    let explanationB = b.explanation?.toLowerCase() ?? "";
    if (explanationA != explanationB) {
      if (explanationA < explanationB) return -1;
      else return 1;
    }

    return 0;
  };

  let array1 = (rawArray1 ?? []).slice().sort(compareExplanations);
  let array2 = (rawArray2 ?? []).slice().sort(compareExplanations);

  for (var i = 0; i < array1.length; i++) {
    if (
      array1[i].workOrderID != array2[i].workOrderID ||
      array1[i].workSubTypeID != array2[i].workSubTypeID ||
      array1[i].explanation != array2[i].explanation
    ) {
      // console.log(`  ${array1[i].workOrderID} != ${array2[i].workOrderID} ||
      // ${array1[i].workSubTypeID} != ${array2[i].workSubTypeID} ||
      // ${array1[i].explanation} != ${array2[i].explanation}`);
      return false;
    }
    // console.log(`  ${array1[i].workOrderID} EQUALS ${array2[i].workOrderID} &&
    //   ${array1[i].workSubTypeID} EQUALS ${array2[i].workSubTypeID} &&
    //   ${array1[i].explanation} EQUALS ${array2[i].explanation}`);
  }

  return true;
}
function CompareTimesheetRows(a: TimesheetRow, b: TimesheetRow): number {
  let aRowNumber = a.rowNumber ?? 0;
  let bRowNumber = b.rowNumber ?? 0;
  if (aRowNumber != bRowNumber) {
    return aRowNumber - bRowNumber;
  }

  let aName = a.employeeName!.toLocaleLowerCase();
  let bName = b.employeeName!.toLocaleLowerCase();
  if (aName != bName) {
    if (aName < bName) return -1;
    else if (aName > bName) return 1;
  }

  let aWorkOrderNumber = !isNaN(Number(a.workOrderNumber)) ? Number(a.workOrderNumber!) : 0;
  let aHasWorkOrder = aWorkOrderNumber > 0;
  let bWorkOrderNumber = !isNaN(Number(b.workOrderNumber)) ? Number(b.workOrderNumber!) : 0;
  let bHasWorkOrder = bWorkOrderNumber > 0;
  if (aHasWorkOrder != bHasWorkOrder) {
    if (aHasWorkOrder) return -1;
    else return 1;
  }

  if (aWorkOrderNumber != bWorkOrderNumber) {
    return aWorkOrderNumber - bWorkOrderNumber;
  }

  let aHasArea = !!a.areaID;
  let aAreaID = a.areaID ?? "";
  let aAreaName = a.areaName?.toLowerCase() ?? "";
  let bHasArea = !!b.areaID;
  let bAreaID = b.areaID ?? "";
  let bAreaName = b.areaName?.toLowerCase() ?? "";

  if (aHasArea != bHasArea) {
    if (aHasArea) return -1;
    else return 1;
  }

  if (aAreaID != bAreaID) {
    if (aAreaName > bAreaName) return -1;
    else return 1;
  }

  let aHasSubArea = !!a.subAreaID;
  let aSubAreaID = a.subAreaID ?? "";
  let aSubAreaName = a.subAreaName?.toLowerCase() ?? "";
  let bHasSubArea = !!b.subAreaID;
  let bSubAreaID = b.subAreaID ?? "";
  let bSubAreaName = b.subAreaName?.toLowerCase() ?? "";

  if (aHasSubArea != bHasSubArea) {
    if (aHasSubArea) return -1;
    else return 1;
  }

  if (aSubAreaID != bSubAreaID) {
    if (aSubAreaName > bSubAreaName) return -1;
    else return 1;
  }

  let aIsCorrectionRow = a.isCorrectionRow;
  let bIsCorrectionRow = b.isCorrectionRow;
  if (aIsCorrectionRow != bIsCorrectionRow) {
    if (aIsCorrectionRow) return 1;
    else return -1;
  }

  return 0;
}
export function SortTimesheetRows(rows: TimesheetRow[]): TimesheetRow[] {
  // return rows;
  return rows.sort(CompareTimesheetRows);
}
function ConvertTimesheetRowsToTimesheetEntries(
  timesheet: UpdatableTimesheetWithTimesheetRows,
  perDiemSubType: WorkSubType | undefined,
  equipmentSubType: WorkSubType | undefined,
  workSubTypes: WorkSubType[],
  people: PersonWithDetails[],
  includeEmptyEntries: boolean
): UpdatableTimesheetEntryWithDetails[] {
  if (!timesheet.timesheetRows) return [];
  let entries = [] as UpdatableTimesheetEntryWithDetails[];
  for (let row of timesheet.timesheetRows) {
    if (!!perDiemSubType && (row.hasPerDiem || row.removePerDiem)) {
      // Add an entry with 1 unit related to the Per Diem work type
      let perDiemSubTypeID = perDiemSubType.id;
      let existingPerDiemEntry = timesheet.initialEntries.find(
        x =>
          x.employeeID == row.employeeID &&
          x.workSubTypeID == perDiemSubTypeID &&
          row.isCorrectionRow == x.isCorrectionEntry
      );
      if (!!existingPerDiemEntry) {
        let entryForRow = new UpdatableTimesheetEntryWithDetails(existingPerDiemEntry);
        entryForRow.units = !!row.removePerDiem ? -1 : 1;
        if (!entryForRow.scaffoldID && !!row.scaffoldID) entryForRow.scaffoldID = row.scaffoldID;
        if (!entryForRow.areaID && !!row.areaID) entryForRow.areaID = row.areaID;
        if (!entryForRow.areaName && !!row.areaName) entryForRow.areaName = row.areaName;
        if (!entryForRow.subAreaID && !!row.subAreaID) entryForRow.subAreaID = row.subAreaID;
        if (!entryForRow.subAreaName && !!row.subAreaName)
          entryForRow.subAreaName = row.subAreaName;
        // console.log(
        //   `ConvertTimesheetRowsToTimesheetEntries EXISTING PER DIEM ROW\n${row.employeeName} (${perDiemSubType.name}) isNew: ${entryForRow.isNew} orig: ${entryForRow.originalUnits}, value: ${entryForRow.units}, workOrder: ${entryForRow.workOrderNumber}, subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      } else {
        let costCodeID = perDiemSubType.useWorkOrderCostCode
          ? row.workOrderCostCodeID
          : perDiemSubType.defaultCostCodeID;
        let entryForRow = new UpdatableTimesheetEntryWithDetails({
          timesheetID: timesheet.id,
          isCorrectionEntry: row.isCorrectionRow,
          rowNumber: row.rowNumber,
          employeeID: row.employeeID,
          workOrderID: row.workOrderID,
          workOrderNumber: row.workOrderNumber,
          workOrderClientWorkOrderNumber: row.workOrderClientWorkOrderNumber,
          workOrderChangeOrderNumber: row.workOrderChangeOrderNumber,
          workOrderReworkNumber: row.workOrderReworkNumber,
          scaffoldID: row.scaffoldID,
          areaID: row.areaID,
          areaName: row.areaName,
          subAreaID: row.subAreaID,
          subAreaName: row.subAreaName,
          workTypeID: perDiemSubType.workTypeID,
          workSubTypeID: perDiemSubTypeID,
          classificationID: people.find(x => x.id == row.employeeID)!.classificationID,
          costCodeID: costCodeID,
          units: !!row.removePerDiem ? -1 : 1,
          employeeName: row.employeeName,
          employeeCode: row.employeeCode,
          workSubTypeName: perDiemSubType.name
        } as TimesheetEntryWithDetails);
        // console.log(
        //   `ConvertTimesheetRowsToTimesheetEntries NEW PER DIEM ROW\n${row.employeeName} (${perDiemSubType.name}) isNew: ${entryForRow.isNew} orig: ${entryForRow.originalUnits}, value: ${entryForRow.units}, workOrder: ${entryForRow.workOrderNumber}, subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      }
    }
    if (
      timesheet.timesheetTypeID == TimesheetType.Equipment &&
      !!equipmentSubType &&
      (includeEmptyEntries || (row.equipmentDays !== undefined && row.equipmentDays != 0))
    ) {
      // Add an entry with 1 unit related to the Per Diem work type
      let equipmentSubTypeID = equipmentSubType.id;
      let existingEquipmentEntry = timesheet.initialEntries.find(
        x =>
          x.employeeID == row.employeeID &&
          x.workSubTypeID == equipmentSubTypeID &&
          row.isCorrectionRow == x.isCorrectionEntry
      );
      if (!!existingEquipmentEntry) {
        let entryForRow = new UpdatableTimesheetEntryWithDetails(existingEquipmentEntry);

        entryForRow.doubleTime = row.equipmentDays;
        entryForRow.units = row.equipmentQuantity;

        // console.log(
        //   `ConvertTimesheetRowsToTimesheetEntries EXISTING PER DIEM ROW\n${row.employeeName} (${equipmentSubType.name}) isNew: ${entryForRow.isNew} orig: ${entryForRow.originalUnits}, value: ${entryForRow.units}, workOrder: ${entryForRow.workOrderNumber}, subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      } else {
        let costCodeID = equipmentSubType.useWorkOrderCostCode
          ? row.workOrderCostCodeID
          : equipmentSubType.defaultCostCodeID;
        let entryForRow = new UpdatableTimesheetEntryWithDetails({
          timesheetID: timesheet.id,
          isCorrectionEntry: row.isCorrectionRow,
          rowNumber: row.rowNumber,
          employeeID: row.employeeID,
          workTypeID: equipmentSubType.workTypeID,
          workSubTypeID: equipmentSubTypeID,
          classificationID: people.find(x => x.id == row.employeeID)!.classificationID,
          costCodeID: costCodeID,
          employeeName: row.employeeName,
          employeeCode: row.employeeCode,
          workSubTypeName: equipmentSubType.name
        } as TimesheetEntryWithDetails);

        entryForRow.doubleTime = row.equipmentDays;
        entryForRow.units = row.equipmentQuantity;

        // console.log(
        //   `ConvertTimesheetRowsToTimesheetEntries NEW PER DIEM ROW\n${row.employeeName} (${equipmentSubType.name}) isNew: ${entryForRow.isNew} orig: ${entryForRow.originalUnits}, value: ${entryForRow.units}, workOrder: ${entryForRow.workOrderNumber}, subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      }
    }

    for (let key of Object.keys(row)) {
      // We need an entry for each work sub type with a value for this row
      // These work sub types are added as keys to the object, but we don't want to create entries for the non-wst keys (like employee, sub area, etc.)
      if (TimesheetRowKeys.includes(key)) continue;
      if (key == perDiemSubType?.id || key == equipmentSubType?.id) continue;

      // Each key is actually a workSubTypeID, and its value is the "RegularTime" value.
      // Ignore any WSTs without a time value
      let value = Number((row as any)[key]);
      if (isNaN(value)) value = 0;

      if (!includeEmptyEntries && (!value || value == 0)) continue;

      // Each timesheet row is a combination of: Employee, workorder, subarea.  Therefore an existing entry would have the same of these 3 values, plus the same WST
      let existingEntry = timesheet.initialEntries.find(
        x =>
          x.isCorrectionEntry == row.isCorrectionRow &&
          x.employeeID == row.employeeID &&
          x.workSubTypeID == key &&
          (row.workOrderID ?? "") == (x.workOrderID ?? "") &&
          (row.areaID ?? "") == (x.areaID ?? "") &&
          (row.subAreaID ?? "") == (x.subAreaID ?? "")
      );

      if (!!existingEntry) {
        let entryForRow = new UpdatableTimesheetEntryWithDetails(existingEntry);

        entryForRow.regularTime = value;

        if (!entryForRow.scaffoldID && !!row.scaffoldID) entryForRow.scaffoldID = row.scaffoldID;
        if (!entryForRow.areaID && !!row.areaID) entryForRow.areaID = row.areaID;
        if (!entryForRow.areaName && !!row.areaName) entryForRow.areaName = row.areaName;
        if (!entryForRow.subAreaID && !!row.subAreaID) entryForRow.subAreaID = row.subAreaID;
        if (!entryForRow.subAreaName && !!row.subAreaName)
          entryForRow.subAreaName = row.subAreaName;
        // console.log(
        //   `ConvertTimesheetRowsToTimesheetEntries UPDATE EXISTING\n${row.employeeName} (${entryForRow.workSubTypeName}) isNew: ${entryForRow.isNew} orig: ${entryForRow.originalRegularTime}, value: ${entryForRow.regularTime}, workOrder: ${entryForRow.workOrderNumber}, subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      } else {
        let subType = workSubTypes.find(x => x.id == key)!;
        let costCodeID = subType.useWorkOrderCostCode
          ? row.workOrderCostCodeID
          : subType.defaultCostCodeID;
        if (!costCodeID?.length) {
          console.error(
            `COST CODE NOT FOUND - employeeName=${row.employeeName}, workOrderNumber=${row.workOrderNumber}, subTypeName=${subType.name}, WOCC: ${row.workOrderCostCodeID}, Default CC: ${subType.defaultCostCodeID}, value: ${value}`
          );
        }
        let entryForRow = new UpdatableTimesheetEntryWithDetails({
          timesheetID: timesheet.id,
          isCorrectionEntry: row.isCorrectionRow,
          rowNumber: row.rowNumber,
          employeeID: row.employeeID,
          workOrderID: row.workOrderID,
          workOrderNumber: row.workOrderNumber,
          workOrderClientWorkOrderNumber: row.workOrderClientWorkOrderNumber,
          workOrderChangeOrderNumber: row.workOrderChangeOrderNumber,
          workOrderReworkNumber: row.workOrderReworkNumber,
          scaffoldID: row.scaffoldID,
          areaID: row.areaID,
          areaName: row.areaName,
          subAreaID: row.subAreaID,
          subAreaName: row.subAreaName,
          workTypeID: subType.workTypeID,
          workSubTypeID: key,
          classificationID: people.find(x => x.id == row.employeeID)!.classificationID,
          costCodeID: costCodeID,
          // regularTime: value,
          employeeName: row.employeeName,
          employeeCode: row.employeeCode,
          workSubTypeName: subType.name
        } as TimesheetEntryWithDetails);

        entryForRow.regularTime = value;

        // console.log(
        //   `ConvertTimesheetRowsToTimesheetEntries CREATE NEW ENTRY\n${row.employeeName} (${subType.name}) isNew: ${entryForRow.isNew} orig: ${entryForRow.originalRegularTime}, value: ${entryForRow.regularTime}, workOrder: ${entryForRow.workOrderNumber}, subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      }
    }
  }

  return entries;
}
export function ConvertTimesheetEntriesToTimesheetRows(
  timesheetType: TimesheetType | undefined,
  allEntries: TimesheetEntryWithDetails[] | undefined,
  workTypes: WorkType[],
  workSubTypes: WorkSubType[]
) {
  if (!allEntries) return [];

  let perDiemTypeID = workTypes.find(x => !!x.isPerDiem)?.id;
  let perDiemSubType = workSubTypes.find(x => x.workTypeID == perDiemTypeID);
  let perDiemSubTypeID = perDiemSubType?.id;

  let equipmentTypeID = workTypes.find(x => !!x.isEquipment)?.id;
  let equipmentSubType = workSubTypes.find(x => x.workTypeID == equipmentTypeID);
  let equipmentSubTypeID = equipmentSubType?.id;

  let directWorkTypeIDs = workTypes
    .filter(x => !!x.isDirect && !x.isPerDiem && !x.isEquipment)
    .map(x => x.id!);
  let directWorkOrderRelatedWorkSubTypeIDs = workSubTypes
    .filter(x => directWorkTypeIDs.includes(x.workTypeID!) && !!x.isWorkOrderRelated)
    .map(x => x.id!);
  let directGeneralWorkSubTypeIDs = workSubTypes
    .filter(x => directWorkTypeIDs.includes(x.workTypeID!) && !x.isWorkOrderRelated)
    .map(x => x.id!);
  let indirectWorkTypeIDs = workTypes
    .filter(x => !x.isDirect && !x.isPerDiem && !x.isEquipment)
    .map(x => x.id!);
  let indirectWorkSubTypeIDs = workSubTypes
    .filter(x => indirectWorkTypeIDs.includes(x.workTypeID!))
    .map(x => x.id!);

  if (!!perDiemSubTypeID) {
    if (
      timesheetType == TimesheetType.Indirect &&
      !indirectWorkSubTypeIDs.includes(perDiemSubTypeID)
    ) {
      indirectWorkSubTypeIDs.push(perDiemSubTypeID);
    }
    if (
      timesheetType == TimesheetType.Direct &&
      !directGeneralWorkSubTypeIDs.includes(perDiemSubTypeID)
    ) {
      directGeneralWorkSubTypeIDs.push(perDiemSubTypeID);
    }
  }

  let rows = [] as TimesheetRow[];
  for (let entry of allEntries) {
    if (!entry.workTypeID || !entry.workSubTypeID) continue;

    var rowTypeForEntry = TimesheetRowType.Indirect;
    if (entry.workSubTypeID == equipmentSubTypeID) {
      rowTypeForEntry = TimesheetRowType.Equipment;
    } else if (!!entry.workOrderID) {
      rowTypeForEntry = TimesheetRowType.DirectWorkOrderRelated;
    } else if (directGeneralWorkSubTypeIDs.includes(entry.workSubTypeID!)) {
      rowTypeForEntry = TimesheetRowType.DirectGeneral;
    }

    let existingRow = rows.find(
      row =>
        row.isCorrectionRow == entry.isCorrectionEntry &&
        row.employeeID == entry.employeeID &&
        row.rowType == rowTypeForEntry &&
        (row.workOrderID ?? "") == (entry.workOrderID ?? "") &&
        (row.areaID ?? "") == (entry.areaID ?? "") &&
        (row.subAreaID ?? "") == (entry.subAreaID ?? "")
    );
    if (!existingRow) {
      existingRow = {
        isCorrectionRow: entry.isCorrectionEntry ?? false,
        rowNumber: entry.rowNumber,
        rowType: rowTypeForEntry,
        employeeID: entry.employeeID!,
        workOrderID: entry.workOrderID,
        hasPerDiem: false,
        removePerDiem: false,
        equipmentDays: 0,
        equipmentQuantity: rowTypeForEntry == TimesheetRowType.Equipment ? 1 : 0,
        errorMessage: ""
      } as TimesheetRow;
      rows.push(existingRow);
    }

    if (!existingRow.contractorName) existingRow.contractorName = entry.contractorName;
    if (!existingRow.workOrderNumber) existingRow.workOrderNumber = entry.workOrderNumber ?? "";
    if (!existingRow.workOrderClientWorkOrderNumber)
      existingRow.workOrderClientWorkOrderNumber = entry.workOrderClientWorkOrderNumber ?? "";
    if (!existingRow.workOrderChangeOrderNumber)
      existingRow.workOrderChangeOrderNumber = entry.workOrderChangeOrderNumber ?? "";
    if (!existingRow.workOrderReworkNumber)
      existingRow.workOrderReworkNumber = entry.workOrderReworkNumber ?? "";
    if (!existingRow.scaffoldID) existingRow.scaffoldID = entry.scaffoldID;
    if (!existingRow.areaID) existingRow.areaID = entry.areaID;
    if (!existingRow.areaName) existingRow.areaName = entry.areaName;
    if (!existingRow.subAreaID) existingRow.subAreaID = entry.subAreaID;
    if (!existingRow.subAreaName) existingRow.subAreaName = entry.subAreaName;
    if (!existingRow.employeeName) existingRow.employeeName = entry.employeeName;
    if (!existingRow.employeeCode) existingRow.employeeCode = entry.employeeCode;
    if (!existingRow.classificationID) existingRow.classificationID = entry.classificationID;
    if (!existingRow.classificationDisplayName)
      existingRow.classificationDisplayName = entry.classificationAlias ?? entry.classificationName;

    let isPerDiemType = entry.workSubTypeID.toLowerCase() == perDiemSubTypeID?.toLowerCase();
    let isEquipmentType = entry.workSubTypeID.toLowerCase() == equipmentSubTypeID?.toLowerCase();
    if (isPerDiemType) {
      existingRow.hasPerDiem = !!entry.units && entry.units > 0 ? true : false;
      existingRow.removePerDiem =
        entry.isCorrectionEntry && !!entry.units && entry.units < 0 ? true : false;
    } else if (isEquipmentType) {
      existingRow.equipmentDays = entry.doubleTime ?? 0;
      existingRow.equipmentQuantity = entry.units ?? 1;
    } else {
      (existingRow as any)[entry.workSubTypeID] = entry.regularTime ?? 0;
    }
  }

  for (let row of rows) {
    switch (row.rowType) {
      case TimesheetRowType.Indirect:
        for (let wstID of indirectWorkSubTypeIDs) {
          if (wstID == perDiemSubTypeID) continue;
          if ((row as any)[wstID] === undefined) {
            // console.log(`  ${row.employeeName} ${wstID} = null`);
            (row as any)[wstID] = null;
          }
        }
        break;
      case TimesheetRowType.DirectWorkOrderRelated:
        for (let wstID of directWorkOrderRelatedWorkSubTypeIDs) {
          if (wstID == perDiemSubTypeID) continue;
          if ((row as any)[wstID] === undefined) (row as any)[wstID] = null;
        }
        break;
      case TimesheetRowType.DirectGeneral:
        for (let wstID of directGeneralWorkSubTypeIDs) {
          if (wstID == perDiemSubTypeID) continue;
          if ((row as any)[wstID] === undefined) (row as any)[wstID] = null;
        }
        break;
    }
  }
  return rows;
}

type TimesheetWithTimesheetRows = Timesheet & {
  timesheetRows: TimesheetRow[];
  isLocked: boolean;
};
export class UpdatableTimesheetWithTimesheetRows implements TimesheetWithTimesheetRows {
  id = undefined as string | undefined;
  timesheetNumber: number | undefined;
  lemID: string | null | undefined;
  ownerID = undefined as string | undefined;
  contractorID = undefined as string | undefined;
  day = undefined as Date | undefined;
  isNightShift: boolean | undefined;

  timesheetTypeID: TimesheetType | undefined;
  timesheetStatusID = undefined as TimesheetStatus | undefined;
  submittedBy: string | null | undefined;
  submittedTo: string | null | undefined;
  submittedOn: Date | null | undefined;
  reviewDeclineReason: string | undefined;
  reviewCancelReason: string | undefined;
  reviewStatusChangeTime: Date | undefined;
  reviewApprovalIsAutomatic: boolean | undefined;
  reviewApprovedBy: string | null | undefined;

  created = undefined as Date | undefined;
  createdBy = undefined as string | null | undefined;
  updated = undefined as Date | null | undefined;
  updatedBy = undefined as string | null | undefined;
  archivedDate = undefined as Date | null | undefined;

  ownerName = "" as string;
  contractorName = "" as string;

  statusLogs = [] as TimesheetStatusLogWithDetails[] | undefined;
  lastStatusLog = {} as TimesheetStatusLogWithDetails | undefined;

  currentUserPermissions = {} as SummarizedTimesheetPermissions;

  initialEntries = [] as TimesheetEntryWithDetails[];
  timesheetRows = [] as TimesheetRow[];

  explanations: Array<TimesheetExplanationWithWorkOrderDetails>;
  initialExplanations: Array<TimesheetExplanationWithWorkOrderDetails>;

  originalIsNightShift: boolean | undefined;

  constructor(
    timesheet: Timesheet & {
      currentUserPermissions: SummarizedTimesheetPermissions;
      statusLogs: Array<TimesheetStatusLogWithDetails> | undefined;
      explanations: Array<TimesheetExplanationWithWorkOrderDetails> | undefined;
      lastStatusLog: TimesheetStatusLogWithDetails;
      contractorName: string;
      ownerName: string;
    },
    entries: TimesheetEntryWithDetails[],
    workTypes: WorkType[],
    workSubTypes: WorkSubType[]
  ) {
    this.id = timesheet.id;
    this.lemID = timesheet.lemID;
    this.timesheetNumber = timesheet.timesheetNumber;

    this.ownerID = timesheet.ownerID;
    this.contractorID = timesheet.contractorID;
    this.day = timesheet.day;
    this.isNightShift = timesheet.isNightShift;

    this.timesheetTypeID = timesheet.timesheetTypeID;
    this.timesheetStatusID = timesheet.timesheetStatusID;
    this.submittedBy = timesheet.submittedBy;
    this.submittedTo = timesheet.submittedTo;
    this.submittedOn = timesheet.submittedOn;
    this.reviewDeclineReason = timesheet.reviewDeclineReason;
    this.reviewCancelReason = timesheet.reviewCancelReason;
    this.reviewStatusChangeTime = timesheet.reviewStatusChangeTime;
    this.reviewApprovalIsAutomatic = timesheet.reviewApprovalIsAutomatic;
    this.reviewApprovedBy = timesheet.reviewApprovedBy;

    this.created = timesheet.created;
    this.createdBy = timesheet.createdBy;
    this.updated = timesheet.updated;
    this.updatedBy = timesheet.updatedBy;
    this.archivedDate = timesheet.archivedDate;
    this.ownerName = timesheet.ownerName;
    this.contractorName = timesheet.contractorName;

    this.statusLogs = timesheet.statusLogs;
    this.lastStatusLog = timesheet.lastStatusLog;

    this.currentUserPermissions = timesheet.currentUserPermissions;

    this.originalIsNightShift = timesheet.isNightShift;

    this.initialEntries = entries;
    this.timesheetRows = SortTimesheetRows(
      ConvertTimesheetEntriesToTimesheetRows(this.timesheetTypeID, entries, workTypes, workSubTypes)
    );
    let timesheetNumberString = `00000${timesheet.timesheetNumber}`.slice(-5);
    this.timesheetRows.forEach(row => (row.timesheetNumber = timesheetNumberString));

    let explanations = timesheet.explanations ?? [];
    this.initialExplanations = explanations?.map(x => ({ ...x }));
    this.explanations = explanations;
  }
  synchronizeTimesheetRows(
    perDiemSubType: WorkSubType | undefined,
    workTypes: WorkType[],
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    let equipmentTypeID = workTypes.find(x => !!x.isEquipment)?.id;
    let equipmentSubType = workSubTypes.find(x => x.workTypeID == equipmentTypeID);
    let entries = this.getEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    );
    this.timesheetRows = SortTimesheetRows(
      ConvertTimesheetEntriesToTimesheetRows(this.timesheetTypeID, entries, workTypes, workSubTypes)
    );
  }

  get nextRowNumber(): number {
    let rows = this.timesheetRows;
    if (!rows?.length) return 1;

    let rowNumbers = rows.map(x => x.rowNumber ?? 0);
    return Math.max(...rowNumbers) + 1;
  }

  get isLocked() {
    return (
      this.timesheetStatusID == TimesheetStatus.Approved ||
      this.timesheetStatusID == TimesheetStatus.Submitted ||
      this.timesheetStatusID == TimesheetStatus.Cancelled
    );
  }
  get isNew() {
    return !this.id?.length;
  }
  /// True if the properties of the timesheet itself have been modified
  get isModified() {
    return this.isNightShiftModified;
  }
  get modifiedData() {
    let isNightShift = this.isNightShiftModified ? this.isNightShift : undefined;
    return {
      isNightShift: isNightShift
    } as Timesheet;
  }
  get isNightShiftModified() {
    return this.originalIsNightShift != this.isNightShift;
  }
  get explanationsModified() {
    return !CompareExplanationArrays(this.explanations, this.initialExplanations);
  }

  /// True if the timesheet AND/OR CHILD ENTRIES have any changes to be saved, including being new
  checkIsDirty(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return (
      this.isNew ||
      this.isNightShiftModified ||
      this.explanationsModified ||
      this.checkHasRemovedEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      ) ||
      this.checkHasModifiedEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      ) ||
      this.checkHasNewEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      )
    );
  }
  getEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return ConvertTimesheetRowsToTimesheetEntries(
      this,
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    );
  }
  getSanitizedEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return this.getEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).map(x => ({
      ...x,
      regularTime: SanitizeNumericValue(x.regularTime),
      overTime: SanitizeNumericValue(x.overTime),
      doubleTime: SanitizeNumericValue(x.doubleTime),
      units: SanitizeNumericValue(x.units)
    }));
  }
  checkHasNewEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return (
      this.getEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      ).findIndex(x => x.isNew) !== -1
    );
  }
  getNewEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ): UpdatableTimesheetEntryWithDetails[] {
    return this.getEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).filter(x => x.isNew);
  }
  getSanitizedNewEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ): UpdatableTimesheetEntryWithDetails[] {
    return this.getNewEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).map(
      x =>
        ({
          ...x,
          ...x.modifiedData,
          id: undefined,
          created: undefined
        } as UpdatableTimesheetEntryWithDetails)
    );
  }
  checkHasModifiedEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return (
      this.getEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      ).findIndex(x => !x.isNew && x.isDirty) !== -1
    );
  }
  getModifiedEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return this.getEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).filter(x => !x.isNew && x.isDirty);
  }
  getModifiedExistingEntryData(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return this.getModifiedEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).map(
      x =>
        ({
          ...x.modifiedData,
          id: x.id,
          timesheetID: x.timesheetID
        } as TimesheetEntryWithDetails)
    );
  }
  checkHasRemovedEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return (
      this.getRemovedEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      ).length > 0
    );
  }
  getRemovedEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    let currentEntries = this.getEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    );
    let currentEntryIDs = currentEntries.filter(x => !!x.id).map(x => x.id!);
    let removedEntries = this.initialEntries.filter(x => !currentEntryIDs.includes(x.id!));
    return removedEntries;
  }
  getRemovedEntryIDs(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return this.getRemovedEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).map(x => x.id!);
  }
}
type TimesheetWithEntries = TimesheetWithDetails & {
  entries: UpdatableTimesheetEntryWithDetails[];
  removedEntryIDs: string[];
  isLocked: boolean;
};
export class UpdatableTimesheetWithEntries implements TimesheetWithEntries {
  id = undefined as string | undefined;
  lemID: string | null | undefined;
  timesheetNumber: number | undefined;
  associatedToLemNumber: string;
  hasEntriesMissingCostCode: boolean | null;

  ownerID = undefined as string | undefined;
  contractorID = undefined as string | undefined;
  day = undefined as Date | undefined;
  isNightShift: boolean | undefined;
  originalIsNightShift: boolean | undefined;

  timesheetTypeID: TimesheetType | undefined;
  timesheetStatusID = undefined as TimesheetStatus | undefined;
  submittedBy: string | null | undefined;
  submittedTo: string | null | undefined;
  submittedOn: Date | null | undefined;
  reviewDeclineReason: string | undefined;
  reviewCancelReason: string | undefined;
  reviewStatusChangeTime: Date | undefined;
  reviewApprovalIsAutomatic: boolean | undefined;
  reviewApprovedBy: string | null | undefined;

  created = undefined as Date | undefined;
  createdBy = undefined as string | null | undefined;
  updated = undefined as Date | null | undefined;
  updatedBy = undefined as string | null | undefined;
  archivedDate = undefined as Date | null | undefined;

  creatorID: string | null;
  creatorName: string;
  ownerName = "" as string;
  ownerClassificationName: string;
  ownerClassificationAlias: string;
  contractorName = "" as string;
  submittedByName: string;
  submittedToName: string;
  reviewApprovedByName: string;

  entryCount = null as number | null;
  totalRegularTime = null as number | null;
  totalOverTime = null as number | null;
  totalDoubleTime = null as number | null;
  totalUnits = null as number | null;

  entries = [] as UpdatableTimesheetEntryWithDetails[];
  removedEntryIDs = [] as string[];

  explanations: Array<TimesheetExplanationWithWorkOrderDetails>;

  statusLogs = [] as TimesheetStatusLogWithDetails[];
  lastStatusLog = {} as TimesheetStatusLogWithDetails;

  currentUserPermissions = {} as SummarizedTimesheetPermissions;

  constructor(
    timesheet: TimesheetWithDetails,
    entries?: UpdatableTimesheetEntryWithDetails[],
    removedEntryIDs?: string[]
  ) {
    this.id = timesheet.id;
    this.lemID = timesheet.lemID;
    this.timesheetNumber = timesheet.timesheetNumber;
    this.associatedToLemNumber = timesheet.associatedToLemNumber;
    this.hasEntriesMissingCostCode = timesheet.hasEntriesMissingCostCode;

    this.ownerID = timesheet.ownerID;
    this.contractorID = timesheet.contractorID;
    this.day = timesheet.day;
    this.isNightShift = timesheet.isNightShift;

    this.timesheetTypeID = timesheet.timesheetTypeID;
    this.timesheetStatusID = timesheet.timesheetStatusID;
    this.submittedBy = timesheet.submittedBy;
    this.submittedTo = timesheet.submittedTo;
    this.submittedOn = timesheet.submittedOn;
    this.reviewDeclineReason = timesheet.reviewDeclineReason;
    this.reviewCancelReason = timesheet.reviewCancelReason;
    this.reviewStatusChangeTime = timesheet.reviewStatusChangeTime;
    this.reviewApprovalIsAutomatic = timesheet.reviewApprovalIsAutomatic;
    this.reviewApprovedBy = timesheet.reviewApprovedBy;

    this.created = timesheet.created;
    this.createdBy = timesheet.createdBy;
    this.updated = timesheet.updated;
    this.updatedBy = timesheet.updatedBy;
    this.archivedDate = timesheet.archivedDate;

    this.creatorID = timesheet.creatorID;
    this.creatorName = timesheet.creatorName;
    this.ownerName = timesheet.ownerName;
    this.ownerClassificationName = timesheet.ownerClassificationName;
    this.ownerClassificationAlias = timesheet.ownerClassificationAlias;
    this.contractorName = timesheet.contractorName;
    this.submittedByName = timesheet.submittedByName;
    this.submittedToName = timesheet.submittedToName;
    this.reviewApprovedByName = timesheet.reviewApprovedByName;

    this.entryCount = timesheet.entryCount;
    this.totalRegularTime = timesheet.totalRegularTime;
    this.totalOverTime = timesheet.totalOverTime;
    this.totalDoubleTime = timesheet.totalDoubleTime;
    this.totalUnits = timesheet.totalUnits;

    this.statusLogs = timesheet.statusLogs;
    this.lastStatusLog = timesheet.lastStatusLog;

    this.currentUserPermissions = timesheet.currentUserPermissions;

    this.originalIsNightShift = timesheet.isNightShift;

    this.entries = entries ?? [];
    this.removedEntryIDs = removedEntryIDs ?? [];

    this.explanations = timesheet.explanations;
  }
  get isLocked() {
    return (
      this.timesheetStatusID == TimesheetStatus.Approved ||
      this.timesheetStatusID == TimesheetStatus.Cancelled ||
      this.timesheetStatusID == TimesheetStatus.Submitted
    );
  }
  get isNew() {
    return !this.id?.length;
  }
  get hasRemovedEntries() {
    return this.removedEntryIDs.length > 0;
  }
  get hasModifiedEntries() {
    return this.entries.findIndex(x => x.isDirty) !== -1;
  }
  get hasNewEntries() {
    return this.entries.findIndex(x => x.isNew) !== -1;
  }
  get modifiedExistingEntryData() {
    return this.entries
      .filter(x => x.isDirty)
      .map(
        x =>
          ({
            ...x.modifiedData,
            id: x.id,
            timesheetID: x.timesheetID
          } as TimesheetEntryWithDetails)
      );
  }
  get isNightShiftModified() {
    return this.originalIsNightShift != this.isNightShift;
  }
  get isDirty() {
    // console.log(
    //   `isDirty isNew: ${this.isNew}, hasRemovedEntries: ${this.hasRemovedEntries}, hasModifiedEntries: ${this.hasModifiedEntries}, hasNewEntries: ${this.hasNewEntries}`
    // );
    return (
      this.isNew ||
      this.isNightShiftModified ||
      this.hasRemovedEntries ||
      this.hasModifiedEntries ||
      this.hasNewEntries
    );
  }
}
export class UpdatableTimesheetEntryWithDetails implements TimesheetEntryWithDetails {
  contractorName: string;
  employeeName: string;
  employeeCode: string;
  workOrderNumber: string;
  workOrderClientWorkOrderNumber: string;
  workOrderChangeOrderNumber: string;
  workOrderReworkNumber: string;
  workOrderType: number | null;
  scaffoldNumber: string;
  areaName: string;
  subAreaName: string;
  workTypeName: string;
  workSubTypeName: string;
  classificationName: string;
  classificationAlias: string;
  costCodeName: string;
  costCodeOverrideName: string;
  id = undefined as string | undefined;
  timesheetID = undefined as string | undefined;
  isCorrectionEntry: boolean | undefined;
  rowNumber: number | undefined;
  workOrderID = undefined as string | null | undefined;
  scaffoldID = undefined as string | null | undefined;
  employeeID = undefined as string | undefined;
  areaID = undefined as string | null | undefined;
  subAreaID = undefined as string | null | undefined;
  workTypeID = undefined as string | null | undefined;
  workSubTypeID = undefined as string | null | undefined;
  classificationID = undefined as string | null | undefined;
  costCodeID = undefined as string | null | undefined;
  costCodeIDOverride: string | null | undefined;

  regularTime = undefined as number | null | undefined;
  overTime = undefined as number | null | undefined;
  doubleTime = undefined as number | null | undefined;
  units = undefined as number | null | undefined;

  overridden: boolean | undefined;
  overriddenBy: string | null | undefined;

  created = undefined as Date | undefined;
  createdBy = undefined as string | null | undefined;
  updated = undefined as Date | null | undefined;
  updatedBy = undefined as string | null | undefined;
  archivedDate = undefined as Date | null | undefined;

  originalAreaID = undefined as string | null | undefined;
  originalSubAreaID = undefined as string | null | undefined;
  originalWorkTypeID = undefined as string | null | undefined;
  originalWorkSubTypeID = undefined as string | null | undefined;
  originalCostCodeID = undefined as string | null | undefined;
  originalCostCodeIDOverride: string | null | undefined;
  originalRegularTime = undefined as number | null | undefined;
  originalOverTime = undefined as number | null | undefined;
  originalDoubleTime = undefined as number | null | undefined;
  originalUnits = undefined as number | null | undefined;
  originalRowNumber = undefined as number | null | undefined;
  originalOverridden: boolean | undefined;
  originalOverriddenBy: string | null | undefined;

  constructor(entry: TimesheetEntryWithDetails) {
    this.contractorName = entry.contractorName;
    this.employeeName = entry.employeeName;
    this.employeeCode = entry.employeeCode;
    this.workOrderNumber = entry.workOrderNumber;
    this.workOrderClientWorkOrderNumber = entry.workOrderClientWorkOrderNumber;
    this.workOrderChangeOrderNumber = entry.workOrderChangeOrderNumber;
    this.workOrderReworkNumber = entry.workOrderReworkNumber;
    this.workOrderType = entry.workOrderType;
    this.scaffoldNumber = entry.scaffoldNumber;
    this.areaName = entry.areaName;
    this.subAreaName = entry.subAreaName;
    this.workTypeName = entry.workTypeName;
    this.workSubTypeName = entry.workSubTypeName;
    this.classificationName = entry.classificationName;
    this.classificationAlias = entry.classificationAlias;
    this.costCodeName = entry.costCodeName;
    this.costCodeOverrideName = entry.costCodeOverrideName;
    this.id = entry.id;
    this.timesheetID = entry.timesheetID;
    this.isCorrectionEntry = entry.isCorrectionEntry;
    this.rowNumber = entry.rowNumber;
    this.workOrderID = entry.workOrderID;
    this.scaffoldID = entry.scaffoldID;
    this.employeeID = entry.employeeID;
    this.areaID = entry.areaID;
    this.subAreaID = entry.subAreaID;
    this.workTypeID = entry.workTypeID;
    this.workSubTypeID = entry.workSubTypeID;
    this.classificationID = entry.classificationID;
    this.costCodeID = entry.costCodeID;
    this.costCodeIDOverride = entry.costCodeIDOverride;
    this.regularTime = entry.regularTime;
    this.overTime = entry.overTime;
    this.doubleTime = entry.doubleTime;
    this.units = entry.units;

    this.overridden = entry.overridden;
    this.overriddenBy = entry.overriddenBy;

    this.created = entry.created;
    this.createdBy = entry.createdBy;
    this.updated = entry.updated;
    this.updatedBy = entry.updatedBy;
    this.archivedDate = entry.archivedDate;

    this.originalAreaID = entry.areaID;
    this.originalSubAreaID = entry.subAreaID;
    this.originalWorkTypeID = entry.workTypeID;
    this.originalWorkSubTypeID = entry.workSubTypeID;
    this.originalCostCodeID = entry.costCodeID;
    this.originalCostCodeIDOverride = entry.costCodeIDOverride;
    this.originalRegularTime = entry.regularTime;
    this.originalOverTime = entry.overTime;
    this.originalDoubleTime = entry.doubleTime;
    this.originalUnits = entry.units;
    this.originalRowNumber = entry.rowNumber;
    this.originalOverridden = entry.overridden;
    this.originalOverriddenBy = entry.overriddenBy;
  }
  get employeeWorkOrder() {
    return `${this.workOrderNumber ?? 0}_${this.employeeName}`;
  }
  sanitizeNumericValue(val: number | string | undefined | null): number | null {
    return SanitizeNumericValue(val);
  }
  get isOverriddenModified() {
    return this.originalOverridden != this.overridden;
  }
  get isOverriddenByModified() {
    return (
      this.originalOverriddenBy != this.overriddenBy ||
      (this.isNew && this.overriddenBy !== undefined)
    );
  }
  get isAreaModified() {
    return this.originalAreaID != this.areaID || (this.isNew && this.areaID !== undefined);
  }
  get isSubAreaModified() {
    return this.originalSubAreaID != this.subAreaID || (this.isNew && this.subAreaID !== undefined);
  }
  get workTypeModified() {
    return this.originalWorkTypeID != this.workTypeID || (this.isNew && !!this.workTypeID);
  }
  get workSubTypeModified() {
    return this.originalWorkSubTypeID != this.workSubTypeID || (this.isNew && !!this.workSubTypeID);
  }
  get costCodeModified() {
    return this.originalCostCodeID != this.costCodeID || (this.isNew && !!this.costCodeID);
  }
  get isCostCodeOverrideModified() {
    return (
      this.originalCostCodeIDOverride != this.costCodeIDOverride ||
      (this.isNew && !!this.costCodeIDOverride)
    );
  }
  get regularTimeModified() {
    return (
      this.sanitizeNumericValue(this.originalRegularTime) !=
        this.sanitizeNumericValue(this.regularTime) ||
      (this.isNew && !!this.regularTime && this.regularTime != 0)
    );
  }
  get overTimeModified() {
    return (
      this.originalOverTime != this.overTime ||
      (this.isNew && !!this.overTime && this.overTime != 0)
    );
  }
  get doubleTimeModified() {
    return (
      this.originalDoubleTime != this.doubleTime ||
      (this.isNew && !!this.doubleTime && this.doubleTime != 0)
    );
  }
  get unitsModified() {
    return this.originalUnits != this.units || (this.isNew && !!this.units && this.units != 0);
  }
  get rowNumberModified() {
    return (
      this.originalRowNumber != this.rowNumber ||
      (this.isNew && !!this.rowNumber && this.rowNumber != 0)
    );
  }
  get isNew() {
    return !this.id || this.id.startsWith("new");
  }
  get isDirty() {
    return (
      this.isOverriddenModified ||
      this.isOverriddenByModified ||
      this.isAreaModified ||
      this.isSubAreaModified ||
      this.workTypeModified ||
      this.workSubTypeModified ||
      this.costCodeModified ||
      this.isCostCodeOverrideModified ||
      this.regularTimeModified ||
      this.overTimeModified ||
      this.doubleTimeModified ||
      this.unitsModified
    );
  }
  get modifiedData() {
    let overridden = this.isOverriddenModified ? this.overridden : undefined;
    let overriddenBy = this.isOverriddenByModified ? this.overriddenBy : undefined;
    let areaID = this.isAreaModified ? this.areaID : undefined;
    let subAreaID = this.isSubAreaModified ? this.subAreaID : undefined;
    let workTypeID = this.workTypeModified ? this.workTypeID : undefined;
    let workSubTypeID = this.workSubTypeModified ? this.workSubTypeID : undefined;
    let costCodeID = this.costCodeModified ? this.costCodeID : undefined;
    let costCodeIDOverride = this.isCostCodeOverrideModified ? this.costCodeIDOverride : undefined;
    let regularTime = this.regularTimeModified
      ? this.sanitizeNumericValue(this.regularTime)
      : undefined;
    let overTime = this.overTimeModified ? this.sanitizeNumericValue(this.overTime) : undefined;
    let doubleTime = this.doubleTimeModified
      ? this.sanitizeNumericValue(this.doubleTime)
      : undefined;
    let units = this.unitsModified ? this.sanitizeNumericValue(this.units) : undefined;
    let rowNumber = this.rowNumberModified ? this.rowNumber : undefined;
    return {
      overridden: overridden,
      overriddenBy: overriddenBy,
      areaID: areaID,
      subAreaID: subAreaID,
      workTypeID: workTypeID,
      workSubTypeID: workSubTypeID,
      costCodeID: costCodeID,
      costCodeIDOverride: costCodeIDOverride,
      regularTime: regularTime,
      overTime: overTime,
      doubleTime: doubleTime,
      units: units,
      rowNumber: rowNumber
    };
  }
  get formattedRegularTime() {
    return !!this.regularTime && this.regularTime != 0 ? this.regularTime.toFixed(2) : undefined;
  }
  get formattedOverTime() {
    return !!this.overTime && this.overTime != 0 ? this.overTime.toFixed(2) : undefined;
  }
  get formattedDoubleTime() {
    return !!this.doubleTime && this.doubleTime != 0 ? this.doubleTime.toFixed(2) : undefined;
  }
  get formattedUnits() {
    return !!this.units && this.units != 0 ? this.units.toFixed(2) : undefined;
  }
}

