import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import { showTextPromptDialog } from "../../../../common/client/views/components/TextPromptDialog.vue";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import {
  canOpenFileInNewWindow,
  componentsFromFileName,
  confirmUniqueName,
  FileData
} from "@fd/lib/vue/mixins/fileHandling";
import { Attachment } from "../../dataMixins/attachment";
import { ExternalLink, externalLinkService, scaffoldRequestService } from "../../services";
import { openExternalLinkDetails } from "./ExternalLinkDialog.vue";
import rules from "@fd/lib/vue/rules";
import { addFileDataToWorkOrder, FormattedWorkOrder } from "./KanbanItem.vue";
import fileHandling from "@fd/lib/vue/mixins/fileHandling";

const AttachmentsDialog = FDVue.extend({
  name: "fd-attachments-dialog",

  mixins: [dialogSupport, rules, fileHandling],

  directives: {
    fdColumn: FDColumnDirective
  },

  props: {
    cy: { type: String, default: "fd-attachments-dialog" }
  },

  data: () => ({
    imageName: "",
    imageSource: "",
    editImageSource: undefined as any,

    allowAdd: true,

    allowPreview: true,
    allowDownload: true,
    allowEdit: false,
    allowDelete: false,

    /*** IMAGE EDIT ****/
    newSavedPhotoName: "" as string,
    newFileData: undefined as FileData | undefined,
    editingFileData: undefined as FileData | undefined,
    tablesearchfiles: "",
    workOrder: {} as FormattedWorkOrder,
    items: [] as Attachment[] | undefined
  }),

  computed: {
    canOpenInNewTab(): boolean {
      // Open in new tab is always available, and is the only action available in XS screen size
      return true;
    },
    canPreview(): boolean {
      return (
        this.allowPreview &&
        (this.items?.filter(x => x.isPreviewable)?.length ?? 0) > 0 &&
        this.$vuetify.breakpoint.smAndUp
      );
    },
    canDownload(): boolean {
      return this.allowDownload && this.$vuetify.breakpoint.mdAndUp;
    },
    canEdit(): boolean {
      return this.allowEdit && this.$vuetify.breakpoint.smAndUp;
    },
    canDelete(): boolean {
      return this.allowDelete && this.$vuetify.breakpoint.smAndUp;
    },
    actionCount(): number {
      let count = 1;
      if (this.canPreview) count += 1;
      if (this.canDownload) count += 1;
      if (this.canEdit) count += 1;
      if (this.canDelete) count += 1;
      return count;
    },
    actionColumnClass(): string {
      return `table-${this.actionCount}-actions-column-min-width`;
    }
  },

  methods: {
    async openWithData(
      workOrder: FormattedWorkOrder,
      attachments: Attachment[] | undefined,
      allowAdd: boolean | undefined,
      allowPreview: boolean | undefined,
      allowDownload: boolean | undefined,
      allowEdit: boolean | undefined,
      allowDelete: boolean | undefined
    ) {
      this.workOrder = workOrder;
      this.items = attachments;
      if (allowAdd !== undefined) this.allowAdd = allowAdd;
      if (allowPreview !== undefined) this.allowPreview = allowPreview;
      if (allowDownload !== undefined) this.allowDownload = allowDownload;
      if (allowEdit !== undefined) this.allowEdit = allowEdit;
      if (allowDelete !== undefined) this.allowDelete = allowDelete;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File) {
      let allFiles = this.workOrder.nonPhotoFiles?.concat(this.workOrder.photos ?? []);
      var fileData = await this.optimizedFileDataForUpload(originalFile, allFiles);
      if (!fileData) return;

      await this.saveNewFileData(fileData);
    },

    cancelDialog() {
      this.closeDialog!(false);
    },

    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.file) {
        await this.deleteFile(item.file);
      }
    },

    // Links
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        let currentProcessing = this.processing;
        this.processing = true;
        try {
          await externalLinkService.updateItem(link.id!, {
            ...link,
            name: editedLink.name,
            address: editedLink.address
          });
          link.name = editedLink.name;
          link.address = editedLink.address;

          var snackbarPayload = {
            text: this.$t("scaffold-requests.existing-scaffold-request.update-link-success", [
              link.name
            ]),
            type: "success"
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
        }
      }
    },
    async deleteLink(link: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        await externalLinkService.deleteItem(link.id!);
        this.workOrder.externalLinks.splice(this.workOrder.externalLinks.indexOf(link), 1);

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.delete-link-success", [
            link.name
          ]),
          type: "info",
          undoCallback: async () => {
            await this.saveNewExternalLink(link);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        newLink.scaffoldRequestID = this.workOrder.scaffoldRequestID;
        await externalLinkService.addItem(newLink);

        if (!this.workOrder.externalLinks) this.workOrder.externalLinks = [];
        this.workOrder.externalLinks.push(newLink);
        this.items?.push({
          type: "link",
          name: newLink.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: newLink
        });

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.save-link-success", [
            newLink.name
          ]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },

    // Files & Photos
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName)
          this.newFileData.name = confirmUniqueName(fileName, this.workOrder.nonPhotoFiles!);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.workOrder.nonPhotoFiles!.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: FileData, originalFileName: string) {
      if (!fileData) return;
      if (!this.workOrder.scaffoldRequestID) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
            this.workOrder.scaffoldRequestID,
            fileNameToDownload
          );
        }
        await scaffoldRequestService.uploadScaffoldRequestFile(
          this.workOrder.scaffoldRequestID,
          fileData.name,
          fileData.file as Blob
        );
        await scaffoldRequestService.uploadScaffoldRequestLegacyFile(
          this.workOrder.scaffoldRequestID,
          fileData.name,
          fileData.file as Blob
        );

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await scaffoldRequestService.deleteScaffoldRequestFile(
            this.workOrder.scaffoldRequestID,
            originalFileName
          );
        }
        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.update-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.update-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.workOrder.nonPhotoFiles!);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPhoto) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      if (!!fileData.file) {
        this.editImageSource = URL.createObjectURL(fileData.file);
      } else {
        this.editImageSource = `/services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile?requestId=${this.workOrder.scaffoldRequestID}&fileName=${fileData.name}`;
      }
    },
    async downloadFile(fileData: FileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }
      if (!this.workOrder.scaffoldRequestID) return;

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.workOrder.scaffoldRequestID!,
          fileName
        );
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      if (!this.workOrder.scaffoldRequestID) return;

      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        // the data probably hasn't been downloaded yet
        fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.workOrder.scaffoldRequestID,
          fileData.name
        );
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async viewFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;
      if (!this.workOrder.scaffoldRequestID) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.workOrder.scaffoldRequestID!,
          fileData.name
        );
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.imageSource = URL.createObjectURL(fileData.file);
      } else {
        this.imageSource = `/services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile?requestId=${this.workOrder.scaffoldRequestID}&fileName=${fileData.name}`;
      }
    },
    async deleteFile(fileData: FileData) {
      if (!this.workOrder.scaffoldRequestID) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
            this.workOrder.scaffoldRequestID!,
            fileData.name
          );
        }
        await scaffoldRequestService.deleteScaffoldRequestFile(
          this.workOrder.scaffoldRequestID!,
          fileData.name
        );

        this.workOrder.nonPhotoFiles!.splice(this.workOrder.nonPhotoFiles!.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.delete-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.delete-file-success", [
              fileData.name
            ]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      if (!fileData) return;
      if (!this.workOrder.scaffoldRequestID) return;

      this.processing = true;
      try {
        await scaffoldRequestService.uploadScaffoldRequestFile(
          this.workOrder.scaffoldRequestID,
          fileData.name,
          fileData.file as Blob
        );
        await scaffoldRequestService.uploadScaffoldRequestLegacyFile(
          this.workOrder.scaffoldRequestID,
          fileData.name,
          fileData.file as Blob
        );

        addFileDataToWorkOrder(this.workOrder, fileData);
        if (fileData.isPhoto) {
          this.newSavedPhotoName = fileData.name;
        } else {
          this.newSavedPhotoName = "";
          this.items?.push({
            type: "file",
            name: fileData.name,
            isPhoto: fileData.isPreviewable ?? false,
            isPreviewable: fileData.isPreviewable ?? false,
            canOpenInNew: canOpenFileInNewWindow(fileData.name),
            file: fileData
          } as Attachment);
        }

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.save-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.save-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  }
});

export default AttachmentsDialog;

export async function openAttachmentsDialog(
  workOrder: FormattedWorkOrder,
  options: {
    attachments?: Attachment[];
    allowAdd?: boolean | undefined;
    allowPreview?: boolean | undefined;
    allowDownload?: boolean | undefined;
    allowEdit?: boolean | undefined;
    allowDelete?: boolean | undefined;
  }
): Promise<boolean> {
  let dialog = createDialog(AttachmentsDialog);
  return await dialog.openWithData(
    workOrder,
    options.attachments,
    options.allowAdd,
    options.allowPreview,
    options.allowDownload,
    options.allowEdit,
    options.allowDelete
  );
}

