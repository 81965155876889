import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { ScaffoldTypeModifier } from "../../../services";

const ScaffoldTypeModifierNewDialog = FDVue.extend({
  name: "fd-scaffold-type-modifier-new-dialog",

  mixins: [dialogSupport, rules],

  components: {},

  data: function() {
    return {
      saving: false,
      scaffoldTypeModifier: {
        name: "",
        modifier: 0
      } as ScaffoldTypeModifier
    };
  },

  computed: {},

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        await this.addScaffoldTypeModifier({
          ...this.scaffoldTypeModifier,
          modifier: !!this.scaffoldTypeModifier.modifier
            ? +this.scaffoldTypeModifier.modifier
            : undefined
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addScaffoldTypeModifier: "ADD_SCAFFOLD_TYPE_MODIFIER"
    })
  }
});

export default ScaffoldTypeModifierNewDialog;

export async function createNewScaffoldTypeModifier(): Promise<boolean> {
  let dialog = createDialog(ScaffoldTypeModifierNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.showDialog!();
}

