var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        width: "1024px",
        "data-cy": "timesheethistorydialog"
      },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("timesheets.corrections.dialog-title")))]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-card-subtitle",
                { staticClass: "pb-sm-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0 order-sm-1 order-2",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "pa-0 ma-0",
                              attrs: { align: "center", justify: "center" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-5",
                                  attrs: {
                                    small: "",
                                    rounded: "",
                                    outlined: "",
                                    disabled: _vm.processing,
                                    color: "#7a7a7a"
                                  },
                                  on: { click: _vm.reloadTableData }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.mdAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  ),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.mdAndUp
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$t("common.reload")))
                                      ])
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-text-field", {
                                class: [
                                  _vm.$vuetify.breakpoint.sm
                                    ? "fd-restrict-clearable-position"
                                    : "",
                                  "pt-0",
                                  "mt-0",
                                  "fd-table-search-input"
                                ],
                                attrs: {
                                  "data-cy": "tablesearch",
                                  "append-icon": "search",
                                  label: _vm.$t("common.search"),
                                  "single-line": "",
                                  "hide-details": "",
                                  clearable: "",
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.tablesearch,
                                  callback: function($$v) {
                                    _vm.tablesearch = $$v
                                  },
                                  expression: "tablesearch"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0 order-sm-2 order-1",
                          attrs: { cols: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("fd-date-range-picker", {
                            attrs: {
                              dense: "",
                              "text-field-class":
                                "fd-filter-date-picker-margins",
                              min: _vm.minDate,
                              max: _vm.maxDate,
                              loading: _vm.processing,
                              disabled: _vm.processing,
                              "hide-details": "",
                              outlined: false,
                              presets: _vm.dateRangePresetOptions
                            },
                            on: {
                              "input:fromDate": _vm.fromDateChanged,
                              "input:toDate": _vm.toDateChanged
                            },
                            model: {
                              value: _vm.showArchivedDateRange,
                              callback: function($$v) {
                                _vm.showArchivedDateRange = $$v
                              },
                              expression: "showArchivedDateRange"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-data-table", {
                directives: [
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:timesheetNumberString",
                    value: _vm.$t("timesheets.number-label"),
                    expression: "$t('timesheets.number-label')",
                    arg: "timesheetNumberString"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:day",
                    value: _vm.$t("timesheets.day-label"),
                    expression: "$t('timesheets.day-label')",
                    arg: "day"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:ownerName",
                    value: _vm.$t("timesheets.owner-label"),
                    expression: "$t('timesheets.owner-label')",
                    arg: "ownerName"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:timesheetType",
                    value: _vm.$t("timesheets.timesheet-type-label"),
                    expression: "$t('timesheets.timesheet-type-label')",
                    arg: "timesheetType"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:entryCount",
                    value: _vm.$t("timesheets.list.entry-count-column-label"),
                    expression:
                      "$t('timesheets.list.entry-count-column-label')",
                    arg: "entryCount"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalRegularTime",
                    value: _vm.$t(
                      "timesheets.list.total-regular-time-column-label"
                    ),
                    expression:
                      "$t('timesheets.list.total-regular-time-column-label')",
                    arg: "totalRegularTime"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalOverTime.hidden",
                    value: _vm.$t(
                      "timesheets.list.total-over-time-column-label"
                    ),
                    expression:
                      "$t('timesheets.list.total-over-time-column-label')",
                    arg: "totalOverTime",
                    modifiers: { hidden: true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalPerDiemUnits",
                    value: _vm.$t(
                      "timesheets.list.total-per-diem-units-column-label"
                    ),
                    expression:
                      "$t('timesheets.list.total-per-diem-units-column-label')",
                    arg: "totalPerDiemUnits"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalDoubleTime",
                    value: _vm.$t(
                      "timesheets.list.total-double-time-column-label"
                    ),
                    expression:
                      "$t('timesheets.list.total-double-time-column-label')",
                    arg: "totalDoubleTime"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalEquipmentUnits",
                    value: _vm.$t(
                      "timesheets.list.total-equipment-units-column-label"
                    ),
                    expression:
                      "$t('timesheets.list.total-equipment-units-column-label')",
                    arg: "totalEquipmentUnits"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:isNightShift",
                    value: _vm.$t("timesheets.is-night-shift-label"),
                    expression: "$t('timesheets.is-night-shift-label')",
                    arg: "isNightShift"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:action.no-sort.class_fd-actions-cell",
                    arg: "action",
                    modifiers: {
                      "no-sort": true,
                      "class_fd-actions-cell": true
                    }
                  }
                ],
                staticClass: "fd-timesheets-list",
                attrs: {
                  items: _vm.timesheets,
                  "sort-by": "day",
                  "sort-desc": true,
                  search: _vm.tablesearch,
                  loading: _vm.processing,
                  "loading-text": _vm.$t("common.table-loading-message"),
                  "mobile-breakpoint": "0"
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.day",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.formattedDay) +
                            "\n        "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.isNightShift",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("fd-checkbox", {
                          attrs: { readonly: true },
                          model: {
                            value: item.isNightShift,
                            callback: function($$v) {
                              _vm.$set(item, "isNightShift", $$v)
                            },
                            expression: "item.isNightShift"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.timesheetType",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("timesheets.types." + item.timesheetTypeID)
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.entryCount",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("fd-value-display", {
                          attrs: {
                            value: item.entryCount,
                            "no-value-key": "timesheets.list.no-entries-value"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.totalRegularTime",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("fd-value-display", {
                          attrs: {
                            value: item.formattedTotalRegularTime,
                            "no-value-key": "timesheets.list.no-time-value"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.totalOverTime",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("fd-value-display", {
                          attrs: {
                            value: item.formattedTotalOverTime,
                            "no-value-key": "timesheets.list.no-time-value"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.totalDoubleTime",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.timesheetTypeID == 2
                          ? _c("fd-value-display", {
                              attrs: {
                                value: item.formattedTotalDoubleTime,
                                "no-value-key": "timesheets.list.no-time-value"
                              }
                            })
                          : _c("fd-value-display", {
                              attrs: {
                                "no-value-key":
                                  "timesheets.list.no-time-entry-value"
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "item.totalUnits",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("fd-value-display", {
                          attrs: {
                            value: item.formattedTotalUnits,
                            "no-value-key": "timesheets.list.no-time-value"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.totalPerDiemUnits",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.timesheetTypeID != 2
                          ? _c(
                              "span",
                              [
                                _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedTotalUnits,
                                    "no-value-key":
                                      "timesheets.list.no-time-value"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("fd-value-display", {
                              attrs: {
                                "no-value-key":
                                  "timesheets.list.no-time-entry-value"
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "item.totalEquipmentUnits",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.timesheetTypeID == 2
                          ? _c(
                              "span",
                              [
                                _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedTotalUnits,
                                    "no-value-key":
                                      "timesheets.list.no-time-value"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("fd-value-display", {
                              attrs: {
                                "no-value-key":
                                  "timesheets.list.no-time-entry-value"
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "item.action",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "table-action-column-min-width" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  disabled: _vm.processing
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.correctTimesheet(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    fas fa-highlighter-line\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "timesheets.corrections.correct-button-label"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: _vm.inlineMessage.type } },
                        [
                          _c(
                            "span",
                            { staticStyle: { "white-space": "pre-line" } },
                            [_vm._v(_vm._s(_vm.inlineMessage.message))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    disabled: _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.cancelDialog }
                },
                [_vm._v(_vm._s(_vm.$t("common.cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }