import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { mapMutations } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { lemsService, LemStatus, LEMWithDetails } from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { TranslateResult } from "vue-i18n";
import { UpdatableLEMEntryWithDetails, UpdatableLEMWithEntries } from "../utils/lem";
import { VDataTable } from "@fd/lib/vue/types";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import rules from "@fd/lib/vue/rules";
import { showLEMStatusHistoryDialog } from "./components/dialogs/LemStatusHistoryDialog.vue";
import { openLemTimesheetSearchDialog } from "./components/dialogs/LemTimesheetSearchDialog.vue";

type EntryGroupingType = "groupnone" | "grouptimesheetforeman";
export default FDVue.extend({
  name: "fd-lem-existing",

  mixins: [serviceErrorHandling, rules],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      // *** GLOBAL ***
      slidein: false,
      selectedEntryGroupingType: "grouptimesheetforeman" as EntryGroupingType,
      tablepage: 1,

      submitting: false,
      approving: false,
      declining: false,

      isReadonly: true,

      lem: {} as UpdatableLEMWithEntries
    };
  },

  computed: {
    entryGroupingTypeOptions(): { text: TranslateResult; value: string }[] {
      return [
        { text: this.$t("lems.existing.group-by-none-radio"), value: "groupnone" },
        { text: this.$t("lems.existing.group-by-person-radio"), value: "groupperson" },
        { text: this.$t("lems.existing.group-by-work-order-radio"), value: "groupworkorder" },
        {
          text: this.$t("lems.existing.group-by-person-work-order-radio"),
          value: "groupemployeeworkorder"
        },
        { text: this.$t("lems.existing.group-by-cost-code-radio"), value: "groupcostcode" },
        { text: this.$t("lems.existing.group-by-submitter-radio"), value: "groupsubmitter" },
        { text: this.$t("lems.existing.group-by-foreman-radio"), value: "groupforeman" }
      ];
    },
    lemIsSubmitted(): boolean {
      return this.lem.lemStatusID == LemStatus.Submitted;
    },
    lemIsDeclined(): boolean {
      return this.lem.lemStatusID == LemStatus.Declined;
    },
    lemDeclineComments(): string | undefined {
      return this.lem?.lastStatusLog?.comments;
    },
    lemIsApproved(): boolean {
      return this.lem.lemStatusID == LemStatus.Approved;
    },
    lemIsCancelled(): boolean {
      return this.lem.lemStatusID == LemStatus.Cancelled;
    },
    lemCanBeSubmitted(): boolean {
      return (
        (this.lem.lemStatusID == LemStatus.New || this.lem.lemStatusID == LemStatus.Declined) &&
        this.lem.currentUserPermissions.canSubmit
      );
    },
    lemCanBeApproved(): boolean {
      return (
        this.lem.lemStatusID == LemStatus.Submitted && this.lem.currentUserPermissions.canApprove
      );
    },
    lemCanBeDeclined(): boolean {
      return (
        this.lem.lemStatusID == LemStatus.Submitted && this.lem.currentUserPermissions.canApprove
      );
    },
    timeColumns(): string[] {
      return ["regularTime", "overTime", "units"];
    },
    visibleColumns(): string[] {
      let columns = ["expander"];
      if (this.selectedEntryGroupingType != "grouptimesheetforeman")
        columns.push("timesheetOwnerName");

      columns.push("employeeName");
      if (this.$vuetify.breakpoint.mdAndUp) {
        columns.push("classificationName");
        columns.push("workTypeName");
      }
      columns.push("workSubTypeName");

      columns.push("costCodeName");

      columns.push("workOrderNumber");
      if (this.$vuetify.breakpoint.lgAndUp) {
        columns.push("areaName");
      }

      columns = columns.concat(this.timeColumns);

      columns.push("action");
      return columns;
    },
    numCols(): number {
      return this.visibleColumns.length;
    },
    preTimeNumCols(): number {
      return this.numCols - this.timeColumns.length - this.postTimeNumCols;
    },
    postTimeNumCols(): number {
      let cols = 1;
      return cols;
    },
    itemsPerPage(): number {
      if (this.selectedEntryGroupingType == "grouptimesheetforeman") return -1;
      return 25;
    },
    itemsPerPageOptions(): number[] {
      if (this.selectedEntryGroupingType == "grouptimesheetforeman") return [-1];
      return [5, 10, 15, 25, 50, -1];
    },
    formattedDay(): string {
      return DateUtil.stripTimeFromLocalizedDateTime(this.lem.day);
    },
    groupColumn(): string | undefined {
      if (this.selectedEntryGroupingType == "grouptimesheetforeman")
        return "timesheetNumberAndOwnerName";
      return undefined;
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    async _initialize() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/lems") {
        this.notifyNewBreadcrumb({
          text: this.$t("lems.list.title"),
          to: "/lems",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: ``,
          to: `/lems/${this.$route.params.id}`
        });
      }

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "lems-existing",
        parentalContext: "lems",
        searchStringForFiltering: ""
      });

      this.processing = true;
      try {
        this.lem.id = this.$route.params.id;
        let lem = await lemsService.getByID(this.$route.params.id);
        this.lem = new UpdatableLEMWithEntries(lem);
        this.isReadonly = this.lem.isLocked;
        await this.loadLEMEntries();
        if (!this.lem.entries?.length) {
          this.openNewLemEntryDialog();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    sum(items: UpdatableLEMEntryWithDetails[], propName: string): string | undefined {
      let result = items.reduce((a, b) => a + ((b as any)[propName] ?? 0), 0);
      return !result ? undefined : result.toFixed(2);
    },
    // This method determines if the row in the data-table should be colored to represent if it is "Urgent".
    lemEntryClassName(item: any) {
      return item.isCorrectionEntry ? "fd-correction-table-row-background" : "";
    },
    labelForGroup(groupName: string): TranslateResult | string | undefined {
      if (this.selectedEntryGroupingType == "grouptimesheetforeman") {
        let parts = groupName.split("_");
        let timesheetNumberString = `00000${parts[0]}`.slice(-5);
        let ownerName = parts[1];
        return this.$t("lems.existing.timesheet-number-and-owner-group-label", [
          ownerName,
          timesheetNumberString
        ]);
      }
      return undefined;
    },
    cancel() {
      this.$router.push("/lems");
    },
    async submitLEM() {
      if (!this.lem.currentUserPermissions.canSubmit) return;
      this.processing = true;
      this.submitting = true;
      try {
        await lemsService.submitLEM(this.lem.id!);
        this.lem.lemStatusID = LemStatus.Submitted;
        this.isReadonly = this.lem.isLocked;

        var snackbarPayload = {
          text: this.$t("lems.list.submit-success", [this.lem.legacyID, this.formattedDay]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.push("/lems");
      } catch (error) {
        if ((error as any).statusCode == 422) {
          var snackbarPayload = {
            text: this.$t("lems.list.submit-validation-failed", [
              this.lem.legacyID,
              this.formattedDay
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },
    async declineLEM() {
      if (!this.lem.currentUserPermissions.canApprove) return;
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("lems.approval.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          this.declining = false;
          this.processing = false;
          return false;
        }

        await lemsService.declinePendingLEM(this.lem.id!, reason);
        this.lem.lemStatusID = LemStatus.Declined;
        this.isReadonly = this.lem.isLocked;

        var snackbarPayload = {
          text: this.$t("lems.approval.decline-success", [this.lem.legacyID, this.formattedDay]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.push("/lems");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    },
    async approveLEM() {
      if (!this.lem.currentUserPermissions.canApprove) return;
      this.processing = true;
      this.approving = true;
      try {
        await lemsService.approvePendingLEM(this.lem.id!);
        this.lem.lemStatusID = LemStatus.Approved;
        this.isReadonly = this.lem.isLocked;

        var snackbarPayload = {
          text: this.$t("lems.approval.approve-success", [this.lem.legacyID, this.formattedDay]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.push("/lems");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },
    async showStatusLogDialog() {
      await showLEMStatusHistoryDialog(this.lem?.statusLogs ?? []);
    },
    async removeEntriesForTimesheet(groupName: string) {
      let parts = groupName.split("_");
      let ownerName = parts[1];
      let foundEntry = this.lem.entries.find(x => x.timesheetOwnerName == ownerName);
      if (!foundEntry?.sourceTimesheetID) return;

      this.processing = true;
      try {
        await lemsService.removeEntriesFromTimesheetInLEMWithID(
          this.lem.id!,
          foundEntry.sourceTimesheetID
        );
        await this.loadLEMEntries();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openNewLemEntryDialog() {
      if (await openLemTimesheetSearchDialog(this.lem)) {
        await this.loadLEMEntries();
      }
    },
    async loadLEMEntries() {
      let lemEntries = await lemsService.getEntriesForLEMID(this.$route.params.id);
      this.lem.entries = lemEntries.map(x => new UpdatableLEMEntryWithDetails(x));
    },

    // *** INLINE NAVIGATION ***
    getFieldRef(fieldName: string, item: UpdatableLEMEntryWithDetails) {
      let id = item.id!.replace("-", "").replace("-", "");
      return `${fieldName}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(
      fieldName: string,
      index: number,
      visibleItems: UpdatableLEMEntryWithDetails[]
    ) {
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(fieldName: string, item: UpdatableLEMEntryWithDetails) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.tablepage <= 1) return;
        this.tablepage -= 1;

        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(
            fieldName,
            datatable.computedItemsPerPage,
            visibleItems
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex, visibleItems);
    },
    async selectNextField(fieldName: string, item: UpdatableLEMEntryWithDetails) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex >= visibleItems.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(this.lem.entries.length / datatable.computedItemsPerPage);

        if (this.tablepage >= maxPage) return;
        this.tablepage += 1;

        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, 0, visibleItems);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex, visibleItems);
    },
    async enterPressed(e: KeyboardEvent, fieldName: string, item: UpdatableLEMEntryWithDetails) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    }
  },

  watch: {
    lem() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/lems") {
        this.notifyNewBreadcrumb({
          text: this.$t("lems.list.title"),
          to: "/lems",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: !!this.lem.id ? `LEM #${this.lem.legacyID}` : this.$t("lems.existing.new-lem"),
        to: `/lems/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    console.log(`created`);
    this._initialize();
  }
});

