var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        width: "950px",
        persistent: "",
        "data-cy": "materialOrdernewdialog"
      },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "materialOrdernew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.dialogTitle))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-form",
            { ref: "form", on: { submit: _vm.onSubmit } },
            [
              _c(
                "v-row",
                {
                  staticClass: "pt-1",
                  attrs: { "no-gutters": "", align: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between px-3 pb-0 pt-5",
                      attrs: { align: "center", cols: "12", sm: "6" }
                    },
                    [
                      _c("fd-date-picker", {
                        attrs: {
                          label: _vm.$t("material-orders.order-date-label"),
                          disabled: _vm.processing,
                          rules: [_vm.rules.required]
                        },
                        model: {
                          value: _vm.materialOrderDate,
                          callback: function($$v) {
                            _vm.materialOrderDate = $$v
                          },
                          expression: "materialOrderDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between px-3 pb-0",
                      class: { "pt-5": _vm.$vuetify.breakpoint.smAndUp },
                      attrs: { align: "center", cols: "12", sm: "6" }
                    },
                    [
                      _c("fd-date-picker", {
                        attrs: {
                          label: _vm.$t("material-orders.expected-date-label"),
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.expectedDate,
                          callback: function($$v) {
                            _vm.expectedDate = $$v
                          },
                          expression: "expectedDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between px-3 py-0",
                      attrs: { align: "center", cols: "12", sm: "6" }
                    },
                    [
                      _vm.directionIsMaterialReturn
                        ? _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.availableSuppliers,
                              label: _vm.$t(
                                "material-orders.to-supplier-label"
                              ),
                              "item-text": "name",
                              "item-value": "id",
                              disabled: _vm.processing,
                              rules: [_vm.rules.required],
                              "return-object": ""
                            },
                            model: {
                              value: _vm.selectedToSupplier,
                              callback: function($$v) {
                                _vm.selectedToSupplier = $$v
                              },
                              expression: "selectedToSupplier"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.directionIsMaterialRequest
                        ? _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.availableSuppliers,
                              label: _vm.$t(
                                "material-orders.from-supplier-label"
                              ),
                              "item-text": "name",
                              "item-value": "id",
                              disabled: _vm.processing,
                              rules: [_vm.rules.required],
                              "return-object": ""
                            },
                            model: {
                              value: _vm.selectedFromSupplier,
                              callback: function($$v) {
                                _vm.selectedFromSupplier = $$v
                              },
                              expression: "selectedFromSupplier"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between px-3 py-0",
                      attrs: { align: "center", cols: "12", sm: "6" }
                    },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          label: _vm.$t(
                            "material-orders.reference-number-label"
                          ),
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.referenceNumber,
                          callback: function($$v) {
                            _vm.referenceNumber = $$v
                          },
                          expression: "referenceNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between px-3 py-0",
                      attrs: { align: "center", cols: "12" }
                    },
                    [
                      _c("fd-textarea", {
                        attrs: {
                          outlined: "",
                          cy: "instructions",
                          label: _vm.$t("material-orders.instructions-label"),
                          disabled: _vm.processing,
                          rows: "1",
                          "auto-grow": ""
                        },
                        model: {
                          value: _vm.instructions,
                          callback: function($$v) {
                            _vm.instructions = $$v
                          },
                          expression: "instructions"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-expansion-panels",
                {
                  staticStyle: { "border-radius": "0" },
                  attrs: { multiple: "", accordion: "" },
                  model: {
                    value: _vm.openCategoryPanels,
                    callback: function($$v) {
                      _vm.openCategoryPanels = $$v
                    },
                    expression: "openCategoryPanels"
                  }
                },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "fd-count-sheet-group-panel-summary" },
                    [
                      _c(
                        "v-expansion-panel-header",
                        { staticStyle: { "padding-left": "24px !important" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("countsheet.dialog.summary-panel-title")
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        [
                          !_vm.partsForSummary ||
                          _vm.partsForSummary.length == 0
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            "justify-content": "center"
                                          },
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("fd-alert", {
                                            attrs: {
                                              label: _vm.$t(
                                                "material-orders.summary-has-no-parts-message"
                                              ),
                                              type: "hint"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.partsForSummary, function(part) {
                            return _c(
                              "v-row",
                              {
                                key: part.id,
                                staticClass: "fd-part-row",
                                attrs: { "no-gutters": "" }
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "fd-group-part-content" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-1",
                                        attrs: { cols: "7" }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fd-count-sheet-part-code-label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(part.publicID) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fd-count-sheet-part-description-label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          part.description
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                !!part.groupName
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "fd-count-sheet-part-group-label"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              part.groupName.toUpperCase()
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pl-0 py-1",
                                        attrs: { cols: "5" }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pl-1 order-1 order-sm-2",
                                                attrs: { cols: "6" }
                                              },
                                              [
                                                _c("fd-text-field", {
                                                  staticClass:
                                                    "fd-count-sheet-text-field",
                                                  attrs: {
                                                    "data-cy": "summarycount",
                                                    dense: "",
                                                    "hide-details": "",
                                                    disabled: _vm.processing,
                                                    label: _vm.$t(
                                                      "material-orders.count-label"
                                                    ),
                                                    numeric: "",
                                                    "highlight-on-focus": ""
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.partCountValueChanged(
                                                        part
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: part.count,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        part,
                                                        "count",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "part.count"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pl-1 order-1 order-sm-2",
                                                attrs: { cols: "6" }
                                              },
                                              [
                                                _c("fd-text-field", {
                                                  staticClass:
                                                    "fd-count-sheet-text-field",
                                                  attrs: {
                                                    "data-cy":
                                                      "summarybackorder",
                                                    value:
                                                      part.backOrderDetails
                                                        .backOrderCount,
                                                    dense: "",
                                                    "hide-details": "",
                                                    disabled: _vm.processing,
                                                    label: _vm.$t(
                                                      "material-orders.back-ordered-label"
                                                    ),
                                                    numeric: "",
                                                    readonly: true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panel",
                    { staticClass: "fd-count-sheet-group-panel-individual" },
                    [
                      _c(
                        "v-expansion-panel-header",
                        { staticStyle: { "padding-left": "24px !important" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "countsheet.dialog.individual-panel-title"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        [
                          !_vm.visibleCountSheetGroups ||
                          _vm.visibleCountSheetGroups.length == 0
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            "justify-content": "center"
                                          },
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          !_vm.selectedFromSupplier &&
                                          !_vm.selectedToSupplier
                                            ? _c("fd-alert", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "material-orders.new.select-a-supplier-message"
                                                  ),
                                                  type: "hint"
                                                }
                                              })
                                            : _c("fd-alert", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "material-orders.new.selected-supplier-has-no-parts-message"
                                                  ),
                                                  type: "hint"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-container",
                                {
                                  staticClass:
                                    "pt-0 fd-embedded-table-container",
                                  attrs: { fluid: "" }
                                },
                                [
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      staticStyle: { "border-radius": "0" },
                                      attrs: { multiple: "", accordion: "" },
                                      model: {
                                        value: _vm.openIndividualPanels,
                                        callback: function($$v) {
                                          _vm.openIndividualPanels = $$v
                                        },
                                        expression: "openIndividualPanels"
                                      }
                                    },
                                    _vm._l(
                                      _vm.visibleCountSheetGroups,
                                      function(group, index) {
                                        return _c(
                                          "v-expansion-panel",
                                          {
                                            key: index,
                                            staticClass:
                                              "fd-count-sheet-group-panel"
                                          },
                                          [
                                            _c("v-expansion-panel-header", [
                                              _vm._v(" " + _vm._s(group.name))
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "v-expansion-panel-content",
                                              [
                                                !group.parts ||
                                                group.parts.length == 0
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c("v-row", {
                                                              staticStyle: {
                                                                "justify-content":
                                                                  "center"
                                                              },
                                                              attrs: {
                                                                "no-gutters": ""
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm._l(
                                                  group.sortedParts,
                                                  function(part) {
                                                    return _c(
                                                      "v-row",
                                                      {
                                                        key: part.id,
                                                        staticClass:
                                                          "fd-part-row",
                                                        attrs: {
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "fd-group-part-content"
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "py-1",
                                                                attrs: {
                                                                  cols: "7"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    attrs: {
                                                                      "no-gutters":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "fd-count-sheet-part-code-label"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  part.publicID
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "fd-count-sheet-part-description-label"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  part.description
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pl-0 py-1",
                                                                attrs: {
                                                                  cols: "5"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    attrs: {
                                                                      "no-gutters":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "pl-1 order-1 order-sm-2",
                                                                        attrs: {
                                                                          cols:
                                                                            "6"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "fd-text-field",
                                                                          {
                                                                            ref: _vm.fieldRefForPart(
                                                                              "count",
                                                                              part
                                                                            ),
                                                                            refInFor: true,
                                                                            staticClass:
                                                                              "fd-count-sheet-text-field",
                                                                            attrs: {
                                                                              "data-cy":
                                                                                "count",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing,
                                                                              label: _vm.$t(
                                                                                "material-orders.count-label"
                                                                              ),
                                                                              numeric:
                                                                                "",
                                                                              "highlight-on-focus":
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              keydown: [
                                                                                function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    !$event.type.indexOf(
                                                                                      "key"
                                                                                    ) &&
                                                                                    _vm._k(
                                                                                      $event.keyCode,
                                                                                      "up",
                                                                                      38,
                                                                                      $event.key,
                                                                                      [
                                                                                        "Up",
                                                                                        "ArrowUp"
                                                                                      ]
                                                                                    )
                                                                                  ) {
                                                                                    return null
                                                                                  }
                                                                                  return (function(
                                                                                    e
                                                                                  ) {
                                                                                    return _vm.selectPreviousField(
                                                                                      e,
                                                                                      "count",
                                                                                      part
                                                                                    )
                                                                                  })(
                                                                                    $event
                                                                                  )
                                                                                },
                                                                                function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    !$event.type.indexOf(
                                                                                      "key"
                                                                                    ) &&
                                                                                    _vm._k(
                                                                                      $event.keyCode,
                                                                                      "down",
                                                                                      40,
                                                                                      $event.key,
                                                                                      [
                                                                                        "Down",
                                                                                        "ArrowDown"
                                                                                      ]
                                                                                    )
                                                                                  ) {
                                                                                    return null
                                                                                  }
                                                                                  return (function(
                                                                                    e
                                                                                  ) {
                                                                                    return _vm.selectNextField(
                                                                                      e,
                                                                                      "count",
                                                                                      part
                                                                                    )
                                                                                  })(
                                                                                    $event
                                                                                  )
                                                                                },
                                                                                function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    !$event.type.indexOf(
                                                                                      "key"
                                                                                    ) &&
                                                                                    _vm._k(
                                                                                      $event.keyCode,
                                                                                      "enter",
                                                                                      13,
                                                                                      $event.key,
                                                                                      "Enter"
                                                                                    )
                                                                                  ) {
                                                                                    return null
                                                                                  }
                                                                                  return (function(
                                                                                    e
                                                                                  ) {
                                                                                    return _vm.enterPressed(
                                                                                      e,
                                                                                      "count",
                                                                                      part
                                                                                    )
                                                                                  })(
                                                                                    $event
                                                                                  )
                                                                                }
                                                                              ],
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.partCountValueChanged(
                                                                                  part
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                part.count,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  part,
                                                                                  "count",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "part.count"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "pl-1 order-1 order-sm-2",
                                                                        staticStyle: {
                                                                          position:
                                                                            "relative"
                                                                        },
                                                                        attrs: {
                                                                          cols:
                                                                            "6"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "fd-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "fd-count-sheet-text-field",
                                                                            attrs: {
                                                                              "data-cy":
                                                                                "backorder",
                                                                              value:
                                                                                part
                                                                                  .backOrderDetails
                                                                                  .backOrderCount,
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing,
                                                                              label: _vm.$t(
                                                                                "material-orders.back-ordered-label"
                                                                              ),
                                                                              numeric:
                                                                                "",
                                                                              readonly: true
                                                                            }
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        !!part.backOrderDetails &&
                                                                        part
                                                                          .backOrderDetails
                                                                          .orderDetails
                                                                          .length >
                                                                          0
                                                                          ? _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs: {
                                                                                  top:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key:
                                                                                        "activator",
                                                                                      fn: function(
                                                                                        ref
                                                                                      ) {
                                                                                        var on =
                                                                                          ref.on
                                                                                        return [
                                                                                          !!part
                                                                                            .backOrderDetails
                                                                                            .backOrderCount >
                                                                                          0
                                                                                            ? _c(
                                                                                                "v-icon",
                                                                                                _vm._g(
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ml-1",
                                                                                                    staticStyle: {
                                                                                                      position:
                                                                                                        "absolute",
                                                                                                      right:
                                                                                                        "8px",
                                                                                                      top:
                                                                                                        "16px"
                                                                                                    },
                                                                                                    attrs: {
                                                                                                      small:
                                                                                                        "",
                                                                                                      color:
                                                                                                        "black",
                                                                                                      dark:
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  on
                                                                                                ),
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "info"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ]
                                                                                      }
                                                                                    }
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                )
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "v-row",
                                                                                  {
                                                                                    attrs: {
                                                                                      dense:
                                                                                        "",
                                                                                      "no-gutters":
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "material-orders.new.related-orders"
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm._l(
                                                                                  part
                                                                                    .backOrderDetails
                                                                                    .orderDetails,
                                                                                  function(
                                                                                    orderDetails
                                                                                  ) {
                                                                                    return _c(
                                                                                      "v-row",
                                                                                      {
                                                                                        key:
                                                                                          orderDetails.materialOrderID,
                                                                                        staticClass:
                                                                                          "pl-3",
                                                                                        attrs: {
                                                                                          dense:
                                                                                            "",
                                                                                          "no-gutters":
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "material-orders.new.related-order-details",
                                                                                                [
                                                                                                  (
                                                                                                    "00000" +
                                                                                                    orderDetails.materialOrderNumber
                                                                                                  ).slice(
                                                                                                    -5
                                                                                                  ),
                                                                                                  _vm.formatDate(
                                                                                                    orderDetails.materialOrderDate
                                                                                                  ),
                                                                                                  orderDetails.outstandingCount
                                                                                                ]
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                )
                                                                              ],
                                                                              2
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.canOpenBCountDialog
                ? _c(
                    "v-row",
                    { staticClass: "mx-0", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mx-0", attrs: { cols: "12" } },
                        [
                          !_vm.partCountsAreEmpty
                            ? _c("fd-alert", {
                                attrs: {
                                  type: "info",
                                  label: _vm.$t(
                                    "material-orders.new.paste-bcounts-button-disabled-clear-counts-message"
                                  )
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass:
                    "mx-0 mb-3 d-flex d-md-none justify-end align-center v-card__actions"
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "clear-small",
                        text: "",
                        disabled: _vm.processing || _vm.partCountsAreEmpty,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.clearPartCounts }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "material-orders.new.clear-counts-button-label"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "bcount-small",
                        outlined: "",
                        color: "primary",
                        disabled: _vm.processing || !_vm.canOpenBCountDialog,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.openBCountDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "material-orders.new.paste-bcounts-button-label"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-none d-md-flex",
                      attrs: {
                        "data-cy": "clear",
                        text: "",
                        disabled: _vm.processing || _vm.partCountsAreEmpty
                      },
                      on: { click: _vm.clearPartCounts }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "material-orders.new.clear-counts-button-label"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-none d-md-flex",
                      attrs: {
                        "data-cy": "bcount",
                        outlined: "",
                        color: "primary",
                        disabled: _vm.processing || !_vm.canOpenBCountDialog
                      },
                      on: { click: _vm.openBCountDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "material-orders.new.paste-bcounts-button-label"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                    },
                    [_vm._v("\n          |\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        "data-cy": "cancel",
                        disabled: _vm.saving,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "save",
                        color: "primary",
                        disabled: _vm.processing,
                        loading: _vm.saving,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.save-and-close")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }