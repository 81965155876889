import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { mapActions } from "vuex";
import { addDaysToDate, stripTimeFromLocalizedDateTime } from "@fd/lib/client-util/datetime";
import { VDataTable } from "@fd/lib/vue/types";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  Classification,
  contractorService,
  ContractorWithTags,
  CrewWithEmployees,
  TimesheetEntryWithDetails,
  timesheetService,
  TimesheetStatus,
  TimesheetWithDetails,
  PersonWithDetails,
  ProjectLocation,
  projectLocationService,
  WorkOrderWithLegacyDetails,
  WorkSubType,
  WorkType,
  TimesheetExplanationWithWorkOrderDetails,
  TimesheetWithChildren
} from "@fd/current/client/services";
import {
  GetPersonName,
  PersonHasEquipmentClassification,
  PersonWithDetailsAndName,
  SortItemsWithName
} from "@fd/current/client/utils/person";
import { TranslateResult } from "vue-i18n";
import {
  SortTimesheetRows,
  TimesheetRow,
  TimesheetRowType,
  UpdatableTimesheetEntryWithDetails,
  UpdatableTimesheetWithTimesheetRows
} from "../../../utils/timesheet";
import userAccess from "../../../dataMixins/userAccess";
import { GroupableSelectListOption, SelectListOption } from "@fd/lib/vue/utility/select";
import {
  createNewCrew,
  SortCrewEmployees,
  updateExistingCrew
} from "./CrewDetailsBottomDialog.vue";
import { showAdditionalDetailsBottomDialog } from "../../../../../common/client/views/components/AdditionalDetailsBottomDialog.vue";

function CompareWorkSubTypes(a: WorkSubType, b: WorkSubType): number {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}
function SortWorkSubTypes(items: WorkSubType[] | null | undefined): WorkSubType[] {
  if (!items) return [];
  return items.sort(CompareWorkSubTypes);
}
type TableHeader = {
  text: string | TranslateResult | undefined | null;
  value: string | undefined;
  align?: "start" | "center" | "end";
  sortable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  divider?: boolean;
  class?: string | string[];
  cellClass?: string | string[];
  width?: string | number;
  filter?: (value: any, search: string, item: any) => boolean;
  sort?: (a: any, b: any) => number;
};
let today = new Date(new Date().toDateString());
const LabourEntryDialog = FDVue.extend({
  name: "fd-labour-entry-dialog",
  mixins: [dialogSupport, rules, userAccess],
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      tablepage: 1,
      canEditOtherDaysTimesheets: true,

      workOrder: {} as WorkOrderWithLegacyDetails,
      workOrderDateList: [
        {
          text: stripTimeFromLocalizedDateTime(today),
          value: today
        }
      ] as {
        text: string;
        value: Date;
        hasTimesheet?: boolean;
      }[],
      currentTimesheet: null as UpdatableTimesheetWithTimesheetRows | null,
      timesheetsWithoutEntries: [] as TimesheetWithChildren[],
      timesheets: [] as UpdatableTimesheetWithTimesheetRows[],
      selectedDay: today,
      contractor: {} as ContractorWithTags,
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],
      selectedEmployeeID: null as string | null,
      selectedCrewID: null as string | null
    };
  },

  computed: {
    workOrderNumbersWithDetailWorkSubTypes(): any[] {
      if (!this.currentTimesheet?.id) return [];
      return [...new Set(this.currentTimesheet.explanations?.map(x => x.workOrderNumber))];
    },
    currentTimesheetIsDeclined(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Declined;
    },
    currentTimesheetDeclineComments(): string | undefined {
      return this.currentTimesheet?.lastStatusLog?.comments;
    },
    currentTimesheetIsReadonly(): boolean {
      return !this.canEditTimesheet(this.currentTimesheet);
    },
    timesheetIsSubmitted(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Submitted;
    },
    timesheetIsApproved(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Approved;
    },
    timesheetIsCancelled(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Cancelled;
    },
    canSave(): boolean {
      let timesheetsToSave = this.timesheets.filter(x => this.canSaveTimesheet(x));
      return timesheetsToSave.length > 0;
    },
    canModifySelectedCrew(): boolean {
      if (!this.selectedCrewID) return false;
      return this.selectedCrew?.ownerID == this.curUserID || this.currentUserCanConfigureSettings;
    },
    minDate(): Date | undefined | null {
      let startDate = this.workOrder.startDate;
      if (!startDate) startDate = this.workOrder.requestSubmittedOn;
      return !!startDate ? new Date(new Date(startDate).toDateString()) : undefined;
    },
    maxDate(): Date {
      let maxDate = !!this.workOrder.completedDate ? this.workOrder.completedDate : new Date();
      return new Date(maxDate.toDateString());
    },
    newTimesheetEntries(): UpdatableTimesheetEntryWithDetails[] {
      if (!this.currentTimesheet?.timesheetRows) return [];

      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      return this.currentTimesheet.getNewEntries(
        this.perDiemSubType,
        this.equipmentSubType,
        allWorkSubTypes,
        allPeople
      );
    },
    modifiedTimesheetEntries(): UpdatableTimesheetEntryWithDetails[] {
      if (!this.currentTimesheet?.timesheetRows) return [];

      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      return this.currentTimesheet.getModifiedEntries(
        this.perDiemSubType,
        this.equipmentSubType,
        allWorkSubTypes,
        allPeople
      );
    },
    removedTimesheetEntries(): TimesheetEntryWithDetails[] {
      if (!this.currentTimesheet?.timesheetRows) return [];

      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      return this.currentTimesheet.getRemovedEntries(
        this.perDiemSubType,
        this.equipmentSubType,
        allWorkSubTypes,
        allPeople
      );
    },
    // Used for both the table templates (sorting doesn't matter) and table headers (sorting DOES matter)
    visibleWorkSubTypes(): WorkSubType[] {
      let visibleWorkSubTypes = [] as WorkSubType[];
      this.selectableWorkTypes.forEach(wt => {
        let subTypes = SortWorkSubTypes(
          this.allSelectableWorkSubTypes.filter(wst => wst.workTypeID == wt.id)
        );
        subTypes.forEach(wst => {
          visibleWorkSubTypes.push(wst);
        });
      });
      return visibleWorkSubTypes;
    },
    tableHeaders(): TableHeader[] {
      let headers = [
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName",
          class: "fd-table-frozen-first-column",
          cellClass: "fd-table-frozen-first-column"
        }
      ] as TableHeader[];

      for (let workSubType of this.visibleWorkSubTypes) {
        if (!workSubType.useWorkOrderCostCode && !workSubType.defaultCostCodeID?.length) {
          console.log(
            `${workSubType.name} - defaultCostCodeID: ${workSubType.defaultCostCodeID}, useWorkOrderCostCode: ${workSubType.useWorkOrderCostCode}`
          );
        }
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      headers.push({
        text: this.$t("common.action"),
        value: "action",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    unwatchedMethodNames(): string[] {
      return [
        "currentTimesheetExplanationsForWorkOrderNumber",
        "workSubTypeNameForExplanation",
        "workSubTypeHoursValueChanged",
        "checkWorkSubTypeAdditionalDetails",
        "showNewExplanationDialog",
        "dateIsToday",
        "canEditTimesheet",
        "saveDialog",
        "getFieldRef",
        "focusFieldForVisibleItemAtIndex",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "loadTimesheets",
        "loadSelectedDayTimesheetDetails",
        "calculateTotal",
        "formatNumber",
        "formatDate",
        "selectableWorkSubTypesForWorkTypeID"
      ];
    },
    itemsPerPage(): number {
      return 10;
    },
    itemsPerPageOptions(): number[] {
      return [5, 10, 25, -1];
    },
    formattedSelectedDay(): string {
      return stripTimeFromLocalizedDateTime(this.selectedDay);
    },
    selectableAreas(): ProjectLocation[] {
      if (!this.contractor.areaIDs?.length) return [];
      return this.allAreas.filter(x => this.contractor.areaIDs!.includes(x.id!));
    },
    selectedArea(): ProjectLocation | undefined {
      return this.selectableAreas.find(x => x.id == this.workOrder.areaID);
    },
    selectableSubAreas(): ProjectLocation[] {
      return this.allSubAreas.filter(x => x.parentLocationID == this.selectedArea?.id);
    },
    selectedSubArea(): ProjectLocation | undefined {
      return this.selectableSubAreas.find(x => x.id == this.workOrder.subAreaID);
    },
    perDiemSubType(): WorkSubType | undefined {
      let perDiemTypeID = (this.$store.state.workTypes.fullList as WorkType[]).find(
        x => !!x.isPerDiem
      )?.id;
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      return allWorkSubTypes.find(x => x.workTypeID == perDiemTypeID);
    },
    equipmentSubType(): WorkSubType | undefined {
      let equipmentTypeID = (this.$store.state.workTypes.fullList as WorkType[]).find(
        x => !!x.isEquipment
      )?.id;
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      return allWorkSubTypes.find(x => x.workTypeID == equipmentTypeID);
    },
    allSelectableWorkSubTypes(): WorkSubType[] {
      return this.selectableGroupedWorkSubType
        .filter(x => !!(x as WorkSubType))
        .map(x => x as WorkSubType);
    },
    selectableWorkTypes(): WorkType[] {
      return (this.$store.state.workTypes.fullList as WorkType[]).filter(
        x => !x.isPerDiem && x.isDirect && this.contractor.workTypeIDs?.includes(x.id!)
      );
    },
    selectableGroupedWorkSubType(): GroupableSelectListOption<WorkSubType>[] {
      if (!this.contractor.workTypeIDs?.length) return [];

      let allWorkSubTypes = (this.$store.state.workSubTypes.fullList as WorkSubType[]).filter(
        x => (!!x.isWorkOrderRelated && !!x.useWorkOrderCostCode) || !!x.defaultCostCodeID
      );
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let entriesWithTime = [] as UpdatableTimesheetEntryWithDetails[];
      if (this.currentTimesheet?.timesheetRows) {
        entriesWithTime = this.currentTimesheet
          .getEntries(this.perDiemSubType, this.equipmentSubType, allWorkSubTypes, allPeople)
          .filter(x => !!x.regularTime && x.regularTime > 0);
      }
      let selectableWorkTypes = this.selectableWorkTypes;
      let selectableGroupedWorkSubType = [] as GroupableSelectListOption<WorkSubType>[];
      selectableWorkTypes.forEach(workType => {
        let selectableSubTypes = this.selectableWorkSubTypesForWorkTypeID(workType.id);
        if (!selectableSubTypes?.length) return;

        selectableGroupedWorkSubType.push({ header: workType.name! });
        selectableGroupedWorkSubType = selectableGroupedWorkSubType.concat(
          selectableSubTypes.map(x => ({
            ...x,
            displayName: x.code ?? x.name,
            disabled: entriesWithTime.findIndex(e => e.workSubTypeID == x.id) !== -1
          }))
        );
      });
      return selectableGroupedWorkSubType;
    },
    selectableEmployees(): SelectListOption<PersonWithDetailsAndName>[] {
      let selectableEmployees = SortItemsWithName(
        (this.$store.state.users.fullList as PersonWithDetails[])
          .filter(
            x =>
              !PersonHasEquipmentClassification(x) &&
              !!x.contractorID &&
              x.contractorID == this.workOrder.assignedContractorID
          )
          .map(x => ({
            ...x,
            name: GetPersonName(x),
            disabled:
              this.currentTimesheet?.timesheetRows?.find(t => t.employeeID == x.id) != undefined
          }))
      ) as SelectListOption<PersonWithDetailsAndName>[];

      return selectableEmployees;
    },
    selectedEmployee(): PersonWithDetailsAndName | undefined {
      return this.selectableEmployees.find(x => x.id == this.selectedEmployeeID);
    },
    selectableCrews(): SelectListOption<CrewWithEmployees>[] {
      let selectableCrews = SortItemsWithName(
        (this.$store.state.crews.fullList as CrewWithEmployees[])
          .filter(
            x =>
              x.contractorID == this.workOrder.assignedContractorID &&
              (!x.ownerID || x.ownerID == this.curUserID)
          )
          .map(
            x =>
              ({
                ...x,
                disabled:
                  !x.employees?.length ||
                  !x.employees.filter(
                    e =>
                      this.currentTimesheet?.timesheetRows?.find(
                        t => t.employeeID == e.employeeID
                      ) == undefined
                  )?.length
              } as SelectListOption<CrewWithEmployees>)
          )
      );

      return selectableCrews;
    },
    selectedCrew(): CrewWithEmployees | undefined {
      return this.selectableCrews.find(x => x.id == this.selectedCrewID);
    },
    selectedEmployees(): PersonWithDetailsAndName[] {
      var selectedEmployeeIDs = [] as string[];
      if (!!this.selectedEmployeeID) selectedEmployeeIDs.push(this.selectedEmployeeID);
      if (!!this.selectedCrew)
        selectedEmployeeIDs = selectedEmployeeIDs.concat(
          SortCrewEmployees(this.selectedCrew.employees)?.map(x => x.employeeID!) ?? []
        );

      return selectedEmployeeIDs
        .filter(x => !!this.selectableEmployees.find(e => e.id! == x))
        .map(x => this.selectableEmployees.find(e => e.id! == x)!);
      // return this.selectableEmployees.filter(x => selectedEmployeeIDs.includes(x.id!));
    }
  },

  watch: {
    timesheetsWithoutEntries(newValue, oldValue) {
      console.log(`*** timesheetsWithoutEntries changed ${oldValue} -> ${newValue}`);
    },
    selectedDay() {
      let currentTimesheet = this.timesheets.find(
        x =>
          x.day!.getTime() == this.selectedDay.getTime() &&
          x.contractorID == this.workOrder.assignedContractorID!
      );
      if (currentTimesheet) {
        this.currentTimesheet = currentTimesheet;
      } else {
        this.reloadSelectedDayTimesheetDetails();
      }
    }
  },

  methods: {
    currentTimesheetExplanationsForWorkOrderNumber(
      woNumber: string
    ): TimesheetExplanationWithWorkOrderDetails[] {
      return this.currentTimesheet?.explanations?.filter(x => x.workOrderNumber == woNumber) ?? [];
    },
    workSubTypeNameForExplanation(
      explanation: TimesheetExplanationWithWorkOrderDetails
    ): string | null | undefined {
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let workSubType = allWorkSubTypes.find(x => x.id == explanation.workSubTypeID);
      return workSubType?.name;
    },
    checkWorkSubTypeAdditionalDetails(
      newValue: number,
      workSubTypeID: string,
      workOrderID: string | null | undefined,
      workOrderNumber: string | null | undefined
    ) {
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let wst = allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!!wst?.requiresAdditionalDetails) {
        let existingExplanation = this.currentTimesheet?.explanations?.find(
          x => x.workSubTypeID == workSubTypeID && x.workOrderID == workOrderID
        );
        if (newValue > 0) {
          if (!existingExplanation) {
            this.showNewExplanationDialog(
              workOrderID ?? "",
              workOrderNumber ?? "",
              workSubTypeID,
              wst.name ?? ""
            );
          }
        } else {
          // Value was removed for this WST/WO combination
          // Check if there are any other entries with this combination
          if (!!existingExplanation) {
            let otherExistingRowsWithValue = this.currentTimesheet?.timesheetRows.filter(
              x =>
                x.workOrderID == workOrderID &&
                !!(x as any)[workSubTypeID] &&
                (x as any)[workSubTypeID] > 0
            );
            if (!otherExistingRowsWithValue?.length) {
              let existingIndex = this.currentTimesheet!.explanations.indexOf(existingExplanation);
              this.currentTimesheet!.explanations.splice(existingIndex, 1);
            }
          }
        }
      }
    },
    async showNewExplanationDialog(
      workOrderID: string,
      workOrderNumber: string,
      workSubTypeID: string,
      workSubTypeName: string
    ) {
      if (!this.currentTimesheet?.id) return;

      let newExplanation = {
        timesheetID: this.currentTimesheet.id,
        workOrderID: workOrderID,
        workOrderNumber: workOrderNumber,
        workSubTypeID: workSubTypeID
      } as TimesheetExplanationWithWorkOrderDetails;

      let title = this.$t("timesheets.existing.additional-details-label", [
        `WO#${workOrderNumber}`
      ]);
      let label = workSubTypeName;
      let explanationText = await showAdditionalDetailsBottomDialog(
        { title, label },
        this.$refs.content as Vue
      );

      newExplanation.explanation = explanationText;
      this.currentTimesheet.explanations = this.currentTimesheet.explanations?.concat([
        newExplanation
      ]);
    },
    workSubTypeHoursValueChanged(row: TimesheetRow, workSubTypeID: string, newValue: any) {
      this.checkWorkSubTypeAdditionalDetails(
        newValue,
        workSubTypeID,
        row.workOrderID,
        row.workOrderNumber
      );
    },
    ...mapActions({
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadEmployees: "LOAD_USERS",
      loadCrews: "LOAD_CREWS",
      loadClassifications: "LOAD_CLASSIFICATIONS"
    }),
    canEditTimesheet(timesheet: UpdatableTimesheetWithTimesheetRows | null) {
      if (!timesheet) return false;

      let locked = timesheet.isLocked ?? false;
      let currentDay =
        new Date(timesheet.day!.toDateString()).getTime() ==
        new Date(new Date().toDateString()).getTime();
      return !locked && (currentDay || this.canEditOtherDaysTimesheets);
    },

    calculateTotal(item: any): string {
      var allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let total = allWorkSubTypes.reduce((a, b) => {
        let val = Number(item[b.id!]) ?? 0;
        if (isNaN(val)) val = 0;
        return a + val;
      }, 0);
      return total.toFixed(2);
    },

    formatNumber(number: string | number | undefined | null): string | undefined {
      let val = Number(number);
      if (isNaN(val)) return undefined;
      return val.toFixed(2);
    },

    formatDate(date: Date | string | null | undefined): String {
      return stripTimeFromLocalizedDateTime(date);
    },

    async open(workOrder: WorkOrderWithLegacyDetails) {
      this.workOrder = workOrder;
      if (!!this.workOrder?.completedDate) {
        if (this.workOrder.completedDate.getTime() < this.selectedDay.getTime()) {
          this.selectedDay = new Date(this.workOrder.completedDate.toDateString());
        }
      }
      this.loadData();
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    async addNewCrew() {
      this.optOutOfErrorHandling();
      let newCrewID = await createNewCrew(
        this.contractor.id!,
        this.curUserID,
        undefined,
        this.$refs.content as Vue
      );
      if (newCrewID) {
        this.selectedCrewID = newCrewID as string;
        this.addTimesheetRows();
      }
    },
    async editCrew() {
      this.optOutOfErrorHandling();
      if (!this.selectedCrew) return;
      await updateExistingCrew(this.selectedCrew, this.$refs.content as Vue);
    },
    async deleteCrew() {
      this.optOutOfErrorHandling();
      if (!this.selectedCrew?.id) return;
      this.processing = true;
      try {
        await this.$store.dispatch("DELETE_CREW", this.selectedCrew);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async addTimesheetRows() {
      this.optOutOfErrorHandling();
      if (!this.currentTimesheet) return;

      if (this.currentTimesheet.isLocked) return;

      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.addform as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      try {
        this.selectedEmployees.forEach(e => {
          let existingRow = this.currentTimesheet!.timesheetRows?.find(x => x.employeeID == e.id);
          if (!!existingRow) return;

          let newRow = {
            rowType: TimesheetRowType.DirectWorkOrderRelated,
            employeeID: e.id,
            employeeName: e.name,
            employeeCode: e.employeeCode,
            classificationID: e.classificationID,
            classificationDisplayName: this.getClassificationDisplayNameForID(e.classificationID),
            workOrderID: this.workOrder.id,
            workOrderNumber: this.workOrder.legacyID ?? "",
            scaffoldID: this.workOrder.scaffoldID ?? "",
            areaID: this.workOrder.areaID ?? "",
            subAreaID: this.workOrder.subAreaID ?? "",
            workOrderCostCodeID: this.workOrder.costCodeID ?? "",
            errorMessage: "",
            rowNumber: this.currentTimesheet?.nextRowNumber ?? 1
          } as TimesheetRow;

          this.currentTimesheet!.timesheetRows.push(newRow);
        });
        this.currentTimesheet.timesheetRows = SortTimesheetRows(
          this.currentTimesheet.timesheetRows
        );
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    removeTimesheetRow(row: TimesheetRow) {
      if (!this.currentTimesheet) return;

      const index = this.currentTimesheet.timesheetRows.indexOf(row);
      if (index < 0) {
        return;
      }
      this.currentTimesheet.timesheetRows.splice(index, 1);
    },

    async saveModifiedTimesheets(): Promise<boolean> {
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];

      let timesheetsToSave = this.timesheets.filter(x =>
        this.canSaveTimesheet(x, allWorkSubTypes, allPeople)
      );
      if (timesheetsToSave.length == 0) {
        this.processing = false;
        var snackbarPayload = {
          text: this.$t("timesheets.entries.no-changes-to-save-message"),
          type: "info",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        return false;
      }

      // Check all timesheets for any error entries
      for (let timesheet of timesheetsToSave) {
        var errorEntries = timesheet
          .getEntries(this.perDiemSubType, this.equipmentSubType, allWorkSubTypes, allPeople)
          .filter(x => !x.regularTime && !x.overTime && !x.doubleTime && !x.units);

        if (errorEntries.length) {
          this.inlineMessage.message = this.$t(
            "timesheets.entries.entries-missing-data-for-day-error-message",
            [stripTimeFromLocalizedDateTime(timesheet.day)]
          );
          return false;
        }
      }

      for (let timesheet of timesheetsToSave) {
        if (timesheet.isNew) {
          let newID = await timesheetService.addItem(timesheet);
          timesheet.id = newID;
          timesheet
            .getNewEntries(this.perDiemSubType, this.equipmentSubType, allWorkSubTypes, allPeople)
            .forEach(x => (x.timesheetID = newID));
        }
        if (
          timesheet.checkHasNewEntries(
            this.perDiemSubType,
            this.equipmentSubType,
            allWorkSubTypes,
            allPeople
          )
        ) {
          await timesheetService.addEntriesToTimesheetWithID(
            timesheet.id!,
            timesheet.getSanitizedNewEntries(
              this.perDiemSubType,
              this.equipmentSubType,
              allWorkSubTypes,
              allPeople
            )
          );
        }
        if (
          timesheet.checkHasModifiedEntries(
            this.perDiemSubType,
            this.equipmentSubType,
            allWorkSubTypes,
            allPeople
          )
        ) {
          await timesheetService.updateEntriesForTimesheetWithID(
            timesheet.id!,
            timesheet.getModifiedExistingEntryData(
              this.perDiemSubType,
              this.equipmentSubType,
              allWorkSubTypes,
              allPeople
            )
          );
        }
        if (
          timesheet.checkHasRemovedEntries(
            this.perDiemSubType,
            this.equipmentSubType,
            allWorkSubTypes,
            allPeople
          )
        ) {
          await timesheetService.removeEntriesFromTimesheetWithID(
            timesheet.id!,
            timesheet.getRemovedEntryIDs(
              this.perDiemSubType,
              this.equipmentSubType,
              allWorkSubTypes,
              allPeople
            )
          );
        }
        if (timesheet.explanationsModified) {
          await timesheetService.updateExplanationsForTimesheetWithID(
            timesheet.id!,
            timesheet.explanations ?? []
          );
        }
      }

      return true;
    },

    canSaveTimesheet(
      timesheet: UpdatableTimesheetWithTimesheetRows | null | undefined,
      allWorkSubTypes: WorkSubType[] | undefined = undefined,
      allPeople: PersonWithDetails[] | undefined = undefined
    ): boolean {
      if (!allWorkSubTypes)
        allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      if (!allPeople) allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      return (
        !!timesheet &&
        this.canEditTimesheet(timesheet) &&
        timesheet.checkIsDirty(
          this.perDiemSubType,
          this.equipmentSubType,
          allWorkSubTypes,
          allPeople
        )
      );
    },
    validateAdditionalDetails(): boolean {
      return (this.$refs.additionaldetailsform as HTMLFormElement)?.validate() ?? true;
    },
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!this.validateAdditionalDetails()) {
        return;
      }
      this.processing = true;
      try {
        if (!(await this.saveModifiedTimesheets())) {
          return;
        }
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    selectableWorkSubTypesForWorkTypeID(workTypeID: string | null | undefined): WorkSubType[] {
      var allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      return SortWorkSubTypes(
        allWorkSubTypes.filter(x => x.workTypeID == workTypeID && x.isWorkOrderRelated)
      );
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },
    // Calls `loadSelectedDayTimesheetDetails` but wrapped in processing and erro handling
    async reloadSelectedDayTimesheetDetails() {
      this.optOutOfErrorHandling();
      this.processing = true;
      try {
        await this.loadSelectedDayTimesheetDetails();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadTimesheets() {
      console.log(`loadTimesheets`);
      this.timesheetsWithoutEntries = await timesheetService.getTimesheetsForLabourEntryDialog(
        this.workOrder.foremanID!,
        this.minDate ?? this.maxDate,
        this.maxDate
      );
      this.populateWorkOrderDateList();
    },
    dateIsToday(date: Date): boolean {
      return date.getTime() == today.getTime();
    },
    populateWorkOrderDateList() {
      let minDate = new Date((this.minDate ?? this.maxDate).valueOf());
      let maxDate = new Date(this.maxDate.valueOf());
      // console.log(`workOrderDateList min: ${minDate}, max: ${maxDate}`);
      let date = minDate;
      let dates = [] as {
        text: string;
        value: Date;
        disabled?: boolean;
        hasTimesheet?: boolean;
      }[];
      while (date.getTime() <= maxDate.getTime()) {
        // console.log(`    ${date} less than max date`);
        let timesheet = this.timesheetsWithoutEntries.find(t => t.day!.getTime() == date.getTime());
        // console.log(`    timesheet: ${JSON.stringify(timesheet)}`);
        // If there isn't a timesheet for this day, or the timesheet exists but is editable, add the date to the list

        var dateEditable =
          !timesheet ||
          timesheet.timesheetStatusID == TimesheetStatus.New ||
          timesheet.timesheetStatusID == TimesheetStatus.Declined;
        // if (
        //   !!timesheet &&
        //   !(
        //     timesheet.timesheetStatusID == TimesheetStatus.New ||
        //     timesheet.timesheetStatusID == TimesheetStatus.Declined
        //   )
        // ) {
        //   // console.log(`    Existing timesheet not editable.  Ignore date.`);
        //   date.setDate(date.getDate() + 1);
        //   continue;
        // }

        // console.log(`    Adding date to list.`);
        dates.push({
          value: new Date(date.valueOf()),
          text: stripTimeFromLocalizedDateTime(date),
          disabled: !dateEditable,
          hasTimesheet: !!timesheet
        });

        date.setDate(date.getDate() + 1);
      }
      // console.log(`  dates: ${dates}`);
      // Reverse the dates so the latest date is on top of the list
      this.workOrderDateList = dates.sort((a, b) => {
        return b.value.getTime() - a.value.getTime();
      });
    },
    async loadSelectedDayTimesheetDetails() {
      console.log(`loadSelectedDayTimesheetDetails selectedDay: ${this.selectedDay}`);
      let timesheet = this.timesheetsWithoutEntries.find(x => x.day == this.selectedDay);
      console.log(`\ttimesheet for day: ${JSON.stringify(timesheet)}`);
      if (!timesheet) {
        timesheet = await timesheetService.getByOwnerIDAndDate(
          this.workOrder.foremanID!,
          this.selectedDay,
          false
        );
        if (!!timesheet) {
          this.timesheetsWithoutEntries.push(timesheet);
          console.log(`\tadded timesheet to list: ${JSON.stringify(timesheet)}`);
        }
      }
      if (!timesheet) {
        let ownerName = this.workOrder.foremanName;
        // if (!ownerName?.length) {
        //   let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
        //   ownerName = GetPersonName(allPeople.find(x => x.id == this.workOrder.foremanID));
        // }
        timesheet = {
          ownerID: this.workOrder.foremanID!,
          ownerName: ownerName,
          contractorID: this.workOrder.assignedContractorID,
          contractorName: this.contractor.name,
          day: this.selectedDay,
          timesheetStatusID: TimesheetStatus.New
        } as TimesheetWithChildren;
        this.timesheetsWithoutEntries.push(timesheet);
        console.log(`\tadded timesheet to list: ${JSON.stringify(timesheet)}`);
      }
      let entries = [] as TimesheetEntryWithDetails[];
      if (!!timesheet.id) {
        entries = (await timesheetService.getEntriesForTimesheetID(timesheet.id)).filter(
          x => !!x.workSubTypeID && x.workOrderID == this.workOrder.id!
        );
      }

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      let timesheetWithEntries = new UpdatableTimesheetWithTimesheetRows(
        timesheet,
        entries,
        allWorkTypes,
        allWorkSubTypes
      );
      timesheetWithEntries.timesheetRows = SortTimesheetRows(timesheetWithEntries.timesheetRows);
      this.currentTimesheet = timesheetWithEntries;
      this.timesheets.push(timesheetWithEntries);
    },
    async loadData() {
      this.optOutOfErrorHandling();
      this.processing = true;
      try {
        await Promise.all([
          this.loadAreas(),
          this.loadSubAreas(),
          this.loadWorkTypes(),
          this.loadWorkSubTypes(),
          this.loadCostCodes(),
          this.loadEmployees(),
          this.loadCrews(),
          this.loadClassifications(),
          this.loadTimesheets()
        ]);
        this.contractor = await contractorService.getByID(this.workOrder.assignedContractorID!);
        await this.loadSelectedDayTimesheetDetails();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    getClassificationDisplayNameForID(
      classificationID: string | null | undefined
    ): string | undefined {
      let classification = (this.$store.state.classifications.fullList as Classification[]).find(
        x => x.id == classificationID
      );
      return classification?.alias ?? classification?.name;
    },

    // *** INLINE NAVIGATION ***
    getDataTableForItem() {
      return this.$refs.datatable as VDataTable;
    },
    getFieldRef(fieldName: string, item: TimesheetRow) {
      let field = fieldName!.replace("-", "").replace("-", "");
      let id = item.employeeID!.replace("-", "").replace("-", "");
      return `${field}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(
      fieldName: string,
      index: number,
      visibleItems: TimesheetRow[]
    ) {
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        console.log(`itemField: ${itemField}`);
        itemField?.focus();
      });
    },
    async selectPreviousField(fieldName: string, item: TimesheetRow) {
      let datatable = this.getDataTableForItem();
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.tablepage <= 1) return;
        this.tablepage -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(
            fieldName,
            datatable.computedItemsPerPage,
            visibleItems
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex, visibleItems);
    },
    async selectNextField(fieldName: string, item: TimesheetRow) {
      if (!this.currentTimesheet) return;

      let datatable = this.getDataTableForItem();
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex >= visibleItems.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(
                this.currentTimesheet.timesheetRows.length / datatable.computedItemsPerPage
              );

        if (this.tablepage >= maxPage) return;
        this.tablepage += 1;

        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, 0, visibleItems);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex, visibleItems);
    },
    async enterPressed(e: KeyboardEvent, fieldName: string, item: TimesheetRow) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    }
  }
});

export default LabourEntryDialog;

export async function openLabourEntryDialog(
  workOrder: WorkOrderWithLegacyDetails
): Promise<string | boolean> {
  let dialog = createDialog(LabourEntryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(workOrder);
}

