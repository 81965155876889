import FDVue from "@fd/lib/vue";
import { PropValidator } from "vue/types/options";
import { ScaffoldRequestStatuses, ScaffoldRequestSubTypes, ScaffoldRequestTypes } from "../../../../services";
import * as DateUtil from "@fd/lib/client-util/datetime";

import personBySecurityIDDataStore from "../../../../store/referenceData/personBySecurityID";

// TODO: We don't use this interface, I've left it here for my notes temporarily
export interface WorkOrderRequestDetailsProps {
  // Hot job switch at top
  // TODO: This component emits change:isurgent when changed, where can we put that in an interface?
  showEditControls: boolean;
  editControlsDisabled: boolean;
}

export interface WorkOrderRequestDetails {
  isUrgent: boolean;
  lastUrgentValueChangedDate: Date | null;
  lastUrgentValueChangedBy: string | null; // TODO: Use lookup?

  requestType: ScaffoldRequestTypes; // TODO: Use $lookup
  requestSubType: ScaffoldRequestSubTypes; // TODO: Use $lookup
  requestNumber: string | null;
  legacyID: number | null;
  scaffoldNumber: number | null;

  requiredDate: Date | null;
  approvedRequiredDate: Date | null;
  requiredUntilDate: Date | null;
  requestDate?: Date; // v-if workOrder.isPlanned is proxy for presence/absence
  requestSubmittedOn: Date | null;

  requestingContractorID: string | null;
  requestingEmployeeID: string | null;
  requestSubmitterIDs: string[];
  disciplineID: string | null;
  workPackageNames: string[];

  requestStatus: ScaffoldRequestStatuses;
  workOrderStatusName: string; // TODO: Use $lookup
  // TODO: The old conversion applies GMT time in this odd way for some reason, which often produces "Invalid date"
  // let convertedDate = new Date(this.workOrder.lastStatusChangeDate.toLocaleString() + " GMT");
  // return DateUtil.localizedDateTimeString(convertedDate);
  lastStatusChangedDate: Date | null;
  lastStatusChangedBy: string | null;
  assignedContractorID: string | null;
  coordinatorID: string | null;
  generalForemanID: string | null;
  foremanID: string | null;

  startDate: Date | null;
  completedDate: Date | null;
  priority: number;
  isPlanned?: boolean; // This flag is currently unavailable in new data and we're passing it as null when absent
  progress: number;
}

export default FDVue.extend({
  name: "fd-work-order-request-details",

  components: {
    "fd-work-order-detail-value": () => import("./fields/WorkOrderDetailValue.vue"),
    "fd-work-order-detail-value-gap": () => import("./fields/WorkOrderDetailValueGap.vue"),
    "fd-work-order-detail-date-value": () => import("./fields/WorkOrderDetailDateValue.vue"),
    "fd-work-order-detail-date-time-value": () => import("./fields/WorkOrderDetailDateTimeValue.vue"),
    "fd-work-order-detail-enum-value": () => import("./fields/WorkOrderDetailEnumValue.vue"),
    "fd-work-order-detail-string-list-value": () => import("./fields/WorkOrderDetailStringListValue.vue")
  },

  props: {
    workOrder: { type: [Object] } as PropValidator<WorkOrderRequestDetails>,
    showEditControls: { type: Boolean, default: false },
    editControlsDisabled: { type: Boolean, default: false },
    cy: { type: String, default: "fd-work-order-request-details" }
  },

  data: () => ({
    ScaffoldRequestStatuses
  }),

  computed: {
    formattedLastUrgentValueChangeDate(): string | undefined {
      return !!this.workOrder.lastUrgentValueChangedDate
        ? DateUtil.localizedDateTimeString(this.workOrder.lastUrgentValueChangedDate)
        : undefined;
    },
    lastUrgentValueChangedSummaryString(): string | undefined {
      console.log(
        `WO-${this.workOrder.legacyID} lastUrgentValueChangedSummaryString lastUrgentValueChangedDate: ${this.workOrder.lastUrgentValueChangedDate}`
      );
      // If the work order isn't urgent, or there is no log date, return nothing
      if (!this.workOrder.isUrgent || !this.formattedLastUrgentValueChangeDate) return undefined;
      let summaryValues = [] as string[];

      if (!!this.workOrder.lastUrgentValueChangedBy?.length) {
        summaryValues.push(`${personBySecurityIDDataStore.lookupCaption(this.workOrder.lastUrgentValueChangedBy)}`);
      }

      if (!!this.formattedLastUrgentValueChangeDate?.length)
        summaryValues.push(this.formattedLastUrgentValueChangeDate);

      return summaryValues.join(" - ");
    }
  }
});
