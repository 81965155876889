var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.$t("suppliers.new-dialog-title")))]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper",
                {
                  attrs: { "non-linear": "" },
                  model: {
                    value: _vm.step,
                    callback: function($$v) {
                      _vm.step = $$v
                    },
                    expression: "step"
                  }
                },
                [
                  _c(
                    "v-stepper-header",
                    { staticClass: "fd-responsive-stepper" },
                    [
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            "data-cy": "details",
                            complete: _vm.step > _vm.detailsStep,
                            step: _vm.detailsStep,
                            editable: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("countsheetgroups.new.steps.details")
                              ) +
                              "\n            "
                          ),
                          _vm.detailserror
                            ? _c("small", { staticClass: "mt-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "countsheetgroups.new.steps.details-error-message"
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            "data-cy": "partselection",
                            complete: _vm.step > _vm.partSelectionStep,
                            step: _vm.partSelectionStep,
                            editable: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "countsheetgroups.new.steps.part-selection"
                                )
                              ) +
                              "\n            "
                          ),
                          _vm.partserror
                            ? _c("small", { staticClass: "mt-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "countsheetgroups.new.steps.parts-error-message"
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-items",
                    [
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "pl-0 pr-0 pb-0",
                          staticStyle: { "padding-top": "2px" },
                          attrs: { step: _vm.detailsStep }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "detailsform",
                              on: { submit: _vm.preventSubmit }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "px-2 mb-3 fd-inline-request-tags-qualifier",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-chip-selector", {
                                            attrs: {
                                              "available-items":
                                                _vm.availableTags,
                                              "selected-items":
                                                _vm.selectedTags,
                                              "item-label": "name",
                                              disabled: _vm.processing,
                                              "control-label": _vm.$t(
                                                "common.add-tags"
                                              ),
                                              "chip-class":
                                                "keywords-selection-chip-colors",
                                              outlined: false
                                            },
                                            on: {
                                              "update:selectedItems": function(
                                                $event
                                              ) {
                                                _vm.selectedTags = $event
                                              },
                                              "update:selected-items": function(
                                                $event
                                              ) {
                                                _vm.selectedTags = $event
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "px-2 pt-3" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "align-center justify-space-between pb-1",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              label: _vm.$t("common.name"),
                                              disabled: _vm.processing,
                                              rules: [_vm.rules.required]
                                            },
                                            model: {
                                              value: _vm.supplier.name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "supplier.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "align-center justify-space-between pt-1",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              label: _vm.$t("common.alias"),
                                              "persistent-hint": "",
                                              hint: _vm.$t("common.alias-hint"),
                                              disabled: _vm.processing,
                                              rules: [
                                                _vm.rules.aliascounter,
                                                _vm.rules.required
                                              ],
                                              maxlength: "20",
                                              counter: ""
                                            },
                                            model: {
                                              value: _vm.supplier.alias,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "alias",
                                                  $$v
                                                )
                                              },
                                              expression: "supplier.alias"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("fd-rich-textarea", {
                                            attrs: {
                                              label: _vm.$t(
                                                "common.description"
                                              ),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.supplier.description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.supplier,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression: "supplier.description"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "pl-0 pr-0 pb-0",
                          staticStyle: { "padding-top": "2px" },
                          attrs: { step: _vm.partSelectionStep }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "new-part",
                              attrs: { flat: "", tile: "" }
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "ml-0 mr-0" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "12" } },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass: "pl-3 pr-0 pt-0 pb-0"
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "suppliers.parts.only-included-parts-label"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.showOnlyIncludedParts,
                                                  callback: function($$v) {
                                                    _vm.showOnlyIncludedParts = $$v
                                                  },
                                                  expression:
                                                    "showOnlyIncludedParts"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-4",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "suppliers.parts.parts-selection-sub-menu-information"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "mr-0 ml-0" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "append-icon": "search",
                                              label: "Search",
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.partsTableSearch,
                                              callback: function($$v) {
                                                _vm.partsTableSearch = $$v
                                              },
                                              expression: "partsTableSearch"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-data-table", {
                                directives: [
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:name",
                                    value: _vm.$t("common.name"),
                                    expression: "$t('common.name')",
                                    arg: "name"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:description.hide-when-extra-small",
                                    value: _vm.$t("common.description"),
                                    expression: "$t('common.description')",
                                    arg: "description",
                                    modifiers: { "hide-when-extra-small": true }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:include.no-sort",
                                    value: _vm.$t("common.include"),
                                    expression: "$t('common.include')",
                                    arg: "include",
                                    modifiers: { "no-sort": true }
                                  }
                                ],
                                ref: "partsDataTable",
                                staticClass: "fixed-header v-table__overflow",
                                staticStyle: {
                                  "max-height": "calc(50vh - 100px)",
                                  "backface-visibility": "hidden"
                                },
                                attrs: {
                                  items: _vm.parts,
                                  search: _vm.partsTableSearch,
                                  loading: _vm.processing,
                                  "loading-text": _vm.$t(
                                    "common.table-loading-message"
                                  ),
                                  "sort-by": ["name"],
                                  "mobile-breakpoint": "0"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "header.include",
                                    fn: function(ref) {
                                      var header = ref.header
                                      return [
                                        _c("v-checkbox", {
                                          attrs: {
                                            value: _vm.allSearchedPartsSelected,
                                            indeterminate:
                                              _vm.someSearchedPartsSelected,
                                            label: _vm.$t(
                                              "common." + header.value
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.flipSearchedPartselected()
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.include",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("v-simple-checkbox", {
                                          attrs: {
                                            value: item.selected,
                                            disabled: _vm.processing
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.flipPartSelected(item)
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.saving,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("|")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "continue",
                            disabled:
                              _vm.step == _vm.lastStep ||
                              _vm.processing ||
                              _vm.saving,
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              _vm.step = Number(_vm.step) + 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.continue")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }