import { mapState } from "vuex";
import FDVue from "@fd/lib/vue";

export default FDVue.extend({
  name: "TermsDialog",

  data: () => ({
    platformName: "Swift Plant",
    lastUpdated: "June 26, 2023",
    companyName: "MPC Solutions LLC",
    companyAddress: "1006 Lemke avenue, Rothschild, WI, 54474",
    companyCountry: "Wisconsin,  United States",
    companyContactURL: "https://mpcsolutionsllc.com/contact.html",
    companyInfoEmail: "info@mpcsolutionsllc.com"
  }),

  computed: {
    termsPersistent: {
      cache: false,
      get(): boolean {
        return this.$store.state.termsPersistent;
      }
    },
    dialog: {
      get(): boolean {
        return this.$store.state.dialogTerms;
      },
      set(val: boolean) {
        this.$store.commit("SET_TERMS_DIALOG", val);
      }
    }
  }
});

