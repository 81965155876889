import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions, mapState } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import {
  Tag,
  WorkOrderWithLegacyDetails,
  workOrderService,
  WorkOrderStatusWithLegacyID,
  workOrderStatusHelper,
  WorkOrderStatuses,
  WorkOrderUrgentLogWithDetails,
  personService,
  SummarizedWorkOrderPermissions,
  walkdownService,
  WalkdownStatuses,
  ContractorWithTags,
  contractorService,
  WalkdownWithRequestDetails,
  SummarizedWalkdownPermissions,
  scaffoldRequestService,
  ProjectLocation,
  projectLocationService,
  messageService,
  noteService,
  ExternalLink,
  externalLinkService,
  Part,
  countSheetService,
  CountSheetWithParts,
  CountSheetGroupWithParts,
  ScaffoldRequestTypes,
  CountSheetReviewStatus,
  CountSheetType,
  workOrderEstimateService,
  WorkOrderEstimateWithLatest,
  workOrderTakeoffService,
  WorkOrderTakeoffPart,
  ScaffoldBayLength,
  ScaffoldBayWidth,
  ScaffoldBayHeight,
  walkdownReferenceDataService,
  ScaffoldTypeModifier,
  ScaffoldDistanceModifier,
  ScaffoldElevationModifier,
  ScaffoldHeightModifier,
  BuildDismantleRatio,
  ScaffoldCongestionFactor,
  InternalModifier,
  HoardingModifier,
  WorkOrderActualsEstimatedTimeTotals,
  WorkOrderActualWorkedTimeTotals,
  PartGenerationType,
  reportService,
  TakeoffPrintoutPart,
  LegacyEstimateTotalTimes,
  ProjectCostCode,
  workPackageService,
  WorkPackage
} from "../services";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import userAccess from "../dataMixins/userAccess";
import rules from "@fd/lib/vue/rules";
import * as DateUtil from "@fd/lib/client-util/datetime";
import fileHandling, {
  confirmUniqueName,
  isFilePreviewable,
  FileData,
  isFilePhoto,
  canOpenFileInNewWindow,
  componentsFromFileName
} from "@fd/lib/vue/mixins/fileHandling";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import messaging, {
  ParseMessageWithSenderDetails,
  SortMessagesArray
} from "../dataMixins/messaging";
import notes, { ParseNoteWithSenderDetails, SortNotesArray } from "../dataMixins/notes";
import { openExternalLinkDetails } from "./components/ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import { Attachment } from "../dataMixins/attachment";
import { GetPersonName, PersonWithName, SortItemsWithName } from "../utils/person";
import { TranslateResult } from "vue-i18n";
import { WorkPackageWithNameCode } from "../dataMixins/scaffoldRequest";
import {
  CountSheetGroupPartFromCountSheetPart,
  CountSheetGroupWithSortedParts,
  CountSheetPartFromGroupPart,
  SummarizeModifiedPartsInGroups
} from "../dataMixins/countSheet";
import { SortCountSheetGroups, SortParts } from "../dataMixins/countSheetGroupSorting";
import { openWorkOrderDetailsDialog } from "./components/WorkOrderDetailsDialog.vue";
import { WorkOrderDetails } from "./components/WorkOrderDetailsForm.vue";
import { createWorkOrderEstimateDesignedNewDialog } from "./components/dialogs/SP.WorkOrderEstimateDesignedNewDialog.vue";
import { createWorkOrderEstimateLWHNewDialog } from "./components/dialogs/SP.WorkOrderEstimateLWHNewDialog.vue";
import printBlob from "../../../lib/client-util/printBlob";

type Keyword = Tag;
type FormattedWorkOrderWithLegacyDetails = WorkOrderWithLegacyDetails & {
  isWalkdownPlanned: boolean;
  isWorkPlanned: boolean;
  formattedStartDate: string;
  formattedRequiredDate: string;
};
type WorkOrderStatusWithLegacyIDAndDisabled = WorkOrderStatusWithLegacyID & { disabled: boolean };
type WorkOrderEstimateWithDetails = WorkOrderEstimateWithLatest & {
  generationTypeName: string;
  generationMethodName: string;
  dateString: string;
  bayLengthName: string;
  lengthBayCount: number;
  bayWidthName: string;
  widthBayCount: number;
  bayHeightName: string;

  estimatedTotalTime: number;
  estimatedErectMPP: number;
  estimatedDismantleMPP: number;

  scaffoldTypeModifierName: string;
  scaffoldDistanceModifierName: string;
  scaffoldElevationModifierName: string;
  scaffoldHeightModifierName: string;
  scaffoldCongestionFactorName: string;
  buildDismantleRatioName: string;
  internalModifierName: string;
  hoardingModifierName: string;
};
type LegacyEstimateTotalTimesWithTotals = LegacyEstimateTotalTimes & {
  estimatedTotalTime: number;
  estimatedErectMPP: number;
  estimatedDismantleMPP: number;
};

function CanSelectWorkOrderStatus(
  workOrderStatus: WorkOrderStatuses,
  currentStatus: WorkOrderStatuses | undefined,
  requestType: ScaffoldRequestTypes | undefined,
  currentUserCanEditWorkOrderStatus: boolean,
  currentUserCanCancelWorkOrder: boolean,
  currentUserCanEditWorkOrderProgress: boolean
): boolean {
  // Since this screen doesn't save automatically, allow the user to re-set back to the original status
  if (workOrderStatus == currentStatus) return true;

  if (!currentUserCanEditWorkOrderStatus) return false;

  if (currentStatus == WorkOrderStatuses.Walkdown) return false;

  // Approved can never be selected
  if (workOrderStatus == WorkOrderStatuses.Approved) return false;

  // Walkdown can be selected only if the work order is currently approved, or can be reversed if in In Scheduling
  if (workOrderStatus == WorkOrderStatuses.Walkdown)
    return (
      (requestType == ScaffoldRequestTypes.Erect ||
        requestType == ScaffoldRequestTypes.Dismantle ||
        requestType == ScaffoldRequestTypes.Modify) &&
      (currentStatus == WorkOrderStatuses.Approved ||
        currentStatus == WorkOrderStatuses.InScheduling)
    );

  if (currentStatus == WorkOrderStatuses.InScheduling) return false;

  // Estimated can never be selected
  if (workOrderStatus == WorkOrderStatuses.Estimated) return false;

  // InScheduling can never be selected
  if (workOrderStatus == WorkOrderStatuses.InScheduling) return false;

  // CompletionPendingAdministration can never be selected
  if (workOrderStatus == WorkOrderStatuses.CompletionPendingAdministration) return false;

  // Started or Completed can be selected only if the user has permission to modify a work order's progress
  if (
    workOrderStatus == WorkOrderStatuses.Started ||
    workOrderStatus == WorkOrderStatuses.Completed
  )
    return currentUserCanEditWorkOrderProgress;

  // Cancelled can be selected only if the user has permission to cancel work orders
  if (workOrderStatus == WorkOrderStatuses.Cancelled) return currentUserCanCancelWorkOrder;

  return true;
}

export default FDVue.extend({
  name: "fd-web-scheduler-existing",

  mixins: [serviceErrorHandling, tabbedView, userAccess, rules, fileHandling, messaging, notes],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-walkdown-form": () => import("./components/forms/WalkdownForm.vue"),
    "fd-work-package-selector": () => import("./components/WorkPackageSelectionDialog.vue"),
    "fd-count-sheet-form": () => import("./components/forms/CountSheetForm.vue"),
    "fd-subheader": () => import("@fd/lib/vue/components/layout/Subheader.vue"),
    "sp-work-order-estimate-takeoff-parts-form": () =>
      import("./components/forms/SP.WorkOrderEstimateTakeoffPartsForm.vue"),
    "sp-work-order-estimate-summary-form": () =>
      import("./components/forms/SP.WorkOrderEstimateSummaryForm.vue")
  },

  data: function() {
    return {
      // *** GLOBAL ***
      slidein: false,
      saving: false,
      submitting: false,

      // Form data errors
      detailsTabError: false,
      walkdownTabError: false,
      photosTabError: false,
      notesTabError: false,
      messagingTabError: false,

      firstTabKey: `1`,
      detailsTab: {
        tabname: "Basic",
        key: `1`,
        visible: true
      } as Tab,
      walkdownTab: {
        tabname: "Walkdown",
        key: `2`,
        visible: false
      } as Tab,
      estimatesTab: {
        tabname: this.$t("scheduler.tabs.estimates"),
        key: `11`,
        visible: false
      } as Tab,
      photosTab: {
        tabname: "Photos",
        key: `3`,
        visible: false
      } as Tab,
      notesTab: {
        tabname: "Notes",
        key: `4`,
        visible: false
      } as Tab,
      scopeTab: {
        tabname: this.$t("scaffold-requests.existing-scaffold-request.tabs.scope-change"),
        key: `5`,
        visible: false
      } as Tab,
      optionsTab: {
        tabname: "Options",
        key: `6`,
        visible: false
      } as Tab,
      messagingTab: {
        tabname: "Messaging",
        key: `7`,
        visible: false
      } as Tab,
      attachmentsTab: {
        tabname: "Attachments",
        key: "9",
        visible: false
      } as Tab,
      countSheetTab: {
        tabname: this.$t("scheduler.tabs.countsheet"),
        key: `10`,
        visible: false
      } as Tab,

      /*** DATA ***/
      workOrderID: "" as string,
      workOrder: {
        currentUserPermissions: {} as SummarizedWorkOrderPermissions,
        walkdown: {
          currentUserPermissions: {} as SummarizedWalkdownPermissions
        } as WalkdownWithRequestDetails
      } as FormattedWorkOrderWithLegacyDetails,

      /*** DETAILS ***/
      selectedKeywords: [] as Keyword[],

      // IWPs
      availableIWPs: [] as WorkPackageWithNameCode[],
      selectedIWPs: [] as WorkPackageWithNameCode[],

      // REFERENCE DATA
      workOrderSelectableStatuses: [] as WorkOrderStatusWithLegacyIDAndDisabled[],
      scaffoldContractors: [] as ContractorWithTags[],
      allCoordinators: [] as PersonWithName[],
      allGeneralForemen: [] as PersonWithName[],
      allForemen: [] as PersonWithName[],
      priorityValues: Array.from(Array(5).keys()).map(x => x + 1),
      progressValues: Array.from(Array(101).keys()).filter(x => x % 5 == 0),
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],

      /*** ESTIMATES ****/
      tablesearchestimates: "",
      workOrderEstimates: [] as WorkOrderEstimateWithDetails[],
      loadingEstimateTimeSummary: false,
      takeoffParts: [] as WorkOrderTakeoffPart[],
      estimatePanel: [0, 1, 2],
      summaryPanelTimeUnitDivider: 1,
      workOrderActualsEstimatedTimes: {} as WorkOrderActualsEstimatedTimeTotals,
      workOrderActualWorkedTimes: {} as WorkOrderActualWorkedTimeTotals,
      latestEstimateTimeTotals: undefined as LegacyEstimateTotalTimesWithTotals | undefined,

      allScaffoldTypes: [] as ScaffoldTypeModifier[],
      allScaffoldDistances: [] as ScaffoldDistanceModifier[],
      allScaffoldElevations: [] as ScaffoldElevationModifier[],
      allScaffoldHeights: [] as ScaffoldHeightModifier[],
      allBuildDismantleRatios: [] as BuildDismantleRatio[],
      allScaffoldCongestionFactors: [] as ScaffoldCongestionFactor[],
      allInternalModifiers: [] as InternalModifier[],
      allHoardingModifiers: [] as HoardingModifier[],

      // *** ATTACHMENTS ***
      touchedFileName: "",
      showPhotoTabAttachmentAlert: false,
      showAttachmentTabPhotoAlert: false,
      tablesearchfiles: "",
      allFiles: [] as FileData[],

      // Image Edit
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined,

      // *** COUNT SHEET ***
      // The following will control whether or not the save button shows the processing/loading indicator
      countSheetReadonly: false,
      countSheet: undefined as CountSheetWithParts | undefined,
      countSheetGroups: [] as CountSheetGroupWithSortedParts[]
    };
  },

  computed: {
    // *** GLOBAL ***
    ...mapState(["avatarInitials"]),

    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      let tabs = [this.walkdownTab] as Tab[];
      if (this.workOrder.currentUserPermissions.canViewEstimateDetails) {
        tabs.push(this.estimatesTab);
      }
      tabs = tabs.concat([
        this.photosTab,
        this.notesTab,
        this.messagingTab,
        this.scopeTab,
        this.optionsTab,
        this.attachmentsTab
      ] as Tab[]);
      if (this.allowCountSheet) {
        tabs.push(this.countSheetTab);
      }
      return tabs;
    },

    displayAdditionalActionButtons(): boolean {
      return this.selectedTab == this.walkdownTab || this.selectedTab == this.countSheetTab;
    },

    readonly(): boolean {
      return !(this.workOrder?.currentUserPermissions?.canEditAnything == true);
    },

    schedulerRules(): any {
      return {
        workOrderStatus: [this.rules.required],
        workOrderStatusDetail:
          this.workOrderIsOnHold || this.workOrderIsCancelled ? [this.rules.required] : [],
        coordinatorID: [],
        generalForemanID:
          this.workOrder.workOrderStatus == WorkOrderStatuses.Started ? [this.rules.required] : [],
        foremanID:
          this.workOrder.workOrderStatus == WorkOrderStatuses.Started ? [this.rules.required] : [],
        isUrgentDetail: this.workOrder.isUrgent ? [this.rules.required] : [],
        assignedContractorID: [],
        areaID: [this.rules.required],
        subAreaID: [this.rules.required],
        clientWorkOrderReason: !this.readonly && !!this.workOrder.isClientWorkOrder ? [] : [],
        clientWorkOrderReferenceNumber:
          !this.readonly && !!this.workOrder.isClientWorkOrder ? [this.rules.required] : [],
        changeOrderReason: !this.readonly && !!this.workOrder.isChangeOrder ? [] : [],
        changeOrderReferenceNumber:
          !this.readonly && !!this.workOrder.isChangeOrder ? [this.rules.required] : [],
        reworkReason: !this.readonly && this.workOrder.isRework ? [] : [],
        reworkReferenceNumber:
          !this.readonly && this.workOrder.isRework ? [this.rules.required] : []
      };
    },

    workOrderIsOnHold(): boolean {
      return this.workOrder.workOrderStatus == WorkOrderStatuses.OnHold;
    },

    workOrderIsCancelled(): boolean {
      return this.workOrder.workOrderStatus == WorkOrderStatuses.Cancelled;
    },

    workOrderNeedsWalkdown(): boolean {
      return (
        this.workOrder.workOrderStatus == WorkOrderStatuses.Walkdown ||
        this.workOrder.workOrderStatus == WorkOrderStatuses.Estimated
      );
    },

    // *** DETAILS ***
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    allKeywords(): Keyword[] {
      return this.$store.state.tags.fullList as Keyword[];
    },

    canEditArea(): boolean {
      return this.workOrder.currentUserPermissions.canEditArea;
    },
    canEditSiteContact(): boolean {
      return this.workOrder.currentUserPermissions.canEditSiteContact;
    },
    canEditLocation(): boolean {
      return this.workOrder.currentUserPermissions.canEditLocation;
    },
    canEditDescription(): boolean {
      return this.workOrder.currentUserPermissions.canEditWorkDescription;
    },
    canEditWorkPackages(): boolean {
      return this.workOrder.currentUserPermissions.canEditWorkPackages;
    },

    showOnToDo: {
      get(): boolean {
        return this.workOrderNeedsWalkdown
          ? this.workOrder.isWalkdownPlanned
          : this.workOrder.isWorkPlanned;
      },
      set(val: boolean) {
        if (this.workOrderNeedsWalkdown) {
          this.workOrder.isWalkdownPlanned = val;
        } else {
          this.workOrder.isWorkPlanned = val;
        }
      }
    },

    subAreas(): ProjectLocation[] {
      let areaID = this.workOrder.areaID;
      if (!areaID) return [];

      let subAreas = this.allSubAreas.filter(
        x => !!x.parentLocationID && x.parentLocationID == areaID
      );
      if (
        this.workOrder.currentUserPermissions.canEditArea &&
        !this.readonly &&
        subAreas.length == 1 &&
        !this.workOrder.subAreaID
      ) {
        this.$nextTick(() => {
          this.workOrder.subAreaID = subAreas[0].id;
        });
      }
      return subAreas;
    },

    // *** WALK DOWN ***
    walkdownReadonly(): boolean {
      return (
        this.workOrder?.workOrderStatus != WorkOrderStatuses.Walkdown ||
        this.workOrder?.walkdown?.walkdownStatus == WalkdownStatuses.Approved ||
        !this.workOrder?.walkdown?.currentUserPermissions?.canPerformToDoListWalkdown
      );
    },

    // *** ESTIMATE ***
    summaryPanelTimeUnit() {
      if (this.summaryPanelTimeUnitDivider == 60) return this.$t("common.hours");

      return this.$t("common.minutes-short");
    },
    currentEstimate(): WorkOrderEstimateWithDetails {
      let estimate = this.workOrderEstimates.find(x => !!x.isLatest);
      if (!estimate && !!this.workOrderEstimates?.length) estimate = this.workOrderEstimates[0];
      return estimate ?? ({} as WorkOrderEstimateWithDetails);
    },

    currentEstimateIsLWH(): boolean {
      return this.currentEstimate.generationTypeID == PartGenerationType.LWH;
    },
    currentEstimateLWHSummary(): string {
      var parts = [] as string[];
      parts.push((this.currentEstimate?.length ?? 0).toString());
      parts.push((this.currentEstimate?.width ?? 0).toString());
      parts.push((this.currentEstimate?.height ?? 0).toString());
      return parts.join(" x ");
    },
    currentEstimateTimesArePreview(): boolean {
      return (
        !!this.latestEstimateTimeTotals &&
        (this.currentEstimate.estimatedTotalErectMinutes !=
          this.currentEstimateTimeTotals.estimatedTotalErectMinutes ||
          this.currentEstimate.estimatedTotalDismantleMinutes !=
            this.currentEstimateTimeTotals.estimatedTotalDismantleMinutes ||
          this.currentEstimate.estimatedTotalModifyMinutes !=
            this.currentEstimateTimeTotals.estimatedTotalModifyMinutes ||
          this.currentEstimate.estimatedTotalMobilizationMinutes !=
            this.currentEstimateTimeTotals.estimatedTotalMobilizationMinutes ||
          this.currentEstimate.estimatedTotalDemobilizationMinutes !=
            this.currentEstimateTimeTotals.estimatedTotalDemobilizationMinutes ||
          this.currentEstimate.estimatedTotalHoardingMinutes !=
            this.currentEstimateTimeTotals.estimatedTotalHoardingMinutes ||
          this.currentEstimate.estimatedTotalWeight !=
            this.currentEstimateTimeTotals.estimatedTotalWeight ||
          this.currentEstimate.estimatedTotalPartCount !=
            this.currentEstimateTimeTotals.estimatedTotalPartCount)
      );
    },
    currentEstimateTimeTotals(): LegacyEstimateTotalTimes {
      return (
        this.latestEstimateTimeTotals ??
        ({
          estimatedTotalErectMinutes: this.currentEstimate.estimatedTotalErectMinutes,
          estimatedTotalDismantleMinutes: this.currentEstimate.estimatedTotalDismantleMinutes,
          estimatedTotalModifyMinutes: this.currentEstimate.estimatedTotalModifyMinutes,
          estimatedTotalMobilizationMinutes: this.currentEstimate.estimatedTotalMobilizationMinutes,
          estimatedTotalDemobilizationMinutes: this.currentEstimate
            .estimatedTotalDemobilizationMinutes,
          estimatedTotalHoardingMinutes: this.currentEstimate.estimatedTotalHoardingMinutes,
          estimatedTotalWeight: this.currentEstimate.estimatedTotalWeight,
          estimatedTotalPartCount: this.currentEstimate.estimatedTotalPartCount,
          estimatedTotalTime:
            (this.currentEstimate.estimatedTotalDemobilizationMinutes ?? 0.0) +
            (this.currentEstimate.estimatedTotalDismantleMinutes ?? 0.0) +
            (this.currentEstimate.estimatedTotalErectMinutes ?? 0.0) +
            (this.currentEstimate.estimatedTotalHoardingMinutes ?? 0.0) +
            (this.currentEstimate.estimatedTotalMobilizationMinutes ?? 0.0) +
            (this.currentEstimate.estimatedTotalModifyMinutes ?? 0.0),
          estimatedErectMPP:
            (this.currentEstimate.estimatedTotalErectMinutes ?? 0.0) /
            (this.currentEstimate.estimatedTotalPartCount ?? 1),
          estimatedDismantleMPP:
            (this.currentEstimate.estimatedTotalDismantleMinutes ?? 0.0) /
            (this.currentEstimate.estimatedTotalPartCount ?? 1)
        } as LegacyEstimateTotalTimesWithTotals)
      );
    },

    // *** PHOTOS ***
    photoFiles(): FileData[] {
      return this.allFiles.filter(x => x.isPhoto);
    },

    // *** NOTES ***
    currentUserCanAddNote(): boolean {
      return true;
    },

    // *** MESSAGING ***
    currentUserCanAddMessage(): boolean {
      return true;
    },

    // *** SCOPE ***
    missingRequiredClientWorkOrderData(): boolean {
      return (
        !!this.workOrder.isClientWorkOrder && !this.workOrder.clientWorkOrderReferenceNumber?.length
      );
    },
    missingRequiredChangeOrderData(): boolean {
      return !!this.workOrder.isChangeOrder && !this.workOrder.changeOrderReferenceNumber?.length;
    },
    missingRequiredReworkData(): boolean {
      return !!this.workOrder.isRework && !this.workOrder.reworkReferenceNumber?.length;
    },
    scopeTabError(): boolean {
      return (
        !this.readonly &&
        (this.missingRequiredClientWorkOrderData ||
          this.missingRequiredChangeOrderData ||
          this.missingRequiredReworkData)
      );
    },

    // *** OPTIONS ***

    // *** ATTACHMENTS ***
    nonPhotoAttachments(): Attachment[] {
      let attachments = [] as Attachment[];

      this.allFiles.forEach(file => {
        attachments.push({
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        });
      });

      this.workOrder?.externalLinks?.forEach(link => {
        attachments.push({
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments.filter(x => !x.isPhoto);
    },

    // *** COUNT SHEET ***
    allowCountSheet(): boolean {
      return (
        this.$route.name == "CountSheetAdministrationExisting" ||
        this.$route.name == "AdministrationExisting"
      );
    },
    canPickIndividual(): boolean {
      return (
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Modify ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Erect
      );
    },
    canRemoveAll(): boolean {
      return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Dismantle;
    },
    canHaveNoMaterial(): boolean {
      return (
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Modify ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Maintenance ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Paint ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Insulation
      );
    },
    canSubmitCountSheet(): boolean {
      return (
        this.allowCountSheet &&
        (this.workOrder.workOrderStatus == WorkOrderStatuses.Completed ||
          this.workOrder.workOrderStatus == WorkOrderStatuses.CompletionPendingAdministration)
      );
    },
    countSheetIsEditable(): boolean {
      return (
        this.allowCountSheet &&
        (this.countSheet?.reviewStatusID == CountSheetReviewStatus.Draft ||
          this.countSheet?.reviewStatusID == CountSheetReviewStatus.Declined)
      );
    }
  },

  methods: {
    async _initialize() {
      this.workOrderID = this.$route.params.id;

      this.processing = true;

      try {
        await this.$store.dispatch("LOAD_TAGS");
        await Promise.all([
          this.loadCoordinators(),
          this.loadGeneralForemen(),
          this.loadForemen(),
          this.loadSelectableWorkOrderStatuses(),
          this.loadAreas(),
          this.loadSubAreas(),
          this.loadCostCodes(),
          this.loadScaffoldBayHeights(),
          this.loadScaffoldBayLengths(),
          this.loadScaffoldBayWidths(),
          this.loadScaffoldTypes(),
          this.loadScaffoldHeights(),
          this.loadScaffoldElevations(),
          this.loadScaffoldDistances(),
          this.loadScaffoldCongestionFactors(),
          this.loadBuildDismantleRatios(),
          this.loadInternalModifiers(),
          this.loadHoardingModifiers()
        ]);
        // processing has been set to false after the reference data loaded.
        this.processing = true;
        var allContractors = await contractorService.getAll(false, null, null);
        // Processing has automatically been set to false after this load happens
        this.processing = true;
        this.scaffoldContractors = allContractors.filter(
          x => !!x.isScaffoldCompany && x.isScaffoldCompany
        );
        await this.loadWorkOrderDetails();
        this.processing = true;

        await this.loadWorkOrderEstimateData();

        this.workOrderActualWorkedTimes = await workOrderEstimateService.getWorkOrderActualWorkedTimeTotals(
          this.workOrder.id!
        );

        let messages = await messageService.getForWorkOrder(this.workOrder.id!);
        this.messages = SortMessagesArray(messages).map(x =>
          ParseMessageWithSenderDetails(x, this.curUserID)
        );

        let notes = await noteService.getForWorkOrder(this.workOrder.id!);
        this.notes = notes.map(x => ParseNoteWithSenderDetails(x));
        if (!!this.workOrder.notes?.length) {
          this.notes.push({
            isPinned: true,
            isNew: false,
            initials: "",
            name: `${this.$t("scaffold-requests.notes")}`,
            role: "",
            date: "",
            time: "",
            text: this.workOrder.notes,
            sender: undefined,
            id: undefined,
            noteThreadID: undefined,
            personID: undefined,
            sentTime: new Date(0),
            archivedDate: undefined,
            created: undefined,
            createdBy: undefined,
            updated: undefined,
            updatedBy: undefined
          });
        }
        this.notes = SortNotesArray(this.notes);
        // Processing has automatically been set to false after this load happens
        this.processing = true;

        await this.loadFiles();

        if (this.allowCountSheet) {
          // Load count sheet requires the work order to be loaded to use its ID
          await this.loadCountSheet();
          // Load count sheet group data requires the count sheet (if any) to be loaded to get part count information
          await this.loadCountSheetGroupData();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS",
      loadScaffoldBayLengths: "LOAD_SCAFFOLD_BAY_LENGTHS",
      loadScaffoldBayWidths: "LOAD_SCAFFOLD_BAY_WIDTHS",
      loadScaffoldBayHeights: "LOAD_SCAFFOLD_BAY_HEIGHTS",
      loadCostCodes: "LOAD_PROJECT_COST_CODES"
    }),

    getTimeDisplay(
      minutes: string | number | undefined | null,
      digits: number = 2,
      allowZero: boolean = false
    ) {
      if (minutes == undefined || minutes == null) return undefined;
      let val = Number(minutes);
      if (isNaN(val)) return undefined;

      let time = val / this.summaryPanelTimeUnitDivider;
      return this.formatNumber(time, digits, allowZero);
    },
    formatNumber(
      number: string | number | undefined | null,
      digits: number = 2,
      allowZero: boolean = false
    ): string | undefined {
      if (number == undefined || number == null) return undefined;
      let val = Number(number);
      if (isNaN(val)) return undefined;
      if (!allowZero && val == 0) return undefined;
      return val.toFixed(digits);
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldTypes(): Promise<void> {
      this.allScaffoldTypes = (
        await walkdownReferenceDataService.getAllScaffoldTypeModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldDistances(): Promise<void> {
      this.allScaffoldDistances = (
        await walkdownReferenceDataService.getAllScaffoldDistanceModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldHeights(): Promise<void> {
      this.allScaffoldHeights = (
        await walkdownReferenceDataService.getAllScaffoldHeightModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldElevations(): Promise<void> {
      this.allScaffoldElevations = (
        await walkdownReferenceDataService.getAllScaffoldElevationModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldCongestionFactors(): Promise<void> {
      this.allScaffoldCongestionFactors = (
        await walkdownReferenceDataService.getAllScaffoldCongestionFactors()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadBuildDismantleRatios(): Promise<void> {
      this.allBuildDismantleRatios = (
        await walkdownReferenceDataService.getAllBuildDismantleRatios()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadInternalModifiers(): Promise<void> {
      this.allInternalModifiers = (
        await walkdownReferenceDataService.getAllInternalModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadHoardingModifiers(): Promise<void> {
      this.allHoardingModifiers = (
        await walkdownReferenceDataService.getAllHoardingModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.back();
    },

    validateScopeForm(): boolean {
      let scopeForm = this.$refs.scopechangeform as HTMLFormElement;
      if (!scopeForm) {
        return !this.scopeTabError;
      }
      return scopeForm.validate() && !this.scopeTabError;
    },

    async save(closeOnComplete: boolean) {
      this.inlineMessage.message = null;
      this.processing = true;
      this.saving = true;
      try {
        var saveWalkdown =
          this.workOrder.workOrderStatus == WorkOrderStatuses.Walkdown &&
          this.workOrder.walkdown.walkdownStatus != WalkdownStatuses.Approved;
        var workOrder = { ...this.workOrder };
        workOrder.walkdown = {} as WalkdownWithRequestDetails;
        var saveCountSheet =
          this.workOrder.workOrderStatus == WorkOrderStatuses.CompletionPendingAdministration &&
          this.countSheetIsEditable;
        var saveEstimateModifiers =
          this.workOrder.currentUserPermissions.canEditEstimateDetails &&
          !!this.currentEstimate &&
          this.currentEstimateTimesArePreview;

        if (this.currentUserCanEditWorkOrderSchedule) {
          if (!(this.$refs.detailsform as HTMLFormElement).validate() || !this.validateScopeForm())
            return;
          // These values are set to undefined so that the server doesn't recognize them as modified and throw a permissions error
          // This is not security in itself, the server still checks
          if (!this.workOrder.currentUserPermissions.canEditArea) {
            workOrder.areaID = undefined;
            workOrder.subAreaID = undefined;
          }
          if (!this.workOrder.currentUserPermissions.canEditSiteContact)
            workOrder.siteContact = undefined;
          if (!this.workOrder.currentUserPermissions.canEditLocation)
            workOrder.specificWorkLocation = undefined;
          if (!this.workOrder.currentUserPermissions.canEditWorkDescription)
            workOrder.detailedWorkDescription = undefined;
          if (!this.workOrder.currentUserPermissions.canEditPriority)
            workOrder.priority = undefined;
          if (!this.workOrder.currentUserPermissions.canEditProgress)
            workOrder.progress = undefined;
          if (!this.workOrder.currentUserPermissions.canEditAssignedContractor)
            workOrder.assignedContractorID = undefined;
          if (!this.workOrder.currentUserPermissions.canEditAssignedCoordinator)
            workOrder.coordinatorID = undefined;
          if (!this.workOrder.currentUserPermissions.canEditAssignedGeneralForeman)
            workOrder.generalForemanID = undefined;
          if (!this.workOrder.currentUserPermissions.canEditAssignedForeman)
            workOrder.foremanID = undefined;
          if (
            this.workOrder.workOrderStatus != WorkOrderStatuses.Cancelled &&
            !this.workOrder.currentUserPermissions.canEditStatus
          )
            workOrder.workOrderStatus = undefined;
          if (
            this.workOrder.workOrderStatus == WorkOrderStatuses.Cancelled &&
            !this.workOrder.currentUserPermissions.canCancel
          )
            workOrder.workOrderStatus = undefined;
          if (!this.workOrder.currentUserPermissions.canEditRequiredDate)
            workOrder.requiredDate = undefined;

          if (!this.workOrder.currentUserPermissions.canPushToToDoList) {
            workOrder.plannedWalkdownStartDate = undefined;
            workOrder.plannedWorkStartDate = undefined;
          } else {
            if (this.workOrder.isWalkdownPlanned && !this.workOrder.plannedWalkdownStartDate) {
              workOrder.plannedWalkdownStartDate = new Date(new Date().toUTCString());
            } else if (
              !this.workOrder.isWalkdownPlanned &&
              !!this.workOrder.plannedWalkdownStartDate
            ) {
              workOrder.plannedWalkdownStartDate = null;
            }

            if (this.workOrder.isWorkPlanned && !this.workOrder.plannedWorkStartDate) {
              workOrder.plannedWorkStartDate = new Date(new Date().toUTCString());
            } else if (!this.workOrder.isWorkPlanned && !!this.workOrder.plannedWorkStartDate) {
              workOrder.plannedWorkStartDate = null;
            }
          }

          workOrder.workPackages = this.selectedIWPs.map(iwp => ({ ...iwp } as WorkPackage));

          workOrder.tagIDs =
            this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;
          await workOrderService.updateItem(
            this.workOrder.id!,
            workOrder,
            "WorkOrderSchedulerExisting.save"
          );
        }
        if (saveWalkdown) {
          if (this.workOrder.walkdown.currentUserPermissions.canPerformToDoListWalkdown) {
            await this.saveWalkdown(false);
          }
        }
        if (saveCountSheet) {
          await this.saveCountSheet();
        }
        if (saveEstimateModifiers) {
          this.currentEstimate.modificationPercent = !this.currentEstimate.modificationPercent
            ? 0
            : +this.currentEstimate.modificationPercent;
          this.currentEstimate.factor1 = !this.currentEstimate.factor1
            ? 0
            : +this.currentEstimate.factor1;
          this.currentEstimate.factor2 = !this.currentEstimate.factor2
            ? 0
            : +this.currentEstimate.factor2;
          this.currentEstimate.crewSize = !this.currentEstimate.crewSize
            ? 0
            : +this.currentEstimate.crewSize;
          await workOrderEstimateService.updateWorkOrderEstimateModifiers(
            this.currentEstimate.id!,
            this.currentEstimate
          );
        }
        var snackbarPayload = {
          text: this.$t("scheduler.save-success", [this.workOrder.legacyID]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        if (closeOnComplete) {
          this.$router.back();
        } else {
          await this.loadWorkOrderDetails();
          if (saveEstimateModifiers) {
            await this.loadWorkOrderEstimateData();
          }
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    /*** LOADING ***/
    async loadWorkOrderDetails() {
      var workOrder = await workOrderService.getWorkOrderByID(this.workOrderID);
      this.workOrder = {
        ...workOrder,
        progress: !!workOrder.progress
          ? Math.max(Math.min(100, Math.round(workOrder.progress / 5) * 5), 0)
          : 0, // The progress picker is in increments of 5 so round to the nearest 5
        priority:
          !!workOrder.priority && workOrder.priority > 0 ? Math.min(workOrder.priority, 5) : 5,
        formattedStartDate: !!workOrder.startDate
          ? DateUtil.stripTimeFromLocalizedDateTime(workOrder.startDate)
          : "",
        formattedRequiredDate: !!workOrder.requiredDate
          ? DateUtil.stripTimeFromLocalizedDateTime(workOrder.requiredDate)
          : "",
        isWalkdownPlanned:
          !!workOrder.plannedWalkdownStartDate && workOrder.plannedWalkdownStartDate <= new Date(),
        isWorkPlanned:
          !!workOrder.plannedWorkStartDate && workOrder.plannedWorkStartDate <= new Date(),
        // re-sort the change logs descending (most recent first)
        urgentChangeLogs: workOrder.urgentChangeLogs.sort((a, b) => {
          let changedA = !!a.changed ? new Date(a.changed) : new Date(2999, 12, 31);
          let changedB = !!b.changed ? new Date(b.changed) : new Date(2999, 12, 31);
          return changedB.getTime() - changedA.getTime();
        })
      } as FormattedWorkOrderWithLegacyDetails;

      this.countSheetReadonly =
        !this.allowCountSheet ||
        this.workOrder.workOrderStatus != WorkOrderStatuses.CompletionPendingAdministration ||
        this.countSheet?.currentUserPermissions.canEditAnything == false;

      // Update disabled values based on loaded workorder
      this.workOrderSelectableStatuses.forEach(
        x =>
          (x.disabled = !CanSelectWorkOrderStatus(
            x.value,
            this.workOrder.workOrderStatus,
            this.workOrder.scaffoldRequestType,
            this.workOrder.currentUserPermissions.canEditStatus,
            this.workOrder.currentUserPermissions.canCancel,
            this.workOrder.currentUserPermissions.canEditProgress
          ))
      );

      this.selectedKeywords = this.workOrder.tagIDs
        ? (this.workOrder.tagIDs
            .map(x => this.allKeywords.find(y => y.id == x))
            .filter(x => !!x) as Keyword[])
        : [];

      if (this.workOrder.workPackages?.length) {
        let iwps = this.workOrder.workPackages.map(x => {
          return {
            ...x,
            nameCode: `${x.name} | ${x.activityID}`
          } as WorkPackageWithNameCode;
        });
        this.availableIWPs = iwps;
        this.selectedIWPs = iwps;
      }
    },

    // DOES NOT manage processing or error message logic
    async loadSelectableWorkOrderStatuses(): Promise<void> {
      var allStatuses = await workOrderStatusHelper.getWorkingSelectableWorkOrderStatuses();
      this.workOrderSelectableStatuses = allStatuses.map(
        x =>
          ({
            ...x,
            disabled: !CanSelectWorkOrderStatus(
              x.value,
              this.workOrder.workOrderStatus,
              this.workOrder.scaffoldRequestType,
              this.workOrder.currentUserPermissions.canEditStatus,
              this.workOrder.currentUserPermissions.canCancel,
              this.workOrder.currentUserPermissions.canEditProgress
            )
          } as WorkOrderStatusWithLegacyIDAndDisabled)
      );
    },

    // DOES NOT manage processing or error message logic
    async loadCoordinators(): Promise<void> {
      let coordinators = await personService.getAllCoordinators();
      this.allCoordinators = coordinators.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadGeneralForemen(): Promise<void> {
      let generalForemen = await personService.getAllGeneralForemen();
      this.allGeneralForemen = generalForemen.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadForemen(): Promise<void> {
      let foremen = await personService.getAllForemen();
      this.allForemen = foremen.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },

    async loadCountSheet() {
      if (!this.allowCountSheet) return;
      this.countSheet = await countSheetService.getByWorkOrderID(this.workOrder.id!);
    },

    async loadCountSheetGroupData(): Promise<void> {
      if (!this.allowCountSheet) return;
      await this.loadCountSheetGroups({
        forcedArchivedState: false,
        archivedFromDate: null,
        archivedToDate: null
      });
      this.countSheet = await countSheetService.getByWorkOrderID(this.workOrder.id!);

      let countSheetGroups = SortCountSheetGroups(
        (this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[])
          .filter(group => !!group.parts?.length)
          .map(
            group =>
              ({
                ...group,
                sortedParts: SortParts(
                  group.parts?.map(part =>
                    CountSheetGroupPartFromCountSheetPart(part, this.countSheet)
                  )
                )
              } as CountSheetGroupWithSortedParts)
          )
      );

      let ungroupedPartsWithDetails = this.countSheet.parts?.filter(x => !x.countSheetGroupName);
      if (!!ungroupedPartsWithDetails?.length) {
        let ungroupedParts = ungroupedPartsWithDetails.map(
          x =>
            ({
              id: x.partID,
              name: x.name,
              description: x.description,
              publicID: x.publicID
            } as Part)
        ) as Part[];
        let ungroupedGroup = {
          name: `${this.$t("common.other")}`,
          order: 999,
          parts: ungroupedParts
        } as CountSheetGroupWithParts;

        let ungroupedGroupWithSortedParts = {
          ...ungroupedGroup,
          sortedParts: SortParts(
            ungroupedGroup.parts?.map(part =>
              CountSheetGroupPartFromCountSheetPart(part, this.countSheet)
            )
          )
        } as CountSheetGroupWithSortedParts;

        countSheetGroups.push(ungroupedGroupWithSortedParts);
      }

      this.countSheetGroups = countSheetGroups;

      this.countSheetGroups.forEach(x => (x.parts = SortParts(x.parts)));
    },

    getCoordinatorsForContractor(contractorID: string) {
      var coordinatorsForContractor = SortItemsWithName(
        this.allCoordinators.filter(x => !!x.contractorID && x.contractorID == contractorID)
      );
      return coordinatorsForContractor;
    },

    getGeneralForemenForContractor(contractorID: string) {
      var generalForemenForContractor = SortItemsWithName(
        this.allGeneralForemen.filter(x => !!x.contractorID && x.contractorID == contractorID)
      );
      return generalForemenForContractor;
    },

    getForemenForContractor(contractorID: string) {
      var foremenForContractor = SortItemsWithName(
        this.allForemen.filter(x => !!x.contractorID && x.contractorID == contractorID)
      );
      return foremenForContractor;
    },
    async showWorkOrderSummary() {
      let workOrderDetails = {
        ...this.workOrder,
        workPackageNames: this.workOrder.workPackages?.map(x => x.name)
      } as WorkOrderDetails;
      await openWorkOrderDetailsDialog(workOrderDetails, this.workOrder.walkdown);
    },

    // *** HOT JOB LOG HISTORY ***
    validLogsForWorkOrder(
      workOrder: FormattedWorkOrderWithLegacyDetails
    ): WorkOrderUrgentLogWithDetails[] {
      // If the urgent flag is off, show all history
      if (!workOrder.isUrgent) return workOrder.urgentChangeLogs ?? [];
      if (!this.workOrder.urgentChangeLogs?.length) return [];
      let firstLog = this.workOrder.urgentChangeLogs[0];
      // The work order is urgent.  If the first log isn't, include it in the history instead of as hint text
      if (firstLog.isUrgent != undefined && !firstLog.isUrgent)
        return workOrder.urgentChangeLogs ?? [];
      let allLogs = workOrder.urgentChangeLogs;
      if (!allLogs?.length) return [];
      if (allLogs.length <= 1) return [];
      return allLogs.slice(1, allLogs.length);
    },

    currentUrgentLogSummaryString(): string | undefined {
      if (!this.workOrder.urgentChangeLogs?.length) return undefined;
      let firstLog = this.workOrder.urgentChangeLogs[0];
      if (firstLog.isUrgent != undefined && !firstLog.isUrgent) return undefined;
      return this.getUrgentLogSummaryString(firstLog);
    },

    formatLogChangedDate(log: WorkOrderUrgentLogWithDetails): string {
      if (!log.changed) return "";
      var changedDate = DateUtil.localizedDateTimeString(log.changed);
      return changedDate;
    },

    getLogActionIcon(log: WorkOrderUrgentLogWithDetails): string {
      var changeAction = "far fa-comment-alt-edit";
      // If the `isUrgent` value exists, then the value is what changed.  If it doesn't then the reason is what changed.
      if (log.isUrgent != undefined && log.isUrgent != null) {
        changeAction = log.isUrgent ? "far fa-fire-alt" : "far fa-ban";
      }
      return changeAction;
    },

    getLogActionString(log: WorkOrderUrgentLogWithDetails): string | TranslateResult {
      var changedBy = log.changedByName;
      var changeAction = this.$t("scheduler.urgent-detail-log-action-description", [changedBy]);
      // If the `isUrgent` value exists, then the value is what changed.  If it doesn't then the reason is what changed.
      if (log.isUrgent != undefined && log.isUrgent != null) {
        changeAction = log.isUrgent
          ? this.$t("scheduler.urgent-enabled-log-action-description", [changedBy])
          : this.$t("scheduler.urgent-disabled-log-action-description", [changedBy]);
      }
      return changeAction;
    },

    getUrgentLogSummaryString(log: WorkOrderUrgentLogWithDetails): string | undefined {
      return [this.getLogActionString(log), this.formatLogChangedDate(log)].join(" - ");
    },

    /*** WALKDOWN ***/
    async unassignWalkdown() {
      var item = this.workOrder;
      console.log(`flipWalkdownPlanned WorkOrder ID: ${item.legacyID}`);
      if (!this.workOrder.walkdown.currentUserPermissions.canUnassignWalkdown) return;

      if (!item.isWalkdownPlanned) {
        return;
      }

      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var updatedItem = await workOrderService.updateItem(
          item.id!,
          {
            id: item.id,
            legacyID: item.legacyID,
            plannedWalkdownStartDate: null
          } as WorkOrderWithLegacyDetails,
          "WorkOrderSchedulerExisting.unassignWalkdown"
        );

        item.plannedWalkdownStartDate = updatedItem.plannedWalkdownStartDate;
        item.isWalkdownPlanned =
          !!updatedItem.plannedWalkdownStartDate &&
          updatedItem.plannedWalkdownStartDate < new Date();

        await this.loadWorkOrderDetails();

        var snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.legacyID]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async submitWalkdown() {
      if (!this.workOrder.walkdown.currentUserPermissions.canPerformToDoListWalkdown) return;
      if (!(this.$refs.walkdownform as any).validate()) return;
      if (!((this.$refs.walkdownform as Vue).$refs.fdwalkdownform as HTMLFormElement).validate())
        return;

      this.processing = true;
      this.submitting = true;
      var originalWOStatus = this.workOrder.workOrderStatus;
      var originalWDStatus = this.workOrder.walkdown.walkdownStatus;
      try {
        await this.saveWalkdown(true);

        var snackbarPayload = {
          text: this.$t("scheduler.walkdown-submit-success", [this.workOrder.legacyID]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.back();
      } catch (error) {
        this.workOrder.walkdown.walkdownStatus = originalWDStatus;
        this.workOrder.workOrderStatus = originalWOStatus;
        this.handleError(error as Error);
        return false;
      } finally {
        this.processing = false;
        this.submitting = false;
      }
    },

    // Does not handle processing or error handling, meant to be called from another method that does
    // DO NOT CALL DIRECTLY FROM VIEW
    async saveWalkdown(submit: boolean) {
      if (submit) {
        this.workOrder.walkdown.walkdownStatus = WalkdownStatuses.Submitted;
        this.workOrder.workOrderStatus = WorkOrderStatuses.Estimated;
      } else if (this.workOrder.walkdown.walkdownStatus == WalkdownStatuses.Pending) {
        this.workOrder.walkdown.walkdownStatus = WalkdownStatuses.Draft;
      }
      this.workOrder.walkdown.length = !this.workOrder.walkdown.length
        ? null
        : +this.workOrder.walkdown.length;
      this.workOrder.walkdown.width = !this.workOrder.walkdown.width
        ? null
        : +this.workOrder.walkdown.width;
      this.workOrder.walkdown.height = !this.workOrder.walkdown.height
        ? null
        : +this.workOrder.walkdown.height;

      this.workOrder.walkdown.deckLevels = !this.workOrder.walkdown.deckLevels
        ? null
        : +this.workOrder.walkdown.deckLevels;
      this.workOrder.walkdown.barricadeGates = !this.workOrder.walkdown.barricadeGates
        ? null
        : +this.workOrder.walkdown.barricadeGates;
      console.log(this.workOrder.walkdown.vlfChangeAmount);
      this.workOrder.walkdown.vlfChangeAmount = !this.workOrder.walkdown.vlfChangeAmount
        ? null
        : +this.workOrder.walkdown.vlfChangeAmount;
      await walkdownService.updateItem(this.workOrder.walkdown.id!, this.workOrder.walkdown);
    },

    /*** ESTIMATES ***/
    parseWorkOrderEstimateDetails(e: WorkOrderEstimateWithLatest): WorkOrderEstimateWithDetails {
      let bayLength = (this.$store.state.scaffoldBayLengths.fullList as ScaffoldBayLength[]).find(
        l => l.id == e.scaffoldBayLengthID
      );
      let lengthBayCount =
        !!bayLength?.inches && bayLength.inches > 0
          ? Math.floor(((e.width ?? 0) * 12) / bayLength.inches)
          : 0;
      let bayWidth = (this.$store.state.scaffoldBayWidths.fullList as ScaffoldBayWidth[]).find(
        l => l.id == e.scaffoldBayWidthID
      );
      let widthBayCount =
        !!bayWidth?.inches && bayWidth.inches > 0
          ? Math.floor(((e.width ?? 0) * 12) / bayWidth.inches)
          : 0;
      let bayHeight = (this.$store.state.scaffoldBayHeights.fullList as ScaffoldBayHeight[]).find(
        l => l.id == e.scaffoldBayHeightID
      );

      let typeModifier = this.allScaffoldTypes.find(mod => mod.id == e.scaffoldTypeModifierID);
      let heightModifier = this.allScaffoldHeights.find(
        mod => mod.id == e.scaffoldHeightModifierID
      );
      let distanceModifier = this.allScaffoldDistances.find(
        mod => mod.id == e.scaffoldDistanceModifierID
      );
      let elevationModifier = this.allScaffoldElevations.find(
        mod => mod.id == e.scaffoldElevationModifierID
      );
      let congestionFactor = this.allScaffoldCongestionFactors.find(
        mod => mod.id == e.scaffoldCongestionFactorID
      );
      let buildDismantleRatio = this.allBuildDismantleRatios.find(
        mod => mod.id == e.buildDismantleRatioID
      );
      let internalModifier = this.allInternalModifiers.find(mod => mod.id == e.internalModifierID);
      let hoardingModifier = this.allHoardingModifiers.find(mod => mod.id == e.hoardingModifierID);
      return {
        ...e,
        generationTypeName: this.$t(
          `work-order-estimates.generation-types.${e.generationTypeID ?? 0}`
        ),
        generationMethodName: !!e.walkdownID
          ? this.$t("scheduler.estimates.generation-methods.walkdown")
          : this.$t("scheduler.estimates.generation-methods.manual"),
        dateTimeString: DateUtil.localizedDateTimeString(
          new Date(DateUtil.isoDateTimeString(e.created))
        ),
        dateString: DateUtil.stripTimeFromLocalizedDateTime(
          new Date(DateUtil.isoDateTimeString(e.created))
        ),
        bayLengthName: bayLength?.name,
        lengthBayCount: lengthBayCount,
        bayWidthName: bayWidth?.name,
        widthBayCount: widthBayCount,
        bayHeightName: bayHeight?.name,

        estimatedTotalTime:
          (e.estimatedTotalDemobilizationMinutes ?? 0.0) +
          (e.estimatedTotalDismantleMinutes ?? 0.0) +
          (e.estimatedTotalErectMinutes ?? 0.0) +
          (e.estimatedTotalHoardingMinutes ?? 0.0) +
          (e.estimatedTotalMobilizationMinutes ?? 0.0) +
          (e.estimatedTotalModifyMinutes ?? 0.0),
        estimatedErectMPP: (e.estimatedTotalErectMinutes ?? 0.0) / (e.estimatedTotalPartCount ?? 1),
        estimatedDismantleMPP:
          (e.estimatedTotalDismantleMinutes ?? 0.0) / (e.estimatedTotalPartCount ?? 1),

        scaffoldTypeModifierName: typeModifier?.name,
        scaffoldHeightModifierName: heightModifier?.name,
        scaffoldDistanceModifierName: distanceModifier?.name,
        scaffoldElevationModifierName: elevationModifier?.name,
        scaffoldCongestionFactorName: congestionFactor?.name,
        buildDismantleRatioName: buildDismantleRatio?.ratio,
        internalModifierName: internalModifier?.name,
        hoardingModifierName: hoardingModifier?.name
      } as WorkOrderEstimateWithDetails;
    },

    async loadWorkOrderEstimateData() {
      this.loadingEstimateTimeSummary = true;
      try {
        let estimates = await workOrderEstimateService.getEstimatesForWorkOrderWithID(
          this.workOrder.id!
        );
        this.workOrderEstimates = estimates
          .sort((a, b) => {
            // Sort with newest at top
            let aCreated = new Date(a.created!);
            let bCreated = new Date(b.created!);
            return bCreated.getTime() - aCreated.getTime();
          })
          .map(this.parseWorkOrderEstimateDetails);
        this.latestEstimateTimeTotals = undefined;

        this.takeoffParts = await workOrderTakeoffService.getTakeoffForWorkOrderWithID(
          this.workOrder.id!
        );

        this.workOrderActualsEstimatedTimes = await workOrderEstimateService.getWorkOrderActualsEstimatedTimeTotals(
          this.workOrder.id!
        );
      } catch (error) {
        throw error;
      } finally {
        this.loadingEstimateTimeSummary = false;
      }
    },

    async openNewEstimateDialog(designed: boolean) {
      // Grab the latest estimate to pass in to the dialog to default the modifier values
      let estimate = this.currentEstimate;
      let created = false;
      if (designed) {
        created = await createWorkOrderEstimateDesignedNewDialog(this.workOrder, estimate);
      } else {
        // We're opening a new LWH dialog, so we need more than modifier values
        // If the estimate is a designed estimate, grab the latest LWH dialog for default estimate values
        if (estimate?.generationTypeID == PartGenerationType.Designed) {
          let lwhEstimates = this.workOrderEstimates?.filter(
            e =>
              e.generationTypeID == PartGenerationType.LWH ||
              e.generationTypeID == PartGenerationType.Modify
          );
          if (!!lwhEstimates?.length) {
            estimate = lwhEstimates.find(x => !!x.isLatest) ?? lwhEstimates[0];
            console.log(`estimate replace with LWH estimate: ${JSON.stringify(estimate)}`);
          }
        }

        created = await createWorkOrderEstimateLWHNewDialog(this.workOrder, estimate);
      }
      if (created) {
        this.processing = true;
        try {
          await this.loadWorkOrderEstimateData();
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = false;
        }
      }
    },

    async reloadCurrentEstimateTimeSummary() {
      this.processing = true;
      this.loadingEstimateTimeSummary = true;
      try {
        this.currentEstimate.modificationPercent = !this.currentEstimate.modificationPercent
          ? 0
          : +this.currentEstimate.modificationPercent;
        this.currentEstimate.factor1 = !this.currentEstimate.factor1
          ? 0
          : +this.currentEstimate.factor1;
        this.currentEstimate.factor2 = !this.currentEstimate.factor2
          ? 0
          : +this.currentEstimate.factor2;
        this.currentEstimate.crewSize = !this.currentEstimate.crewSize
          ? 0
          : +this.currentEstimate.crewSize;
        let latestEstimateTimeTotals = await workOrderEstimateService.getWorkOrderLegacyEstimateTotalTimesWithNewModifiers(
          this.workOrder.id!,
          this.currentEstimate
        );
        this.latestEstimateTimeTotals = {
          ...latestEstimateTimeTotals,
          estimatedTotalTime:
            (latestEstimateTimeTotals.estimatedTotalDemobilizationMinutes ?? 0.0) +
            (latestEstimateTimeTotals.estimatedTotalDismantleMinutes ?? 0.0) +
            (latestEstimateTimeTotals.estimatedTotalErectMinutes ?? 0.0) +
            (latestEstimateTimeTotals.estimatedTotalHoardingMinutes ?? 0.0) +
            (latestEstimateTimeTotals.estimatedTotalMobilizationMinutes ?? 0.0) +
            (latestEstimateTimeTotals.estimatedTotalModifyMinutes ?? 0.0),
          estimatedErectMPP:
            (latestEstimateTimeTotals.estimatedTotalErectMinutes ?? 0.0) /
            (latestEstimateTimeTotals.estimatedTotalPartCount ?? 1),
          estimatedDismantleMPP:
            (latestEstimateTimeTotals.estimatedTotalDismantleMinutes ?? 0.0) /
            (latestEstimateTimeTotals.estimatedTotalPartCount ?? 1)
        };
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.loadingEstimateTimeSummary = false;
      }
    },

    async downloadAndPrintTakeoffReport(reportType: string) {
      let parts = this.takeoffParts.map(
        x =>
          ({
            ...x,
            partID: x.partID!,
            name: x.partName,
            code: x.partPublicID,
            description: x.partDescription
          } as TakeoffPrintoutPart)
      );
      let areaName = this.allAreas.find(x => x.id == this.workOrder.areaID)?.name;
      let subAreaName = this.allSubAreas.find(x => x.id == this.workOrder.subAreaID)?.name;
      let workOrderCostCodeName = (this.$store.state.projectCostCodes
        .fullList as ProjectCostCode[]).find(x => x.id == this.workOrder.costCodeID)?.name;
      this.processing = true;
      try {
        var blob = await reportService.getTakeoffPrintoutReportContentWithData(
          parts,
          this.currentEstimate?.id ?? null,
          this.workOrder.scaffoldID ?? null,
          this.workOrder.legacyID?.toString() ?? "",
          workOrderCostCodeName ?? "",
          areaName ?? "",
          subAreaName ?? "",
          reportType,
          DateUtil.localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "takeoff-printout.xlsx");
        } else {
          printBlob(blob, "takeoff-printout.pdf", "application/pdf");
        }
      } catch (error) {
      } finally {
        this.processing = false;
      }
    },

    async clearTakeoffParts() {
      this.processing = true;
      try {
        // Clearing the parts is the equivalent to creating a new, empty designed estimate
        // This tracks the fact that the user changed something, and also gives a new record to associate the new time calculations too
        // without putting new times to old estimate data.
        let latestEstimate = {} as WorkOrderEstimateWithDetails;
        if (!!this.workOrderEstimates?.length)
          latestEstimate =
            this.workOrderEstimates.find(x => !!x.isLatest) ?? this.workOrderEstimates[0];
        await workOrderEstimateService.generateNewDesignedEstimateForWorkOrderWithID(
          this.workOrder.id!,
          [],
          latestEstimate
        );

        await this.loadWorkOrderEstimateData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadWorkPackages(searchString: string) {
      if (!searchString?.length) this.availableIWPs = [];
      else {
        let allIWPs = await workPackageService.searchAll(searchString);
        this.availableIWPs = allIWPs.map(x => {
          return {
            ...x,
            nameCode: `${x.name} | ${x.activityID}`
          };
        });
      }
    },

    /*** NOTES ***/
    async addNewNote() {
      if (!this.newNoteText.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newNote = await noteService.addNewNoteForWorkOrder(
          this.newNoteText,
          this.workOrder.id!
        );
        this.inlineMessage.message = "";
        let noteToAdd = ParseNoteWithSenderDetails(newNote);
        noteToAdd.isNew = true;
        this.notes.push(noteToAdd);
        this.notes = SortNotesArray(this.notes);
        this.newNoteText = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // *** MESSAGES ***
    async addNewMessage() {
      if (!this.newMessageText.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newMessage = await messageService.addNewMessageForWorkOrder(
          this.newMessageText,
          this.workOrder.id!
        );
        this.inlineMessage.message = "";
        this.messages.push(ParseMessageWithSenderDetails(newMessage, this.curUserID));
        this.messages = SortMessagesArray(this.messages);
        this.newMessageText = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.file) {
        await this.deleteFile(item.file);
      }
    },

    // Links
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        newLink.scaffoldRequestID = this.workOrder.scaffoldRequestID;
        await externalLinkService.addItem(newLink);

        if (!this.workOrder.externalLinks) this.workOrder.externalLinks = [];
        this.workOrder.externalLinks.push(newLink);

        this.showAttachmentTabPhotoAlert = false;
        this.showPhotoTabAttachmentAlert = false;

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.save-link-success", [
            newLink.name
          ]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = newLink.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        let currentProcessing = this.processing;
        this.processing = true;
        try {
          await externalLinkService.updateItem(link.id!, {
            ...link,
            name: editedLink.name,
            address: editedLink.address
          });
          link.name = editedLink.name;
          link.address = editedLink.address;

          var snackbarPayload = {
            text: this.$t("scaffold-requests.existing-scaffold-request.update-link-success", [
              link.name
            ]),
            type: "success"
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.touchedFileName = link.name ?? "";
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
        }
      }
    },
    async deleteLink(link: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        await externalLinkService.deleteItem(link.id!);
        this.workOrder.externalLinks.splice(this.workOrder.externalLinks.indexOf(link), 1);

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.delete-link-success", [
            link.name
          ]),
          type: "info",
          undoCallback: async () => {
            await this.saveNewExternalLink(link);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = link.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },

    // Files & Photos
    async loadFiles() {
      if (!this.workOrder.scaffoldRequestID) return;

      var fileNames = await scaffoldRequestService.getScaffoldRequestFileList(
        this.workOrder.scaffoldRequestID
      );
      this.allFiles = fileNames.map(function(fileName) {
        return {
          name: fileName,
          isPreviewable: isFilePreviewable(fileName),
          isPhoto: isFilePhoto(fileName)
        } as FileData;
      });
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;

      // GIF files with animations will lose their animation during this process
      // Both due to the quality compression done above (resizing the dimensions of an animated GIF does nothing), and also going through the edit image process
      // This is OK as we shouldn't need animations for any reason
      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: FileData, originalFileName: string) {
      if (!fileData) return;
      if (!this.workOrder.scaffoldRequestID) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
            this.workOrder.scaffoldRequestID,
            fileNameToDownload
          );
        }
        await scaffoldRequestService.uploadScaffoldRequestFile(
          this.workOrder.scaffoldRequestID,
          fileData.name,
          fileData.file as Blob
        );
        await scaffoldRequestService.uploadScaffoldRequestLegacyFile(
          this.workOrder.scaffoldRequestID,
          fileData.name,
          fileData.file as Blob
        );

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await scaffoldRequestService.deleteScaffoldRequestFile(
            this.workOrder.scaffoldRequestID,
            originalFileName
          );
        }
        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.update-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.update-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      if (!fileData) return;
      if (!this.workOrder.scaffoldRequestID) return;

      this.processing = true;
      try {
        await scaffoldRequestService.uploadScaffoldRequestFile(
          this.workOrder.scaffoldRequestID,
          fileData.name,
          fileData.file as Blob
        );
        await scaffoldRequestService.uploadScaffoldRequestLegacyFile(
          this.workOrder.scaffoldRequestID,
          fileData.name,
          fileData.file as Blob
        );

        this.allFiles.push(fileData);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.save-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.save-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.touchedFileName = fileData.name;
        this.showPhotoTabAttachmentAlert = this.selectedTab == this.photosTab && !fileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.selectedTab == this.attachmentsTab && fileData.isPhoto == true;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPhoto) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      if (!!fileData.file) {
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.editImageSource = `/services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile?requestId=${this.workOrder.scaffoldRequestID}&fileName=${fileData.name}`;
      }
    },
    async downloadFile(fileData: FileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }
      if (!this.workOrder.scaffoldRequestID) return;

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.workOrder.scaffoldRequestID!,
          fileName
        );
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      if (!this.workOrder.scaffoldRequestID) return;

      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        // the data probably hasn't been downloaded yet
        fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.workOrder.scaffoldRequestID,
          fileData.name
        );
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async viewFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;
      if (!this.workOrder.scaffoldRequestID) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.workOrder.scaffoldRequestID!,
          fileData.name
        );
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.imageSource = `/services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile?requestId=${this.workOrder.scaffoldRequestID}&fileName=${fileData.name}`;
      }
    },
    async deleteFile(fileData: FileData) {
      if (!this.workOrder.scaffoldRequestID) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
            this.workOrder.scaffoldRequestID!,
            fileData.name
          );
        }
        await scaffoldRequestService.deleteScaffoldRequestFile(
          this.workOrder.scaffoldRequestID!,
          fileData.name
        );

        this.allFiles.splice(this.allFiles.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.delete-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.delete-file-success", [
              fileData.name
            ]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // *** COUNT SHEET ***
    async saveAndSubmitCountSheet() {
      if (!this.allowCountSheet) return;
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.submitting = true;
      try {
        var saved = await this.saveCountSheet();
        if (saved) {
          var submitted = await this.submitCountSheet();
          if (submitted) {
            var snackbarPayload = {
              text: this.$t("scheduler.count-sheet-submit-success", [this.workOrder.legacyID]),
              type: "success",
              undoCallback: null
            };
            this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

            this.$router.back();
          }
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.submitting = false;
      }
    },
    async saveCountSheet(): Promise<boolean> {
      if (!this.countSheet) return false;

      var materialNotRequired = this.countSheet.countSheetTypeID == CountSheetType.NotApplicable;
      let countSheet = this.countSheet;
      if (materialNotRequired) {
        this.countSheet.parts = [];
      } else {
        this.countSheet.parts = SummarizeModifiedPartsInGroups(this.countSheetGroups).map(x =>
          CountSheetPartFromGroupPart(x, countSheet)
        );
      }
      await countSheetService.saveCountSheet(this.countSheet.id!, this.countSheet);
      return true;
    },
    async submitCountSheet(): Promise<boolean> {
      if (
        !this.countSheet ||
        (!SummarizeModifiedPartsInGroups(this.countSheetGroups)?.length &&
          this.countSheet.countSheetTypeID == CountSheetType.Individual)
      ) {
        this.inlineMessage.message = this.$t(
          "countsheet.dialog.part-selection-required-to-submit-message"
        );
        this.inlineMessage.type = "error";
        return false;
      }

      // Parts for summary always includes rejected rows and rows with assigned totals
      // So we can't rely on that to always tell us if things have a count or not
      // Also, for a submit we can't have any overridden values, so we can ignore override related values
      var addedOrRemovedParts = SummarizeModifiedPartsInGroups(this.countSheetGroups).filter(
        x => x.addCount > 0 || x.removeCount > 0
      );

      if (
        !addedOrRemovedParts.length &&
        this.countSheet.countSheetTypeID == CountSheetType.Individual
      ) {
        this.inlineMessage.message = this.$t(
          "countsheet.dialog.part-selection-required-to-submit-message"
        );
        this.inlineMessage.type = "error";
        return false;
      }

      var updatedCountSheet = await countSheetService.submitCountSheet(this.countSheet.id!);
      this.countSheet.reviewStatusID = updatedCountSheet.reviewStatusID;
      this.countSheet.countSheetTypeID = updatedCountSheet.countSheetTypeID;
      this.workOrder.countSheet = this.countSheet;
      this.workOrder.workOrderStatus = updatedCountSheet.workOrder.workOrderStatus;
      this.workOrder.workOrderStatusName = updatedCountSheet.workOrder.workOrderStatusName;
      this.workOrder.isArchived = updatedCountSheet.workOrder.isArchived;
      this.workOrder.archivedDate = updatedCountSheet.workOrder.archivedDate;
      this.workOrder.completedDate = updatedCountSheet.workOrder.completedDate;
      this.workOrder.progress = updatedCountSheet.workOrder.progress;
      return true;
    }
  },

  watch: {
    // "currentEstimate.scaffoldTypeModifierID": async function(newValue, oldValue) {
    //   if (oldValue == newValue) return;
    //   await this.reloadCurrentEstimateTimeSummary();
    // },
    // "currentEstimate.scaffoldDistanceModifierID": async function(newValue, oldValue) {
    //   if (oldValue == newValue) return;
    //   await this.reloadCurrentEstimateTimeSummary();
    // },
    // "currentEstimate.scaffoldElevationModifierID": async function(newValue, oldValue) {
    //   if (oldValue == newValue) return;
    //   await this.reloadCurrentEstimateTimeSummary();
    // },
    // "currentEstimate.scaffoldHeightModifierID": async function(newValue, oldValue) {
    //   if (oldValue == newValue) return;
    //   await this.reloadCurrentEstimateTimeSummary();
    // },
    // "currentEstimate.buildDismantleRatioID": async function(newValue, oldValue) {
    //   if (oldValue == newValue) return;
    //   await this.reloadCurrentEstimateTimeSummary();
    // },
    // "currentEstimate.scaffoldCongestionFactorID": async function(newValue, oldValue) {
    //   if (oldValue == newValue) return;
    //   await this.reloadCurrentEstimateTimeSummary();
    // },
    // "currentEstimate.internalModifierID": async function(newValue, oldValue) {
    //   if (oldValue == newValue) return;
    //   await this.reloadCurrentEstimateTimeSummary();
    // },
    // "currentEstimate.hoardingModifierID": async function(newValue, oldValue) {
    //   if (oldValue == newValue) return;
    //   await this.reloadCurrentEstimateTimeSummary();
    // },
    "workOrder.areaID": function() {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.workOrder.subAreaID &&
        !this.subAreas.map(x => x.id).includes(this.workOrder.subAreaID)
      ) {
        this.workOrder.subAreaID = null;
      }
    },
    workOrder(newValue: WorkOrderWithLegacyDetails) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if (this.$route.name == "SchedulerExisting") {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scheduler") {
          this.notifyNewBreadcrumb({
            text: this.$t("scheduler.list-title"),
            to: "/scheduler",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        var breadcrumbText = `WO #${newValue.legacyID}`;
        this.notifyNewBreadcrumb({
          text: breadcrumbText,
          to: `/scheduler/${newValue.id}`
        });
      } else if (this.$route.name == "EstimatedWorkOrderExisting") {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/estimatedworkorders") {
          this.notifyNewBreadcrumb({
            text: this.$t("work-order-estimates.list-title"),
            to: "/estimatedworkorders",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        var breadcrumbText = `WO #${newValue.legacyID}`;
        this.notifyNewBreadcrumb({
          text: breadcrumbText,
          to: `/estimatedworkorders/${newValue.id}`
        });
      } else if (this.$route.name == "WorkOrderHistoryExisting") {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/workorderhistory") {
          this.notifyNewBreadcrumb({
            text: this.$t("work-order-history.list-title"),
            to: "/workorderhistory",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        var breadcrumbText = `WO #${newValue.legacyID}`;
        this.notifyNewBreadcrumb({
          text: breadcrumbText,
          to: `/workorderhistory/${newValue.id}`
        });
      } else if (this.$route.name == "AdministrationExisting") {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/administration") {
          this.notifyNewBreadcrumb({
            text: this.$t("work-order-admin.list.title"),
            to: "/administration",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        var breadcrumbText = `WO #${newValue.legacyID}`;
        this.notifyNewBreadcrumb({
          text: breadcrumbText,
          to: `/administration/${newValue.id}`
        });
      } else if (this.$route.name == "CountSheetAdministrationExisting") {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/countsheetadministration") {
          this.notifyNewBreadcrumb({
            text: this.$t("work-order-admin.list.count-sheet-admin-title"),
            to: "/countsheetadministration",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        var breadcrumbText = `WO #${newValue.legacyID}`;
        this.notifyNewBreadcrumb({
          text: breadcrumbText,
          to: `/countsheetadministration/${newValue.id}`
        });
      } else if (this.$route.name == "WorkOrderExisting") {
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        var breadcrumbText = `WO #${newValue.legacyID}`;
        this.notifyNewBreadcrumb({
          text: breadcrumbText,
          to: `/workorder/${newValue.id}`
        });
      }
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Load up breadcrumb placeholder
    if (this.$route.name == "SchedulerExisting") {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scheduler") {
        this.notifyNewBreadcrumb({
          text: this.$t("scheduler.list-title"),
          to: "/scheduler",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: ``,
        to: `/scheduler/${this.$route.params.id}`
      });

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "scheduler-existing",
        parentalContext: "scheduler",
        selectedTab: this.firstTabKey
      });
    } else if (this.$route.name == "EstimatedWorkOrderExisting") {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/estimatedworkorders") {
        this.notifyNewBreadcrumb({
          text: this.$t("work-order-estimates.list-title"),
          to: "/estimatedworkorders",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: ``,
        to: `/estimatedworkorders/${this.$route.params.id}`
      });

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "work-order-estimates-existing",
        parentalContext: "work-order-estimates",
        selectedTab: this.firstTabKey
      });
    } else if (this.$route.name == "WorkOrderHistoryExisting") {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/workorderhistory") {
        this.notifyNewBreadcrumb({
          text: this.$t("work-order-history.list-title"),
          to: "/workorderhistory",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: ``,
        to: `/workorderhistory/${this.$route.params.id}`
      });

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "work-order-history-existing",
        parentalContext: "history",
        selectedTab: this.firstTabKey
      });
    } else if (this.$route.name == "AdministrationExisting") {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/administration") {
        this.notifyNewBreadcrumb({
          text: this.$t("work-order-admin.list.title"),
          to: "/administration",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: ``,
        to: `/administration/${this.$route.params.id}`
      });

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "work-order-admin-existing",
        parentalContext: "work-order-admin",
        selectedTab: this.firstTabKey
      });
    } else if (this.$route.name == "CountSheetAdministrationExisting") {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/countsheetadministration") {
        this.notifyNewBreadcrumb({
          text: this.$t("work-order-admin.list.count-sheet-admin-title"),
          to: "/countsheetadministration",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: ``,
        to: `/countsheetadministration/${this.$route.params.id}`
      });

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "count-sheet-admin-existing",
        parentalContext: "work-order-admin",
        selectedTab: this.firstTabKey
      });
    } else if (this.$route.name == "WorkOrderExisting") {
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");

      this.notifyNewBreadcrumb({
        text: ``,
        to: `/workorder/${this.$route.params.id}`
      });

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "work-order-existing",
        parentalContext: "null",
        selectedTab: this.firstTabKey
      });
    }

    await this._initialize();
  },

  beforeUpdate: async function() {
    if (!this.workOrderID) {
      await this._initialize();
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (this.workOrderID != to.params.id) {
      this.workOrderID = "";
    }
    next();
  }
});

