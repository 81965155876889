import { render, staticRenderFns } from "./SP.ScaffoldDesigns.vue?vue&type=template&id=a3c26cc0&"
import script from "./SP.ScaffoldDesigns.vue.ts?vue&type=script&lang=ts&"
export * from "./SP.ScaffoldDesigns.vue.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a3c26cc0')) {
      api.createRecord('a3c26cc0', component.options)
    } else {
      api.reload('a3c26cc0', component.options)
    }
    module.hot.accept("./SP.ScaffoldDesigns.vue?vue&type=template&id=a3c26cc0&", function () {
      api.rerender('a3c26cc0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/estimationtool/client/views/SP.ScaffoldDesigns.vue"
export default component.exports