import FDVue from "..";
import rules from "../rules";

export default FDVue.extend({
  name: "fp-add-remove-field",

  inheritAttrs: false,

  mixins: [rules],

  props: {
    value: { type: Number, default: 0 },
    beforeValue: { type: Number, default: 0 },
    allowAdd: { type: Boolean, default: true },
    allowRemove: { type: Boolean, default: true },
    label: { type: String, default: "" },
    beforeLabel: {
      type: String,
      default: function() {
        return this.$t("fp-add-remove-field.before");
      }
    },
    addLabel: {
      type: String,
      default: function() {
        return this.$t("fp-add-remove-field.add");
      }
    },
    removeLabel: {
      type: String,
      default: function() {
        return this.$t("fp-add-remove-field.remove");
      }
    },
    afterLabel: {
      type: String,
      default: function() {
        return this.$t("fp-add-remove-field.after");
      }
    },
    showInfo: { type: Boolean, default: true },
    info: {
      type: String,
      default: function() {
        return this.$t("fp-add-remove-field.default-info-message");
      }
    },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: [Boolean, String], default: false },

    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fp-add-remove-field" }
  },
  data: () => ({
    addError: false,
    removeError: false
  }),
  computed: {
    isRequired(): boolean {
      let isRequired = !(
        this.required == undefined ||
        this.required == "false" ||
        this.required === false
      );
      return isRequired;
    },
    /** Sanitized equivalent of the current value */
    sanitizedValue(): number {
      return this.sanitizeNumber(this.value);
    },
    /** Sanitized value if positive */
    addValue(): number {
      if (this.sanitizedValue > 0) return this.sanitizedValue;
      return 0;
    },
    /** Sanitized positive equivalent of the value, if the value is negative. */
    removeValue(): number {
      if (this.sanitizedValue < 0) return Math.abs(this.sanitizedValue);
      return 0;
    },
    /** Total of all entered values: Before + Add - Remove */
    afterValue(): number {
      return this.sanitizeNumber(this.beforeValue) + this.addValue - this.removeValue;
    }
  },
  methods: {
    validate(): boolean {
      return this.validateAdd(this.addValue) && this.validateRemove(this.removeValue);
    },
    validateAdd(value: any): boolean {
      this.addError = false;

      let addValue = this.sanitizeNumber(value);
      if (addValue > 0 && !this.allowAdd) this.addError = true;

      if (!!this.allowAdd && this.isRequired && !addValue && !this.removeValue)
        this.addError = true;

      return !this.addError;
    },
    validateRemove(value: any): boolean {
      this.removeError = false;

      let removeValue = this.sanitizeNumber(value);
      if (removeValue > 0 && !this.allowRemove) this.removeError = true;

      if (!!this.allowRemove && this.isRequired && !removeValue && !this.addValue)
        this.removeError = true;

      return !this.removeError;
    },
    /**
     * Validates the value is a number and returns the equivalent value to 2 decimal places.
     */
    sanitizeNumber(value: number | string | undefined | null): number {
      if (!value) return 0;
      let number = Number(value);
      if (isNaN(number)) return 0;
      return Number(number.toFixed(0));
    },
    addValueChanged(newAddValue: any) {
      this.validateAdd(newAddValue);
      this.$emit("input", Math.abs(this.sanitizeNumber(newAddValue)));
      this.$nextTick(() => {
        this.validateRemove(this.removeValue);
      });
    },
    removeValueChanged(newRemoveValue: any) {
      this.validateRemove(newRemoveValue);
      this.$emit("input", -Math.abs(this.sanitizeNumber(newRemoveValue)));
      this.$nextTick(() => {
        this.validateAdd(this.addValue);
      });
    }
  }
});

