import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { mapMutations, mapActions } from "vuex";
import { filterByTags } from "../services/taggableItems";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { createNewContractor } from "./components/ContractorNewDialog.vue";
import { ContractorWithTags } from "../services";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";
import userAccess from "../dataMixins/userAccess";

type ContractorWithDetails = ContractorWithTags & {
  archived: boolean;
};

export default FDVue.extend({
  name: "fd-Contractors",

  mixins: [errorHandling, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-archived-data-loader": () => import("@fd/lib/vue/components/ArchivedDataLoader.vue")
  },

  data: function() {
    return {
      archivedLoading: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },

  computed: {
    actionColumnArgument(): string {
      // If the user CAN configure settings, they get both a DELETE and EDIT button.  If not, they only get an EDIT button.
      return this.currentUserCanConfigureSettings ? "actions" : "action";
    },
    contractors(): ContractorWithDetails[] {
      return (filterByTags(
        this.tagsSelectedForFiltering,
        this.$store.state.contractors.fullList
      ) as ContractorWithTags[]).map(x => {
        return {
          ...x,
          description: truncateWithEllipsis(stripHtml(x.description)),
          archived: !!x.archivedDate
        } as ContractorWithDetails;
      });
    },
    tagsInUse(): any[] {
      return this.$store.getters.getSortedInUseTags(this.$store.state.contractors.fullList);
    },

    showArchived: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        this.processing = true;
        this.archivedLoading = true;
        try {
          await this.reloadContractors();
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = false;
          this.archivedLoading = false;
        }
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.reloadContractors();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    tagsSelectedForFiltering: {
      get(): any[] {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val: any[]) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    async openNewDialog() {
      this.optOutOfErrorHandling();
      await createNewContractor();
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteContractor({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async flipArchived(item: ContractorWithDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateContractor({ id: item.id, archivedDate: archivedDate, name: item.name });
        await this.reloadContractors();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.reloadContractors();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async reloadContractors() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadContractors({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadTags: "LOAD_TAGS",
      updateContractor: "UPDATE_CONTRACTOR",
      deleteContractor: "DELETE_CONTRACTOR"
    })
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.

    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "contractors",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: []
    });

    this.notifyNewBreadcrumb({
      text: this.$t("contractors.list-title"),
      to: "/contractors",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([this.reloadContractors(), this.loadTags()]);
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

