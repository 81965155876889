import FDVue from "@fd/lib/vue";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import Vue from "vue";
import { WorkOrderTakeoffPart } from "../../../services";

export default FDVue.extend({
  name: "",
  directives: {
    fdColumn: FDColumnDirective
  },
  props: {
    currentEstimate: {},
    workOrderActualsEstimatedTimes: {},
    workOrderActualWorkedTimes: {},
    processing: {}
  },
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    formatNumber(
      number: string | number | undefined | null,
      digits: number = 2,
      allowZero: boolean = false
    ): string | undefined {
      if (number == undefined || number == null) return undefined;
      let val = Number(number);
      if (isNaN(val)) return undefined;
      if (!allowZero && val == 0) return undefined;
      return val.toFixed(digits);
    }
  }
});

