var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "designedform" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            {
              staticClass: "mx-0 pl-0 pr-0 pb-0 pt-3",
              attrs: { align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12" }
                },
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "work-order-estimates.new-estimate.bcount-label"
                      ),
                      value: _vm.bCountString,
                      rules: [_vm.rules.required],
                      "hide-details": ""
                    },
                    on: {
                      input: function(v) {
                        return _vm.$emit("update:bCountString", v)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.bCountString || _vm.bCountString.length == 0
            ? _c("fd-alert", {
                staticClass: "mx-3",
                attrs: {
                  type: "info",
                  label: _vm.$t(
                    "work-order-estimates.new-estimate.enter-bcount-message"
                  )
                }
              })
            : _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "secondary-section-style mt-3" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "work-order-estimates.new-estimate.bcount-parts-list"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "fd-embedded-table-container",
                              attrs: { fluid: "" }
                            },
                            [
                              _c("v-data-table", {
                                directives: [
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:name",
                                    arg: "name"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:quantity",
                                    arg: "quantity"
                                  }
                                ],
                                attrs: {
                                  dense: "",
                                  items: _vm.bCountPartQuantities,
                                  "mobile-breakpoint": "0",
                                  "items-per-page": 5
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
          _vm._v(" "),
          _vm.invalidBCountPartCodes.length > 0
            ? _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            color: "error",
                            transition: "expand-transition"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                align: "center",
                                "no-gutters": "",
                                dense: _vm.$vuetify.breakpoint.xsOnly
                              }
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "shrink pr-2" },
                                [
                                  _c(
                                    "v-icon",
                                    { staticStyle: { color: "inherit" } },
                                    [_vm._v("mdi-alert")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "grow pr-1" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "work-order-estimates.new-estimate.invalid-bcount-part-codes-message"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "shrink" },
                                [
                                  _vm.showingInvalidPartCodes
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "white"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showingInvalidPartCodes = false
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.breakpoint.xsOnly
                                                ? _vm.$t(
                                                    "work-order-estimates.new-estimate.hide-part-codes-button-label-short"
                                                  )
                                                : _vm.$t(
                                                    "work-order-estimates.new-estimate.hide-part-codes-button-label"
                                                  )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.showingInvalidPartCodes
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "white"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showingInvalidPartCodes = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.breakpoint.xsOnly
                                                ? _vm.$t(
                                                    "work-order-estimates.new-estimate.show-part-codes-button-label-short"
                                                  )
                                                : _vm.$t(
                                                    "work-order-estimates.new-estimate.show-part-codes-button-label"
                                                  )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expand-transition",
                            [
                              _vm.showingInvalidPartCodes
                                ? _c(
                                    "v-container",
                                    {
                                      staticStyle: {
                                        "font-size": "0.75rem !important"
                                      }
                                    },
                                    _vm._l(_vm.invalidBCountPartCodes, function(
                                      code
                                    ) {
                                      return _c(
                                        "v-row",
                                        {
                                          key: code,
                                          attrs: { "no-gutters": "", dense: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                - " +
                                              _vm._s(code) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }