import FDVue from "@fd/lib/vue";
import { PropValidator } from "vue/types/options";

export interface WorkOrderScopeDetails {
  isChangeOrder: boolean;
  changeOrderReferenceNumber: string | null;
  isRework: boolean;
  reworkReferenceNumber: string | null;
}

export default FDVue.extend({
  name: "fd-work-order-scope-details",

  components: {
    "fd-work-order-detail-value": () => import("./fields/WorkOrderDetailValue.vue"),
    "fd-work-order-detail-flag-value": () => import("./fields/WorkOrderDetailFlagValue.vue")
  },

  props: {
    workOrder: { type: [Object] } as PropValidator<WorkOrderScopeDetails>,
    cy: { type: String, default: "fd-work-order-scope-details" }
  }
});
