import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import i18n from "../../../i18n";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import {
  CrewEmployeeWithName,
  CrewWithEmployees,
  Person,
  PersonWithDetails
} from "../../../services";
import { VForm } from "@fd/lib/vue/types";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import rules from "@fd/lib/vue/rules";
import { GetPersonName, PersonWithDetailsAndName, SortItemsWithName } from "../../../utils/person";

type PersonWithDetailsAndSelected = PersonWithDetailsAndName & {};

type CrewEmployeeLike = {
  name: string | null | undefined;
  order: number | null | undefined;
};
function CompareCrewEmployees(a: CrewEmployeeLike, b: CrewEmployeeLike) {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name!.toLocaleLowerCase();
  let bName = b.name!.toLocaleLowerCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}
export function SortCrewEmployees<T extends CrewEmployeeLike>(items: T[] | undefined): T[] {
  if (!items) return [];
  return items.sort(CompareCrewEmployees);
}

const CrewDetailsBottomDialog = FDVue.extend({
  name: "fd-crew-details-bottom-dialog",

  mixins: [dialogSupport, rules],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {},

  data: () => ({
    // *** GLOBAL ***
    step: 1,
    lastStep: 2,

    detailsStep: 1,
    employeesStep: 2,

    detailserror: false,
    employeeserror: false,

    // The following will control whether or not the save button shows the processing/loading indicator
    saving: false,

    // *** EMPLOYEES ***
    showOnlyIncludedEmployees: false,
    tablesearchemployees: "",
    selectableEmployees: [] as Array<PersonWithDetailsAndSelected>,

    sourceCrew: undefined as CrewWithEmployees | undefined,
    crew: {
      name: "",
      description: "",
      ownerID: null,
      employees: [] as CrewEmployeeWithName[]
    } as CrewWithEmployees
  }),

  computed: {
    unwatchedMethodNames(): string[] {
      return ["splitFilter", "isEmployeeSelected", "orderForItem"];
    },
    // *** EMPLOYEES ***

    employees(): Array<PersonWithDetailsAndSelected> {
      let returnValue = this.selectableEmployees;
      if (this.showOnlyIncludedEmployees)
        returnValue = returnValue.filter(x => this.isEmployeeSelected(x));
      return returnValue;
    },

    selectedEmployeeIDs(): string[] {
      return this.crew.employees?.map(e => e.employeeID!) ?? [];
      // return this.selectableEmployees.filter(x => this.isEmployeeSelected(x)).map(x => x.id!);
    },

    searchedEmployees(): Array<PersonWithDetailsAndSelected> {
      // This is a hack because the employees list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .employeesDataTable as any)?.customFilter;
      if (this.tablesearchemployees && customFilter) {
        return this.employees.filter(
          x =>
            customFilter(x.name!, this.tablesearchemployees, x) ||
            customFilter(x.firstName!, this.tablesearchemployees, x) ||
            customFilter(x.lastName!, this.tablesearchemployees, x) ||
            customFilter(x.employeeCode!, this.tablesearchemployees, x)
        );
      } else {
        return this.employees;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedEmployeesSelected(): boolean {
      return this.searchedEmployees.findIndex(x => !this.isEmployeeSelected(x)) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedEmployeesSelected(): boolean {
      var searchedEmployees = this.searchedEmployees;
      return (
        searchedEmployees.findIndex(x => this.isEmployeeSelected(x)) !== -1 &&
        searchedEmployees.findIndex(x => !this.isEmployeeSelected(x)) !== -1
      );
    }
  },

  methods: {
    splitFilter(value: any, search: string | null, item: any): boolean {
      if (value == null || search == null || typeof value === "boolean") return false;

      value = value.toLocaleLowerCase();
      let searchArray = search.split(" ").map(x => x.toLocaleLowerCase());

      // If any search terms are NOT found in the value, return false
      return !(searchArray.findIndex(x => value.indexOf(x) === -1) !== -1);
    },
    async openForNew(
      contractorID: string,
      ownerID: string | null | undefined,
      defaultName: string | undefined = undefined,
      mountPoint?: Element | undefined
    ) {
      this.crew.contractorID = contractorID;
      this.crew.ownerID = ownerID;
      this.crew.name = defaultName;
      this.optOutOfErrorHandling();
      return await this.showDialog!(mountPoint);
    },
    filterInactiveEmployees() {
      if (!this.employees?.length || !this.crew) return;

      this.crew.employees = this.crew.employees?.filter(
        x => this.employees.findIndex(e => e.id == x.employeeID) !== -1
      );
    },
    async openExisting(crew: CrewWithEmployees, mountPoint?: Element | undefined) {
      this.sourceCrew = crew;
      this.crew = { ...crew } as CrewWithEmployees;
      this.filterInactiveEmployees();
      SortCrewEmployees(this.crew.employees);
      this.optOutOfErrorHandling();
      return await this.showDialog!(mountPoint);
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.detailserror = !(this.$refs.detailsform as VForm).validate();

      return !(this.detailserror || this.employeeserror);
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      if (!this.validate()) {
        var message = i18n.t("contractors.crews.new-crew.error-message");
        if (this.detailserror)
          message += "\n\t- " + i18n.t("contractors.crews.new-crew.steps.details");
        if (this.employeeserror)
          message += "\n\t- " + i18n.t("contractros.crews.new-crew.steps.employees");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return;
      }

      this.saving = true;
      this.processing = true;

      try {
        if (!this.crew.id) {
          var newCrewID = await this.$store.dispatch("ADD_CREW", {
            ...this.crew
          } as CrewWithEmployees);
          this.crew.id = newCrewID;
          this.crew.employees?.forEach(x => (x.crewID = newCrewID));
        } else {
          await this.$store.dispatch("UPDATE_CREW", this.crew);
        }
        if (!!this.sourceCrew) {
          this.sourceCrew.name = this.crew.name;
          this.sourceCrew.description = this.crew.description;
          this.sourceCrew.ownerID = this.crew.ownerID;
          this.sourceCrew.ownerName = this.crew.ownerName;
          this.sourceCrew.employees = this.crew.employees;
        }
        this.closeDialog(newCrewID);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    ...mapActions({
      loadEmployees: "LOAD_USERS"
    }),

    // *** EMPLOYEES ***
    orderForItem(id: string) {
      return this.crew.employees?.find(x => x.employeeID == id)?.order;
    },
    async employeeSelected(item: PersonWithDetailsAndSelected) {
      if (this.isEmployeeSelected(item)) return;

      if (!this.crew.employees) this.crew.employees = [];
      let newCrewEmployee = {
        crewID: this.crew.id,
        employeeID: item.id,
        name: item.name,
        order: this.crew.employees.length + 1
      } as CrewEmployeeWithName;
      this.crew.employees.push(newCrewEmployee);
    },
    async employeeDeselected(employee: Person) {
      if (!this.crew.employees) return;

      let index = this.crew.employees.findIndex(x => x.employeeID == employee.id!);
      if (index < 0) return;

      this.crew.employees.splice(index, 1);

      this.crew.employees.forEach((item, index) => {
        console.log(`  ${item.name}: ${item.order} --> ${index + 1}`);
        item.order = index + 1;
      });
    },
    isEmployeeSelected(employee: Person) {
      return this.selectedEmployeeIDs.includes(employee.id!);
    },
    flipEmployeeSelected(item: PersonWithDetailsAndSelected) {
      let itemIsSelected = this.isEmployeeSelected(item);
      if (!itemIsSelected) this.employeeSelected(item);
      else this.employeeDeselected(item);
    },

    flipSearchedEmployeeselected() {
      let selected = !this.allSearchedEmployeesSelected;
      for (let employee of this.searchedEmployees) {
        if (selected) this.employeeDeselected(employee);
        else this.employeeSelected(employee);
      }
    }
  },

  created: async function() {
    this.processing = true;

    // *** EMPLOYEES ***
    await Promise.all([this.loadEmployees()]);

    this.selectableEmployees = SortItemsWithName(
      (this.$store.state.users.fullList as PersonWithDetails[])
        .filter(x => x.contractorID == this.crew.contractorID)
        .map(
          x =>
            ({
              ...x,
              name: GetPersonName(x, true, false)
            } as PersonWithDetailsAndSelected)
        )
    );

    this.filterInactiveEmployees();

    this.processing = false;
  }
});

export default CrewDetailsBottomDialog;

export async function createNewCrew(
  contractorID: string,
  ownerID: string | undefined | null = undefined,
  defaultName: string | undefined = undefined,
  parent?: Vue | Element | null | undefined
): Promise<string | boolean> {
  let dialog = createDialog(CrewDetailsBottomDialog);
  dialog.optOutOfErrorHandling();
  let mountPoint = undefined;
  if (!!parent) {
    mountPoint = document.createElement("div");
    mountPoint.id = "mountPoint";
    if (!!(parent as Vue)) (parent as Vue).$el.appendChild(mountPoint);
    else if (!!(parent as Element)) (parent as Element).appendChild(mountPoint);
  }
  return await dialog.openForNew(contractorID, ownerID, defaultName, mountPoint);
}

export async function updateExistingCrew(
  crew: CrewWithEmployees,
  parent?: Vue | Element | null | undefined
): Promise<string | boolean> {
  let dialog = createDialog(CrewDetailsBottomDialog);
  dialog.optOutOfErrorHandling();
  let mountPoint = undefined;
  if (!!parent) {
    mountPoint = document.createElement("div");
    mountPoint.id = "mountPoint";
    if (!!(parent as Vue)) (parent as Vue).$el.appendChild(mountPoint);
    else if (!!(parent as Element)) (parent as Element).appendChild(mountPoint);
  }
  return await dialog.openExisting(crew, mountPoint);
}

