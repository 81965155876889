import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import {
  Tag,
  scaffoldService,
  ScaffoldWithDetails,
  CountSheetWithParts,
  countSheetService,
  Part,
  PartWithTotal
} from "../services";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import userAccess from "../dataMixins/userAccess";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { WorkPackageWithNameCode } from "../dataMixins/scaffoldRequest";
import { showScaffoldVLFHistoryDialog } from "./components/dialogs/ScaffoldVLFHistoryDialog.vue";

type Keyword = Tag;
type ScaffoldWithExtraDetails = ScaffoldWithDetails & {
  daysStanding: number | undefined;
  archived: boolean;
  tagNumber: string;
};
type FormattedCountSheet = CountSheetWithParts & {
  scaffoldNumber: string | undefined;
  legacyWorkOrderID: number | string | undefined;
  requestTypeName: string | undefined;
  workOrderStatusName: string | undefined;
  formattedReviewStatusChangeTime: string | undefined;
  // Transfer IDs only exist for approved count sheets
  transferIDsList?: string | undefined;
};

export default FDVue.extend({
  name: "fd-scaffold-existing",

  mixins: [tabbedView, userAccess, errorHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-work-order-details": () => import("./components/WorkOrderDetailsForm.vue"),
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue")
  },

  data: function() {
    return {
      workOrderTableSearch: "",
      inventoryTableSearch: "",
      transactionsTableSearch: "",
      // *** GLOBAL ***
      slidein: false,

      firstTabKey: `1`,
      detailsTab: {
        tabname: "Details",
        key: `1`,
        visible: true
      } as Tab,
      workOrdersTab: {
        tabname: "Work Orders",
        key: `2`,
        visible: false
      } as Tab,
      inventoryTab: {
        tabname: "Inventory",
        key: `3`,
        visible: true
      } as Tab,
      transactionsTab: {
        tabname: "Transactions",
        key: `4`,
        visible: false
      } as Tab,
      countSheetsTab: {
        tabname: this.$t("scaffolds.existing-scaffold.tabs.count-sheets"),
        key: `5`,
        visible: false
      } as Tab,
      materialTab: {
        tabname: this.$t("scaffolds.existing-scaffold.tabs.material"),
        key: `6`,
        visible: false
      } as Tab,
      inspectionsTab: {
        tabname: this.$t("scaffolds.existing-scaffold.tabs.inspections"),
        key: `7`,
        visible: false
      } as Tab,

      /*** KEYWORDS ***/
      selectedKeywords: [] as Keyword[],

      /*** IWPs ***/
      availableIWPs: [] as WorkPackageWithNameCode[],
      selectedIWPs: [] as WorkPackageWithNameCode[],

      // *** COUNT SHEETS ***
      countsheettablesearch: "",
      countSheets: [] as FormattedCountSheet[],

      // *** MATERIAL ***
      standingPartCounts: [] as PartWithTotal[],
      partstablesearch: "",
      // Table Footer page size options
      partsItemsPerPage: 25,
      partsItemsPerPageOptions: [5, 10, 15, 25, 50, -1],

      // Main entity
      scaffold: {} as ScaffoldWithExtraDetails,
      expanderColSpan: 10
    };
  },

  computed: {
    // *** GLOBAL ***
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [
        this.workOrdersTab,
        this.inventoryTab,
        this.transactionsTab,
        this.countSheetsTab,
        this.materialTab,
        this.inspectionsTab
      ] as Tab[];
    },

    // *** DETAILS ***
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },

    // *** MATERIAL ***
    standingParts(): (Part & { total: number; totalWeight: string | undefined })[] {
      let allParts = this.$store.state.parts.fullList as Part[];
      return this.standingPartCounts.map(x => {
        let part = allParts.find(p => x.partID == p.id!)!;
        return {
          ...part,
          total: x.total,
          totalWeight: !part.weight || !x.total ? undefined : (x.total * part.weight).toFixed(1)
        };
      });
    }
  },

  methods: {
    formatDate(date: Date | string | undefined) {
      return DateUtil.localizedDateTimeString(date);
    },
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadParts: "LOAD_PARTS"
    }),

    onSubmit(e: Event) {
      e.preventDefault();
      //this.save();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    async loadScaffoldDetails() {
      var scaffold = await scaffoldService.getByID(this.$route.params.id);
      this.scaffold = {
        ...scaffold,
        daysStanding: !!scaffold.actualErectDate
          ? Math.round(
              ((!!scaffold.actualDismantleDate
                ? scaffold.actualDismantleDate
                : new Date()
              ).getTime() -
                scaffold.actualErectDate.getTime()) /
                86400000
            )
          : undefined,
        archived: !!scaffold.archivedDate,
        tagNumber: `${scaffold.legacyID}`,
        workOrders: scaffold.workOrders?.map(x => ({
          ...x,
          workPackageNames: x.workPackages?.map(x => (x.name ?? "") + " | " + (x.activityID ?? "")),
          formattedRequestDate: DateUtil.stripTimeFromLocalizedDateTime(x.requestDate),
          formattedCompletedDate: DateUtil.stripTimeFromLocalizedDateTime(x.completedDate)
        }))
      } as ScaffoldWithExtraDetails;
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/scaffolds");
    },

    workOrderCustomSort(items: any[], sortBy: string[], sortDesc: boolean[], locale: string) {
      let desc = sortDesc[0] == true;
      let propName = sortBy[0];
      // Examine the property trying to be sorted, and switch it to the ACTUAL property name of the object
      // This prop name is the name of the item in the table, and therefore sometimes won't match the object property.
      if (propName === "formattedCompletedDate") {
        propName = "completedDate";
      } else if (propName === "formattedRequestDate") {
        propName = "requestDate";
      }

      items.sort((a: any, b: any) => {
        let val1 = (a as any)[propName];
        let val2 = (b as any)[propName];
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });
      return items;
    },

    async vlfHistoryClicked() {
      showScaffoldVLFHistoryDialog(this.scaffold.vlfHistory);
    },

    // *** COUNT SHEETS ***
    async loadCountSheets() {
      let countSheets = await countSheetService.getByScaffoldID(this.$route.params.id);
      let formattedCountSheets = countSheets.map(
        x =>
          ({
            ...x,
            scaffoldNumber: x.workOrder?.scaffoldNumber,
            legacyWorkOrderID: x.workOrder?.legacyID,
            requestTypeName: x.workOrder?.requestTypeName,
            workOrderStatusName: x.workOrder?.workOrderStatusName,
            formattedReviewStatusChangeTime: this.formatDate(x.reviewStatusChangeTime),
            transferIDsList: !!x.relatedLegacyTransferIDs
              ? x.relatedLegacyTransferIDs.join(", ")
              : undefined
          } as FormattedCountSheet)
      );
      this.countSheets = formattedCountSheets;
    },

    // *** MATERIAL
    async loadStandingPartCounts() {
      this.standingPartCounts = await scaffoldService.getStandingPartCountsForScaffoldID(
        this.$route.params.id,
        null,
        null
      );
    }
  },

  watch: {
    scaffold(newValue: ScaffoldWithDetails) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffolds") {
        this.notifyNewBreadcrumb({
          text: this.$t("scaffolds.list-title"),
          to: "/scaffolds",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      var breadcrumbText = `T-${newValue.legacyID}`;
      this.notifyNewBreadcrumb({
        text: breadcrumbText,
        to: `/scaffolds/${newValue.id}`
      });
    }
  },

  created: async function() {
    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffolds") {
      this.notifyNewBreadcrumb({
        text: this.$t("scaffolds.list-title"),
        to: "/scaffolds",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      this.notifyNewBreadcrumb({
        text: "",
        to: `/countsheets/${this.$route.params.id}`
      });
    }

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "scaffolds-existing",
      parentalContext: "scaffolds",
      searchStringForFiltering: "",
      selectedTab: this.firstTabKey
    });

    this.processing = true;

    try {
      await Promise.all([this.loadParts()]);

      // processing has been set to false after the reference data loaded.
      this.processing = true;
      await this.loadScaffoldDetails();
      // Processing has automatically been set to false after this load happens
      this.processing = true;

      // The control displaying Work Packages NEEDS data to exist in its source in order to be displayed in the control
      // Therefore, we start by loading all referenced IWPs from the scaffold into the source data
      if (this.scaffold.workPackages?.length) {
        this.availableIWPs = this.scaffold.workPackages.map(
          x =>
            ({
              ...x,
              nameCode: `${x.name} | ${x.activityID}`
            } as WorkPackageWithNameCode)
        );
        this.selectedIWPs = this.availableIWPs;
      }

      this.processing = true;
      await this.loadCountSheets();
      await this.loadStandingPartCounts();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});


