var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    _vm._b(
      {
        staticClass: "fd-disabled-select-item-reason",
        attrs: {
          "no-gutters": "",
          "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
          dense: _vm.isDense
        }
      },
      "v-row",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-icon",
        {
          class: _vm.isDense
            ? "fd-disabled-select-item-reason-icon--dense"
            : "fd-disabled-select-item-reason-icon",
          attrs: { small: !_vm.isDense, "x-small": _vm.isDense }
        },
        [_vm._v(_vm._s(_vm.icon))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.isDense
            ? "fd-disabled-select-item-reason-text--dense"
            : "fd-disabled-select-item-reason-text"
        },
        [_vm._v("\n    " + _vm._s(_vm.message) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }