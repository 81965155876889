var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.workOrder &&
      _vm.workOrder.workOrderStatus == _vm.WorkOrderStatuses.Declined
        ? _c("fd-alert", {
            staticClass: "mx-3 mt-5",
            attrs: {
              type: "warning",
              label: !!_vm.workOrder.approvalComments
                ? _vm.$t("scaffold-requests.declined-with-reason")
                : _vm.$t("scaffold-requests.declined"),
              comments: _vm.workOrder.approvalComments,
              "persistent-comments": ""
            }
          })
        : _vm.walkdown &&
          _vm.walkdown.walkdownStatus == _vm.WalkdownStatuses.Declined
        ? _c("fd-alert", {
            staticClass: "mx-3 mt-5",
            attrs: {
              type: "warning",
              label: !!_vm.walkdown.approvalComments
                ? _vm.$t("scaffold-requests.walkdown-declined-with-reason")
                : _vm.$t("scaffold-requests.walkdown-declined"),
              comments: _vm.walkdown.approvalComments,
              "persistent-comments": ""
            }
          })
        : _vm.workOrder &&
          _vm.workOrder.workOrderStatus == _vm.WorkOrderStatuses.OnHold
        ? _c("fd-alert", {
            staticClass: "mx-3 mt-5",
            attrs: {
              type: "warning",
              label: _vm.$t("scheduler.on-hold-reason-extended"),
              comments: _vm.workOrder.workOrderStatusDetail,
              "persistent-comments": ""
            }
          })
        : _vm.workOrder &&
          _vm.workOrder.workOrderStatus == _vm.WorkOrderStatuses.Cancelled
        ? _c("fd-alert", {
            staticClass: "mx-3 mt-5",
            attrs: {
              type: "error",
              label: _vm.$t("scheduler.cancellation-reason-extended"),
              comments: _vm.workOrder.workOrderStatusDetail,
              "persistent-comments": ""
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.workOrder && _vm.workOrder.isUrgent
        ? _c("fd-alert", {
            staticClass: "mx-3 mt-5",
            attrs: {
              type: "urgent",
              label: _vm.$t("scheduler.urgent-status"),
              comments: _vm.workOrder.isUrgentDetail,
              "comment-hint": _vm.lastUrgentDetailChangedSummaryString,
              "persistent-comments": ""
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }