var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      !_vm.sortedStatusLogs || _vm.sortedStatusLogs.length == 0
        ? _c(
            "v-row",
            {
              staticClass: "fd-log-history-entry pt-3",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "fd-log-history-entry-title",
                  attrs: { cols: "12" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "fd-log-history-no-history-message" },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.noHistoryMessage) + "\n      "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.sortedStatusLogs, function(log) {
        return _c(
          "v-row",
          {
            key: log.id,
            staticClass: "fd-log-history-entry pt-3",
            attrs: { "no-gutters": "" }
          },
          [
            _c(
              "v-col",
              {
                staticClass: "fd-log-history-entry-title",
                attrs: { cols: "12" }
              },
              [
                !!_vm.iconForItem && !!_vm.iconForItem(log)
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "fd-log-history-entry-title--icon",
                        attrs: {
                          color: !_vm.colorForItem
                            ? undefined
                            : _vm.colorForItem(log),
                          "x-small": ""
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              !_vm.iconForItem ? "" : _vm.iconForItem(log)
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "fd-log-history-entry-title--text",
                    class: {
                      "fd-log-history-entry-title--no-icon":
                        !_vm.iconForItem || !_vm.iconForItem(log)
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.summaryStringForItemWithDate(log)) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            !!_vm.detailStringForItem && !!_vm.detailStringForItem(log)
              ? _c(
                  "v-col",
                  {
                    staticClass: "fd-log-history-entry-reason-text",
                    attrs: { cols: "12" }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.detailStringForItem(log)))])]
                )
              : _vm._e()
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }