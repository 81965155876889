var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-0",
      attrs: { fluid: "", "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy }
    },
    [
      _c(
        "v-card-title",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-1",
              attrs: {
                "data-cy": "buttonadd",
                color: "primary",
                dark: "",
                fab: ""
              },
              on: {
                click: function(v) {
                  return _vm.$emit("click:add", v)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-plus")])],
            1
          ),
          _vm._v(" "),
          !!_vm.info
            ? _c("v-divider", {
                staticClass: "mx-4",
                attrs: { inset: "", vertical: "" }
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.info
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                {
                                  staticClass: "ml-1",
                                  attrs: { color: "black", dark: "" }
                                },
                                on
                              ),
                              [_vm._v("info")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    92516267
                  )
                },
                [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.info))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-text-field", {
            staticClass: "pl-0",
            attrs: {
              value: _vm.value,
              "append-icon": "search",
              label: _vm.$t("common.search"),
              "single-line": "",
              "hide-details": "",
              clearable: ""
            },
            on: {
              input: function(v) {
                return _vm.$emit("input", v)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }