var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$vuetify.breakpoint.smAndUp
    ? _c(
        "v-row",
        { staticClass: "mx-0 px-0" },
        [
          _c("v-col", { attrs: { cols: "6" } }, [_c("strong", [_vm._v(" ")])]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "6" } }, [_vm._v(" ")])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }