import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { TranslateResult } from "vue-i18n";
import {
  CountSheetGroupWithParts,
  ScaffoldSearchResult,
  Part,
  partService,
  PartWithTags,
  PartWithTotal,
  scaffoldService,
  TransferDirection,
  Yard,
  yardService,
  TransferPartWithDetails,
  TransferPartDetails,
  transferService,
  WorkOrder,
  workOrderService,
  ScaffoldRequestTypes,
  WorkOrderStatuses
} from "../../../services";
import {
  CountSheetGroupPartFromTransferPart,
  CountSheetGroupWithSortedParts,
  FlattenedPartsList,
  PartWithCounts,
  SummarizeModifiedPartsInGroups
} from "../../../dataMixins/countSheet";
import { SortCountSheetGroups, SortParts } from "../../../dataMixins/countSheetGroupSorting";
import { mapActions } from "vuex";
import rules from "@fd/lib/vue/rules";
import userAccess from "../../../dataMixins/userAccess";
import { openBCountBottomDialog } from "./SP.BCountBottomDialog.vue";

const TransferNewDialog = FDVue.extend({
  name: "fd-transfer-new-dialog",
  mixins: [dialogSupport, rules, userAccess],

  components: {
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue")
  },
  data: function() {
    return {
      saving: false,

      direction: TransferDirection.YardTransfer,
      transferDate: new Date(),
      transactionNumber: "",
      parts: [] as TransferPartWithDetails[],
      allParts: [] as PartWithTags[],
      partStandingTotals: [] as PartWithTotal[],
      countSheetGroups: [] as CountSheetGroupWithSortedParts[],
      openCategoryPanels: [] as number[],
      openIndividualPanels: [] as number[],

      partSearch: "",
      partSources: [] as TransferPartDetails[],

      /*** WORK ORDER ***/
      availableWorkOrders: [] as WorkOrder[],
      selectedWorkOrder: null as WorkOrder | null,
      userSearchedWorkOrder: false,

      /*** TO/FROM SCAFFOLD ***/
      availableScaffolds: [] as ScaffoldSearchResult[],
      selectedFromScaffold: null as ScaffoldSearchResult | null,
      selectedToScaffold: null as ScaffoldSearchResult | null,
      userSearchedScaffold: false,

      /*** TO/FROM YARD ***/
      allYards: [] as Yard[],
      selectedFromYardID: null as string | null,
      selectedToYardID: null as string | null
    };
  },
  computed: {
    canOpenBCountDialog(): boolean {
      return this.partCountsAreEmpty && this.partsAreEditable;
    },
    partCountsAreEmpty(): boolean {
      return !this.partsForSummary.filter(x => !!x.count && x.count > 0).length;
    },
    canSearchWorkOrders(): boolean {
      return this.userSearchedWorkOrder || !this.userSearchedScaffold;
    },
    canSearchScaffolds(): boolean {
      return this.userSearchedScaffold || !this.userSearchedWorkOrder;
    },
    fromYards(): (Yard & { disabled: boolean })[] {
      return this.allYards.map(x => ({
        ...x,
        disabled: x.id == this.selectedToYardID
      }));
    },
    toYards(): (Yard & { disabled: boolean })[] {
      return this.allYards.map(x => ({
        ...x,
        disabled: x.id == this.selectedFromYardID
      }));
    },
    visibleCountSheetGroups(): CountSheetGroupWithSortedParts[] {
      if (!this.partSearch.length) return this.countSheetGroups;
      else {
        let lowersearch = this.partSearch.toLowerCase();
        return this.countSheetGroups
          .map(x => ({
            ...x,
            sortedParts: x.sortedParts.filter(
              p =>
                p.name?.toLowerCase().includes(lowersearch) ||
                p.publicID?.toLowerCase().includes(lowersearch)
            )
          }))
          .filter(x => x.sortedParts.length > 0);
      }
    },
    unwatchedMethodNames(): string[] {
      return [
        "open",
        "fieldRefForPart",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "partCountValueChanged",
        "numberOfPartsSourcedByYardID"
      ];
    },
    dialogTitle(): string | TranslateResult {
      if (this.directionIsScaffoldDelivery) return this.$t("transfers.new.dialog-title-delivery");
      else if (this.directionIsScaffoldReturn) return this.$t("transfers.new.dialog-title-return");
      else return this.$t("transfers.new.dialog-title-transfer");
    },
    directionIsScaffoldDelivery(): boolean {
      return this.direction == TransferDirection.ScaffoldDelivery;
    },
    directionIsScaffoldReturn(): boolean {
      return this.direction == TransferDirection.ScaffoldReturn;
    },
    directionIsYardTransfer(): boolean {
      return this.direction == TransferDirection.YardTransfer;
    },
    partsForSummary(): PartWithCounts[] {
      return SummarizeModifiedPartsInGroups(this.countSheetGroups);
    },
    partsAreEditable(): boolean {
      return (
        (!!this.selectedFromScaffold || !!this.selectedFromYardID) &&
        (!!this.selectedToScaffold || !!this.selectedToYardID)
      );
    }
  },
  watch: {
    selectedFromScaffold() {
      this.selectedToScaffold = null;
      this.clearParts();
      if (!this.selectedFromScaffold && this.userSearchedScaffold) {
        this.userSearchedScaffold = false;
        this.selectedWorkOrder = null;
      } else if (
        !!this.selectedFromScaffold &&
        !this.userSearchedScaffold &&
        !this.userSearchedWorkOrder
      ) {
        this.userSearchedScaffold = true;
      }

      if (!this.userSearchedWorkOrder)
        this.loadWorkOrders(undefined, this.selectedFromScaffold?.id);
    },
    selectedToScaffold() {
      this.selectedFromScaffold = null;
      this.clearParts();
      if (!this.selectedToScaffold && this.userSearchedScaffold) {
        this.userSearchedScaffold = false;
        this.selectedWorkOrder = null;
      } else if (
        !!this.selectedToScaffold &&
        !this.userSearchedScaffold &&
        !this.userSearchedWorkOrder
      ) {
        this.userSearchedScaffold = true;
      }

      if (!this.userSearchedWorkOrder) this.loadWorkOrders(undefined, this.selectedToScaffold?.id);
    },
    transferDate() {
      this.clearParts();
    },
    selectedWorkOrder() {
      if (this.userSearchedScaffold) return;
      if (!this.selectedWorkOrder && this.userSearchedWorkOrder) {
        this.userSearchedWorkOrder = false;
        this.selectedToScaffold = null;
        this.selectedFromScaffold = null;
      } else if (
        !!this.selectedWorkOrder &&
        !this.userSearchedScaffold &&
        !this.userSearchedWorkOrder
      ) {
        this.userSearchedWorkOrder = true;
      }
      if (!!this.selectedWorkOrder?.scaffoldID)
        this.loadScaffolds(this.selectedWorkOrder.scaffoldID);
    }
  },
  methods: {
    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS"
    }),
    async open(direction: TransferDirection | undefined) {
      if (direction !== undefined) this.direction = direction;

      this.loadScreenData();

      this.optOutOfErrorHandling();
      return await this.showDialog();
    },
    async loadScreenData() {
      this.processing = true;
      try {
        await this.loadYards();
        await this.loadParts();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },
    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async saveDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (await this.saveNewTransfer()) {
          this.closeDialog!(true);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async saveNewTransfer(): Promise<boolean> {
      let parts = this.partsForSummary
        .filter(x => !!x.count && x.count > 0)
        .map(
          x =>
            ({
              partID: x.id,
              count: +x.count!,
              currentAssignedCount: +x.assigned
            } as TransferPartDetails)
        );
      if (parts.length == 0) {
        this.inlineMessage.message = this.$t(
          "transfers.new.part-selection-required-to-submit-message"
        );
        this.inlineMessage.type = "error";
        return false;
      }

      if (this.direction == TransferDirection.ScaffoldDelivery) {
        if (!this.selectedFromYardID) {
          this.inlineMessage.message = this.$t("transfers.new.source-required-message");
          this.inlineMessage.type = "error";
          return false;
        }
        if (!this.selectedToScaffold) {
          this.inlineMessage.message = this.$t("transfers.new.destination-required-message");
          this.inlineMessage.type = "error";
          return false;
        }
        await transferService.deliverPartsToScaffold(
          this.selectedFromYardID,
          this.selectedToScaffold.id!,
          this.selectedWorkOrder?.id ?? null,
          this.transferDate,
          this.transactionNumber,
          parts
        );
      } else if (this.direction == TransferDirection.ScaffoldReturn) {
        if (!this.selectedFromScaffold) {
          this.inlineMessage.message = this.$t("transfers.new.source-required-message");
          this.inlineMessage.type = "error";
          return false;
        }
        if (!this.selectedToYardID) {
          this.inlineMessage.message = this.$t("transfers.new.destination-required-message");
          this.inlineMessage.type = "error";
          return false;
        }
        await transferService.returnPartsToYard(
          this.selectedFromScaffold.id!,
          this.selectedToYardID,
          this.selectedWorkOrder?.id ?? null,
          this.transferDate,
          this.transactionNumber,
          parts
        );
      }
      if (this.direction == TransferDirection.YardTransfer) {
        if (!this.selectedFromYardID) {
          this.inlineMessage.message = this.$t("transfers.new.source-required-message");
          this.inlineMessage.type = "error";
          return false;
        }
        if (!this.selectedToYardID) {
          this.inlineMessage.message = this.$t("transfers.new.destination-required-message");
          this.inlineMessage.type = "error";
          return false;
        }
        await transferService.transferPartsBetweenYards(
          this.selectedFromYardID,
          this.selectedToYardID,
          this.transferDate,
          this.transactionNumber,
          parts
        );
      }

      return true;
    },

    async loadPartSources() {
      this.partSources = [];
      if (!this.selectedFromScaffold?.id) return;

      let partSources = await transferService.getScaffoldPartSources(this.selectedFromScaffold.id);

      this.partSources = partSources.filter(
        s => this.partsForSummary.findIndex(p => p.id == s.partID) !== -1
      );
    },

    // *** WORK ORDERS ***
    async loadWorkOrders(
      workOrderSearchString: string | undefined,
      scaffoldSearchString: string | undefined
    ) {
      // let scaffoldSearchString = "";
      // if (this.userSearchedScaffold && (this.selectedFromScaffold || this.selectedToScaffold)) {
      //   let selectedID = this.selectedFromScaffold?.id ?? this.selectedToScaffold?.id;
      //   scaffoldSearchString = selectedID!;
      // } else {
      //   this.userSearchedWorkOrder = true;
      // }
      if (!workOrderSearchString?.length && !scaffoldSearchString?.length) {
        this.userSearchedWorkOrder = false;
        this.availableWorkOrders = [];
        return;
      }

      // If we're looking for WOs related to a scaffold, we don't care about the date
      // If we're NOT looking based on a scaffold, we DO care about the date
      let restrictDay = !scaffoldSearchString?.length;
      let activeDay = restrictDay ? this.transferDate : undefined;

      let workOrders = await workOrderService.search(
        activeDay,
        workOrderSearchString,
        scaffoldSearchString,
        undefined,
        undefined,
        undefined
      );
      this.availableWorkOrders = workOrders.map(x => ({
        ...x,
        description: "WO-" + `00000${x.legacyID}`.slice(-5),
        details: `${ScaffoldRequestTypes[x.scaffoldRequestType!]} (${
          WorkOrderStatuses[x.workOrderStatus!]
        })`
      }));
      this.userSearchedWorkOrder =
        !!workOrderSearchString?.length && this.availableWorkOrders.length > 0;
    },

    // *** SCAFFOLDS ***
    getScaffoldDescription(scaffold: {
      legacyID: number | null | undefined;
      existingRequestNumber: string | null | undefined;
    }): string {
      let paddedTagNumber = `00000${scaffold.legacyID}`.slice(-5);
      let existingRequestString = "";
      if (!!scaffold.existingRequestNumber?.length) {
        existingRequestString = ` | R-${scaffold.existingRequestNumber}`;
      }
      return `T-${paddedTagNumber}${existingRequestString}`;
    },

    getScaffoldDetails(scaffold: {
      areaName: string | null | undefined;
      subAreaName: string | null | undefined;
    }): string {
      // The sub area is more specific than the area, so if a subarea exists, display it
      // Otherwise, display the area
      let areaString = "";
      if (scaffold.subAreaName && scaffold.subAreaName.trim().length > 0) {
        areaString = `${scaffold.subAreaName.trim()}`;
      }
      if (areaString.length == 0 && scaffold.areaName && scaffold.areaName.trim().length > 0) {
        areaString = `${scaffold.areaName.trim()}`;
      }

      var detailsString = areaString.length > 0 ? `${areaString}` : "";
      return `${detailsString}`;
    },
    numberOfPartsSourcedByYardID(yardID: string): number {
      return (this.partSources as TransferPartDetails[]).filter(x => x.yardID == yardID).length;
    },
    async loadScaffolds(searchString: string) {
      if (!searchString?.length && !this.selectedWorkOrder) {
        this.userSearchedScaffold = false;
        this.availableScaffolds = [];
        return;
      }
      if (!!this.selectedWorkOrder) {
        if (!!this.selectedWorkOrder.scaffoldID) {
          let scaffold = await scaffoldService.getByIDWithOptions(
            this.selectedWorkOrder.scaffoldID,
            false,
            false
          );
          let scaffolds = [
            {
              ...scaffold,
              legacyID: scaffold.legacyID,
              areaName: scaffold.areaName ?? "",
              subAreaName: scaffold.subAreaName ?? "",
              existingRequestNumber: scaffold.erectRequestNumber
            } as ScaffoldSearchResult
          ];
          this.availableScaffolds = scaffolds.map(x => {
            return {
              ...x,
              description: this.getScaffoldDescription(x),
              details: this.getScaffoldDetails(x)
            } as ScaffoldSearchResult;
          });
        }
        this.userSearchedScaffold = false;
      } else {
        let scaffolds = await scaffoldService.searchAll(searchString, searchString.length >= 4);
        this.availableScaffolds = scaffolds.map(x => {
          return {
            ...x,
            description: this.getScaffoldDescription(x),
            details: this.getScaffoldDetails(x)
          } as ScaffoldSearchResult;
        });
        this.userSearchedScaffold = this.availableScaffolds.length > 0;
      }

      if (this.availableScaffolds.length == 1) {
        if (this.direction == TransferDirection.ScaffoldDelivery) {
          this.selectedToScaffold = this.availableScaffolds[0];
        } else if (this.direction == TransferDirection.ScaffoldReturn) {
          this.selectedFromScaffold = this.availableScaffolds[0];
        }
      }
    },

    // *** YARDS ***
    async loadYards() {
      this.allYards = (await yardService.getAll(false, null, null))
        .filter(x => !x.isSystemYard)
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });
    },

    // *** PARTS & COUNT SHEET ***
    async clearPartCounts() {
      this.partsForSummary.forEach(p => {
        p.count = 0;
        this.partCountValueChanged(p);
      });
    },
    /// Clears and rebuilds all count sheet groups, and reloads part quantities for yards
    async clearParts() {
      this.inlineMessage.message = "";
      var scaffoldID = this.selectedFromScaffold?.id ?? this.selectedToScaffold?.id;
      if (!!scaffoldID) {
        this.partStandingTotals = await scaffoldService.getStandingPartCountsForScaffoldID(
          scaffoldID,
          null,
          this.transferDate
        );
      } else {
        this.partStandingTotals = [];
      }

      this.parts = this.allParts.map(
        x =>
          ({
            name: x.name,
            description: x.description,
            publicID: x.publicID,
            countSheetGroupID: x.countSheetGroupID,
            partID: x.id,
            count: 0,
            currentAssignedCount: this.partStandingTotals.find(t => t.partID == x.id)?.total
          } as TransferPartWithDetails)
      );
      let countSheetGroups = SortCountSheetGroups(
        (this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[])
          .filter(group => !!group.parts?.length)
          .map(
            group =>
              ({
                ...group,
                sortedParts: SortParts(
                  group.parts?.map(part =>
                    CountSheetGroupPartFromTransferPart(part, this.parts, this.direction)
                  )
                )
              } as CountSheetGroupWithSortedParts)
          )
      );

      let ungroupedPartsWithDetails = this.parts.filter(x => !x.countSheetGroupID);
      if (!!ungroupedPartsWithDetails?.length) {
        let ungroupedParts = ungroupedPartsWithDetails.map(
          x =>
            ({
              id: x.partID,
              name: x.name,
              description: x.description,
              publicID: x.publicID
            } as Part)
        ) as Part[];
        let ungroupedGroup = {
          name: `${this.$t("common.other")}`,
          order: 999,
          parts: ungroupedParts
        } as CountSheetGroupWithParts;

        let ungroupedGroupWithSortedParts = {
          ...ungroupedGroup,
          sortedParts: SortParts(
            ungroupedGroup.parts?.map(part =>
              CountSheetGroupPartFromTransferPart(part, this.parts, this.direction)
            )
          )
        } as CountSheetGroupWithSortedParts;

        countSheetGroups.push(ungroupedGroupWithSortedParts);
      }

      countSheetGroups.forEach(x => (x.parts = SortParts(x.parts)));

      this.countSheetGroups = countSheetGroups;

      if (this.directionIsScaffoldReturn) await this.loadPartSources();
    },
    async loadParts() {
      await this.$store.dispatch("LOAD_PARTS");
      this.allParts = this.$store.state.parts.fullList as PartWithTags[];
      // this.allParts = await partService.getAll(false, null, null);

      await this.loadCountSheetGroups({
        forcedArchivedState: false,
        archivedFromDate: null,
        archivedToDate: null
      });
      await this.clearParts();
    },

    // *** SCREEN NAVIGATION ***
    partWithID(id: string): PartWithCounts | undefined {
      return FlattenedPartsList(this.countSheetGroups).find(x => x.id == id);
    },
    async openBCountDialog() {
      let partCounts = await openBCountBottomDialog(this.$refs.content as Vue);
      if (!partCounts?.length) return;

      partCounts.forEach(p => {
        let part = this.partWithID(p.partID);
        if (!!part) {
          part.count = p.quantity;
          this.partCountValueChanged(part);
        }
      });
      if (!this.openCategoryPanels.includes(0)) this.openCategoryPanels.push(0);
    },
    partCountValueChanged(part: PartWithCounts) {
      let addCount = 0;
      let removeCount = 0;
      let count = !!part.count ? +part.count : 0;
      if (this.direction == TransferDirection.ScaffoldReturn) {
        removeCount = count;
      } else {
        addCount = count;
      }
      part.total = part.assigned + addCount - removeCount;
    },
    fieldRefForPart(fieldName: string, part: PartWithCounts) {
      let overrideRef = part.overridden == true ? "override" : "";

      let id = part.id!.replace("-", "").replace("-", "");
      return `${fieldName}${overrideRef}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, groupNumber: number, partIndex: number) {
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.order == groupNumber
      );
      if (!group) return;
      let sortedGroupParts = group.sortedParts;
      if (!sortedGroupParts.length) return;

      let groupPanelNumber = this.countSheetGroups.indexOf(group!);
      if (!this.openIndividualPanels.includes(groupPanelNumber)) {
        this.openIndividualPanels.push(groupPanelNumber);
        let self = this;

        this.$nextTick(() => {
          setTimeout(() => {
            self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, partIndex);
          }, 500);
        });
        return;
      }

      if (partIndex < 0) partIndex = 0;
      if (partIndex >= sortedGroupParts.length) partIndex = sortedGroupParts.length - 1;
      let item = sortedGroupParts[partIndex];

      let itemFieldRef = this.fieldRefForPart(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    selectPreviousField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      if (!group) return;

      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (groupNumber <= 1) return;
        groupNumber -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 999);
        });
        e.preventDefault();
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, previousIndex);
      e.preventDefault();
    },
    selectNextField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      // console.log(`selectNextField fieldName: ${fieldName}, part: ${item.publicID}`);
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex >= sortedGroupParts.length - 1) {
        groupNumber += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 0);
        });
        e.preventDefault();
        return;
      }
      let nextIndex = currentItemIndex + 1;
      // console.log(`  current: ${currentItemIndex}, next: ${nextIndex}`);
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, nextIndex);
      e.preventDefault();
    },
    enterPressed(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      if (e.shiftKey) this.selectPreviousField(e, fieldName, item);
      else this.selectNextField(e, fieldName, item);
    }
  }
});

export default TransferNewDialog;

export async function showTransferNewDialog(options?: {
  direction?: TransferDirection;
}): Promise<boolean> {
  let dialog = createDialog(TransferNewDialog);
  return await dialog.open(options?.direction);
}

