var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-3", attrs: { "no-gutters": "" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "scheduler.estimates.summary-panel.estimate-modifiers-label"
                            )
                          ) +
                          "\n          "
                      ),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("click:updateModifiers")
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "scheduler.estimates.summary-panel.change-modifiers-button"
                              )
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            staticClass: "px-3",
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              dense: "",
                              label: _vm.$t(
                                "work-order-estimates.modifiers.scaffold-type"
                              ),
                              value:
                                _vm.currentEstimate.scaffoldTypeModifierName,
                              "hide-details": ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            staticClass: "px-3",
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              dense: "",
                              label: _vm.$t(
                                "work-order-estimates.modifiers.scaffold-distance"
                              ),
                              value:
                                _vm.currentEstimate
                                  .scaffoldDistanceModifierName,
                              "hide-details": ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            staticClass: "px-3",
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              dense: "",
                              label: _vm.$t(
                                "work-order-estimates.modifiers.scaffold-elevation"
                              ),
                              value:
                                _vm.currentEstimate
                                  .scaffoldElevationModifierName,
                              "hide-details": ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            staticClass: "px-3",
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              dense: "",
                              label: _vm.$t(
                                "work-order-estimates.modifiers.scaffold-height"
                              ),
                              value:
                                _vm.currentEstimate.scaffoldHeightModifierName,
                              "hide-details": ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            staticClass: "px-3",
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              dense: "",
                              label: _vm.$t(
                                "work-order-estimates.modifiers.build-dismantle-ratio"
                              ),
                              value:
                                _vm.currentEstimate.buildDismantleRatioName,
                              "hide-details": ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            staticClass: "px-3",
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              dense: "",
                              label: _vm.$t(
                                "work-order-estimates.modifiers.congestion-factor"
                              ),
                              value:
                                _vm.currentEstimate
                                  .scaffoldCongestionFactorName,
                              "hide-details": ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            staticClass: "px-3",
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              dense: "",
                              label: _vm.$t(
                                "work-order-estimates.modifiers.internal"
                              ),
                              value: _vm.currentEstimate.internalModifierName,
                              "hide-details": ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            staticClass: "px-3",
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              dense: "",
                              label: _vm.$t(
                                "work-order-estimates.modifiers.hoarding"
                              ),
                              value: _vm.currentEstimate.hoardingModifierName,
                              "hide-details": ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2", sm: "3" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _vm.$t(
                                    "scheduler.estimates.summary-panel.part-generation-type-label"
                                  )
                                : _vm.$t(
                                    "scheduler.estimates.summary-panel.part-generation-type-label-short"
                                  )
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.currentEstimate.generationTypeName) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "1", sm: "3" } }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", sm: "3" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              readonly: true,
                              label: _vm.$t(
                                "scheduler.walkdown-hard-barricade"
                              ),
                              value: _vm.currentEstimate.isHardBarricade,
                              "hide-details": ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-simple-table", [
                    _c("thead", [
                      _c("tr", [
                        _c("th", {
                          staticClass: "py-0",
                          staticStyle: { border: "none" }
                        }),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "py-0",
                            staticStyle: { border: "none" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "fd-estimate-summary-header-label"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "scheduler.estimates.summary-panel.walkdown-column-header"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "py-0",
                            staticStyle: { border: "none" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "fd-estimate-summary-header-label"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "scheduler.estimates.summary-panel.actuals-material-column-header"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "py-0",
                            staticStyle: { border: "none" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "fd-estimate-summary-header-label"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "scheduler.estimates.summary-panel.actuals-time-material-column-header"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticStyle: { "border-top": "none" },
                            attrs: { scope: "col" }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "scheduler.estimates.summary-panel.labour-summary-column-label"
                                  )
                                ) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: { "border-top": "none" },
                            attrs: { scope: "col" }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "scheduler.estimates.summary-panel.minutes-column-label"
                                  )
                                ) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: { "border-top": "none" },
                            attrs: { scope: "col" }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "scheduler.estimates.summary-panel.minutes-column-label"
                                  )
                                ) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: { "border-top": "none" },
                            attrs: { scope: "col" }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "scheduler.estimates.summary-panel.minutes-column-label"
                                  )
                                ) +
                                "\n              "
                            )
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.mobilization-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.currentEstimate
                                    .estimatedTotalMobilizationMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualsEstimatedTimes
                                    .estimatedTotalMobilizationMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [_c("fd-value-display")], 1)
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.erect-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.currentEstimate.estimatedTotalErectMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualsEstimatedTimes
                                    .estimatedTotalErectMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualWorkedTimes
                                    .workedTotalErectMinutes,
                                  2,
                                  true
                                )
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.dismantle-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.currentEstimate
                                    .estimatedTotalDismantleMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualsEstimatedTimes
                                    .estimatedTotalDismantleMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualWorkedTimes
                                    .workedTotalDismantleMinutes,
                                  2,
                                  true
                                )
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.demobilization-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.currentEstimate
                                    .estimatedTotalDemobilizationMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualsEstimatedTimes
                                    .estimatedTotalDemobilizationMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [_c("fd-value-display")], 1)
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.hoarding-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.currentEstimate
                                    .estimatedTotalHoardingMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualsEstimatedTimes
                                    .estimatedTotalHoardingMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [_c("fd-value-display")], 1)
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.modify-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.currentEstimate
                                    .estimatedTotalModifyMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualsEstimatedTimes
                                    .estimatedTotalModifyMinutes
                                ),
                                "no-value-text": "0.00"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualWorkedTimes
                                    .workedTotalModifyMinutes,
                                  2,
                                  true
                                )
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.total-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fd-estimate-summary-estimated-time-callout"
                            },
                            [
                              _c("fd-value-display", {
                                staticStyle: { width: "fit-content" },
                                attrs: {
                                  value: _vm.formatNumber(
                                    _vm.currentEstimate.estimatedTotalTime
                                  ),
                                  "no-value-text": "0.00"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fd-estimate-summary-estimated-time-callout"
                            },
                            [
                              _c("fd-value-display", {
                                staticStyle: { width: "fit-content" },
                                attrs: {
                                  value: _vm.formatNumber(
                                    _vm.workOrderActualsEstimatedTimes
                                      .estimatedTotalMinutes
                                  ),
                                  "no-value-text": "0.00"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fd-estimate-summary-estimated-time-callout"
                            },
                            [
                              _c("fd-value-display", {
                                staticStyle: { width: "fit-content" },
                                attrs: {
                                  value: _vm.formatNumber(
                                    _vm.workOrderActualWorkedTimes
                                      .workedTotalMinutes,
                                    2,
                                    true
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.weight-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.currentEstimate.estimatedTotalWeight,
                                  0
                                ),
                                "no-value-text": "0"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualsEstimatedTimes
                                    .actualTotalWeight,
                                  0
                                ),
                                "no-value-text": "0"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualWorkedTimes.totalWeight,
                                  0,
                                  true
                                )
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.pieces-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.currentEstimate.estimatedTotalPartCount,
                                  0
                                ),
                                "no-value-text": "0"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualsEstimatedTimes
                                    .actualTotalPartCount,
                                  0
                                ),
                                "no-value-text": "0"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("fd-value-display", {
                              staticStyle: { width: "fit-content" },
                              attrs: {
                                value: _vm.formatNumber(
                                  _vm.workOrderActualWorkedTimes.totalPartCount,
                                  0,
                                  true
                                )
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.erect-mpp-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fd-estimate-summary-estimated-time-callout"
                            },
                            [
                              _c("fd-value-display", {
                                staticStyle: { width: "fit-content" },
                                attrs: {
                                  value: _vm.formatNumber(
                                    _vm.currentEstimate.estimatedErectMPP
                                  ),
                                  "no-value-text": "0.00"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fd-estimate-summary-estimated-time-callout"
                            },
                            [
                              _c("fd-value-display", {
                                staticStyle: { width: "fit-content" },
                                attrs: {
                                  value: _vm.formatNumber(
                                    _vm.workOrderActualsEstimatedTimes
                                      .erectMinsPerPiece
                                  ),
                                  "no-value-text": "0.00"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fd-estimate-summary-estimated-time-callout"
                            },
                            [
                              _c("fd-value-display", {
                                staticStyle: { width: "fit-content" },
                                attrs: {
                                  value: _vm.formatNumber(
                                    _vm.workOrderActualWorkedTimes
                                      .erectMinsPerPiece,
                                    2,
                                    true
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "scheduler.estimates.summary-panel.dismantle-mpp-label"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fd-estimate-summary-estimated-time-callout"
                            },
                            [
                              _c("fd-value-display", {
                                staticStyle: { width: "fit-content" },
                                attrs: {
                                  value: _vm.formatNumber(
                                    _vm.currentEstimate.estimatedDismantleMPP
                                  ),
                                  "no-value-text": "0.00"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fd-estimate-summary-estimated-time-callout"
                            },
                            [
                              _c("fd-value-display", {
                                staticStyle: { width: "fit-content" },
                                attrs: {
                                  value: _vm.formatNumber(
                                    _vm.workOrderActualsEstimatedTimes
                                      .dismantleMinsPerPiece
                                  ),
                                  "no-value-text": "0.00"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "fd-estimate-summary-estimated-time-callout"
                            },
                            [
                              _c("fd-value-display", {
                                staticStyle: { width: "fit-content" },
                                attrs: {
                                  value: _vm.formatNumber(
                                    _vm.workOrderActualWorkedTimes
                                      .dismantleMinsPerPiece,
                                    2,
                                    true
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }