import FDVue from "@fd/lib/vue";
import {
  addDaysToDate,
  getNameOfDayFromNumber,
  localizedDateTimeString,
  localizedShortDateString
} from "@fd/lib/client-util/datetime";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { mapActions, mapMutations } from "vuex";
import { TranslateResult } from "vue-i18n";
import { DataTableHeader } from "vuetify";
import moment from "moment";
import {
  Contractor,
  DayInWeek,
  DayTimeSummary,
  EmployeeWeekTimeSummary,
  reportService,
  timesheetService
} from "../services";
import { SortItemsWithName } from "../utils/person";
import downloadBlob from "../../../lib/client-util/downloadBlob";
import printBlob from "../../../lib/client-util/printBlob";

let today = new Date(new Date().toDateString());
export default FDVue.extend({
  name: "fd-foreman-timesheet-weekending-summary",
  mixins: [serviceErrorHandling],
  data: function() {
    return {
      selectedDay: today,
      selectedContractorID: "",

      // Received from a service since days displayed are user configurable
      daysOfWeek: [] as DayInWeek[],
      employeeWeekendingSummaries: [] as EmployeeWeekTimeSummary[]
    };
  },
  computed: {
    selectableContractors() {
      return SortItemsWithName(this.$store.state.contractors.fullList as Contractor[]).filter(
        x => !!x.tracksEmployeeTime
      );
    },
    tablesearch: {
      get() {
        //return this.$store.state.searchStringForFiltering;
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },
    visibleSummaries() {
      let employeeWeekendingSummaries = this.employeeWeekendingSummaries;

      if (!!this.tablesearch) {
        employeeWeekendingSummaries = employeeWeekendingSummaries.filter(x =>
          `${x.employeeName} ${x.employeeCode} ${x.classificationAlias} ${x.classificationName}`
            .toLowerCase()
            .includes(this.tablesearch.toLowerCase())
        );
      }

      return employeeWeekendingSummaries;
    },
    totalSummary(): EmployeeWeekTimeSummary {
      let totalSummary = {
        employeeCode: "",
        employeeName: "",
        employeeID: "",
        classificationAlias: "",
        classificationName: "",
        totalRegularTime: 0,
        totalOverTime: 0,
        totalTime: 0,
        totalPerDiemCount: 0,
        dailyTimeSummaries: []
      } as EmployeeWeekTimeSummary;
      let totalTimeSummaries = [] as DayTimeSummary[];
      for (let employeeSummary of this.visibleSummaries) {
        totalSummary.totalRegularTime += employeeSummary.totalRegularTime;
        totalSummary.totalOverTime += employeeSummary.totalOverTime;
        totalSummary.totalPerDiemCount += employeeSummary.totalPerDiemCount;
        totalSummary.totalTime += employeeSummary.totalTime;

        employeeSummary.dailyTimeSummaries.forEach(s => {
          let existingSummary = totalTimeSummaries.find(x => x.date.getTime() == s.date.getTime());
          if (!existingSummary) {
            existingSummary = {
              date: s.date,
              regularTime: 0,
              overTime: 0,
              totalTime: 0,
              perDiemCount: 0
            };
            totalTimeSummaries.push(existingSummary);
          }
          existingSummary.regularTime += s.regularTime;
          existingSummary.overTime += s.overTime;
          existingSummary.perDiemCount += s.perDiemCount;
          existingSummary.totalTime += s.totalTime;
        });
      }
      totalSummary.dailyTimeSummaries = totalTimeSummaries;

      return totalSummary;
    }
  },
  watch: {
    selectedDay(newValue, oldValue) {
      if (new Date(newValue).getTime() == new Date(oldValue).getTime()) return;
      this.loadData();
    },
    selectedContractorID(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.loadData(false);
    }
  },
  methods: {
    reloadTableData() {
      this.loadData(false);
    },
    async loadData(loadDays: boolean = true) {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        if (loadDays) {
          await this.loadDaysOfWeekContainingSelectedDay();
        }
        await this.loadTimesheetsForWeekContainingSelectedDay();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    getHoursOnDate(
      employeeSummary: EmployeeWeekTimeSummary,
      date: Date
    ): DayTimeSummary | undefined {
      let hours = employeeSummary.dailyTimeSummaries.find(x => x.date.getTime() == date.getTime());

      return hours;
    },
    parseRegularTime(employeeSummary: EmployeeWeekTimeSummary, date: Date): number {
      return this.getHoursOnDate(employeeSummary, date)?.regularTime ?? 0;
    },
    parseOverTime(employeeSummary: EmployeeWeekTimeSummary, date: Date): number {
      return this.getHoursOnDate(employeeSummary, date)?.overTime ?? 0;
    },
    parsePerDiem(employeeSummary: EmployeeWeekTimeSummary, date: Date): number {
      return this.getHoursOnDate(employeeSummary, date)?.perDiemCount ?? 0;
    },
    parseTotalTime(employeeSummary: EmployeeWeekTimeSummary, date: Date): number {
      return this.getHoursOnDate(employeeSummary, date)?.totalTime ?? 0;
    },
    dayName(dayNumber: number): string {
      return getNameOfDayFromNumber(dayNumber);
    },
    formatDate(date: Date | string | null | undefined): string {
      return localizedShortDateString(date);
    },
    formatNumber(number: number | null | undefined, precision: number = 2) {
      return !number ? undefined : number.toFixed(precision);
    },
    dateIsToday(date: Date) {
      return date.getTime() == today.getTime();
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS"
    }),
    async loadDaysOfWeekContainingSelectedDay() {
      this.daysOfWeek = (
        await timesheetService.getDatesForWeekContainingDate(this.selectedDay)
      ).sort((a, b) => a.displayOrder - b.displayOrder);
    },
    async loadTimesheetsForWeekContainingSelectedDay() {
      if (!this.selectedContractorID) return;

      this.employeeWeekendingSummaries = (
        await timesheetService.getTimeSummaryForContractorInWeek(
          this.selectedContractorID,
          this.selectedDay
        )
      ).sort((a, b) => {
        let aName = a.employeeName!.toLocaleLowerCase();
        let bName = b.employeeName!.toLocaleLowerCase();

        if (aName < bName) return -1;
        else if (aName > bName) return 1;

        return 0;
      });
    },
    async selectPreviousWeekending(e: Event) {
      e.stopPropagation();
      e.preventDefault();
      this.selectedDay = addDaysToDate(this.daysOfWeek[0].date, -1);
      return false;
    },
    async selectNextWeekending(e: Event) {
      e.stopPropagation();
      e.preventDefault();
      this.selectedDay = addDaysToDate(this.daysOfWeek[this.daysOfWeek.length - 1].date, 7);
      return false;
    },
    async downloadAndPrintTimesheetWeekendingReport(showOnlyVisible: boolean, reportType: string) {
      this.processing = true;
      try {
        let dataRows = this.employeeWeekendingSummaries;
        if (showOnlyVisible) {
          dataRows = this.visibleSummaries;
        }
        if (dataRows.length == 0) {
          this.$store.dispatch("SHOW_SNACKBAR", {
            text: this.$t("timesheets.weekending-summary.printing.no-data-message"),
            type: "info"
          });
          return;
        }
        dataRows = SortItemsWithName(
          dataRows.map(x => ({
            ...x,
            name: x.employeeName
          }))
        );
        var blob = await reportService.getTimesheetWeekendingPrintoutReportContentWithData(
          dataRows,
          this.daysOfWeek.map(x => ({ ...x, name: getNameOfDayFromNumber(x.dayNumber) })),
          this.totalSummary,
          reportType,
          localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "timesheet-weekending-printout.xlsx");
        } else {
          printBlob(blob, "timesheet-weekending-printout.pdf", "application/pdf");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },
  created: async function() {
    this.setFilteringContext({
      context: "timesheetweekendingsummary",
      parentalContext: null,
      contractorsForFiltering: [],
      searchStringForFiltering: "",
      contextForFiltering: "all",
      peopleForFiltering: [],
      statusesForFiltering: []
    });

    this.notifyNewBreadcrumb({
      text: this.$t("timesheets.weekending-summary.title"),
      to: "/timesheetweekendingsummary",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.loadContractors();
      await this.loadDaysOfWeekContainingSelectedDay();
      if (this.selectableContractors.length > 0) {
        this.selectedContractorID = this.selectableContractors[0].id!;
      }
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

