var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "data-cy": "countsheetgroupexisting" },
          on: { submit: _vm.onSubmit }
        },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: function(item) {
                                        return _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || "/countsheetgroups/"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "py-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "pt-3",
                          attrs: { "icons-and-text": "", "show-arrows": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: "#tab-" + _vm.detailsTab.key },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "countsheetgroups.existing.tabs.details"
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.partsTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.partsTab.key,
                                  attrs: {
                                    "data-cy": "parts",
                                    href: "#tab-" + _vm.partsTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.partsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "countsheetgroups.existing.tabs.parts"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-truck-loading")])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: "tab-" + _vm.detailsTab.key }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pb-md-0 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.countSheetGroup.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.countSheetGroup,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "countSheetGroup.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-rich-textarea", {
                                        attrs: {
                                          disabled: _vm.processing,
                                          label: _vm.$t("common.description"),
                                          allowImages: false
                                        },
                                        model: {
                                          value:
                                            _vm.countSheetGroup.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.countSheetGroup,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "countSheetGroup.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t("common.archived"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.countSheetGroup.archived,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.countSheetGroup,
                                              "archived",
                                              $$v
                                            )
                                          },
                                          expression: "countSheetGroup.archived"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.partsTab.key,
                              attrs: { value: "tab-" + _vm.partsTab.key }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c("fd-inline-add-button", {
                                            attrs: { "data-cy": "addpart" },
                                            on: { click: _vm.openPartsDialog }
                                          }),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "countsheetgroups.existing.parts-listing-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer")
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-table-sortable",
                                            rawName: "v-fd-table-sortable"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:publicID",
                                            value: _vm.$t("common.code"),
                                            expression: "$t('common.code')",
                                            arg: "publicID"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:description",
                                            arg: "description"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:action.no-sort.class_fd-action-cell",
                                            arg: "action",
                                            modifiers: {
                                              "no-sort": true,
                                              "class_fd-action-cell": true
                                            }
                                          }
                                        ],
                                        attrs: {
                                          items: _vm.parts,
                                          "mobile-breakpoint": "0"
                                        },
                                        on: { "sort:end": _vm.dragEnded },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.fd-drag",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("far fa-grip-lines")]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.action",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-1-action-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteCountSheetGroupPart(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                link: "",
                                disabled: _vm.processing,
                                loading: _vm.deleting,
                                color: "error",
                                outlined: "",
                                small: _vm.$vuetify.breakpoint.name == "xs"
                              },
                              on: { click: _vm.deleteItem }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.delete")))]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                text: "",
                                link: "",
                                disabled: _vm.processing,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.cancel }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.cancel")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("fd-menu-save", {
                            attrs: {
                              disabled: _vm.processing,
                              loading: _vm.saving,
                              small: _vm.$vuetify.breakpoint.xsOnly
                            },
                            on: {
                              "click:save": function($event) {
                                return _vm.save(false)
                              },
                              "click:save-and-close": function($event) {
                                return _vm.save(true)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }