import Vue from "vue";
import Vuex from "vuex";
import { pause } from "@fd/lib/client-util/util";

import moment from "moment";

import { AutomaticCrudState } from "./store/automatic";
import SupplierStore from "./store/suppliers";
import OwnerStore from "./store/owners";
import RegionStore from "./store/regions";
import ContractorStore from "./store/contractors";
import UserStore from "./store/people";
import TagStore from "./store/tags";
import PartStore from "./store/parts";
import ProjectStore from "./store/projects";
import ProjectCostCodeStore from "./store/projectcostcodes";
import EstimateStore from "./store/estimates";
import LanguageStore from "./store/languages";
import CountSheetGroupStore from "./store/countsheetgroups";
import WorkTypeStore from "./store/worktypes";
import ScaffoldDistanceModifierStore from "./store/scaffolddistancemodifiers";
import ScaffoldElevationModifierStore from "./store/scaffoldelevationmodifiers";
import ScaffoldHeightModifierStore from "./store/scaffoldheightmodifiers";
import ScaffoldTypeModifierStore from "./store/scaffoldtypemodifiers";
import ScaffoldCongestionFactorStore from "./store/scaffoldcongestionfactors";
import BuildDismantleRatioStore from "./store/builddismantleratios";
import HoardingModifierStore from "./store/hoardingmodifiers";
import InternalModifierStore from "./store/internalmodifiers";
import ScaffoldBayLengthStore from "./store/scaffoldbaylengths";
import ScaffoldBayWidthStore from "./store/scaffoldbaywidths";
import ScaffoldBayHeightStore from "./store/scaffoldbayheights";
import WorkSubTypeStore from "./store/worksubtypes";
import NotificationStore from "./store/notifications";
import WorkPackageStore from "./store/workpackages";
import YardStore from "./store/yards";
import ClassificationsStore from "./store/classifications";
import DisciplineStore from "./store/disciplines";
import DropoffLocationStore from "./store/dropofflocations";
import CrewStore from "./store/crews";
import {
  SupplierWithTags,
  Owner,
  Region,
  Tag,
  PartWithTags,
  EstimateWithParts,
  ContractorWithTags,
  PersonWithDetails,
  Language,
  Notification,
  WorkPackage,
  Discipline,
  AccessInformation,
  versionService,
  CountSheetGroupWithParts,
  ScaffoldBayLength,
  ScaffoldBayWidth,
  ScaffoldBayHeight,
  WorkTypeWithDetails,
  WorkSubType,
  Yard,
  DropoffLocation,
  ProjectCostCode,
  Classification,
  CrewWithEmployees,
  Environment,
  ScaffoldDistanceModifier,
  ScaffoldElevationModifier,
  ScaffoldHeightModifier,
  ScaffoldTypeModifier,
  ScaffoldCongestionFactor,
  BuildDismantleRatio,
  HoardingModifier,
  InternalModifier
} from "./services";

import {
  projectService,
  ProjectWithParts,
  projectLocationService,
  ProjectLocation
} from "./services/index";

// TODO: We need to export this to re-export our state variable elsewhere
export interface Breadcrumb {
  disabled?: boolean;
  exact?: boolean;
  href?: string;
  link?: boolean;
  text?: string | number;
  to?: string | object;
}

export interface Filter {
  context: string;
  parentalContext?: string;
  contextForFiltering?: string;
  disciplinesForFiltering?: string[];
  foremanIDsForFiltering?: string[];
  generalForemanIDsForFiltering?: string[];
  areasForFiltering?: string[];
  subAreasForFiltering?: string[];
  showArchivedForFiltering?: boolean;
  showArchivedForFilteringFromDate?: Date;
  showArchivedForFilteringToDate?: Date;
  searchStringForFiltering?: string;
  statusesForFiltering?: string[];
  tagsForFiltering?: string[];
  suppliersForFiltering?: string[];
  projectsForFiltering?: string[];
  contractorsForFiltering?: string[];
  requestTypesForFiltering?: number[];
  peopleForFiltering?: string[];
  selectedTab?: number;
  expandedPanel?: number;
}

interface BreadcrumbUpdate {
  text: string | number;
  to: string; // TODO: API supports objects but since we don't deep compare when checking history we can't do the same; we should allow objects and do deep compare for history check
  resetHistory?: boolean;
  history?: BreadcrumbUpdate[] | undefined;
}

import i18n from "./i18n";
import { LANGUAGES } from "./i18n";
import { showDisableInsteadConfirmation } from "../../common/client/views/components/DisableInsteadDialog.vue";

import VueI18n from "vue-i18n";
import { Lang } from "vuetify/types/services/lang";
import { versions } from "process";
Vue.use(Vuex);

export interface StoreState {
  // Modules
  suppliers: AutomaticCrudState<SupplierWithTags>;
  owners: AutomaticCrudState<Owner>;
  regions: AutomaticCrudState<Region>;
  tags: AutomaticCrudState<Tag>;
  parts: AutomaticCrudState<PartWithTags>;
  projects: AutomaticCrudState<ProjectWithParts>;
  projectCostCodes: AutomaticCrudState<ProjectCostCode>;
  estimates: AutomaticCrudState<EstimateWithParts>;
  contractors: AutomaticCrudState<ContractorWithTags>;
  users: AutomaticCrudState<PersonWithDetails>;
  languages: AutomaticCrudState<Language>;
  notifications: AutomaticCrudState<Notification>;
  workPackages: AutomaticCrudState<WorkPackage>;
  disciplines: AutomaticCrudState<Discipline>;
  countSheetGroups: AutomaticCrudState<CountSheetGroupWithParts>;
  workTypes: AutomaticCrudState<WorkTypeWithDetails>;
  workSubTypes: AutomaticCrudState<WorkSubType>;
  yards: AutomaticCrudState<Yard>;
  classifications: AutomaticCrudState<Classification>;
  dropoffLocations: AutomaticCrudState<DropoffLocation>;
  crews: AutomaticCrudState<CrewWithEmployees>;
  scaffoldBayLengths: AutomaticCrudState<ScaffoldBayLength>;
  scaffoldBayWidths: AutomaticCrudState<ScaffoldBayWidth>;
  scaffoldBayHeights: AutomaticCrudState<ScaffoldBayHeight>;
  scaffoldDistanceModifiers: AutomaticCrudState<ScaffoldDistanceModifier>;
  scaffoldElevationModifiers: AutomaticCrudState<ScaffoldElevationModifier>;
  scaffoldHeightModifiers: AutomaticCrudState<ScaffoldHeightModifier>;
  scaffoldTypeModifiers: AutomaticCrudState<ScaffoldTypeModifier>;
  scaffoldCongestionFactors: AutomaticCrudState<ScaffoldCongestionFactor>;
  buildDismantleRatios: AutomaticCrudState<BuildDismantleRatio>;
  hoardingModifiers: AutomaticCrudState<HoardingModifier>;
  internalModifiers: AutomaticCrudState<InternalModifier>;
  // State Properties
  barColor: string;
  barImage: string;
  lastBreadcrumbs: Breadcrumb[];
  currentBreadcrumbs: Breadcrumb[];
  breadcrumbs: Breadcrumb[];
  loginReturnPath: string | null;
  showAppBar: boolean;
  showDrawer: boolean;
  showFooter: boolean;
  showBottomBar: boolean;
  drawer: any; // TODO: TYPE THIS!
  drawerMini: boolean;
  dialogPrivacy: boolean;
  dialogTerms: boolean;
  termsPersistent: boolean;
  snackbar: boolean;
  snackbarText: string;
  snackbarType: string;
  snackbarUndoCallback: (() => Promise<void>) | null;
  snackbarCountdownInterval: number | null;
  snackbarCountdown: number;
  project: ProjectWithParts;
  projectLocations: ProjectLocation[];
  filteringContext: string | null;
  searchStringForFiltering: string;
  tagsForFiltering: [];
  suppliersForFiltering: [];
  statusesForFiltering: [];
  projectsForFiltering: [];
  contractorsForFiltering: [];
  peopleForFiltering: [];
  filters: Array<Filter>;
  inlineDialogRef: any; // TODO: TYPE THIS
  avatarInitials: string;
  curUserFullName: string | null;
  curUserID: string | null;
  language: LANGUAGES;
  curUserAccess: AccessInformation;
  curEnvironment: Environment;
  datepickerLanguage: string;
  versionString: string;
  applicationName: string;
  themeName: string;
  footerImageSource: string;
  brandImageWidthValue: number;
  brandImageHeightValue: number;
  brandImageWidthSmallerValue: number;
  brandImageHeightSmallerValue: number;
  imageSourceLogo: string;
  imageSourceLogoReversed: string;
  imageSourceLogoGray: string;
}

export default new Vuex.Store<StoreState>({
  modules: {
    suppliers: SupplierStore,
    owners: OwnerStore,
    regions: RegionStore,
    tags: TagStore,
    parts: PartStore,
    projects: ProjectStore,
    projectCostCodes: ProjectCostCodeStore,
    estimates: EstimateStore,
    contractors: ContractorStore,
    users: UserStore,
    languages: LanguageStore,
    notifications: NotificationStore,
    workPackages: WorkPackageStore,
    countSheetGroups: CountSheetGroupStore,
    workTypes: WorkTypeStore,
    workSubTypes: WorkSubTypeStore,
    yards: YardStore,
    classifications: ClassificationsStore,
    dropoffLocations: DropoffLocationStore,
    disciplines: DisciplineStore,
    crews: CrewStore,
    scaffoldDistanceModifiers: ScaffoldDistanceModifierStore,
    scaffoldElevationModifiers: ScaffoldElevationModifierStore,
    scaffoldHeightModifiers: ScaffoldHeightModifierStore,
    scaffoldTypeModifiers: ScaffoldTypeModifierStore,
    scaffoldCongestionFactors: ScaffoldCongestionFactorStore,
    buildDismantleRatios: BuildDismantleRatioStore,
    hoardingModifiers: HoardingModifierStore,
    internalModifiers: InternalModifierStore,
    scaffoldBayLengths: ScaffoldBayLengthStore,
    scaffoldBayWidths: ScaffoldBayWidthStore,
    scaffoldBayHeights: ScaffoldBayHeightStore
  },
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage: "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
    lastBreadcrumbs: [] as Breadcrumb[],
    currentBreadcrumbs: [] as Breadcrumb[],
    breadcrumbs: [] as Breadcrumb[],
    loginReturnPath: null as string | null,
    showAppBar: false,
    showDrawer: false,
    showFooter: false,
    showBottomBar: false,
    drawer: null,
    drawerMini: false,
    dialogPrivacy: false,
    dialogTerms: false,
    snackbar: false,
    snackbarText: "",
    snackbarType: "info",
    snackbarUndoCallback: null as (() => Promise<void>) | null,
    // Holds a reference to the numeric interval timer ID so that it can be cleared if needed.
    snackbarCountdownInterval: null as number | null,
    snackbarCountdown: 20,
    projectLocations: [] as ProjectLocation[],
    filteringContext: null,
    searchStringForFiltering: "",
    tagsForFiltering: [],
    suppliersForFiltering: [],
    statusesForFiltering: [],
    projectsForFiltering: [],
    contractorsForFiltering: [],
    peopleForFiltering: [],
    filters: [] as Filter[],
    inlineDialogRef: null,
    avatarInitials: "",
    curUserFullName: null,
    curUserID: null,
    language: LANGUAGES.ENGLISH,
    datepickerLanguage: "en-us",
    versionString: "0.0.0.0",
    applicationName: "",
    themeName: "blank",
    footerImageSource: "/assets/img/logo.svg",
    brandImageWidthValue: -1,
    brandImageHeightValue: -1,
    brandImageWidthSmallerValue: -1,
    brandImageHeightSmallerValue: -1,
    imageSourceLogo: "/assets/img/logo.svg",
    imageSourceLogoReversed: "/assets/img/logo_reversed.svg",
    imageSourceLogoGray: "/assets/img/logo_gray.svg"
  } as StoreState,
  mutations: {
    SET_APPLICATION_NAME(state, payload) {
      state.applicationName = payload;
      document.title = payload;
      console.log(`state doc title: ${document.title}`);
    },
    SET_THEME(state, payload) {
      state.themeName = payload;
      // console.log(`clear height values`);
      state.brandImageWidthValue = -1;
      state.brandImageHeightValue = -1;
      state.brandImageWidthSmallerValue = -1;
      state.brandImageHeightSmallerValue = -1;
      if (state.themeName == "sunbelt") {
        state.imageSourceLogo = "/assets/img/logo_sunbelt.svg";
        state.imageSourceLogoReversed = "/assets/img/logo_reversed_sunbelt.svg";
        state.imageSourceLogoGray = "/assets/img/logo_gray_sunbelt.svg";
      } else if (state.themeName == "apache") {
        state.imageSourceLogo = "/assets/img/logo_apache.svg";
        state.imageSourceLogoReversed = "/assets/img/logo_reversed_apache.svg";
        state.imageSourceLogoGray = "/assets/img/logo_gray_apache.svg";
      } else if (state.themeName == "ameco") {
        state.imageSourceLogo = "/assets/img/logo_ameco.svg";
        state.imageSourceLogoReversed = "/assets/img/logo_reversed_ameco.svg";
        state.imageSourceLogoGray = "/assets/img/logo_gray_ameco.svg";
      } else if (state.themeName == "peri") {
        state.imageSourceLogo = "/assets/img/logo_peri.svg";
        state.imageSourceLogoReversed = "/assets/img/logo_reversed_peri.svg";
        state.imageSourceLogoGray = "/assets/img/logo_gray_peri.svg";
      } else {
        state.imageSourceLogo = "/assets/img/logo.svg";
        state.imageSourceLogoReversed = "/assets/img/logo_reversed.svg";
        state.imageSourceLogoGray = "/assets/img/logo_gray.svg";
      }
    },
    SET_BRAND_LOGO_WIDTH(state, payload) {
      state.brandImageWidthValue = payload;
    },
    SET_BRAND_LOGO_HEIGHT(state, payload) {
      state.brandImageHeightValue = payload;
    },
    SET_BRAND_LOGO_WIDTH_SMALLER(state, payload) {
      state.brandImageWidthSmallerValue = payload;
    },
    SET_BRAND_LOGO_HEIGHT_SMALLER(state, payload) {
      state.brandImageHeightSmallerValue = payload;
    },
    SET_FOOTER_IMAGE_SOURCE(state, payload) {
      state.footerImageSource = payload;
    },
    SET_CURRENT_VERSION(state, payload) {
      state.versionString = payload;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_SHOW_APP_BAR(state, payload) {
      state.showAppBar = payload;
    },
    SET_SHOW_DRAWER(state, payload) {
      state.showDrawer = payload;
    },
    SET_SHOW_FOOTER(state, payload) {
      state.showFooter = payload;
    },
    SET_SHOW_BOTTOM_BAR(state, payload) {
      state.showBottomBar = payload;
    },
    SET_LOGIN_RETURN_PATH(state, payload) {
      state.loginReturnPath = payload;
    },
    CLEAR_LOGIN_RETURN_PATH(state) {
      state.loginReturnPath = null;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_DRAWER_MINI(state, payload) {
      state.drawerMini = payload;
    },
    SET_PRIVACY_DIALOG(state, payload) {
      state.dialogPrivacy = payload;
    },
    SET_TERMS_DIALOG(state, payload) {
      state.dialogTerms = payload;
      state.termsPersistent = false;
    },
    SET_TERMS_DIALOG_PERSISTENT(state, payload) {
      state.dialogTerms = payload;
      state.termsPersistent = true;
    },
    SET_SNACKBAR(state, payload) {
      state.snackbar = payload;
    },
    SET_SNACKBAR_TEXT(state, payload) {
      state.snackbarText = payload;
    },
    SET_SNACKBAR_TYPE(state, payload) {
      state.snackbarType = payload;
    },
    SET_SNACKBAR_UNDO_CALLBACK(state, payload) {
      state.snackbarUndoCallback = payload;
    },
    SET_SNACKBAR_COUNTDOWN_INTERVAL(state, payload) {
      state.snackbarCountdownInterval = payload;
    },
    SET_SNACKBAR_COUNTDOWN(state, payload) {
      state.snackbarCountdown = payload;
    },
    CLEAR_SNACKBAR_COUNTDOWN_INTERVAL(state, payload) {
      if (state.snackbarCountdownInterval != null) {
        clearInterval(state.snackbarCountdownInterval);
        state.snackbarCountdownInterval = null;
      }
    },
    SET_AVATAR_INITIALS(state, payload) {
      state.avatarInitials = payload;
    },
    SET_CUR_USERFULLNAME(state, payload: string) {
      state.curUserFullName = payload;
    },
    SET_CUR_USERID(state, payload: string) {
      state.curUserID = payload;
    },
    SET_CUR_USER_ACCESS(state, payload: AccessInformation) {
      state.curUserAccess = payload;
    },
    SET_CUR_ENVIRONMENT(state, payload: Environment) {
      state.curEnvironment = payload;
    },
    SET_PREFERRED_LANGUAGE(state, payload) {
      // The "number" associated to the language is used to qualify the items chosen in the language menu.
      state.language = payload.number;
      //This updates the i18n library to know which language to display
      i18n.locale = payload.shortCode;
      //This qualifies the various vuetify datepickers to use the appropriate language
      state.datepickerLanguage = payload.shortCodeExt;
      //The moment library allows us to give fully qualified dates (in long form) in any language.
      moment.locale(payload.shortCodeExt);
    },
    // *** USER FILTERING MANAGMENT ***
    ADD_CHILD_FILTERING_CONTEXT(state, payload) {
      // Confirm the context has a parent context, and that the parent exists in the filters list
      if (
        !payload.parentalContext ||
        state.filters.findIndex(x => x.context == payload.parentalContext) == -1
      )
        return;

      if (state.filters.findIndex(x => x.context == payload.context) == -1) {
        // This is a new Filter Context so add it to the Filters array
        state.filters.push(payload);
      }
    },
    SET_FILTERING_CONTEXT(state, payload) {
      // First check to see if this filter context has been saved previously
      if (state.filters.findIndex(x => x.context == payload.context) == -1) {
        // This is a new filter context, next check to see if it has any parental heritage stored in the existing filters
        if (state.filters.findIndex(x => x.context == payload.parentalContext) == -1) {
          // There is no entry of a parent for this new filter context so empty out the filters array,
          // you are in a new chain of potential screens with related filter contexts.

          state.filters = [];
        }

        // This is a new Filter Context so add it to the Filters array
        state.filters.push(payload);
      } else {
        // This filter context was already being tracked. Likely that someone was on a sub screen like "Modifier Values"
        // then clicked the breadcrumb to go back to the main "Modifiers" listing screen. For this situation we want to
        // clear the cached filtering data of any child screens so the user has to start over setting them up in this
        // situation. To do this we need to remove any items that have the parental heritage of the item.
        if (payload.parentalContext == null) {
          // This means that this is a top context item in a chain so you can clip all other entries below it.
          state.filters = state.filters.filter(
            x => x.context == payload.context && x.parentalContext == null
          );
        } else {
          // If we are here we know that the current Filter Context has related contextual children that are being tracked.
          // The behavior we want is that since the user has returned up the contextual chain that these child filter contexts
          // are to have their caching removed.

          let immediateChildFilterContext: Filter;
          let immediateChildFilterIndex;

          // First find the immediate contextual child of the current item if it exists (there should be at least one if we are here.)
          immediateChildFilterIndex = state.filters.findIndex(
            x => x.parentalContext == payload.context
          )!;
          while (immediateChildFilterIndex > -1) {
            // Save the info of this immediate child.
            immediateChildFilterContext = state.filters.find(
              x => x.parentalContext == payload.context
            )!;
            // Filter the overall store filters to remove this immediate child.
            state.filters = state.filters.filter(
              x => x.parentalContext != payload.context && x.parentalContext != null
            );
            // Check to see if the child that was removed had any children and repeat the process.
            immediateChildFilterIndex = state.filters.findIndex(
              x => x.parentalContext == immediateChildFilterContext.context
            );
          }
        }
      }
      state.filteringContext = payload.context;
    },
    SET_CONTEXT_FOR_FILTERING(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.contextForFiltering = payload;
    },
    SET_DISCIPLINES_FOR_FILTERING(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.disciplinesForFiltering = payload;
    },
    SET_GENERAL_FOREMAN_IDS_FOR_FILTERING(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.generalForemanIDsForFiltering = payload;
    },
    SET_FOREMAN_IDS_FOR_FILTERING(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.foremanIDsForFiltering = payload;
    },
    SET_AREAS_FOR_FILTERING(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.areasForFiltering = payload;
    },
    SET_SUB_AREAS_FOR_FILTERING(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.subAreasForFiltering = payload;
    },
    SET_SHOW_ARCHIVED_FOR_FILTERING(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.showArchivedForFiltering = payload;
    },
    SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.showArchivedForFilteringFromDate = payload;
    },
    SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.showArchivedForFilteringToDate = payload;
    },
    SET_SEARCH_STRING_FOR_FILTERING(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.searchStringForFiltering = payload;
    },
    SET_TAGS_FOR_FILTERING(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.tagsForFiltering = payload;
    },
    SET_SUPPLIERS_FOR_FILTERING(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.suppliersForFiltering = payload;
    },
    SET_STATUSES_FOR_FILTERING(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.statusesForFiltering = payload;
      state.filters = state.filters;
    },
    SET_PROJECTS_FOR_FILTERING(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.projectsForFiltering = payload;
    },
    SET_PEOPLE_FOR_FILTERING(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.peopleForFiltering = payload;
    },
    SET_REQUEST_TYPES_FOR_FILTERING(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.requestTypesForFiltering = payload;
    },
    SET_CONTRACTORS_FOR_FILTERING(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.contractorsForFiltering = payload;
    },
    SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.selectedTab = payload;
    },
    SET_EXPANDED_PANEL_INDEX_IN_FILTERING_CONTEXT(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.expandedPanel = payload;
    },
    SET_INLINE_DIALOG_REF(state, payload) {
      if (state.inlineDialogRef == null) {
        state.inlineDialogRef = payload;
      } else if (state.inlineDialogRef != payload) {
        (state.inlineDialogRef as any).cancelDialog();
        state.inlineDialogRef = payload;
      }
    },

    // *** BREADCRUMB MANAGEMENT ***
    NOTIFY_NAVIGATION_STARTED(state) {
      // This is called before each navigation; on a page that is breadcrumb-aware the
      // currentBreadcrumb array will be non-empty; we'll copy that to our backup, clear the
      // current list of breadcrumbs, and wait for the next page to notify us that it's ready
      // for breadcrumbs; if navigation works through a page that doesn't know about breadcrumbs,
      // the currentBreadcrumb array will be empty and we'll basically be resetting the list of
      // breadcrumbs for a new navigation
      state.lastBreadcrumbs = state.currentBreadcrumbs;
      state.currentBreadcrumbs = [];
    },
    NOTIFY_NEW_BREADCRUMB(state, payload: BreadcrumbUpdate) {
      // Set the current breadcrumbs, using the last breadcrumbs as a chain unless the caller is
      // requesting a fresh breadcrumb chain
      // debugger
      var newBreadcrumb = { ...payload, disabled: true };
      if (payload.resetHistory || state.lastBreadcrumbs.length === 0) {
        state.currentBreadcrumbs = [newBreadcrumb];
      } else {
        // There is currently no predictable way to know when a person has hit the back button
        // via the Vuex route capabilities; even using onpopstate manually doesn't work because
        // that also fires when you navigate more than one step; our approach to this will be
        // to assume that going to a URL that's already in your current breadcrumbs means you
        // intend to go back (e.g. if your breadcrumbs are ['/url/1', '/url/2', '/url/3'] and
        // you navigate to '/url/2' we assume you don't want a breadcrumb list of ['/url/1',
        // '/url/2', '/url/3', '/url/2'] even though that reflects where you actually went and
        // will reflect what happens when you hit the back button); as such, we see if the new
        // URL is already in our list of URLs, and if it is we truncate the current breadcrumb
        // list at that point and use the new data for our next one
        let targetBreadcrumbIndex = state.lastBreadcrumbs.findIndex(x => x.to == payload.to);
        if (targetBreadcrumbIndex === -1) {
          targetBreadcrumbIndex = state.lastBreadcrumbs.length;
        }

        // When we create the new breadcrumb it is always in disabled state, so we need to enable
        // the second last breadcrumb as well as adding the new one; this changes if we are using
        // a back navigation to our starting point since we may no longer have a prior breadcrumb
        // to enable
        if (targetBreadcrumbIndex !== 0) {
          // We have a breadcrumb that needs to be updated
          state.currentBreadcrumbs = [
            ...state.lastBreadcrumbs.slice(0, targetBreadcrumbIndex - 1),
            {
              ...state.lastBreadcrumbs[targetBreadcrumbIndex - 1],
              disabled: false,
              exact: true
            },
            ...(payload.history ?? []),
            newBreadcrumb
          ];
        } else {
          // We're going back to the root of our breadcrumbs; this is once again a simple single
          // entry
          state.currentBreadcrumbs = [newBreadcrumb];
        }
      }

      // Clear the last breadcrumbs; they are not used unless we are in transition between routes
      state.lastBreadcrumbs = [];
    },

    // **** PROJECT LOCATIONS ****
    // This has remained in the main Vuex Store since the automatically generated files are based on a separation of
    // files based on a database table. With regards to Areas and Sub Areas they relate to the same "Project Locations"
    // database table thus they currently are not good candidates for the automatically generated Vuex Store modularization
    // pattern that we have been using for other tables.
    REPLACE_LOCATIONS_FOR_PROJECT(
      state,
      payload: { projectLocations: ProjectLocation[]; projectID: string }
    ) {
      let projectLocations = state.projectLocations.filter(x => x.projectID != payload.projectID);
      projectLocations = projectLocations.concat(payload.projectLocations);
      state.projectLocations = projectLocations;
    },
    REPLACE_LOCATIONS_FOR_PARENT_LOCATION(
      state,
      payload: { projectLocations: ProjectLocation[]; parentLocationID: string }
    ) {
      let projectLocations = state.projectLocations.filter(
        x => x.parentLocationID != payload.parentLocationID
      );
      projectLocations = projectLocations.concat(payload.projectLocations);
      state.projectLocations = projectLocations;
    },
    SET_PROJECT(state, payload) {
      state.project = payload;
    },
    SET_PROJECT_LOCATION(state, payload) {
      let projectLocations = [...state.projectLocations];
      let projectLocationIndex = state.projectLocations.findIndex(x => x.id == payload.id);
      // If payload is not found, it is a new item.
      if (projectLocationIndex === -1) {
        projectLocations.push(payload);
      } else {
        projectLocations[projectLocationIndex] = {
          ...projectLocations[projectLocationIndex],
          ...payload
        };
      }
      state.projectLocations = projectLocations;
    },
    DELETE_PROJECT_LOCATION(state, payload) {
      let projectLocationIndex = state.projectLocations.findIndex(x => x.id == payload.id);
      // If there was no item found then the slice function will essentially just grab the entire array of objects again.
      // Thus nothing would be deleted.
      state.projectLocations = state.projectLocations
        .slice(0, projectLocationIndex)
        .concat(state.projectLocations.slice(projectLocationIndex + 1));
    }
  },
  getters: {
    // Brand Image Default Numbers
    // MPC
    // brandImageWidth: 300,
    // brandImageHeight: 155,
    // brandImageWidthSmaller: 300,
    // brandImageHeightSmaller: 155,
    // Sunbelt
    // brandImageWidth: 300,
    // brandImageHeight: 83,
    // brandImageWidthSmaller: 300,
    // brandImageHeightSmaller: 83,
    brandImageWidth(state, getters): number {
      if (state.brandImageWidthValue < 0) {
        // console.log(`RESET WIDTH`);
        state.brandImageWidthValue = getters.brandImageWidth_Default;
      }
      // console.log(`WIDTH: ${state.brandImageWidthValue}, theme: ${state.themeName}`);
      return state.brandImageWidthValue;
    },
    brandImageWidthSmaller(state, getters): number {
      if (state.brandImageWidthSmallerValue < 0) {
        state.brandImageWidthSmallerValue = getters.brandImageWidth_Default;
      }
      // console.log(`WIDTH SMALLER: ${state.brandImageWidthSmallerValue}, theme: ${state.themeName}`);
      return state.brandImageWidthSmallerValue;
    },
    brandImageHeight(state, getters): number {
      if (state.brandImageHeightValue < 0) {
        // console.log(`RESET HEIGHT`);
        state.brandImageHeightValue = getters.brandImageHeight_Default;
      }
      // console.log(`HEIGHT: ${state.brandImageHeightValue}, theme: ${state.themeName}`);
      return state.brandImageHeightValue;
    },
    brandImageHeightSmaller(state, getters): number {
      if (state.brandImageHeightSmallerValue < 0) {
        state.brandImageHeightSmallerValue = getters.brandImageHeight_Default;
      }
      // console.log(
      // `HEIGHT SMALLER: ${state.brandImageHeightSmallerValue}, theme: ${state.themeName}`
      // );
      return state.brandImageHeightSmallerValue;
    },
    // Brand Image Default Numbers
    // MPC
    // brandImageWidth_Default: 300,
    // brandImageHeight_Default: 155,
    // Sunbelt
    // brandImageWidth_Default: 300,
    // brandImageHeight_Default: 83,
    brandImageWidth_Default(state): number {
      let width = 300;
      if (state.themeName == "sunbelt") width = 300;
      else if (state.themeName == "ameco") width = 300;
      else if (state.themeName == "apache") width = 300;
      else if (state.themeName == "peri") width = 300;
      // console.log(`WIDTH Def: ${width}, theme: ${state.themeName}`);
      return width;
    },
    brandImageHeight_Default(state): number {
      let height = 155;
      if (state.themeName == "sunbelt") height = 83;
      else if (state.themeName == "ameco") height = 83;
      else if (state.themeName == "apache") height = 106;
      else if (state.themeName == "peri") height = 155;
      // console.log(`HEIGHT Def: ${height}, theme: ${state.themeName}`);
      return height;
    },
    // Brand Image Default Numbers < 600px
    // MPC
    // brandImageWidth_Smallest: 120,
    // brandImageHeight_Smallest: 62,
    // Sunbelt
    // brandImageWidth_Smallest: 200,
    // brandImageHeight_Smallest: 55,
    brandImageWidth_Smallest(state): number {
      if (state.themeName == "sunbelt") return 180;
      else if (state.themeName == "ameco") return 180;
      else if (state.themeName == "apache") return 200;
      else if (state.themeName == "peri") return 120;
      else return 120;
    },
    brandImageHeight_Smallest(state): number {
      if (state.themeName == "sunbelt") return 50;
      else if (state.themeName == "ameco") return 50;
      else if (state.themeName == "apache") return 71;
      else if (state.themeName == "peri") return 62;
      else return 62;
    },
    // Brand Image Default Numbers Landscape Orientation
    // MPC
    // brandImageWidth_Medium: 200,
    // brandImageHeight_Medium: 103,
    // brandImageWidth_Medium_alt: 140,
    // brandImageHeight_Medium_alt: 72,
    // Sunbelt
    // brandImageWidth_Medium: 160,
    // brandImageHeight_Medium: 44,
    // brandImageWidth_Medium_alt: 160,
    // brandImageHeight_Medium_alt: 44,
    brandImageWidth_Medium(state): number {
      if (state.themeName == "sunbelt") return 160;
      else if (state.themeName == "ameco") return 160;
      else if (state.themeName == "apache") return 160;
      else if (state.themeName == "peri") return 200;
      else return 200;
    },
    brandImageHeight_Medium(state): number {
      if (state.themeName == "sunbelt") return 44;
      else if (state.themeName == "ameco") return 44;
      else if (state.themeName == "apache") return 57;
      else if (state.themeName == "peri") return 103;
      return 103;
    },
    brandImageWidth_Medium_alt(state): number {
      if (state.themeName == "sunbelt") return 160;
      else if (state.themeName == "ameco") return 160;
      else if (state.themeName == "apache") return 160;
      else if (state.themeName == "peri") return 140;
      return 140;
    },
    brandImageHeight_Medium_alt(state): number {
      if (state.themeName == "sunbelt") return 44;
      else if (state.themeName == "ameco") return 44;
      else if (state.themeName == "apache") return 57;
      else if (state.themeName == "peri") return 72;
      return 72;
    },
    backBreadcrumb(state) {
      return state.currentBreadcrumbs[state.currentBreadcrumbs.length - 2];
    },
    currentVersion(state) {
      if (state.versionString == "0.0.0.0") {
        versionService.getVersion().then(v => (state.versionString = v));
      }
      return state.versionString;
    }
  },
  actions: {
    async SHOW_SNACKBAR(
      context,
      payload: {
        text: string;
        type: "success" | "error" | "info";
        undoCallback?: () => Promise<void>;
      }
    ) {
      // TODO: Consider collapsing the multiple commits below to a single mutation
      context.commit("SET_SNACKBAR", false);
      await pause(250);
      context.commit("CLEAR_SNACKBAR_COUNTDOWN_INTERVAL");
      context.commit("SET_SNACKBAR_COUNTDOWN", 20);
      context.commit("SET_SNACKBAR", true);
      context.commit("SET_SNACKBAR_TEXT", payload.text);
      context.commit("SET_SNACKBAR_UNDO_CALLBACK", payload.undoCallback);
      context.commit("SET_SNACKBAR_TYPE", payload.type);
    },

    // **** AREAS ****
    // This has remained in the main Vuex Store since the automatically generated files are based on a separation of
    // files based on a database table. With regards to Areas and Sub Areas they relate to the same "Project Locations"
    // database table thus they currently are not good candidates for the automatically generated Vuex Store modularization
    // pattern that we have been using for other tables.
    async LOAD_AREA_WITH_SUBAREAS(
      context,
      payload: {
        areaID: string;
        forcedArchivedState: boolean;
        archivedFromDate: Date | null;
        archivedToDate: Date | null;
      }
    ) {
      let [area, subAreas] = await Promise.all([
        projectLocationService.getByID(payload.areaID),
        projectLocationService.getByParentLocationID(
          payload.areaID,
          payload.forcedArchivedState,
          payload.archivedFromDate,
          payload.archivedToDate
        )
      ]);
      context.commit("SET_PROJECT_LOCATION", area);
      context.commit("REPLACE_LOCATIONS_FOR_PARENT_LOCATION", {
        projectLocations: subAreas,
        parentLocationID: payload.areaID
      });
    },
    async ADD_AREA(context, payload) {
      let projectLocationID = await projectLocationService.addItem(payload);
      context.commit("SET_PROJECT_LOCATION", { ...payload, id: projectLocationID });
      context.dispatch("SHOW_SNACKBAR", {
        text: i18n.t("projects.areas.snack-bar-add-message", [payload.name]),
        type: "success"
      });
    },
    async UPDATE_AREA(context, payload) {
      await projectLocationService.updateItem(payload.id, { ...payload, id: undefined });
      context.commit("SET_PROJECT_LOCATION", payload);
      context.dispatch("SHOW_SNACKBAR", {
        text: i18n.t("projects.areas.snack-bar-updated-message", [payload.name]),
        type: "success"
      });
    },
    async DELETE_AREA(context, payload) {
      let deletedItem = context.state.projectLocations.find(x => x.id == payload.id);
      if (await projectLocationService.deleteItem(payload.id)) {
        context.commit("DELETE_PROJECT_LOCATION", payload);
        context.dispatch("SHOW_SNACKBAR", {
          text: i18n.t("projects.areas.snack-bar-delete-message", [payload.name]),
          type: "info",
          undoCallback: async function() {
            context.dispatch("ADD_AREA", deletedItem);
          }
        });
        return true;
      } else {
        if (await showDisableInsteadConfirmation(payload.enabled!)) {
          await context.dispatch("UPDATE_AREA", {
            id: payload.id,
            enabled: false,
            name: payload.name
          });
        }
        return false;
      }
    },

    // **** SUB AREAS ****
    // This has remained in the main Vuex Store since the automatically generated files are based on a separation of
    // files based on a database table. With regards to Areas and Sub Areas they relate to the same "Project Locations"
    // database table thus they currently are not good candidates for the automatically generated Vuex Store modularization
    // pattern that we have been using for other tables.
    async LOAD_SUBAREA(context, payload: string) {
      let projectLocation = await projectLocationService.getByID(payload);
      context.commit("SET_PROJECT_LOCATION", projectLocation);
    },
    async ADD_SUBAREA(context, payload) {
      let projectLocationID = await projectLocationService.addItem(payload);
      context.commit("SET_PROJECT_LOCATION", { ...payload, id: projectLocationID });
      context.dispatch("SHOW_SNACKBAR", {
        text: i18n.t("projects.areas.sub-areas.snack-bar-add-message", [payload.name]),
        type: "success"
      });
    },
    async UPDATE_SUBAREA(context, payload) {
      await projectLocationService.updateItem(payload.id, { ...payload, id: undefined });
      context.commit("SET_PROJECT_LOCATION", payload);
      context.dispatch("SHOW_SNACKBAR", {
        text: i18n.t("projects.areas.sub-areas.snack-bar-updated-message", [payload.name]),
        type: "success"
      });
    },
    async DELETE_SUBAREA(context, payload) {
      let deletedItem = context.state.projectLocations.find(x => x.id == payload.id);
      await projectLocationService.deleteItem(payload.id);
      context.commit("DELETE_PROJECT_LOCATION", payload);
      context.dispatch("SHOW_SNACKBAR", {
        text: i18n.t("projects.areas.sub-areas.snack-bar-delete-message", [payload.name]),
        type: "info",
        undoCallback: async function() {
          context.dispatch("ADD_SUBAREA", deletedItem);
        }
      });
    }
  }
});

