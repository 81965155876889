var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        width: "1024px",
        "data-cy": "lemtimesheetsearchdialog"
      },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("lems.entries.dialog-title", [_vm.formattedDay])
                    )
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                directives: [
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:timesheetNumberString",
                    value: _vm.$t("timesheets.number-label"),
                    expression: "$t('timesheets.number-label')",
                    arg: "timesheetNumberString"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:ownerName",
                    value: _vm.$t("timesheets.owner-label"),
                    expression: "$t('timesheets.owner-label')",
                    arg: "ownerName"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:timesheetType",
                    value: _vm.$t("timesheets.timesheet-type-label"),
                    expression: "$t('timesheets.timesheet-type-label')",
                    arg: "timesheetType"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:entryCount",
                    value: _vm.$t("timesheets.list.entry-count-column-label"),
                    expression:
                      "$t('timesheets.list.entry-count-column-label')",
                    arg: "entryCount"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalRegularTime",
                    value: _vm.$t(
                      "timesheets.list.total-regular-time-column-label"
                    ),
                    expression:
                      "$t('timesheets.list.total-regular-time-column-label')",
                    arg: "totalRegularTime"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalOverTime.hidden",
                    value: _vm.$t(
                      "timesheets.list.total-over-time-column-label"
                    ),
                    expression:
                      "$t('timesheets.list.total-over-time-column-label')",
                    arg: "totalOverTime",
                    modifiers: { hidden: true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalPerDiemUnits",
                    value: _vm.$t(
                      "timesheets.list.total-per-diem-units-column-label"
                    ),
                    expression:
                      "$t('timesheets.list.total-per-diem-units-column-label')",
                    arg: "totalPerDiemUnits"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalDoubleTime",
                    value: _vm.$t(
                      "timesheets.list.total-double-time-column-label"
                    ),
                    expression:
                      "$t('timesheets.list.total-double-time-column-label')",
                    arg: "totalDoubleTime"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalEquipmentUnits",
                    value: _vm.$t(
                      "timesheets.list.total-equipment-units-column-label"
                    ),
                    expression:
                      "$t('timesheets.list.total-equipment-units-column-label')",
                    arg: "totalEquipmentUnits"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:isNightShift",
                    value: _vm.$t("timesheets.is-night-shift-label"),
                    expression: "$t('timesheets.is-night-shift-label')",
                    arg: "isNightShift"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:include.no-sort",
                    value: _vm.$t("common.include"),
                    expression: "$t('common.include')",
                    arg: "include",
                    modifiers: { "no-sort": true }
                  }
                ],
                staticClass: "fd-timesheets-list",
                staticStyle: {
                  "max-height": "calc(50vh - 100px)",
                  "backface-visibility": "hidden",
                  overflow: "auto"
                },
                attrs: {
                  items: _vm.allTimesheets,
                  "sort-by": "ownerName",
                  "sort-desc": true,
                  loading: _vm.processing,
                  "loading-text": _vm.$t("common.table-loading-message"),
                  "mobile-breakpoint": "0"
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.isNightShift",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("fd-checkbox", {
                          attrs: { readonly: true },
                          model: {
                            value: item.isNightShift,
                            callback: function($$v) {
                              _vm.$set(item, "isNightShift", $$v)
                            },
                            expression: "item.isNightShift"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.day",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.formattedDay) +
                            "\n        "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.timesheetType",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("timesheets.types." + item.timesheetTypeID)
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.entryCount",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("fd-value-display", {
                          attrs: {
                            value: item.entryCount,
                            "no-value-key": "timesheets.list.no-entries-value"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.totalRegularTime",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("fd-value-display", {
                          attrs: {
                            value: item.formattedTotalRegularTime,
                            "no-value-key": "timesheets.list.no-time-value"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.totalOverTime",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("fd-value-display", {
                          attrs: {
                            value: item.formattedTotalOverTime,
                            "no-value-key": "timesheets.list.no-time-value"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.totalDoubleTime",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.timesheetTypeID == 2
                          ? _c("fd-value-display", {
                              attrs: {
                                value: item.formattedTotalDoubleTime,
                                "no-value-key": "timesheets.list.no-time-value"
                              }
                            })
                          : _c("fd-value-display", {
                              attrs: {
                                "no-value-key":
                                  "timesheets.list.no-time-entry-value"
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "item.totalUnits",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("fd-value-display", {
                          attrs: {
                            value: item.formattedTotalUnits,
                            "no-value-key": "timesheets.list.no-time-value"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.totalPerDiemUnits",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.timesheetTypeID != 2
                          ? _c(
                              "span",
                              [
                                _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedTotalUnits,
                                    "no-value-key":
                                      "timesheets.list.no-time-value"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("fd-value-display", {
                              attrs: {
                                "no-value-key":
                                  "timesheets.list.no-time-entry-value"
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "item.totalEquipmentUnits",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.timesheetTypeID == 2
                          ? _c(
                              "span",
                              [
                                _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedTotalUnits,
                                    "no-value-key":
                                      "timesheets.list.no-time-value"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("fd-value-display", {
                              attrs: {
                                "no-value-key":
                                  "timesheets.list.no-time-entry-value"
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "header.include",
                    fn: function(ref) {
                      var header = ref.header
                      return [
                        _c("v-checkbox", {
                          attrs: {
                            value: _vm.allTimesheetsSelected,
                            indeterminate: _vm.someTimesheetsSelected,
                            label: _vm.$t("common." + header.value)
                          },
                          on: {
                            change: function($event) {
                              return _vm.flipAllTimesheetsSelected()
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.include",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        !item.lemID
                          ? _c("v-simple-checkbox", {
                              attrs: {
                                value: _vm.isTimesheetSelected(item),
                                disabled: _vm.processing
                              },
                              on: {
                                input: function($event) {
                                  return _vm.flipTimesheetSelected(item)
                                }
                              }
                            })
                          : _c("v-chip", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "timesheets.existing.lem-number-value",
                                    [item.associatedToLemNumber]
                                  )
                                )
                              )
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: _vm.inlineMessage.type } },
                        [
                          _c(
                            "span",
                            { staticStyle: { "white-space": "pre-line" } },
                            [_vm._v(_vm._s(_vm.inlineMessage.message))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    disabled: _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.cancelDialog }
                },
                [_vm._v(_vm._s(_vm.$t("common.close")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "add",
                    color: "primary",
                    loading: _vm.adding,
                    disabled: _vm.processing || !_vm.selectedTimesheetIDs.length
                  },
                  on: { click: _vm.addSelectedTimesheets }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.add")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }