import { VForm } from "@fd/lib/vue/types";
import { mapState, mapMutations, mapActions } from "vuex";
import i18n from "../../i18n";

import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";

const EstimateNewDialog = FDVue.extend({
  name: "fd-estimate-new-dialog",
  mixins: [dialogSupport],

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-inline-edit-dialog": () => import("@fd/lib/vue/components/InlineEditDialog.vue")
  },

  directives: {
    fdColumn: FDColumnDirective
  },

  data: () => ({
    // Variable to dictate to the appropriate controls that can consume it (like date-pickers) what language
    // locale the user has set the platform to.

    step: 1,

    // The following will control whether or not the save button shows the processing/loading indicator
    saving: false,

    // These objects are used for the view specific "Tags" filtering.
    tagsSelectedForFiltering: [],

    // These objects are used for the view specific "Suppliers" filtering.
    suppliersSelectedForFiltering: [],

    estimate: {
      estimatedBy: "",
      clientID: null as string | null,
      projectID: null,
      validFrom: null as Date | null,
      validTo: null as Date | null,
      regionID: null as string | null,
      productivity: null as number | null,
      labourRate: null as number | null
    },

    estimateAreaID: null as string | null,
    estimateSubAreaID: null as string | null,

    estimatePartCounts: new Map<string, number>(),

    part: {
      publicID: "",
      name: "",
      description: "",
      weight: "",
      supplierID: null,
      mpp: "",
      cleatingMPP: "",
      lashingMPP: "",
      carpentryMPP: "",
      otherMPP: "",
      enabled: true
    },

    rules: {
      required: (value: any) => !!value || i18n.t("common.rule-required"),
      numbersonly: (value: any) => !(value && isNaN(value)) || i18n.t("common.rule-numbers-only")
    },

    // The following is mockup data for the potential summation object for the estimate
    estimatesummation: [
      {
        name: "summation-mobilization",
        value: 41.11,
        hightlight: false
      },
      {
        name: "summation-erection",
        value: 332.97,
        hightlight: false
      },
      {
        name: "summation-dismantle",
        value: 221.98,
        hightlight: false
      },
      {
        name: "summation-demobilization",
        value: 41.11,
        hightlight: false
      },
      {
        name: "summation-hoarding",
        value: 0.0,
        hightlight: false
      },
      {
        name: "summation-modify",
        value: 49.95,
        hightlight: false
      },
      {
        name: "summation-total",
        value: 687.12,
        hightlight: true
      },
      {
        name: "summation-weight-kg",
        value: 41522.4,
        hightlight: false
      },
      {
        name: "summation-pieces",
        value: 2966,
        hightlight: false
      },
      {
        name: "summation-erection-mpp",
        value: 6.74,
        hightlight: true
      },
      {
        name: "summation-dismantle-mpp",
        value: 4.49,
        hightlight: true
      }
    ],

    mockPartsSelection: [
      {
        name: "RL.IFPL.0880.01",
        description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
        quantity: 15
      },
      {
        name: "RL.IFPL.0880.02",
        description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
        quantity: 8
      },
      {
        name: "RL.IFPL.0880.03",
        description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
        quantity: 2
      },
      {
        name: "RL.IFPL.0880.04",
        description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
        quantity: 1
      },
      {
        name: "RL.IFPL.0880.05",
        description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
        quantity: 15
      },
      {
        name: "RL.IFPL.0880.06",
        description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
        quantity: 127
      },
      {
        name: "RL.IFPL.0880.07",
        description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
        quantity: 34
      },
      {
        name: "RL.IFPL.0880.08",
        description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
        quantity: 19
      },
      {
        name: "RL.IFPL.0880.09",
        description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
        quantity: 3
      },
      {
        name: "RL.IFPL.0880.10",
        description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
        quantity: 227
      },
      {
        name: "RL.IFPL.0880.11",
        description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
        quantity: 16
      },
      {
        name: "RL.IFPL.0880.12",
        description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
        quantity: 29
      }
    ],

    // Datatable related objects
    expanded: [],

    erroredParts: [
      {
        name: "RL.ICPF.0736.01",
        quantity: 15,
        ignored: false,
        errormessage: null
      },
      {
        name: "RL.ICPF.0990.01",
        quantity: 7,
        ignored: false,
        errormessage: null
      },
      {
        name: "RL.ICPF.1286.01",
        quantity: 10,
        ignored: false,
        errormessage: "ID needs to be filled in."
      },
      {
        name: "RL.ICPF.1570.01",
        quantity: 16,
        ignored: false,
        errormessage: null
      },
      {
        name: "RL.ICPF.1577.02",
        quantity: 8,
        ignored: false,
        errormessage: null
      }
    ]
  }),

  computed: {
    modifiers() {
      return this.$store.state.modifiers.fullList;
    },
    availableSuppliers() {
      return this.$store.getters.sortedEnabledSuppliers;
    },
    availableTags() {
      return this.$store.getters.sortedEnabledTags;
    },
    unaddressedPartErrorsExist() {
      return !!this.erroredParts.length;
    },
    allPartErrorsIgnored() {
      return true;
    },
    allPartErrorsResolved() {
      return true;
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      (this as any).saveDialog();
    },

    mockSavePartToCatalog(item: any) {
      this.erroredParts = this.erroredParts.filter(x => x != item);
    },

    ignorePart(item: any): void {
      this.erroredParts.find(x => x == item)!.ignored = true;

      if (this.erroredParts.findIndex(x => x.ignored == false) > 0) {
        // This means there is still a part that is not part of the catalog AND it has NOT been IGNORED.
        (this as any).allErroredPartsThatRemainHaveBeenIgnored = false;
      } else {
        (this as any).allErroredPartsThatRemainHaveBeenIgnored = true;
      }
    },

    // This function is used to "Cancel" the adding of a new item to the parts catalog. Essentially it just
    // closes the "expanded" item screen for the row in question. This screen only allows one row to be open
    // so this function could have just emptied out the entire array, but instead it has been coded so that it
    // could work with a different table that allows multiple expanded rows.
    cancelItemAdd(item: any): void {
      this.expanded = this.expanded.filter(x => x != item);
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog(): void {
      this.closeDialog(false);
    },

    // method stub for potentially dealing with saving quantities inline in the table.
    async saveQuantity(item: any, fieldName: any, value: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        //await this.updatePart({ id: item.id, [fieldName]: value, name: item.name });
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as VForm).validate()) {
        return;
      }

      this.processing = true;
      try {
        await (this as any).addEstimate({
          ...this.estimate,
          projectLocationID: this.estimateSubAreaID || this.estimateAreaID
        });
        this.closeDialog!(true);
      } catch (error) {
        this.inlineMessage.message = this.$t("estimates.save-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addEstimate: "ADD_ESTIMATE",
      loadModifiers: "LOAD_MODIFIERS",
      loadTags: "LOAD_TAGS"
    }),

    async initialize() {
      this.processing = true;

      try {
        await Promise.all([(this as any).loadTags(), (this as any).loadModifiers()]);
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    }
  },

  created() {
    (this as any).initialize();
  }
});

export default EstimateNewDialog;

export async function createNewEstimate(): Promise<boolean> {
  let dialog = createDialog(EstimateNewDialog);
  return await dialog.showDialog!();
}

