import {
  LEM,
  LEMEntry,
  LEMEntryWithDetails,
  LemStatus,
  LEMStatusLogWithDetails,
  LEMWithDetails,
  PersonWithDetails,
  SummarizedLEMPermissions,
  TimesheetWithDetails,
  WorkSubType
} from "../services";

type LEMWithEntries = LEMWithDetails & {
  entries: UpdatableLEMEntryWithDetails[];
  removedEntryIDs: string[];
  isLocked: boolean;
};
export class UpdatableLEMWithEntries implements LEMWithEntries {
  importedTimesheets: Array<TimesheetWithDetails>;
  id = undefined as string | undefined;
  legacyID = undefined as number | null | undefined;
  environmentID = undefined as string | null | undefined;
  contractorID = undefined as string | undefined;
  day = undefined as Date | undefined;
  lemStatusID = undefined as LemStatus | undefined;
  submittedBy: string | null | undefined;
  submittedOn: Date | null | undefined;
  reviewDeclineReason: string | undefined;
  reviewCancelReason: string | undefined;
  reviewStatusChangeTime: Date | undefined;
  reviewApprovalIsAutomatic: boolean | undefined;
  reviewApprovedBy: string | null | undefined;

  created = undefined as Date | undefined;
  createdBy = undefined as string | null | undefined;
  updated = undefined as Date | null | undefined;
  updatedBy = undefined as string | null | undefined;
  archivedDate = undefined as Date | null | undefined;

  contractorName = "" as string;
  submittedByName: string;
  reviewApprovedByName: string;

  entryCount = null as number | null;
  totalRegularTime = null as number | null;
  totalOverTime = null as number | null;
  totalDoubleTime = null as number | null;
  totalUnits = null as number | null;

  entries = [] as UpdatableLEMEntryWithDetails[];
  removedEntryIDs = [] as string[];

  statusLogs = [] as LEMStatusLogWithDetails[];
  lastStatusLog = {} as LEMStatusLogWithDetails;

  currentUserPermissions = {} as SummarizedLEMPermissions;

  constructor(
    lem: LEMWithDetails,
    entries?: UpdatableLEMEntryWithDetails[],
    removedEntryIDs?: string[]
  ) {
    this.id = lem.id;
    this.legacyID = lem.legacyID;
    this.environmentID = lem.environmentID;
    this.contractorID = lem.contractorID;
    this.day = lem.day;
    this.importedTimesheets = lem.importedTimesheets;

    this.lemStatusID = lem.lemStatusID;
    this.submittedBy = lem.submittedBy;
    this.submittedOn = lem.submittedOn;
    this.reviewDeclineReason = lem.reviewDeclineReason;
    this.reviewCancelReason = lem.reviewCancelReason;
    this.reviewStatusChangeTime = lem.reviewStatusChangeTime;
    this.reviewApprovalIsAutomatic = lem.reviewApprovalIsAutomatic;
    this.reviewApprovedBy = lem.reviewApprovedBy;

    this.created = lem.created;
    this.createdBy = lem.createdBy;
    this.updated = lem.updated;
    this.updatedBy = lem.updatedBy;
    this.archivedDate = lem.archivedDate;

    this.contractorName = lem.contractorName;
    this.submittedByName = lem.submittedByName;
    this.reviewApprovedByName = lem.reviewApprovedByName;

    this.entryCount = lem.entryCount;
    this.totalRegularTime = lem.totalRegularTime;
    this.totalOverTime = lem.totalOverTime;
    this.totalDoubleTime = lem.totalDoubleTime;
    this.totalUnits = lem.totalUnits;

    this.statusLogs = lem.statusLogs;
    this.lastStatusLog = lem.lastStatusLog;

    this.currentUserPermissions = lem.currentUserPermissions;

    this.entries = entries ?? [];
    this.removedEntryIDs = removedEntryIDs ?? [];
  }
  get isLocked() {
    return (
      this.lemStatusID == LemStatus.Approved ||
      this.lemStatusID == LemStatus.Cancelled ||
      this.lemStatusID == LemStatus.Submitted
    );
  }
  get isNew() {
    return !this.id?.length;
  }
  get isModified() {
    return false;
  }
  get hasRemovedEntries() {
    return this.removedEntryIDs.length > 0;
  }
  get hasModifiedEntries() {
    return this.entries.findIndex(x => x.isDirty) !== -1;
  }
  get modifiedExistingEntryData() {
    return this.entries
      .filter(x => x.isDirty)
      .map(
        x =>
          ({
            ...x.modifiedData,
            id: x.id,
            legacyID: x.legacyID,
            lemID: x.lemID
          } as LEMEntryWithDetails)
      );
  }
  get isDirty() {
    return this.isNew || this.isModified || this.hasRemovedEntries || this.hasModifiedEntries;
  }
}
export class UpdatableLEMEntryWithDetails implements LEMEntryWithDetails {
  employeeName: string;
  timesheetNumber: number | null;
  timesheetOwnerName: string;
  workOrderNumber = "" as string;
  scaffoldNumber = "" as string;
  areaName = "" as string;
  subAreaName = "" as string;
  workTypeName = "" as string;
  workSubTypeName = "" as string;
  classificationName = "" as string;
  costCodeName = "" as string;
  id = undefined as string | undefined;
  legacyID = undefined as number | null | undefined;
  lemID = undefined as string | undefined;
  workOrderID = undefined as string | null | undefined;
  sourceTimesheetID: string | null | undefined;
  isCorrectionEntry: boolean | undefined;
  scaffoldID = undefined as string | null | undefined;
  employeeID = undefined as string | undefined;
  areaID = undefined as string | null | undefined;
  subAreaID = undefined as string | null | undefined;
  workTypeID = undefined as string | null | undefined;
  workSubTypeID = undefined as string | null | undefined;
  classificationID = undefined as string | null | undefined;
  costCodeID = undefined as string | null | undefined;
  regularTime = undefined as number | null | undefined;
  overTime = undefined as number | null | undefined;
  doubleTime = undefined as number | null | undefined;
  units = undefined as number | null | undefined;
  created = undefined as Date | undefined;
  createdBy = undefined as string | null | undefined;
  updated = undefined as Date | null | undefined;
  updatedBy = undefined as string | null | undefined;
  archivedDate = undefined as Date | null | undefined;

  originalRegularTime = undefined as number | null | undefined;
  originalOverTime = undefined as number | null | undefined;
  originalDoubleTime = undefined as number | null | undefined;
  originalUnits = undefined as number | null | undefined;

  constructor(entry: LEMEntryWithDetails) {
    this.employeeName = entry.employeeName;
    this.timesheetNumber = entry.timesheetNumber;
    this.timesheetOwnerName = entry.timesheetOwnerName;
    this.workOrderNumber = entry.workOrderNumber;
    this.scaffoldNumber = entry.scaffoldNumber;
    this.areaName = entry.areaName;
    this.subAreaName = entry.subAreaName;
    this.workTypeName = entry.workTypeName;
    this.workSubTypeName = entry.workSubTypeName;
    this.classificationName = entry.classificationName;
    this.costCodeName = entry.costCodeName;
    this.id = entry.id;
    this.legacyID = entry.legacyID;
    this.lemID = entry.lemID;
    this.workOrderID = entry.workOrderID;
    this.sourceTimesheetID = entry.sourceTimesheetID;
    this.isCorrectionEntry = entry.isCorrectionEntry;
    this.scaffoldID = entry.scaffoldID;
    this.employeeID = entry.employeeID;
    this.areaID = entry.areaID;
    this.subAreaID = entry.subAreaID;
    this.workTypeID = entry.workTypeID;
    this.workSubTypeID = entry.workSubTypeID;
    this.classificationID = entry.classificationID;
    this.costCodeID = entry.costCodeID;
    this.regularTime = entry.regularTime;
    this.overTime = entry.overTime;
    this.doubleTime = entry.doubleTime;
    this.units = entry.units;
    this.created = entry.created;
    this.createdBy = entry.createdBy;
    this.updated = entry.updated;
    this.updatedBy = entry.updatedBy;
    this.archivedDate = entry.archivedDate;

    this.originalRegularTime = entry.regularTime;
    this.originalOverTime = entry.overTime;
    this.originalDoubleTime = entry.doubleTime;
    this.originalUnits = entry.units;
  }
  get timesheetNumberAndOwnerName() {
    return `${this.timesheetNumber}_${this.timesheetOwnerName}`;
  }
  sanitizeNumericValue(val: number | string | undefined | null): number | null {
    return !!val ? +val : null;
  }
  get regularTimeModified() {
    return (
      this.originalRegularTime != this.regularTime ||
      (this.isNew && !!this.regularTime && this.regularTime != 0)
    );
  }
  get overTimeModified() {
    return (
      this.originalOverTime != this.overTime ||
      (this.isNew && !!this.overTime && this.overTime != 0)
    );
  }
  get doubleTimeModified() {
    return (
      this.originalDoubleTime != this.doubleTime ||
      (this.isNew && !!this.doubleTime && this.doubleTime != 0)
    );
  }
  get unitsModified() {
    return this.originalUnits != this.units || (this.isNew && !!this.units && this.units != 0);
  }
  get isNew() {
    return !this.id || this.id.startsWith("new");
  }
  get isDirty() {
    return (
      this.regularTimeModified ||
      this.overTimeModified ||
      this.doubleTimeModified ||
      this.unitsModified
    );
  }
  get modifiedData() {
    var regularTime = this.regularTimeModified
      ? this.sanitizeNumericValue(this.regularTime)
      : undefined;
    var overTime = this.overTimeModified ? this.sanitizeNumericValue(this.overTime) : undefined;
    var doubleTime = this.doubleTimeModified
      ? this.sanitizeNumericValue(this.doubleTime)
      : undefined;
    var units = this.unitsModified ? this.sanitizeNumericValue(this.units) : undefined;
    return {
      regularTime: regularTime,
      overTime: overTime,
      doubleTime: doubleTime,
      units: units
    };
  }
  get formattedRegularTime() {
    return !!this.regularTime && this.regularTime != 0 ? this.regularTime.toFixed(2) : undefined;
  }
  get formattedOverTime() {
    return !!this.overTime && this.overTime != 0 ? this.overTime.toFixed(2) : undefined;
  }
  get formattedDoubleTime() {
    return !!this.doubleTime && this.doubleTime != 0 ? this.doubleTime.toFixed(2) : undefined;
  }
  get formattedUnits() {
    return !!this.units && this.units != 0 ? this.units.toFixed(2) : undefined;
  }
}

