import FDVue from "@fd/lib/vue";
import { Part } from "../../../services";

export default FDVue.extend({
  name: "sp-estimate-general-settings-form",
  props: {
    // *** BASE GEAR ***
    collarPartID: { type: String, default: undefined },
    mudsillPartID: { type: String, default: undefined },
    screwJackPartID: { type: String, default: undefined },
    ladderGatePartID: { type: String, default: undefined },
    clampPartID: { type: String, default: undefined },
    crazyLegPartID: { type: String, default: undefined },
    davitArmPartID: { type: String, default: undefined },

    // *** STANDARDS ***
    standard3_0mPartID: { type: String, default: undefined },
    standard2_5mPartID: { type: String, default: undefined },
    standard2_0mPartID: { type: String, default: undefined },
    standard1_5mPartID: { type: String, default: undefined },
    standard1_0mPartID: { type: String, default: undefined },
    standard0_5mPartID: { type: String, default: undefined },

    // *** ACCESS ***
    starterBracketPartID: { type: String, default: undefined },
    ladder10ftPartID: { type: String, default: undefined },
    ladder06ftPartID: { type: String, default: undefined },
    ladder05ftPartID: { type: String, default: undefined },
    ladder03ftPartID: { type: String, default: undefined },
    yoyo100ftPartID: { type: String, default: undefined },
    yoyo050ftPartID: { type: String, default: undefined },
    yoyo030ftPartID: { type: String, default: undefined },
    yoyo020ftPartID: { type: String, default: undefined },

    // *** STEEL TUBES ***
    steelTube13ftPartID: { type: String, default: undefined },
    steelTube10ftPartID: { type: String, default: undefined },
    steelTube08ftPartID: { type: String, default: undefined },
    steelTube06ftPartID: { type: String, default: undefined },
    steelTube04ftPartID: { type: String, default: undefined }
  },
  components: {
    "fd-subheader": () => import("@fd/lib/vue/components/layout/Subheader.vue"),
    "fd-part-selection-field": () => import("../SP.PartSelectionField.vue")
  },

  computed: {
    parts(): Part[] {
      return (this.$store.state.parts.fullList as Part[]).map(x => ({
        ...x,
        searchableData: `${x.publicID} ${x.name} ${x.description}`
      }));
    }
  }
});

